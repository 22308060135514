import styled from "styled-components";

import type { BoxProps, IconProps, TypographyProps } from "@hotelengine/atlas-web";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";

export const MobileSearchHeaderFields = styled.button`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border: ${({ theme }) => `${theme.borderWidth.wide} solid ${theme.colors.borderMinimal}`};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    display: flex;
    gap: ${({ theme }) => theme.spacing[12]};
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) =>
      `${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[8]} ${theme.spacing[16]}`};
    min-width: 309px;
    width: 100%;
    
    &:hover {
        cursor: pointer;
    }
`;

export const SearchIcon = styled<React.FC<IconProps>>(Icon)`
    min-width: ${({ theme }) => theme.spacing[16]};
`;

export const MobileSearchLocation = styled<React.FC<TypographyProps>>(Typography)`
    display: inline-block;
    margin: 0;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const MobileSearchDetails = styled<React.FC<TypographyProps>>(Typography)`
    display: flex;
    margin: 0;
`;

export const ScreenSheetWrapper = styled<React.FC<BoxProps>>(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
  height: 100%;
`;

export const LocationInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing[24]};

  button {
    border-radius: ${({ theme }) => theme.borderRadius[24]};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  &:last-child div {
    border-radius: ${({ theme }) => theme.borderRadius[24]};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
