export const SINGLE_ROOM_SUCCESS_TITLE = "Trip successfully cancelled";
export const MULTI_ONE_ROOM_SUCCESS_TITLE = "Room successfully cancelled";
export const MULTI_ROOM_SUCCESS_TITLE = "Rooms successfully cancelled";
export const CANCELLATION_SUCCESS_SUBTITLE =
  "Guests will receive a notification email and their itinerary will be updated.";

export const CANCELLATION_REFUND_TITLE = "Refund automatically applied";
export const FLIGHT_REFUND_TITLE = "Refund applied";
export const CAR_REFUND_TITLE = "Refund applied";
export const REFUND_INFO_TRAVEL_CREDIT =
  "Due to your cancellation, we automatically refunded the value of the unused nights to your account. This travel credit can be used for any trip for up to one year from issue date.";
export const REFUND_INFO_ORIGINAL =
  "Due to your cancellation, we automatically refunded the value of the unused nights to your original form(s) of payment.";

export const FLIGHT_SUCCESS_TITLE = "Cancellation confirmed";
export const FLIGHT_SUCCESS_SUBTITLE =
  "Passengers will receive a notification email and their itinerary will be updated.";
export const FLIGHT_REQUEST_SUCCESS_TITLE = "Request confirmed";
export const FLIGHT_REQUEST_SUCCESS_SUBTITLE =
  "Member support will reach out to you within 24 hours.";

export const CAR_SUCCESS_TITLE = "Cancellation confirmed";
export const CAR_SUCCESS_SUBTITLE =
  "Travelers will receive a notification email and their itinerary will be updated.";

export const FLIGHT_REFUND_ORIGINAL =
  "Due to your cancellation, we automatically refunded the value of the flight to your original payment method.";
export const FLIGHT_REFUND_ORIGINAL_MINUS_BOOKING_FEE =
  "Due to your cancellation, we've refunded your reservation amount to your original payment method minus the booking fee as per the cancellation policy.";
export const FLIGHT_REFUND_CREDIT =
  "Due to your cancellation, the airline automatically refunded the value of the flight to the travelers as airline credit.";
export const FLIGHT_REFUND_UNKNOWN = (supportPhone: string) =>
  `The final refund amount will be reflected on your payment method's billing statement.\nFor an estimated refund quote, please contact Engine support at ${supportPhone}.`;
