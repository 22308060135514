import styled from "styled-components";

import InputField from "@hotel-engine/app/EditPaymentProfileForm/components/InputField";
import { colors } from "@hotel-engine/styles";

export const CardholderName = styled(InputField)`
  margin-bottom: 16px;
`;

export const FromSection = styled.div`
  margin: 0;

  &.ant-form,
  .ant-form-item-label {
    line-height: ${({ theme }) => theme.legacy.lineHeight.sm};

    label {
      margin-bottom: 4px;
      display: block;
    }
  }

  .ant-form-explain {
    font-weight: 400;
  }

  .has-error {
    .ant-input {
      border-color: ${colors.red[400]};
    }
    .ant-form-explain {
      ${({ theme }) => theme.typography.desktop["body/xs"]};
      color: ${colors.red[400]};
    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  width: 100%;
`;

export const ErrorContainer = styled.div`
  background: ${colors.voraciousWhite};
  border: 1px solid ${colors.red[400]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  color: ${colors.red[400]};
  margin-bottom: 15px;
  margin-left: 32px;
  margin-right: 24px;
  padding: 12px;

  > div {
    margin-bottom: 8px;
    margin-left: 2px;
  }

  span {
    margin-left: 5px;
  }

  ul {
    list-style: circle;
    margin: 0;
    padding-left: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 0;

  button {
    &:last-of-type {
      margin-left: 0;
    }

    &:first-of-type {
      margin-right: 16px;
    }
  }
`;
