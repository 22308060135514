import type { ObjectLike } from "./mergeObjects";

/**
 * Builds an array of query parameter strings based on the prefix and value.
 *
 * @param prefix - The prefix for the query parameter key.
 * @param value - The value to serialize.
 * @returns An array of serialized query parameter strings.
 */
function buildQueryParams(prefix: string, value: unknown): string[] {
  if (value === null || value === undefined) return [];

  if (typeof value === "object" && !Array.isArray(value)) {
    return Object.entries(value as ObjectLike).flatMap(([key, val]) =>
      buildQueryParams(`${prefix}[${key}]`, val)
    );
  }

  if (Array.isArray(value)) {
    return value.flatMap((item, index) => {
      if (typeof item === "object" && item !== null) {
        return buildQueryParams(`${prefix}[${index}]`, item);
      }

      return [`${prefix}[]=${encodeURIComponent(String(item))}`];
    });
  }

  return [`${prefix}=${encodeURIComponent(String(value))}`];
}

/**
 * Serializes an object into a query string with nested parameters using bracket notation.
 *
 * @param params - The object to serialize.
 * @returns A query string representing the serialized object.
 */
function serializeQueryParams<T extends ObjectLike = ObjectLike>(params: T): string {
  if (Object.keys(params).length === 0) return "";

  return Object.entries(params)
    .flatMap(([key, value]) => buildQueryParams(key, value))
    .join("&");
}

export default serializeQueryParams;
