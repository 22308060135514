import { useEffect, useState } from "react";
import { LocalStorageCache } from "@auth0/auth0-react";
import config from "config";
import { routes } from "@hotel-engine/constants";
import { useGetAccessTokenSilently } from "./getToken";

import type { IUser } from "@hotel-engine/types/user";
import type {
  Auth0ContextInterface,
  AuthorizationParams,
  RedirectLoginOptions,
} from "@auth0/auth0-react";
import { type NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import { captureMessage } from "@hotel-engine/utilities/logger";

export const DEFAULT_SCOPES = "openid profile email offline_access";

export const AUTH0_USERS_CONNECTION_NAME = "Nexus-Username-Password-Database";

/**
 *
 * @param token
 * @returns true if token is a json stringified that contains
 * the latestAccountType field
 */
export function isAccessTokenInJson(token: string | null) {
  try {
    const parsed = JSON.parse(token || "");
    return "latestAccountType" in parsed;
  } catch (error) {
    return false;
  }
}

export type AccessTokenStored = {
  business?: string;
  personal?: string;
  unknown?: string;
  latestAccountType: "business" | "personal" | "unknown";
};

export const isAddingNewTokenToExistingJson = (
  currentToken: string | null,
  token?: string,
  userAccountType?: IUser["accountType"]
) => {
  return !!token && !!currentToken && isAccessTokenInJson(currentToken) && !!userAccountType;
};

export const isUpdatingTokenFormatToJSON = (
  currentToken: string | null,
  userAccountType?: IUser["accountType"]
) => {
  return !!currentToken && !isAccessTokenInJson(currentToken) && !!userAccountType;
};

export const isUpdatingAccountTypeFromUnknown = (currentToken: string | null, token?: string) => {
  return (
    !token &&
    !!currentToken &&
    isAccessTokenInJson(currentToken) &&
    (JSON.parse(currentToken) as AccessTokenStored).latestAccountType === "unknown"
  );
};

export const isUpdatingAccountTypeValueInJSON = (currentToken: string | null, token?: string) => {
  return !token && !!currentToken && isAccessTokenInJson(currentToken);
};

/**
 * This function is used to determine if the user has an auth0 access token in the local storage cache
 * @returns true if there is an auth0 access token in the cache
 */
export const hasAuth0AccessToken = (audience = config.auth0Audience) => {
  const cache = new LocalStorageCache();
  const keys = cache.allKeys();
  return keys.find((key) => key.includes(audience));
};

/**
 * This function is used to remove all auth0 access tokens from the local storage cache
 */
export const removeAuth0Cache = (audience = config.auth0Audience) => {
  const cache = new LocalStorageCache();
  const keys = cache.allKeys();
  keys.forEach((key) => {
    if (key.includes(audience)) {
      cache.remove(key);
    }
  });
};

export const signUpWithGoogle = async (
  loginWithRedirect: Auth0ContextInterface["loginWithRedirect"],
  referralToken?: string,
  currentUserId?: number
) => {
  const returnTo = encodeURIComponent(`${globalThis.location.origin}${routes.join.finish}`);
  const redirectPath = `${globalThis.location.origin}/?return=${returnTo}`;
  await loginWithRedirect({
    authorizationParams: {
      connection: "Google-OAuth2-Connection",
      redirect_uri: redirectPath,
      prompt: "login",
      intent: "create",
      referralToken,
      currentUserId,
    },
  });
};

/**
 * This react hook is used to verify our auth0 token outside of axios.
 * If our token is invalid, we can either:
 *   a) get a new access token by using a refresh token
 *.  b) redirect the user to the login page to get a new set of access and refresh tokens
 
 * This hook should be used in any hook that requires a bearer token to Nexus or Auth0.
 * @returns loading - true if the token is being verified and false if the token is done being verified
 */
export const useVerifyToken = (authorizationParams: AuthorizationParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(false);
  const getAccessTokenSilently = useGetAccessTokenSilently();

  useEffect(() => {
    const verifyToken = async () => {
      const token = await getAccessTokenSilently(undefined, {
        authorizationParams,
      });
      if (token) {
        setHasValidToken(true);
      }
      setIsLoading(false);
    };

    void verifyToken();
  }, [authorizationParams, getAccessTokenSilently]);

  return { isLoading, hasValidToken };
};

/**
 * This function is used to selectively delete Auth0 access tokens in this way:
 * 1. Check if an Auth0 access token exists in the local storage cache with the specified
 * audience and scope
 * 2. If so, then remove all Auth0 access tokens in the local storage cache that have the same
 * audience but NOT the scope.
 * That way we preserve the most up to date Auth0 access token.
 *
 * Currently, this is used when a user completes a step up MFA challenge and thus has
 * two access tokens with the same audience in the local storage cache - a new one one with the
 * newly provisioned scope(s), and the old one without. In that case, we clear the old token.
 */
export const cleanAccessTokensWithoutScope = (
  scope: string,
  audience: string = config.auth0Audience
) => {
  const cache = new LocalStorageCache();
  const keys = cache.allKeys();
  const keyWithScope = keys.some((key) => key.includes(scope));
  if (keyWithScope) {
    keys.forEach((key) => {
      if (key.includes(audience) && !key.includes(scope)) {
        cache.remove(key);
      }
    });
  }
};

/**
 * This function is used to issue a step up MFA challenge to the user via Auth0.
 * A redirect uri is taken in as a param, which is required because generating the
 * current url within this function and calling it elsewhere can cause the redirect uri
 * to just resolve to the homepage.
 * @param loginWithRedirect - the loginWithRedirect method from Auth0
 * @param redirectUri - where to redirect the user to
 * @param missingScopes - the scopes that the backend needs
 * @param user - the current user
 */
export const stepUpAuthenticate = async (
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>,
  redirectUri: string,
  missingScopes: string[],
  user: IUser | null
) => {
  const formattedStepUpScopes = missingScopes?.join(" ");
  await loginWithRedirect({
    authorizationParams: {
      redirect_uri: redirectUri,
      scope: `${DEFAULT_SCOPES} ${formattedStepUpScopes}`,
      login_hint: user?.email,
    },
  });
  return null;
};

export interface IAuth0ReturnParams {
  return?: string;
  error?: string;
  error_description?: string;
}

// If a `return` parameter is in the search parameters of the current page,
// and it is a valid URL for this instance of Members
// redirect to it, and include any `error` or `error_description` search parameters as well.
export const handleAuth0Return = (navigate: NavigateFunction, params: IAuth0ReturnParams) => {
  if (params.return) {
    try {
      const url = new URL(params.return);
      if (url.host === globalThis.location.host) {
        let navigatePath = url.pathname;

        if (params.error && params.error_description) {
          url.searchParams.set("error", params.error);
          url.searchParams.set("error_description", params.error_description);
          navigatePath = `${navigatePath}?${url.searchParams}`;
        }

        navigate(navigatePath);
      }
    } catch (error) {
      captureMessage("Exception when handling Auth0 error callback.", { error });
    }
  }
};
