import { useCallback, useEffect } from "react";
import ItineraryContent from "@hotel-engine/app/ItineraryContent";
import { useItineraryQuery } from "@hotel-engine/react-query/itinerary/useItineraryQuery";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";

import * as Styled from "../styles";
import type { ITripPreview } from "../../../contextLegacy";
import { useTripsLegacyContext } from "../../../contextLegacy";
import { useRevalidator } from "@hotel-engine/lib/react-router-dom";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { TRIPS_FEATURE_FLAG } from "pages/Trips/data/loader";

export interface IFlightItineraryWithId extends IFlightItinerary {
  tripId: number;
  bookingNumber: string;
}

export interface ITripsItineraryPreviewPanelProps {
  itineraryId: string | number;
  onClose: (tripPreview: ITripPreview) => void;
}

const FlightItineraryPanel = ({ onClose, itineraryId }: ITripsItineraryPreviewPanelProps) => {
  const isTripsFilteringEnabled = useIsFeatureFlagOn(TRIPS_FEATURE_FLAG);
  const { tripsRefresh } = useTripsLegacyContext();
  const { revalidate } = useRevalidator();

  const { data, isError, isLoading } = useItineraryQuery({
    id: Number(itineraryId),
  });

  const tripItinerary = {
    tripId: itineraryId,
    bookingNumber: data?.bookings[0].bookingNumber,
    ...data?.bookings?.[0]?.details,
  } as IFlightItineraryWithId;

  const handleScroll = useCallback(() => {
    const panel = document.getElementById("preview-panel");

    if (!!panel) {
      panel.style.height = `calc(100vh - 90px + ${`${globalThis.scrollY}px`})`;
    }
  }, []);

  const handleRefresh = () => {
    tripsRefresh();

    if (isTripsFilteringEnabled) {
      revalidate();
    }
  };

  useEffect(() => {
    globalThis.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Styled.TripsPreviewPanel id="preview-panel" data-testid="preview-panel" $isFlight>
      {!!tripItinerary && !isError && (
        <Styled.ScrollContainer
          // This id is needed for post-modification scroll to top
          id="itinerary-scroll-container"
        >
          <ItineraryContent
            isLoading={isLoading}
            itinerary={tripItinerary}
            isPreview={true}
            onClose={onClose}
            refetchReservation={handleRefresh}
            bookingType="flight"
          />
        </Styled.ScrollContainer>
      )}
    </Styled.TripsPreviewPanel>
  );
};

export default FlightItineraryPanel;
