import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import { Avatar, Typography } from "@hotelengine/atlas-web";
import { SelectOption } from "@hotel-engine/common/Select";
import Tag from "@hotel-engine/common/Tag";
import { colors } from "@hotel-engine/styles";
import type { IOccupant, IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";
import { getNameOrEmail } from "@hotel-engine/utilities";

import * as Styled from "../styles";

export const buildGuestOption = ({
  propertyLoyaltyRewards,
  roomIsLoyaltyEligible,
  suggestedOccupant,
}: {
  propertyLoyaltyRewards: IPropertyLoyaltyRewards;
  roomIsLoyaltyEligible: boolean;
  suggestedOccupant: IOccupant;
}) => {
  const { id, firstName, lastName, email, employeeId } = suggestedOccupant;
  const matchingLoyaltyReward =
    roomIsLoyaltyEligible &&
    suggestedOccupant["guestLoyaltyRewards"]?.find(
      (reward) => reward.loyaltyRewardId === propertyLoyaltyRewards.id
    );

  const renderGuestInfo = () => (
    <Styled.GuestInfo adjacentElementSize="small">
      <Styled.GuestInfoRow>
        <Typography
          variant="heading/sm"
          as={Styled.OptionTypography}
          margin={0}
          marginRight={4}
          marginLeft={8}
        >
          {firstName} {lastName}
        </Typography>
        {!!matchingLoyaltyReward && (
          <span data-testid="guest-dropdown-loyalty-badge">
            <LoyaltyBadge
              fill={colors.black}
              style={{
                margin: "0 4px",
                verticalAlign: "middle",
                width: "12px",
                height: "12px",
              }}
            />
          </span>
        )}
        <Tag>Personal Guest</Tag>
      </Styled.GuestInfoRow>
      <Styled.GuestInfoRow>
        <Typography
          variant="body/xs"
          as={Styled.OptionTypography}
          margin={0}
          marginRight={4}
          marginLeft={8}
          color="accentGray"
          data-private
        >
          {email} {employeeId ? `(EID: ${employeeId})` : null}
        </Typography>
      </Styled.GuestInfoRow>
    </Styled.GuestInfo>
  );

  return (
    <SelectOption
      key={`guest-${id}`}
      value={JSON.stringify(suggestedOccupant)}
      data-testid="guest-select-option"
    >
      <Styled.SearchGuestOption>
        <Avatar size={32} name={getNameOrEmail(suggestedOccupant) ?? ""} variant="outlined" />
        {renderGuestInfo()}
      </Styled.SearchGuestOption>
    </SelectOption>
  );
};
