import { useNavigation } from "@hotel-engine/lib/react-router-dom";
import TripsError from "../utils/TripsError";

import type { ITripsResult } from "@hotel-engine/types/trips";
import { useMemo } from "react";
import { useTrips } from "./useTrips";

export interface ITripsResponse {
  isLoading: boolean;
  data: ITripsResult | null;
  error: TripsError | null;
}

/**
 * Retrieves trips route resolved data.
 *
 * @returns {ITripsResponse} The trips result.
 */
function useTripsResult(): ITripsResponse {
  const data = useTrips();
  const navigation = useNavigation();

  return useMemo(() => {
    const error = data.error instanceof TripsError ? data.error : null;
    // navigation state doesn't indicate loading on initial page load,
    // so we also check for idle state without result data
    const isLoading = navigation.state === "loading" || (navigation.state === "idle" && !data);

    return { data: !error ? data : null, isLoading, error } as ITripsResponse;
  }, [data, navigation.state]);
}

export default useTripsResult;
