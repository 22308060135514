import type { ChangeEvent } from "react";
import { IconButton, InputGroup, TextInput } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import { useFormikContext } from "formik";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import useSearchForTrips from "./hooks/useSearchForTrips";

export interface ITripsSearch {
  search: string;
}

export const tripsSearchInitialValues: ITripsSearch = {
  search: "",
};

const SearchForm = ({ isSmallMobileScreen }: { isSmallMobileScreen: boolean }) => {
  const { values, setFieldValue, handleSubmit, errors } = useFormikContext<ITripsSearch>();

  const { state: tripsState } = useTripsLegacyContext();
  const disableSearch = !!tripsState.error || !!tripsState.loading;
  const validationMessage = tripsState.validationError;

  const { getUpdatedQuery } = useSearchForTrips();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const sanitizedQuery = getUpdatedQuery(e.target.value);
    setFieldValue("search", sanitizedQuery);
  };

  return (
    <Styled.SearchInputFormControl
      label=""
      status={validationMessage && !errors.search ? "error" : "default"}
      helperText={errors.search}
      errorText={validationMessage || ""}
    >
      <InputGroup>
        <TextInput
          isClearable
          id="searchTrips"
          aria-label="Search trips"
          placeholder="Search trips"
          size="sm"
          value={values.search}
          isOnColor={!isSmallMobileScreen}
          isDisabled={disableSearch}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        />
        <IconButton
          icon="magnifying-glass"
          aria-label="Submit search"
          type="submit"
          color="everdark"
          size="sm"
          isLoading={tripsState.loading}
          isDisabled={values.search.length < 2 || disableSearch}
          onClick={() => handleSubmit()}
        />
      </InputGroup>
    </Styled.SearchInputFormControl>
  );
};

export default SearchForm;
