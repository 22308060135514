import styled from "styled-components";

export const StickyContainer = styled.div<{ $isPreview: boolean }>`
position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  margin-top: ${({ theme }) => theme.spacing[16]};
  /* Disable theming, needed to counter container padding so we don't to make major adjustments to component data flow */
  margin-left: ${({ $isPreview }) => `${$isPreview ? -24 : -16}px`};
  margin-right: ${({ $isPreview }) => `${$isPreview ? -24 : -16}px`};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  padding-bottom: ${({ theme }) => theme.spacing[24]};
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  z-index: ${({ theme }) => theme.zIndex.modal};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: ${({ $isPreview }) => `${$isPreview ? -24 : -40}px`};
    margin-right: ${({ $isPreview }) => `${$isPreview ? -24 : -40}px`};
  }
`;
