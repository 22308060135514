import { ampli } from "ampli";
import { useMemo } from "react";

import { Box, Typography } from "@hotelengine/atlas-web";
import { ManageFlightDescription } from "@hotel-engine/app/ItineraryContent/flights/components/ManageFlight/ManageFlightDescription";
import { MultiAirlineLogo } from "@hotel-engine/assets/flights/MultiAirlineLogo";
import { useAirlineLinks } from "@hotel-engine/app/ItineraryContent/flights/hooks/useAirlineLinks";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import { CollapsibleContent } from "../../../../../shared/CollapsibleContent";

export interface IGetAssistanceProps {
  itinerary: IFlightItinerary;
}

export const GetAssistance = ({ itinerary }: IGetAssistanceProps) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const navigate = useNavigate();
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();
  const { getAirlineLink } = useAirlineLinks();

  const airlines = itinerary.airlineCodes;
  const confirmationNumber = `#${itinerary.bookingNumber}`;
  const supportNumber = "855-567-4683";

  const assistanceButtons = useMemo(
    () =>
      itinerary.airlineCodes.map((code) => {
        const airlineName = getAirlineNameFromIataAirlineCode(code);
        const airlineSupport = getAirlineLink("support", code);

        return {
          label: `Visit ${airlineName}`,
          url: airlineSupport,
        };
      }),
    [getAirlineLink, getAirlineNameFromIataAirlineCode, itinerary.airlineCodes]
  );

  const multipleAirlines = airlines.length > 1;

  const goToAirlineWebsite = (url: string) => {
    openNewTab(url, "_blank", navigate);

    ampli.clickVisitAirlineWebsite({
      userId: user?.id,
      contractId: itinerary.bookingNumber,
      baseUrl: url,
    });
  };

  return (
    <CollapsibleContent
      toggleBtnLabel={
        <Typography variant="heading/lg" color="foregroundPrimary">
          Need help?
        </Typography>
      }
      testID="support-collapsible"
      toggleTestID="support-toggle-btn"
    >
      <Box gap={16} padding={24} paddingTop={16}>
        <Box display="flex" flexDirection="row" gap={16} marginBottom={16}>
          <Styled.AirlineLogoContainer>
            <MultiAirlineLogo airlineCodes={airlines.slice(0, 2)} size={36} />
          </Styled.AirlineLogoContainer>
          <Box display="flex" alignItems="flex-end" paddingLeft={4} flexDirection="column" gap={8}>
            <ManageFlightDescription airlineCodes={airlines} />
          </Box>
        </Box>
        <Box gap={16} paddingTop={16} paddingBottom={16} display="flex" flexDirection="column">
          {assistanceButtons.map((item, index) => (
            <Styled.AirlineLinkButton key={index} onClick={() => goToAirlineWebsite(item.url)}>
              {item.label}
            </Styled.AirlineLinkButton>
          ))}
        </Box>
        <Styled.Divider />
        <Box gap={16} display="flex" flexDirection="column">
          <Typography variant="body/md" data-testid="contact-support-text">
            If you have issues contacting the airline{multipleAirlines ? "s" : ""}, please contact{" "}
            {COMPANY_NAME} support.
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body/md">Call:&nbsp;</Typography>
            <Typography
              as="a"
              href={`tel:${supportNumber}`}
              variant="link/md"
              color="foregroundPrimary"
            >
              {supportNumber}
            </Typography>
          </Box>
          <Typography variant="body/md">
            For faster support, mention{" "}
            <Typography variant="body/md-strong">{confirmationNumber}</Typography>
          </Typography>
        </Box>
      </Box>
    </CollapsibleContent>
  );
};
