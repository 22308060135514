import { ampli } from "ampli";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, DataTable, Typography } from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useNavigate, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import type { ITrip } from "@hotel-engine/types/trips";

import { useTableColumns } from "./Columns/useTableColumns";
import { NoTripsMessageLegacy, TripsListItem, TripsError } from "../components";
import * as Styled from "./styles";
import type { IAction } from "../contextLegacy";
import { useTripsLegacyContext } from "../contextLegacy";
import SectionList from "../components/SectionList";
import { groupTripsByDay } from "./helpers/groupTripsByDay";
import TripsListPagination from "./Pagination";
import DataTableContent from "./DataTableContent";
import { extractId } from "./helpers/extractId";
import { usePaginationControls } from "./Pagination/usePaginationControls";
import useIdentifyMisplacedTrips from "./useIdentifyMisplacedTrips";

const TripsListViewLegacy = () => {
  const [rowSelectionState, setRowSelectionState] = useState<Record<string, boolean>>({});
  const isMobileScreen = useBreakpoint("xxl", "max");
  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const navigate = useNavigate();
  const [qs, setSearchParams] = useSearchParams();

  const { showPreview, loading, error: tripsError, trips } = tripsState;

  const { columns } = useTableColumns();
  const tripIdentifier = qs.get("booking_number");

  const { cachedTrips, isNextDisabled } = usePaginationControls();

  const currentReservation = useMemo(() => {
    return trips.find((trip, index) => {
      return trip.bookings.some((booking) => {
        switch (booking.bookingType) {
          case "lodging":
            const lodgingMatch = booking.details.internalConfirmationNumber === tripIdentifier;

            if (lodgingMatch) {
              setRowSelectionState({ [index]: true });
              return true;
            }
            return false;
          case "flight":
            const flightMatch =
              String(trip.id) === tripIdentifier ||
              trip.bookings[0].bookingNumber === tripIdentifier;

            if (flightMatch) {
              setRowSelectionState({ [index]: true });
              return true;
            }
            return false;
          case "car":
            const carMatch =
              String(trip.id) === tripIdentifier ||
              trip.bookings[0].bookingNumber === tripIdentifier;

            if (carMatch) {
              setRowSelectionState({ [index]: true });
              return true;
            }
            return false;
          default:
            return false;
        }
      });
    });
  }, [tripIdentifier, trips]);

  const handleItemPreviewClick = useCallback(
    async (record: ITrip) => {
      const id = extractId(record);
      const bookingType = record.bookings[0].bookingType;

      tripsDispatch({ type: "rowSelection", record: { id, bookingType } });
      if (id !== showPreview?.id) {
        setSearchParams({ booking_number: String(id) });
      } else {
        setSearchParams({});
      }
    },
    [setSearchParams, showPreview?.id, tripsDispatch]
  );

  const handleSort = (column: IAction["column"]) => {
    ampli.clickTripsTableSort({ sortBy: `${column}` });

    tripsDispatch({ type: "sort", column });
  };

  useEffect(() => {
    if (String(showPreview?.id) === String(tripIdentifier)) {
      if (!currentReservation) {
        setSearchParams({});
      }

      return;
    }

    if (!tripIdentifier || !currentReservation) return;

    void handleItemPreviewClick(currentReservation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchParams, currentReservation, tripIdentifier]);

  const handleItemSelection = (item: ITrip) => {
    const booking = item.bookings[0];
    const isLodging = booking.bookingType === "lodging";

    const id = isLodging ? booking.details.internalConfirmationNumber : item.id;

    navigate({
      pathname: "/itinerary",
      search: `?contract_number=${id}&type=${booking.bookingType}`,
    });
  };

  const mobileRenderItem = (item: ITrip) => (
    <TripsListItem key={item.id} loading={loading} item={item} onSelection={handleItemSelection} />
  );

  const mobileRenderSectionHeader = (title: string) => (
    <Typography variant="body/sm" color="foregroundTertiary">
      {title}
    </Typography>
  );

  useIdentifyMisplacedTrips();

  return (
    <>
      {isMobileScreen ? (
        <>
          {!!trips?.length && !tripsError && (
            <>
              <SectionList<ITrip>
                sections={groupTripsByDay(trips)}
                renderItem={mobileRenderItem}
                renderSectionHeader={mobileRenderSectionHeader}
                keyExtractor={(item) => `${item.id}`}
              />
              {!isNextDisabled && (
                <Styled.LoadMore>
                  <Button onClick={() => tripsDispatch({ type: "loadMore" })} disabled={loading}>
                    Load More
                  </Button>
                </Styled.LoadMore>
              )}
            </>
          )}
          {trips?.length === 0 && !tripsError && !loading && <NoTripsMessageLegacy />}
          {!!tripsError && !loading && <TripsError />}
        </>
      ) : (
        <>
          <Styled.DesktopContainer>
            <Box style={{ flexBasis: showPreview?.id ? "70%" : "100%" }}>
              {!!(trips?.length || loading) && !tripsError && (
                <DataTable
                  columns={columns}
                  data={loading && !trips.length ? Array(5) : trips}
                  rowClickOptions={{
                    onRowClicked(_, row, table) {
                      void handleItemPreviewClick(row.original);
                      table.resetRowSelection();
                      row.toggleSelected();
                    },
                  }}
                  onSortingChange={(sorting) => handleSort(sorting[0]?.id as IAction["column"])}
                  isManualSortingEnabled
                >
                  <DataTableContent
                    tripIdentifier={tripIdentifier}
                    rowSelectionState={rowSelectionState}
                  />
                </DataTable>
              )}
              {trips?.length === 0 && !tripsError && !loading && (
                <NoTripsMessageLegacy endOfList={!!cachedTrips?.length} />
              )}
              {!!tripsError && !loading && <TripsError />}
            </Box>
          </Styled.DesktopContainer>
          <TripsListPagination />
        </>
      )}
    </>
  );
};

export default TripsListViewLegacy;
