import type { FC } from "react";
import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { TopNavBadge, Button, IconButton } from "@hotelengine/atlas-web";

import type { ButtonProps } from "@hotelengine/atlas-web";

const sharedHeaderIconWrapStyles = css`
  height: 32px;
  width: 32px;
  position: relative;
  ${flexbox({
    alignItems: "center",
    direction: "row",
    justifyContent: "center",
  })};

  svg {
    display: flex;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderIconWrap = styled(IconButton)`
  ${sharedHeaderIconWrapStyles};
  margin-top: ${({ theme }) => theme.spacing[10]};
`;

export const HeaderIconWrapRedesign24 = styled(IconButton)`
  ${sharedHeaderIconWrapStyles};
`;

export const Badge = styled(TopNavBadge)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
`;

export const NotificationActions = styled.aside`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const NotificationAction = styled<FC<ButtonProps>>(Button).attrs({
  variant: "plain",
  size: "sm",
})`
justify-content: flex-start;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: ${({ theme }) => `${theme.spacing[8]}} ${theme.spacing[16]}`};
`;
