import { ampli } from "ampli";
import { Formik } from "formik";
import { useCallback } from "react";
import moment from "moment";

import type { DateRange } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";
import { useLocation, useNavigate, useParams } from "@hotel-engine/lib/react-router-dom";

import { TRIPS_DEFAULT_SORT, type TripsStatus } from "pages/Trips/data/querySchema";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { useAppSelector } from "store/hooks";

import DateFiltersForm from "./components/DateFiltersForm";

export interface IDateFiltersValues {
  group: TripsStatus | "dateRange";
  dateRange: DateRange;
}

const DateFilters = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.Auth.user);
  const [params] = useTripsControl();

  const handleStatusClick = useCallback(
    (nextStatus, dateRange?: DateRange) => {
      if (user && !dateRange) {
        ampli.clickStateOfTrip({
          selectedOption: nextStatus,
          userId: user.id,
        });
      }

      const startDate = moment(dateRange?.from).startOf("day").toISOString();
      const endDate = moment(dateRange?.to).endOf("day").toISOString();

      if (user && dateRange) {
        ampli.clickDatesInTrips({
          endDate,
          startDate,
          userId: user.id,
        });
      }

      const searchParams = new URLSearchParams(location.search);
      const searchStartDate = searchParams.get("filter[start_time_gt]");
      const searchEndDate = searchParams.get("filter[start_time_lt]");

      const dateRangeChanged =
        dateRange && (searchStartDate !== startDate || searchEndDate !== endDate);

      const statusChanged =
        status !== nextStatus ||
        (searchStartDate && searchEndDate && status === nextStatus && nextStatus === "all");

      if (status && (statusChanged || dateRangeChanged)) {
        const isCurrentFuture = /^(today|upcoming)$/.test(status);
        const isNextFuture = /^(today|upcoming)$/.test(nextStatus);

        let currentSort = searchParams.get("sort") ?? TRIPS_DEFAULT_SORT;

        if (isCurrentFuture && !isNextFuture && currentSort === TRIPS_DEFAULT_SORT) {
          currentSort = "-start_time";
        } else if (!isCurrentFuture && isNextFuture) {
          currentSort = TRIPS_DEFAULT_SORT;
        }

        searchParams.set("offset", "0");
        searchParams.set("sort", currentSort);

        if (dateRange) {
          searchParams.set("filter[start_time_gt]", startDate);
          searchParams.set("filter[start_time_lt]", endDate);
        } else {
          searchParams.delete("filter[start_time_gt]");
          searchParams.delete("filter[start_time_lt]");
        }

        navigate({
          pathname: `${routes.trips.base}/${nextStatus}`,
          search: searchParams.toString(),
          hash: location.hash,
        });
      }
    },
    [location.hash, location.search, navigate, status, user]
  );

  const handleDateFiltersSubmit = (values: IDateFiltersValues) => {
    ampli.bookingTableFilteredDateView({
      filterType: values.group,
    });

    if (values.dateRange.from && values.dateRange.to) {
      handleStatusClick("all", values.dateRange);
    } else {
      handleStatusClick(values.group);
    }
  };

  const initialValues = {
    group: params["start_time_gt"] && params["start_time_lt"] ? "dateRange" : status,
    dateRange: {
      from: params["start_time_gt"] ? moment(params["start_time_gt"]).toDate() : undefined,
      to: params["start_time_lt"] ? moment(params["start_time_lt"]).toDate() : undefined,
    },
  } as IDateFiltersValues;

  return (
    <Formik initialValues={initialValues} onSubmit={handleDateFiltersSubmit}>
      <DateFiltersForm />
    </Formik>
  );
};

export default DateFilters;
