import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useCheckoutVerificationQuery } from "@hotel-engine/react-query/checkoutVerification/useCheckoutVerification";
import { usePropertyReviewInfoQuery } from "@hotel-engine/react-query/propertyReviews/usePropertyReviewInfoQuery";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { AuthActions } from "store/Auth/AuthRedux";
import { routes } from "@hotel-engine/constants";
import type {
  ICheckoutVerification,
  INotificationReferrer,
  IPostCheckoutAction,
} from "@hotel-engine/types/notifications";
import type { IPropertyReviewInfo } from "@hotel-engine/types/property";
import AddPropertyReview from "./components/AddPropertyReview";
import CheckoutVerificationDetails from "./components/CheckoutVerification/CheckoutVerificationDetails";
import NotificationError from "./components/NotificationError";
import NotificationSkeleton from "./components/NotificationSkeleton";
import { NotificationSuccess } from "./components/NotificationSuccess";
import { COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";

interface INotificationsContainerProps {
  postCheckoutActions: IPostCheckoutAction[];
  dismissModal?: () => void;
  referrer: INotificationReferrer;
}

export const NotificationsContainer = ({
  postCheckoutActions,
  dismissModal,
  referrer,
}: INotificationsContainerProps) => {
  const { propertyReviewRequestId, checkoutVerificationId, contractNumber } =
    postCheckoutActions[0];
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.Auth.user);
  const dispatch = useAppDispatch();

  // Post checkout actions context
  const { dispatch: dispatchPostCheckoutActions, state: postCheckoutActionsState } =
    usePostCheckoutActions();

  const checkoutVerification = useCheckoutVerificationQuery(checkoutVerificationId || "", {
    enabled: !!checkoutVerificationId,
  });

  const propertyReview = usePropertyReviewInfoQuery(propertyReviewRequestId || "", {
    enabled: !!propertyReviewRequestId,
  });

  let propertyInfoData: IPropertyReviewInfo | ICheckoutVerification | undefined = undefined;

  if (checkoutVerification.data) {
    propertyInfoData = checkoutVerification.data;
  }
  if (propertyReview.data) {
    propertyInfoData = propertyReview.data;
  }

  const incrementStep = () => {
    dispatchPostCheckoutActions({
      type: "UPDATE_STEP",
      payload: postCheckoutActionsState.step + 1,
    });
  };
  const isModal = referrer !== "page";

  const handleClose = () => {
    if (isModal && dismissModal) {
      dismissModal();
    } else {
      // If this is the page, redirect if the user is logged in to the dashboard or if not, the company "marketing website"
      if (user) {
        if (user.email === propertyInfoData?.email) {
          // Logged in user is the same as the reviewer - redirect to dashboard.
          navigate("/");
          dispatchPostCheckoutActions({
            type: "UPDATE_STEP",
            payload: 0,
          });
        } else {
          // Logged in user is a different member than the reviewer - log user out and redirect to login.
          dispatch(AuthActions.clearUserData());
          navigate(routes.login);
        }
      } else {
        globalThis.location.assign(COMPANY_ROUTES.base);
      }
    }
  };

  const skipStep = () => {
    if (postCheckoutActionsState.status === "isCheckoutVerificationSuccess") {
      dispatchPostCheckoutActions({
        type: "UPDATE_STEP",
        payload: postCheckoutActionsState.step + 1,
      });
    } else {
      handleClose();
    }
  };

  const notificationStatus = {
    isError: checkoutVerification.error || propertyReview.error,
    isLoading: checkoutVerification.isLoading || propertyReview.isLoading,
    isPropertyReviewLoading: !checkoutVerification.isLoading && propertyReview.isLoading,
  };

  const { isError, isLoading, isPropertyReviewLoading } = notificationStatus;

  if (isError) {
    return <NotificationError />;
  }

  if (isLoading && postCheckoutActionsState?.isModalVisible) {
    return <NotificationSkeleton isPropertyReviewLoading={isPropertyReviewLoading} />;
  }

  // Already Verified
  if (
    propertyInfoData &&
    (checkoutVerification?.data?.verified || propertyReview?.data?.completed)
  ) {
    return (
      <NotificationSuccess
        isCheckoutVerification={!!checkoutVerificationId}
        propertyInfo={propertyInfoData}
        isAlreadySubmitted
        dismissModal={handleClose}
        referrer={referrer}
      />
    );
  }

  const steps: JSX.Element[] = [];

  // Checkout verification
  if (!!checkoutVerification.data) {
    steps.push(
      <CheckoutVerificationDetails
        checkoutVerification={checkoutVerification.data}
        setStep={incrementStep}
        // TODO add back if re-enable datadogrum
        // referrer={referrer}
      />
    );
  }
  // Reviews
  if (!!propertyReview.data) {
    steps.push(
      <AddPropertyReview
        contractNumber={contractNumber}
        propertyInfo={propertyReview.data}
        setStep={incrementStep}
        skipStep={skipStep}
        // TODO add back if re-enable datadogrum
        // referrer={referrer}
      />
    );
  }

  // Form Submitted
  if (propertyInfoData) {
    steps.push(
      <NotificationSuccess
        isCheckoutVerification={!!checkoutVerification.data}
        dismissModal={handleClose}
        propertyInfo={propertyInfoData}
        referrer={referrer}
      />
    );
  }
  if (steps[postCheckoutActionsState.step]) {
    return steps[postCheckoutActionsState.step];
  }
  return null;
};
