import styled, { css } from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { searchHeight } from "@hotel-engine/constants";
import { buttonReset, colors, sizes } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

interface IDateRangeProps {
  checkInError: boolean;
  checkOutError: boolean;
}

interface IInputProps {
  inputWidth?: string;
  margin?: string;
}

// Using a color config for now until we complete building out our new theme
const dateColors = {
  primary: colors.retroBlue,
  hover: colors.denim,
  secondary: colors.white,
  secondaryHover: colors.grey[100],
  active: colors.placeboBlue,
  text: colors.black,
  contrastText: colors.white,
  error: colors.tomato,
  disabled: colors.grey[100],
  border: colors.grey[200],
  tooltip: colors.black,
} as const;

const mobileDateSizes = {
  titleHeight: 56,
  dateSectionMargin: 12,
  selectedDatesHeight: 63,
  weekHeight: 40,
} as const;

export const DateSelectorInput = styled.div<IInputProps>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: ${sizes.breakpoints.sm}) {
    align-items: stretch;
  }
`;

export const MobileDateSelectorInput = styled(DateSelectorInput)`
  border: solid 1px ${dateColors.border};
  height: 44px;
  max-width: none;
  padding: 0 17px 0 13px;
  width: ${({ inputWidth }) => inputWidth || "50%"};
  margin: ${({ margin }) => margin || "0 8px 0 0"};

  ${mediaQuery(
    "lg",
    "max"
  )(`
        width: 100%;
    `)}
`;

export const DateSelectorIcon = styled(Icon)`
  font-size: 16px;
`;

export const ButtonReset = styled.button`
  ${buttonReset}
`;

export const SeparatorArrow = styled(Icon).attrs(() => ({
  icon: ["far", "arrow-right"],
}))`
  font-size: 11px;
  margin: 0 16px;

  // This should be removed when the location picker is changed into a floating container
  ${mediaQuery(
    "sm",
    "min"
  )(`
        margin: 0 8px;
    `)}
`;

export const DateCalendar = styled.div<{ isFocused?: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      // subtract margin + height of top section
      height: calc(
        100% - ${mobileDateSizes.selectedDatesHeight}px -
          ${mobileDateSizes.dateSectionMargin}px
      );
    `}

  .DayPicker_weekHeader {
    height: ${mobileDateSizes.weekHeight}px;
    border-top: 1px solid ${colors.grey[200]};

    ${mediaQuery("md")(`
                top: 55px;
                height: unset;
                border: none;
            `)}
  }

  ${mediaQuery(
    "md",
    "max"
  )(`
        .DayPicker_weekHeader_ul {
            line-height: ${mobileDateSizes.weekHeight - 2}px;
            margin: 0;
        }

    `)}

  .CalendarMonth_caption {
    color: ${dateColors.text};
    font-size: 16px;
  }

  .DayPickerNavigation_button__default {
    border: none;
  }

  .DateRangePicker_picker__directionLeft {
    transform: translate(-25px, -${searchHeight});
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  }

  .DayPicker_transitionContainer {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    ${mediaQuery(
      "md",
      "max"
    )(`
            padding-top: 53px;

        `)}
  }

  .DayPicker__withBorder {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.12),
      0px 1px 4px rgba(0, 0, 0, 0.04);
  }

  .CalendarMonth_table {
    border-collapse: unset;
  }

  // Style the date picker days
  .CalendarDay {
    border: 1px solid transparent;

    &.CalendarDay__default {
      background: ${dateColors.secondary};
      &:hover,
      &:focus {
        background: ${colors.placeboBlue};
        border: 1px solid ${colors.retroBlue};
      }
    }

    // The two selected days
    &.CalendarDay__selected {
      background: ${dateColors.primary};
      color: ${dateColors.contrastText};

      &:hover,
      &:focus {
        background: ${colors.bavarianBlue};
      }
    }

    // The selected range between check in and check out
    &.CalendarDay__selected_span {
      background: ${dateColors.active};
      color: ${dateColors.text};

      &:hover,
      &:focus {
        background: ${dateColors.active};
      }
    }

    // Selected check in
    &.CalendarDay__selected_start {
      border-radius: ${({
        theme: {
          legacy: {
            shape: { borderRadius },
          },
        },
      }) => `${borderRadius.sm} 0 0 ${borderRadius.sm}`};
    }

    // Selected check out
    &.CalendarDay__selected_end {
      border-radius: ${({
        theme: {
          legacy: {
            shape: { borderRadius },
          },
        },
      }) => `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
    }

    // The non-finalized selected range between check in and check out
    &.CalendarDay__hovered_span {
      background: ${dateColors.active};
      color: ${dateColors.text};

      &:hover,
      &:focus {
        background: ${dateColors.active};
        border: 1px solid ${colors.retroBlue};
        border-radius: ${({
          theme: {
            legacy: {
              shape: { borderRadius },
            },
          },
        }) => `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
      }
    }

    // Build the tooltip when hovering over a date
    &[data-active-tooltip="true"] {
      position: relative;

      &:hover::before {
        position: absolute;
        width: 75px;
        top: -36px;
        left: 50%;
        transform: translateX(-50%);
        content: attr(data-number-of-nights);
        background-color: ${dateColors.tooltip};
        color: ${dateColors.contrastText};
        z-index: 100 /* disable theming (do not replicate) */;
        font-size: 12px;
        padding: 8px;
        border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
      }
      &:hover::after {
        position: absolute;
        top: -3px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${dateColors.tooltip};
        content: " ";
        font-size: 0;
        line-height: 0;
        margin-left: -25%;
        width: 0;
      }
    }
  }

  // Month title
  .CalendarMonth_caption {
    color: ${dateColors.text};
    font-size: 16px;
    padding-top: 13px;

    strong {
      font-weight: normal;
    }
  }

  .CalendarMonthGrid_month__hidden {
    visibility: visible;
  }
`;

export const DateRangePicker = styled(DateCalendar)<IDateRangeProps>`
  height: 100%;

  .DateRangePicker {
    &,
    > div {
      height: 100%;
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput {
    height: 100%;
    width: 100%;

    font-size: 14px;

    .DateInput_input {
      color: ${dateColors.text};
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      line-height: unset;
      cursor: pointer;
      height: 100%;
      border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
      padding: 0;
      border: 1px solid transparent;

      &:focus,
      &:hover {
        border: 1px solid ${colors.retroBlue};
      }
      &:focus {
        outline: 2px solid ${colors.placeboBlue};
      }
    }
  }

  .DateRangePickerInput_calendarIcon {
    margin: 0;
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    width: 40px;
    height: 100%;
    align-self: center;

    &:hover,
    &:focus {
      border: 1px solid ${colors.retroBlue};
    }
    &:focus {
      outline: 2px solid ${colors.placeboBlue};
    }
  }

  ${({ checkInError }) =>
    checkInError &&
    css`
      .DateInput_input[id="date-range-picker-start"] {
        color: ${dateColors.error};

        &::placeholder {
          color: ${dateColors.error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors.error};
        }
      }
    `}

  ${({ checkOutError }) =>
    checkOutError &&
    css`
      .DateInput_input[id="date-range-picker-end"] {
        color: ${dateColors.error};

        &::placeholder {
          color: ${dateColors.error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors.error};
        }
      }
    `}

  .DateRangePickerInput_arrow {
    align-self: center;
    color: inherit;
  }

  .DayPickerNavigation_button {
    &:hover,
    &:focus {
      background: ${colors.grey[50]};
    }
  }
`;

export const CheckOutDate = styled(ButtonReset)<{ $checkOutError?: boolean }>`
  ${({ $checkOutError }) => $checkOutError && css`color: ${dateColors.error} }`}
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${mobileDateSizes.selectedDatesHeight}px;
  margin-bottom: ${mobileDateSizes.dateSectionMargin}px;
`;

export const CalendarIcon = styled(Icon)`
  font-size: 18px;
  margin-left: 16px;
`;

export const MobileCalendarIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 11px;
`;

export const SelectedDate = styled(ButtonReset)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 160px;
  margin-right: 8px;
`;

export const DateType = styled.span`
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  font-size: 12px;
`;

export const Date = styled.span<{ isFocused: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${colors.softBlue};
    `}
  font-size: 14px;
  font-weight: 600;
`;

export const Hyphen = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-top: 18px;
  margin-right: 8px;
`;
