import { Button, Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

interface IClaimYourPersonalAccount {
  onContinue(): void;
}

const ClaimYourPersonalAccount = ({ onContinue }: IClaimYourPersonalAccount) => {
  return (
    <Styled.Container>
      <Styled.HeroImage src="/assets/dashboard/undraw_travel_mode.svg" />
      <Typography variant="heading/2xl" color="foregroundPrimary">
        Get $25 when you claim your personal account
      </Typography>
      <Typography variant="body/sm" color="foregroundPrimary">
        Get the best discounts on all your personal trips. A completely private account, for your
        eyes only.
      </Typography>
      <Styled.BulletContainer>
        <Styled.BulletItem>
          <Icon name="check" size="md" color="sentimentPositiveDefault" />
          <Typography variant="body/sm" color="foregroundPrimary">
            Deeply discounted rates — up to 60% off!
          </Typography>
        </Styled.BulletItem>
        <Styled.BulletItem>
          <Icon name="check" size="md" color="sentimentPositiveDefault" />
          <Typography variant="body/sm" color="foregroundPrimary">
            Complete privacy from your company
          </Typography>
        </Styled.BulletItem>
        <Styled.BulletItem>
          <Icon name="check" size="md" color="sentimentPositiveDefault" />
          <Typography variant="body/sm" color="foregroundPrimary">
            Book without work-related policies or restrictions
          </Typography>
        </Styled.BulletItem>
        <Styled.BulletItem>
          <Icon name="check" size="md" color="sentimentPositiveDefault" />
          <Typography variant="body/sm" color="foregroundPrimary">
            Both {COMPANY_NAME} Rewards and hotel loyalty points
          </Typography>
        </Styled.BulletItem>
      </Styled.BulletContainer>
      <Button size="lg" onClick={onContinue}>
        Let's go!
      </Button>
    </Styled.Container>
  );
};

export default ClaimYourPersonalAccount;
