import { NoTripsMessage } from "../../components";
import * as Styled from "./styles";
import GoogleTripsMap from "@hotel-engine/app/GoogleMap/components/GoogleTripsMap";
import useTripsResult from "../../hooks/useTripsResult";

const TripsMapView = () => {
  const result = useTripsResult();

  return (
    <>
      {result.data?.trips.length || 0 > 0 ? (
        <Styled.TripsMapView>
          <Styled.MapView data-testid="trips-map">
            <GoogleTripsMap loading={result.isLoading} />
          </Styled.MapView>
        </Styled.TripsMapView>
      ) : (
        <Styled.NoTripsView>
          <NoTripsMessage isEmptyMapView />
        </Styled.NoTripsView>
      )}
    </>
  );
};

export default TripsMapView;
