import styled from "styled-components";

import { Button } from "@hotelengine/atlas-web";
import { colors, sizes } from "@hotel-engine/styles";

export const VerificationContentsWrapper = styled.main`
  padding: 24px;
  h1 {
    ${({ theme }) => theme.typography.desktop["heading/lg"]};
    color: ${colors.errieBlack};
  }
  p {
    ${({ theme }) => theme.typography.desktop["body/sm"]};
    color: ${colors.errieBlack};
  }
`;

export const VerificationInstructions = styled.section`
  padding: 12px 0 6px;
  @media screen and (min-width: ${sizes.breakpoints.md}) {
    padding: 24px 0 12px;
  }
`;

export const VerificationForm = styled.section`
  padding: 6px 0 0;
  @media screen and (min-width: ${sizes.breakpoints.md}) {
    padding: 12px 0 0;
  }
`;
export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 48px 0 0;
`;
