import type { UseQueryOptions } from "react-query";
import { useMutation } from "react-query";
import type { IError } from "@hotel-engine/types/errors";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import { endpoints } from "../constants";
import { getAccessToken } from "@hotel-engine/utilities/auth";
import type {
  TCarInsurancePolicy,
  TInsuranceCancellationPreview,
} from "@hotel-engine/types/itinerary";
import type { AxiosError, AxiosResponse } from "axios";

export const useGetCarInsuranceQuery = (
  id?: string,
  options?: UseQueryOptions<TCarInsurancePolicy, IError>,
  altToken?: string
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.carInsurancePolicies, id],

    async () =>
      get<TCarInsurancePolicy>(
        `${endpoints.carInsurancePolicies}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${altToken || (await getAccessToken())}`,
          },
        }
      ),
    options
  );
};

export const usePreviewCancelCarInsuranceQuery = (
  id?: string,
  options?: UseQueryOptions<TInsuranceCancellationPreview, IError>
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.carInsurancePolicies, id, "cancellations"],

    async () =>
      get<TInsuranceCancellationPreview>(
        `${endpoints.carInsurancePolicies}/${id}/cancellations`,
        {}
      ),
    options
  );
};

export const useCancelCarInsuranceQuery = () => {
  const post = useApi("post");

  return useMutation<AxiosResponse<null>, AxiosError, { id: string }>((params) =>
    post(`${endpoints.carInsurancePolicies}/${params.id}/cancellations`)
  );
};
