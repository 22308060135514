import styled from "styled-components";
import { FormControl } from "@hotelengine/atlas-web";

export const SearchInputFormControl = styled(FormControl)`
    width: 100%;

    label {
        display: none;
    }
`;
