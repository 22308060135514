import type { TypographyProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const DottedLine = styled.span<{ color: Exclude<TypographyProps["color"], undefined> }>`
  border-bottom: ${({ color, theme }) =>
    `${theme.borderWidth.normal} dashed ${theme.colors[color]}`};
  padding-bottom: ${({ theme }) => theme.spacing[2]};
  width: fit-content;
`;

export const FieldLine = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;

  span:nth-child(2) {
    text-align: right;
  }
`;
