import type { FormikErrors } from "formik";
import { FieldArray, useFormikContext } from "formik";

import type { ICustomField } from "@hotel-engine/types/customField";

import { Box, FormControl, Select, SelectItem, TextInput } from "@hotelengine/atlas-web";

export interface ICustomFieldsFormikContext {
  /** Array of custom fields with values */
  customFields: ICustomField[];
}

export const CustomFieldsForm = () => {
  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<ICustomFieldsFormikContext>();

  return (
    <FieldArray name="customFields" data-testid="customFields">
      {() => (
        <>
          {values?.customFields.map((customField, index) => {
            const { id, name, options, helpText } = customField;
            const isSelect = Boolean(customField.options?.length);

            if (isSelect) {
              return (
                <Box paddingBottom={16} key={id}>
                  <FormControl
                    status={errors.customFields?.[index] ? "error" : "default"}
                    errorText={
                      (errors.customFields?.[index] as FormikErrors<ICustomField>)?.value ||
                      "This field is required"
                    }
                    label={name}
                    isRequired={customField.required}
                  >
                    <Select
                      placeholder={helpText || `Select ${name}`}
                      value={customField.value}
                      onValueChange={(val) => {
                        if (val === "default") {
                          setFieldValue(`customFields.${index}.value`, "", true);
                        } else {
                          setFieldValue(`customFields.${index}.value`, val, true);
                        }
                        setFieldTouched(`customFields.${index}.value`);
                      }}
                      style={{ width: "100%" }}
                    >
                      <SelectItem
                        key="custom-select-default"
                        data-testid="custom-field-select-option"
                        value="default"
                      >
                        Select an option
                      </SelectItem>
                      {options
                        ?.sort((a, b) => a.localeCompare(b))
                        .map((option, _index) => {
                          return (
                            <SelectItem
                              key={`custom-select-${option}-${_index}`}
                              data-testid="custom-field-select-option"
                              value={option}
                            >
                              {option}
                            </SelectItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              );
            } else {
              return (
                <Box paddingBottom={16} key={id}>
                  <FormControl
                    status={errors.customFields?.[index] ? "error" : "default"}
                    errorText={
                      (errors.customFields?.[index] as FormikErrors<ICustomField>)?.value ||
                      "This field is required"
                    }
                    isRequired={customField.required}
                    label={name}
                  >
                    <TextInput
                      name={`customFields.${index}.value`}
                      placeholder={helpText || `Enter ${name}`}
                      value={customField.value}
                      onChange={(e) => {
                        setFieldValue(`customFields.${index}.value`, e.target.value, true);
                        setFieldTouched(`customFields.${index}.value`);
                      }}
                      data-testid={`customFields-${index}`}
                    />
                  </FormControl>
                </Box>
              );
            }
          })}
        </>
      )}
    </FieldArray>
  );
};
