import { useMemo, type ReactNode } from "react";

import { Avatar, Box, Icon, Typography } from "@hotelengine/atlas-web";
import type { ICarTraveler } from "@hotel-engine/types/itinerary";

import * as Styled from "./styles";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";

interface ITravelerInfoBlockContentProps {
  iconLogo: ReactNode;
  leftContent: ReactNode;
  rightContent?: ReactNode;
}
const TravelerInfoBlockContent = ({
  iconLogo,
  leftContent,
  rightContent,
}: ITravelerInfoBlockContentProps) => {
  return (
    <Styled.TravelerInfoBlockContentGrid $rightContent={!!rightContent}>
      <Box>{iconLogo}</Box>
      <Styled.TravelerBox>{leftContent}</Styled.TravelerBox>
      {!!rightContent && <Box className="right">{rightContent}</Box>}
    </Styled.TravelerInfoBlockContentGrid>
  );
};

interface ITravelersProps {
  travelers: ICarTraveler[];
  logoSrc?: string;
  padTop?: boolean;
}

const Travelers = ({ travelers, logoSrc, padTop = true }: ITravelersProps) => {
  const pluralizeLabel = travelers.length > 1;

  const travelerCollapsibles = useMemo(() => {
    return travelers.map((traveler, travelerIdx) => {
      return (
        <CollapsibleContent
          key={`${traveler.name}__${travelerIdx}`}
          toggleBtnLabel={
            <Box display="flex" gap={12} alignItems="center">
              <Avatar name={traveler.name} />
              <Typography variant="body/md">{traveler.name}</Typography>
            </Box>
          }
        >
          <Box display="flex" flexDirection="column">
            <Styled.TravelerInfoBlock>
              <TravelerInfoBlockContent
                iconLogo={<Icon name="circle-info" color="foregroundPrimary" />}
                leftContent={
                  <Typography variant="body/sm" color="foregroundPrimary">
                    {/* TODO: Using traveler name for now until email is available on this response */}
                    {traveler.email || traveler.name}
                  </Typography>
                }
              />
            </Styled.TravelerInfoBlock>
            {!!traveler.loyaltyNumber?.length && (
              <Styled.TravelerInfoBlock>
                <Box display="flex" flexDirection="column" gap={16}>
                  {traveler.loyaltyNumber?.map((rewardsProgram) => {
                    return (
                      <TravelerInfoBlockContent
                        key={`traveler__${travelerIdx}__rewards__${rewardsProgram.program}`}
                        iconLogo={
                          logoSrc ? <Styled.TripImage src={logoSrc} /> : <Icon name="car--solid" />
                        }
                        leftContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            {rewardsProgram.program}
                          </Typography>
                        }
                        rightContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            {rewardsProgram.memberId || "-"}
                          </Typography>
                        }
                      />
                    );
                  })}
                </Box>
              </Styled.TravelerInfoBlock>
            )}
          </Box>
        </CollapsibleContent>
      );
    });
  }, [logoSrc, travelers]);

  return (
    <Box marginTop={40}>
      <Typography
        variant="uppercase/xs-strong"
        marginBottom={8}
        as="h2"
        color="foregroundSecondary"
      >
        {`Traveler${pluralizeLabel ? "s" : ""}`}
      </Typography>
      <Styled.TravelerCollapsibleContainer $padTop={padTop}>
        {travelerCollapsibles}
      </Styled.TravelerCollapsibleContainer>
    </Box>
  );
};

export default Travelers;
