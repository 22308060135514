import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

const ModalContainer = styled<React.FC<BoxProps>>(Box)`
  ${({ theme }) => `
    background-color: ${theme.colors.backgroundPrimary};
    border: solid ${theme.borderWidth.normal} ${theme.colors.borderMinimal};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 73px auto;
    margin-bottom: ${theme.spacing[32]};
  `}
`;

const CompanyLogosContainer = styled<React.FC<BoxProps>>(Box)`
	width: 62px;
	height: 36px;
	margin-right: ${({ theme }) => theme.spacing[16]};
`;

export const ButtonsContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[16]};
  width: 100%;
`;

const ManageFlightContainer = styled<React.FC<BoxProps>>(Box)<{
  $isPreview: boolean;
}>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
`;

export { ModalContainer, CompanyLogosContainer, ManageFlightContainer };
