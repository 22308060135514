import { useEffect, useRef, useState } from "react";
import { Button, Typography, useTheme, Icon } from "@hotelengine/atlas-web";
import { Form, Formik, useFormikContext } from "formik";

import { useCurrencyCode } from "@hotel-engine/hooks/useCurrencyCode";
import { useCreatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useCreatePaymentProfile";
import type { IBusiness } from "@hotel-engine/types/business";
import type { IErrorResponse, IPaymentProfileError } from "@hotel-engine/types/errors";
import type {
  INewPaymentProfile,
  IPaymentProfile,
  CreditDebitCardPaymentProfile,
} from "@hotel-engine/types/paymentProfile";
import { isIPaymentProfileError, isIV2Error } from "@hotel-engine/types/errors";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { useUpdatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useUpdatePaymentProfile";
import config from "config";

import CardInfo from "./components/CardInfo";
import CardSetup from "./components/CardSetup";
import { buildPaymentProfileSchema, checkForDuplicates } from "./helpers";
import * as Styled from "./styles";
import { PrivacyPolicyDisclaimer } from "../PrivacyPolicyDisclaimer";
import { Unsafe } from "@hotel-engine/data";

// Submit by parent
const AutoSubmit = ({ submitAttempts }) => {
  const { submitForm } = useFormikContext();

  useEffect(() => {
    // Submit the form if submitAttempts by the parent changes.
    if (submitAttempts !== 0) {
      submitForm().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [submitAttempts, submitForm]);
  return null;
};

export const EditPaymentProfileForm = ({
  existingPayment,
  isNewCheckout,
  userMessage,
  requireAuthorization,
  buttonText,
  paymentProfiles,
  hasDirectBill,
  onCancel,
  onSubmit,
  submitAttempts,
  business,
  shouldAutoSubmit,
  isRenderedInModal,
  isAutopayModal,
}: IEditPaymentProfileFormProps) => {
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [cvvInvalid, setCvvInvalid] = useState(false);
  const updatePaymentProfile = useUpdatePaymentProfile();
  const createPaymentProfile = useCreatePaymentProfile();
  // formik's built in loading prop is bugged for this component, so we have to make our own
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const lastToken = useRef("");
  const { tokens } = useTheme();

  const currencyCode = useCurrencyCode();

  const isEditMode = Boolean(existingPayment?.id);
  const initialValues = {
    authorized: false,
    billingPostalCode: existingPayment?.billingPostalCode ?? "",
    default: existingPayment?.default ?? false,
    name: existingPayment?.name ?? "",
    nickname: existingPayment?.nickname ?? "",
  };

  const handleCardUpdate = (updatedValues) => {
    const {
      cvvInvalid: cvvInvalidNewVal,
      expirationMonth: expirationMonthNewVal,
      expirationYear: expirationYearNewVal,
    } = updatedValues;

    setCvvInvalid(cvvInvalidNewVal);
    setExpirationMonth(expirationMonthNewVal);
    setExpirationYear(expirationYearNewVal);
  };

  const handleValidateSpreedly = () => {
    if (isEditMode) return;
    return globalThis.Spreedly.validate();
  };

  const renderCancelButton = (isSubmitting: boolean) => {
    const shouldHideButton =
      (!!!paymentProfiles?.length && !!isNewCheckout && !hasDirectBill && !isRenderedInModal) ||
      isAutopayModal;

    if (!onCancel || shouldHideButton) return null;

    return (
      <Button
        id="cancel"
        variant="outlined"
        type="button"
        isDisabled={isSubmitting}
        onClick={onCancel}
      >
        Cancel
      </Button>
    );
  };

  const handleSave = (data) => {
    const onError = (error: IPaymentProfileError | IErrorResponse) => {
      globalThis.Spreedly.removeHandlers();

      captureMessage("Payment profile form: Save error", {
        data,
        error,
      });
      // first preference: specific error message
      // second preference: general error message
      // third preference: default error message
      setErrorMessage(
        (isIPaymentProfileError(error) && error.clientMessages?.[0]) ||
          (isIV2Error(error) && error.message) ||
          config.defaultErrorMessage
      );

      return setLoading(false);
    };

    const onSuccess = (
      paymentProfile: (IPaymentProfile & Partial<INewPaymentProfile>) | IPaymentProfile
    ) => {
      setLoading(false);
      return onSubmit(paymentProfile);
    };

    const paymentProfile = {
      expirationMonth,
      expirationYear,
      businessId: business?.id,
      ...existingPayment,
      ...data,
    };

    const duplicate = checkForDuplicates(paymentProfiles, paymentProfile);

    // if new card info is a duplicate don't save card.
    if (duplicate) {
      onSuccess({ ...duplicate, duplicate: true });
    }

    if (isEditMode) {
      // This result coming back from the PUT update, brings a different data structure
      updatePaymentProfile.mutate(paymentProfile, {
        onSuccess: (res) =>
          onSuccess({
            ...res,
            ...(res.creditCardType && { type: res.creditCardType }),
          } as CreditDebitCardPaymentProfile),
        onError: onError,
      });
    } else {
      createPaymentProfile.mutate(paymentProfile, {
        onSuccess: (res) => onSuccess(res),
        onError: onError,
      });
    }
  };

  const handleSubmit = (formValues) => {
    setLoading(true);

    const expirationYearVal = existingPayment?.expirationYear || expirationYear;
    const expirationMonthVal = existingPayment?.expirationMonth || expirationMonth;

    // generate profile payment with form values
    const tokenData = {
      full_name: formValues.name,
      month: String(expirationMonthVal),
      year: String(expirationYearVal),
      zip: formValues.billingPostalCode,
    };

    // don't submit if any of the required fields are missing or if the cvv is invalid
    if (cvvInvalid) {
      return setLoading(false);
    }

    // if is an existing payment the card information will stay the same. Only form values will update.
    if (isEditMode) return handleSave(formValues);

    // Spreedly returns paymentMethod with masked card information ready to save it in paymentProfiles.
    globalThis.Spreedly.on("paymentMethod", (token, paymentMethod) => {
      // Spreedly returns a new payment method event for every validation failure if user attempts to submit multiple times.
      // This ref ensures we don't process multiple saves with identical information
      if (token !== lastToken.current) {
        lastToken.current = token;

        const data = {
          ...formValues,
          cardType: paymentMethod.card_type,
          last4: paymentMethod.last_four_digits,
          maskedCardNumber: paymentMethod.number,
          spreedlyFingerprint: paymentMethod.fingerprint,
          spreedlyToken: token,
        };

        Spreedly.validate();
        handleSave(data);
      }
    });

    globalThis.Spreedly.on("errors", (errors) => {
      setErrorMessage(errors[0]["message"]);
      setLoading(false);
    });

    globalThis.Spreedly.tokenizeCreditCard(tokenData);
  };

  return (
    <div>
      {!isNewCheckout && (
        <Typography
          as="h2"
          variant="heading/xl"
          marginBottom={24}
          paddingBottom={16}
          style={{ borderBottom: `1px solid ${tokens.colors.borderDefault}`, width: "100%" }}
        >
          {isEditMode ? "Edit" : "Add"} Card
        </Typography>
      )}
      {!!userMessage && (
        <Styled.UserMessage>
          <Icon name="circle-exclamation" />
          <span>{userMessage}</span>
        </Styled.UserMessage>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={buildPaymentProfileSchema(currencyCode)}
      >
        {({ submitCount, values }) => (
          <Form data-private>
            {!!shouldAutoSubmit && <AutoSubmit submitAttempts={submitAttempts} />}
            <CardInfo
              existingPayment={existingPayment}
              handleCardUpdate={handleCardUpdate}
              isNewCheckout={Boolean(isNewCheckout)}
              isAutopayModal={isAutopayModal}
              submitted={Boolean(submitCount)}
              isRenderedInModal={isRenderedInModal}
            />
            <CardSetup
              addMargin={Boolean(!isNewCheckout && !isAutopayModal)}
              requireAuthorization={Boolean(requireAuthorization)}
              submitted={Boolean(submitCount)}
              isRenderedInModal={isRenderedInModal}
              isAutopayModal={isAutopayModal}
            />
            {errorMessage?.length > 0 && (
              <Styled.ErrorContainer>
                <div>
                  <Icon name="circle-exclamation" size="md" />
                  <span>{errorMessage.includes("decline") ? "Credit Card Declined" : "Uh Oh"}</span>
                </div>
                <ul>
                  <li>
                    {errorMessage.includes("decline")
                      ? "That credit card didn’t work. Please re-enter your information or try a different card."
                      : errorMessage}
                  </li>
                </ul>
              </Styled.ErrorContainer>
            )}
            {!!isAutopayModal && <PrivacyPolicyDisclaimer $bottom="30px" />}
            <Styled.ButtonWrapper
              isRenderedInModal={!!isRenderedInModal}
              addMargin={Boolean(!isNewCheckout && !isAutopayModal)}
            >
              <Button
                id="saveCard"
                type="submit"
                isDisabled={loading || (requireAuthorization && !values.authorized)}
                isLoading={loading}
                onClick={handleValidateSpreedly}
              >
                {isRenderedInModal && !isEditMode ? "Add Card" : buttonText || "Save Card"}
              </Button>
              {renderCancelButton(loading)}
            </Styled.ButtonWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export interface IEditPaymentProfileFormProps {
  /** handles automatic submission when this component is the child of another form */
  shouldAutoSubmit?: boolean;
  /** user's business profile */
  business?: IBusiness;
  /** customized button text */
  buttonText?: string;
  /** current user payment profile */
  existingPayment?: CreditDebitCardPaymentProfile | null;
  /** boolean if user has direct bill set up*/
  hasDirectBill?: boolean;
  /** Array of payment options */
  paymentProfiles?: IPaymentProfile[];
  /** callback that gets called when cancel button is clicked */
  onCancel?: () => void;
  /** callback that gets called when form is submitted */
  onSubmit: (payment: IPaymentProfile) => void;
  /** boolean if form to authorize Hotel Engine to charge card*/
  requireAuthorization?: boolean;
  /** optional form message */
  userMessage?: string;
  /** boolean if the form is editing an existing card or adding a new card to PP*/
  isNewCheckout?: boolean;
  /** number of times the form was submitted */
  submitAttempts?: number;
  /** Whether the form is rendering in a modal or not. Used in FlexPro enrollment, cc incidentals, and management */
  isRenderedInModal?: boolean;
  /** makes UI changes specific to the cc incidentals modal flow: shows the privacy policy, hides the cancel button, adjusts margin */
  isAutopayModal?: boolean;
}

export interface IEditPaymentProfileFormValues {
  authorized?: boolean;
  billingPostalCode?: string;
  default?: boolean;
  name?: string;
  nickname?: string | null;
}
