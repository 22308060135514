import { Box, Typography } from "@hotelengine/atlas-web";
import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";
import * as Styled from "./styles";
import { VehicleDetails } from "pages/Cars/components/CarCard/components/VehicleDetails";
import type { TCarFeatureInfo } from "pages/Cars/components/CarCard/constants";
import type { ICarCar } from "@hotel-engine/types/cars/cars.shared";

export interface ICarDetailsProps {
  car: ICarCar;
  features: TCarFeatureInfo[];
}

const CarDetails = ({ car, features }: ICarDetailsProps) => {
  const { getAcrissCategoryType } = useAcrissInfo();

  if (!car) return null;

  const carVariant = car?.variant ? `${car?.variant} or similar` : "";

  return (
    <Box marginTop={20}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography variant="body/md-strong" color="foregroundPrimary">
          {getAcrissCategoryType(car.category, car.type)}
        </Typography>
        <Typography variant="body/sm" color="foregroundSecondary">
          {carVariant}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={16}
        marginBottom={16}
      >
        <Styled.CarImage src={car.image} />
      </Box>
      <VehicleDetails features={features} isTrips />
    </Box>
  );
};

export default CarDetails;
