import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { ITrip, ITripsResult } from "@hotel-engine/types/trips";
import type { AxiosResponse } from "axios";
import TripsError from "../utils/TripsError";

interface ITripsContextValue {
  trips: ITrip[];
  limit: number;
  mostExpensiveTrip: number;
  leastExpensiveTrip: number;
  error?: TripsError;
}

const TripsContext = createContext<ITripsContextValue | undefined>(undefined);

interface ITripsProviderProps {
  children: ReactNode;
  tripsResult: AxiosResponse<ITripsResult>;
}

export function TripsProvider({ children, tripsResult }: ITripsProviderProps) {
  const isErroredResult = tripsResult instanceof TripsError;

  const value: ITripsContextValue = isErroredResult
    ? {
        trips: [],
        limit: 0,
        mostExpensiveTrip: 0,
        leastExpensiveTrip: 0,
        error: tripsResult,
      }
    : {
        trips: tripsResult.data?.trips || [],
        limit: tripsResult.data?.limit || 0,
        mostExpensiveTrip: Number(tripsResult.data?.mostExpensiveTrip) || 0,
        leastExpensiveTrip: Number(tripsResult.data?.leastExpensiveTrip) || 0,
      };

  return <TripsContext.Provider value={value}>{children}</TripsContext.Provider>;
}

export function useTrips(): ITripsContextValue {
  const context = useContext(TripsContext);

  if (context === undefined) {
    throw new Error("useTrips must be used within a TripsProvider");
  }

  return context;
}
