import { useCallback, useEffect, useMemo, useRef } from "react";
import type { IFilters, TripsFilterStatus } from "../contextLegacy";
import { useTripsLegacyContext } from "../contextLegacy";
import identifyMisplacedTrips from "./helpers/identifyMisplacedTrips";
import { DataDogTracingAttributes } from "@hotel-engine/constants/flightsSearch";
import { datadogLogs } from "@datadog/browser-logs";

interface ITripsFilter {
  status: TripsFilterStatus;
  filters: IFilters;
}

const useIdentifyMisplacedTrips = () => {
  const { state: tripsState } = useTripsLegacyContext();
  const tripsRef = useRef<ITripsFilter>({
    status: tripsState.status,
    filters: tripsState.filters,
  });

  const misplacedTrips = useMemo(() => {
    const data = identifyMisplacedTrips({
      trips: tripsState.trips,
      ...tripsRef.current,
    });

    return data;
  }, [tripsState.trips]);

  /**
   * The intent of this useEffect is to preserve the trips, so it can avoid catching valid trips
   * by identifyMisplacedTrips function. Because status changes synchronously, but trips not.
   */
  useEffect(() => {
    tripsRef.current = {
      status: tripsState.status,
      filters: tripsState.filters,
    };
  }, [tripsState.filters, tripsState.status]);

  const traceMisplacedTrips = useCallback(
    async (trips: ReturnType<typeof identifyMisplacedTrips>) => {
      for await (const trip of trips) {
        datadogLogs.logger.info(trip.message, {
          [DataDogTracingAttributes.BusinessId]: trip.businessId,
          "io-htleng-trip-id": trip.tripId,
          "io-htleng-status": trip.status,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (misplacedTrips.length > 0) {
      void traceMisplacedTrips(misplacedTrips);
    }
  }, [misplacedTrips, traceMisplacedTrips]);
};

export default useIdentifyMisplacedTrips;
