import type { CarRentalVendorAirportAccessType } from "@hotel-engine/types/cars/cars.shared";
import { useTranslation } from "react-i18next";

import { isCarsTranslationKey } from "../utilities";

export const useAirportAccess = () => {
  const { t, i18n } = useTranslation("cars");

  return {
    getAirportAccess: (
      airportAccessCode?: CarRentalVendorAirportAccessType
    ): string | undefined => {
      const translationKey = `airportAccess.${airportAccessCode}`;

      return isCarsTranslationKey(translationKey, i18n) ? t(translationKey) : undefined;
    },
  };
};
