import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { Button } from "@hotelengine/atlas-web";

interface IReviewCompleteButtonProps {
  dismissModal: () => void;
  referrer: string;
}
export const NotificationCompleteButton = ({
  dismissModal,
  referrer,
}: IReviewCompleteButtonProps) => {
  return (
    <Button data-testid="close-action-button" onClick={dismissModal}>
      {referrer !== "page" ? "Close" : `Go to ${COMPANY_NAME}`}
    </Button>
  );
};
