import { useLocation } from "@hotel-engine/lib/react-router-dom";

import { useBreakpoint } from "@hotel-engine/hooks";

import type { IReservationBase } from "@hotel-engine/types/reservation";

import type { IReservationBaseWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/lodging";
import type { ITripPreview } from "pages/Trips/contextLegacy";

import BookingSuccess from "../shared/BookingSuccess";
import CancelledBanner from "../shared/CancelledBanner";
import SameDayBanner from "./components/SameDayBanner";
import HeaderActions from "../shared/HeaderActions";
import ItineraryContentBody from "./components/ItineraryContentBody";
import PropertyDetails from "./components/PropertyDetails";

import { ModificationsProvider } from "../ModificationsContext";
import * as Styled from "../styles";
import moment from "moment";
import { Loader } from "../styles";
import { Box } from "@hotelengine/atlas-web";
import ManageTrip from "./components/ManageTrip";

export interface ILocationState {
  editCustomFields?: boolean;
  confirmation?: boolean;
  propertyQuery?: {
    propertyId: number;
    location: string;
    checkIn: string;
    checkOut: string;
    guestCount: string;
    roomCount: string;
    s: string;
  };
}

export interface ILodgingItineraryContentProps {
  /** Used to manage location specific logic depending on whether the user is on the Trips page or Itinerary page */
  isPreview: boolean;
  /** The reservation being displayed */
  itinerary: IReservationBase | IReservationBaseWithId;
  /** Used to refetch the reservation after modifications are made within the panel */
  refetchReservation: () => Promise<void> | void;
  /** Used to render loader while full reservation is being fetched on the Trips page */
  isLoading?: boolean;
  /** Only used when on Trips page preview panel close button is pressed */
  onClose?: ((tripPreview: ITripPreview) => void) | ((reservation: IReservationBase) => void);
}

const LodgingItineraryContent = ({
  isPreview,
  itinerary,
  refetchReservation,
  isLoading,
  onClose,
}: ILodgingItineraryContentProps) => {
  const isMobile = useBreakpoint("md", "max");
  const { state: locationState } = useLocation<ILocationState>();

  const isReservationCancelled = /cancelled/.test(itinerary.status);
  const isPartiallyCancelled = itinerary.rooms?.some((room) => room?.status === "cancelled");
  const isReservationPast = /completed/.test(itinerary.status);
  const shouldShowBookingConfirmation = !isReservationCancelled && !!locationState?.confirmation;
  const isPastCheckIn = moment().isAfter(moment(itinerary.checkIn).endOf("day"));
  const shouldShowSameDayWarning =
    !isReservationCancelled &&
    !isReservationPast &&
    !!itinerary.sameDayReservation &&
    !isPastCheckIn;
  const showLoader = isPreview && isLoading;

  const bookingConfirmationId = itinerary.propertyConfirmationNumber || itinerary.contractNumber;

  return (
    <ModificationsProvider
      reservation={itinerary}
      isPreview={isPreview}
      refetchReservation={refetchReservation}
    >
      {!!showLoader ? (
        <Box backgroundColor="backgroundSecondary" style={{ height: "100vh" }}>
          <Loader data-testid="itin-content-loader" size="lg" />
        </Box>
      ) : (
        <>
          <Box
            backgroundColor="backgroundSecondary"
            paddingRight={isPreview ? 8 : 0}
            paddingLeft={isPreview ? 8 : 0}
          >
            {!!shouldShowBookingConfirmation && (
              <Styled.SuccessWrapperContainer>
                <BookingSuccess propertyConfirmationNumber={bookingConfirmationId} />
              </Styled.SuccessWrapperContainer>
            )}
            {(!!isReservationCancelled || !!isPartiallyCancelled) && (
              <CancelledBanner
                isPartiallyCancelled={!!(isPartiallyCancelled && !isReservationCancelled)}
                isLodging
                isRefresh
              />
            )}
            {!!shouldShowSameDayWarning && <SameDayBanner />}
            <HeaderActions
              onClose={onClose}
              itinerary={isPreview ? (itinerary as IReservationBaseWithId) : undefined}
            />
            <Styled.PaddedItineraryContainer $isPreview={isPreview || isMobile}>
              <PropertyDetails />
              <ItineraryContentBody />
            </Styled.PaddedItineraryContainer>
          </Box>
          <ManageTrip />
        </>
      )}
    </ModificationsProvider>
  );
};

export default LodgingItineraryContent;
