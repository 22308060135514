import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { isCar, isFlight, isLodging } from "../../../../isLodging";

export enum CancellationType {
  Car = "car",
  CarInsurance = "carInsurance",
  FlexSingleRoom = "flex-single-room",
  FlexMultiRoom = "flex-multi-room",
  Flight = "flight",
  RefundableSingleRoom = "refundable-single-room",
  RefundableMultiRoom = "refundable-multi-room",
}

export const useCancellationType = () => {
  const { reservation } = useBaseModificationsContext();

  const isCarItinerary = isCar(reservation);
  const isFlightItinerary = isFlight(reservation);
  const isLodgingItinerary = isLodging(reservation);

  if (isFlightItinerary) {
    return {
      cancellationType: CancellationType.Flight,
      isFlexType: undefined,
      isSingleRoomType: undefined,
    };
  } else if (isCarItinerary) {
    return {
      cancellationType: CancellationType.Car,
      isFlexType: undefined,
      isSingleRoomType: undefined,
    };
  } else if (isLodgingItinerary) {
    const getSingleRoomCancellationType = () => {
      return {
        cancellationType: reservation.flexEnabled
          ? CancellationType.FlexSingleRoom
          : CancellationType.RefundableSingleRoom,
        isFlexType: reservation.flexEnabled,
        isSingleRoomType: true,
      };
    };

    const getMultiRoomCancellationType = () => {
      return {
        cancellationType: reservation.flexEnabled
          ? CancellationType.FlexMultiRoom
          : CancellationType.RefundableMultiRoom,
        isFlexType: reservation.flexEnabled,
        isSingleRoomType: false,
      };
    };

    if (reservation.roomCount === 1) {
      return getSingleRoomCancellationType();
    } else {
      return getMultiRoomCancellationType();
    }
  } else {
    return { cancellationType: undefined, isFlexType: undefined, isSingleRoomType: undefined };
  }
};
