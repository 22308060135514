import styled, { css } from "styled-components";
import { Button, IconButton } from "@hotelengine/atlas-web";

export const Container = styled.div`
  display: inline-block;
`;

export const FilterButton = styled(Button).attrs({
  variant: "outlined",
  size: "md",
})<{ $isApplied?: boolean }>`
  position: relative;
  padding-right: ${({ $isApplied }) => ($isApplied ? "32px" : "16px")};
  white-space: nowrap;
  min-width: fit-content;
  
  ${({ $isApplied }) =>
    $isApplied &&
    css`
    border-width: ${({ theme }) => theme.borderWidth.wide};
  `}

  &:focus-visible {
    box-shadow: none;
  }
`;

export const ResetIcon = styled(IconButton)<{ disabled?: boolean }>`
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  transform: 2px;
  border: none;
  background: none;
  padding: 4px 14px 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  
  &:hover {
    opacity: ${({ disabled }) => (disabled ? "0.5" : "0.8")};
    background: none !important;
  }
`;

export const ScrollableContent = styled.div`
  max-height: calc(70vh - 120px);
  overflow-y: auto;
  padding-right: 16px;
  margin-right: -16px;
`;

export const ResetButton = styled(Button).attrs({
  variant: "outlined",
  size: "md",
})`
  flex: 1;
`;

export const DoneButton = styled(Button).attrs({
  variant: "filled",
  size: "md",
})`
  flex: 1;
`;
