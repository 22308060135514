import styled from "styled-components";
import { FormControl, IconButton } from "@hotelengine/atlas-web";

export const SearchInputFormControl = styled(FormControl)`
    width: 100%;

    label {
        display: none;
    }
`;

export const SearchButton = styled(IconButton)`
    &:disabled {
        border-width: ${({ theme }) => theme.borderWidth.normal} !important;
        border-color: ${({ theme }) => theme.colors.borderDefault} !important;
        border-style: solid !important;
    }
 `;
