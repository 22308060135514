import styled from "styled-components";

export const InfoBox = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border-color: ${({ theme }) => theme.colors.borderSubtle};
  min-height: 48px;
`;

export const InfoSection = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoDivider = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px dashed;
  border-color: ${({ theme }) => theme.colors.borderSubtle};
`;

export const FlexMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px 16px 16px;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
