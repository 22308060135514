import { v4 as uuidv4 } from "uuid";
import type { ICalendarTraveler } from "@hotel-engine/types/reservation";
import { getContractDateSpan, sortContractsByEarliestCheckin } from "./helpers";
import * as Styled from "./styles";
import { Typography } from "@hotelengine/atlas-web";

interface ITravelerRow {
  /** Moment object representing end (Saturday) of week in view */
  endOfWeek: moment.Moment;
  /** Selected contract. Used to show selected state on the contract bar */
  selectedContract?: string;
  /** Sets selected contract */
  setSelectedContract: (contractNum: string) => void;
  /** Moment object representing start (Sunday) of week in view */
  startOfWeek: moment.Moment;
  /** The traveler represented by the current row */
  traveler: ICalendarTraveler;
}

export const TravelerRow = ({
  endOfWeek,
  selectedContract,
  setSelectedContract,
  startOfWeek,
  traveler,
}: ITravelerRow) => {
  const contractBars = sortContractsByEarliestCheckin(traveler.contracts).map((contract) => {
    const gridDateSpan = getContractDateSpan({
      contract,
      startOfWeek,
      endOfWeek,
    });

    const contractIsSelected =
      selectedContract === contract.groupContractId || selectedContract === contract.id;

    return (
      <Styled.Contract
        end={gridDateSpan.end}
        key={contract.id}
        onClick={() => setSelectedContract(contract.groupContractId ?? contract.id)}
        start={gridDateSpan.start}
        selected={contractIsSelected}
        status={contract.status}
        type="button"
      >
        <Styled.PropertyName selected={contractIsSelected} status={contract.status}>
          {contract.propertyName}
        </Styled.PropertyName>
      </Styled.Contract>
    );
  });

  const hasTraveler = !!traveler.firstName && !!traveler.lastName;
  return (
    <Styled.CalendarRow
      className={hasTraveler ? "" : "empty"}
      data-testid={`calRow-${traveler.firstName + traveler.lastName}`}
      key={`calRow-${uuidv4()}`}
    >
      <Styled.TravelerCell>
        <Typography variant="body/md" color="foregroundPrimary">
          {traveler.firstName} {traveler.lastName}
        </Typography>
        <Typography variant="body/sm" color="foregroundPrimary">
          {!!traveler.department && traveler.department}
          {!!traveler.department && !!traveler.employeeId && " · "}
          {!!traveler.employeeId && traveler.employeeId}
        </Typography>
      </Styled.TravelerCell>
      {/* Cells that are behind the contract bars */}
      <Styled.WeekGridCellContainer>
        {Array.from(Array(7).keys()).map((_, i) => (
          <Styled.Cell key={`gridCell-${uuidv4()}`} index={i} />
        ))}
      </Styled.WeekGridCellContainer>
      {contractBars}
    </Styled.CalendarRow>
  );
};
