import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Typography } from "@hotelengine/atlas-web";

import { useDatesInfo } from "../../../ItineraryDateRange/useDatesInfo";
import * as Styled from "../../styles";

export interface IBookingDetailsProps {
  itinerary: IReservationBase;
  roomIndex: number;
}

const RoomBookingDetails = ({ itinerary, roomIndex }: IBookingDetailsProps) => {
  const {
    dateOfCheckIn,
    formattedCheckInTime,
    checkinPropertyTimezone,
    dateOfCheckOut,
    formattedCheckoutTime,
    checkoutPropertyTimezone,
    nightCount,
  } = useDatesInfo(itinerary, roomIndex);

  const pillCopy = String(nightCount) + ` night${nightCount > 1 ? "s" : ""}`;

  return (
    <>
      <Styled.ListItem>
        <Typography variant="body/sm" color="foregroundSecondary">
          Check-in
        </Typography>
        <Typography
          variant="body/sm"
          color="foregroundPrimary"
          style={{ textAlign: "right" }}
          data-testid={`room-check-in-${roomIndex}`}
        >
          {`${dateOfCheckIn} • ${formattedCheckInTime || "4:00 pm"} ${checkinPropertyTimezone}`}
        </Typography>
      </Styled.ListItem>
      <Styled.ListItem>
        <Typography variant="body/sm" color="foregroundSecondary">
          Checkout
        </Typography>
        <Typography
          variant="body/sm"
          color="foregroundPrimary"
          style={{ textAlign: "right" }}
          data-testid={`room-check-out-${roomIndex}`}
        >
          {`${dateOfCheckOut} • ${formattedCheckoutTime || "11:00 am"} ${checkoutPropertyTimezone}`}
        </Typography>
      </Styled.ListItem>
      <Styled.ListItem>
        <Typography variant="body/sm" color="foregroundSecondary">
          Duration
        </Typography>
        <Typography variant="body/sm" color="foregroundPrimary" style={{ textAlign: "right" }}>
          {pillCopy}
        </Typography>
      </Styled.ListItem>
    </>
  );
};

export default RoomBookingDetails;
