import type React from "react";
import styled, { css } from "styled-components";

import { buttonReset } from "@hotel-engine/styles";
import { hexToRgba } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Button, Typography } from "@hotelengine/atlas-web";

export const ClusterMarker = styled(Button)<{
  className: string;
  $loading: boolean;
  $pointCount: number;
}>`
${buttonReset}
  background-color: ${({ theme }) => hexToRgba(theme.colors.foregroundPrimary, 0.5)};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  border: 0;
  transition: none;
  opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  min-height: unset;
  min-width: unset;


  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.colors.accentBlue, 0.5)} !important;

    p {
      background-color: ${({ theme }) => theme.colors.accentBlue};
    }
  }

  &.active {
    background-color: ${({ theme }) => hexToRgba(theme.colors.accentBlue, 0.5)} !important;

    p {
      background-color: ${({ theme }) => theme.colors.accentBlue};
    }
  }

  &.favorite {
    background-color: ${({ theme }) => hexToRgba(theme.colors.accentRed, 0.5)} !important;

    p {
      background-color: ${({ theme }) => theme.colors.accentRed};
    }

    &:hover,
    &.active {
        background-color: ${({ theme }) => hexToRgba(theme.colors.accentBlue, 0.5)} !important;

        p {
            background-color: ${({ theme }) => theme.colors.accentBlue};
        }
    }
  }

  &.preferred,
  &.specific {
    background-color: ${({ theme }) => hexToRgba(theme.colors.accentBlue, 0.5)} !important;

    p {
      background-color: ${({ theme }) => theme.colors.accentBlue};
    }

    &:hover,
    &.active {
        background-color: ${({ theme }) => hexToRgba(theme.colors.accentBlueLight, 0.5)} !important;

        p {
            background-color: ${({ theme }) => theme.colors.accentBlueLight};
        }
    }
  }

  ${({ $pointCount }) => {
    const isLargeCluster = $pointCount >= 10;
    const isXLargeCluster = $pointCount >= 25;
    const clusterSpacing = {
      pSpacing: 24,
      positioning: 16,
      padding: 4,
    };

    if (isXLargeCluster) {
      clusterSpacing.pSpacing = 40;
      clusterSpacing.positioning = 26;
      clusterSpacing.padding = 6;
    } else if (isLargeCluster) {
      clusterSpacing.pSpacing = 32;
      clusterSpacing.positioning = 22;
      clusterSpacing.padding = 6;
    }

    return css`
      margin-left: ${({ theme }) => `-${theme.spacing[clusterSpacing.positioning]}`};
      margin-top: ${({ theme }) => `-${theme.spacing[clusterSpacing.positioning]}`};
      padding: ${({ theme }) => theme.spacing[clusterSpacing.padding]};

      p {
        height: ${({ theme }) => theme.spacing[clusterSpacing.pSpacing]};
        line-height: ${({ theme }) => theme.spacing[clusterSpacing.pSpacing]};
        width: ${({ theme }) => theme.spacing[clusterSpacing.pSpacing]};
      }
    `;
  }}
`;

export const ClusterText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/md",
})`
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  background-color: ${({ theme }) => theme.colors.backgroundInverse};
  color: ${({ theme }) => theme.colors.foregroundInverse};
  margin-bottom: 0;
`;
