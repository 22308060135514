import { ampli } from "ampli";
import { useFormikContext } from "formik";
import { Typography } from "@hotelengine/atlas-web";

import Button from "@hotel-engine/common/Button";
import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";

import { ExpressBookSelectedGuest } from "../ExpressBookSelectedGuest/ExpressBookSelectedGuest";
import { ExpressBookSearchGuest } from "../ExpressBookSearchGuest/ExpressBookSearchGuest";

import * as Styled from "./styles";
import { useAppSelector } from "store/hooks";

export const ExpressBookAdditionalGuest = ({ index }: { index: number }) => {
  const form = useFormikContext<IExpressBookCheckoutForm>();

  // Grabs necessary info for usage tracking //
  const amplitudeAnalytics = useAppSelector((store) => store.ExpressBook.analytics);

  const selectedAdditionalGuestCount = form.values?.guestList?.reduce((acc, curr) => {
    let additionalGuestCount = 0;

    if (!!curr.secondary?.user) {
      additionalGuestCount++;
    }

    return acc + additionalGuestCount;
  }, 0);
  const EXPRESS_BOOK_ROOM_COUNT = 1; // Defaulting for now, but may open up EB to handle multiple rooms in the future
  const guestCount = useAppSelector((store) => store.ShareOptions.search.guestCount || 1);
  const availableSecondaryRooms = guestCount - EXPRESS_BOOK_ROOM_COUNT;

  const leftOverSecondaryGuests = availableSecondaryRooms - selectedAdditionalGuestCount;

  const secondaryMode = form.values.guestList[index].secondary.mode;

  // logic for displaying the Additional Guest Button
  const shouldShowAdditionalGuestButton =
    leftOverSecondaryGuests > 0 && form.values.guestList[index].primary.mode === "selected";

  const shouldShowRemoveGuest =
    secondaryMode !== "hidden" && form.values.guestList[index].primary.mode === "selected";

  const onGuestRemove = () => {
    form.setFieldValue(`guestList.${index}`, {
      ...form.values.guestList[index],
      secondary: { user: undefined, mode: "hidden" },
    });
    ampli.clickRemoveGuest(amplitudeAnalytics);
  };

  const onAdditionalGuest = () => {
    form.setFieldValue(`guestList.${index}`, {
      ...form.values.guestList[index],
      secondary: { user: undefined, mode: "search" },
    });
    ampli.clickAdditionalGuest(amplitudeAnalytics);
  };

  const selectedUser = form.values.guestList[index].secondary.user;
  let secondaryGuest: JSX.Element | undefined = undefined;
  if (secondaryMode === "selected" && selectedUser) {
    secondaryGuest = <ExpressBookSelectedGuest guestType="secondary" index={index} />;
  }

  if (secondaryMode === "search") {
    secondaryGuest = <ExpressBookSearchGuest guestType="secondary" index={index} />;
  }

  return shouldShowRemoveGuest ? (
    <>
      <Styled.RemoveGuest>
        <Typography variant="body/sm" as="p" margin={0}>
          Guest
        </Typography>
        <Button type="link" onClick={onGuestRemove}>
          <Typography variant="body/sm" as="p" margin={0} marginLeft={8} color="accentRedLight">
            Remove
          </Typography>
        </Button>
      </Styled.RemoveGuest>
      {secondaryGuest}
    </>
  ) : (
    <>
      {!!shouldShowAdditionalGuestButton && (
        <>
          <Styled.AdditionalGuestButton onClick={onAdditionalGuest} data-testid="add-guest-button">
            <Styled.AdditionalGuestButtonIcon name="plus" color="accentBlueStrong" /> Additional
            Guest
          </Styled.AdditionalGuestButton>
          <Typography variant="body/xs" as="p" margin={0} marginTop={8} color="accentGrayLight">
            You can include up to 1 additional guest for this room
          </Typography>
        </>
      )}
    </>
  );
};
