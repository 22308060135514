import { Icon } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

interface IExpressBookPaymentErrorBannerProps {
  errors: string[];
}

export const ExpressBookPaymentErrorBanner = ({ errors }: IExpressBookPaymentErrorBannerProps) => {
  return (
    <Styled.ExpressBookPaymentErrorBannerWrapper>
      <Icon name="circle-exclamation--solid" color="sentimentCriticalDefault" size="md" />
      <Styled.ExpressBookPaymentErrorBannerColumn>
        <Styled.ExpressBookPaymentErrorBannerTitle>
          Payment Error
        </Styled.ExpressBookPaymentErrorBannerTitle>
        <ul>
          {errors.map((error, erroridx) => (
            <li key={erroridx}>{error}</li>
          ))}
        </ul>
      </Styled.ExpressBookPaymentErrorBannerColumn>
    </Styled.ExpressBookPaymentErrorBannerWrapper>
  );
};
