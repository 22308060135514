import { Box, BrandLogo, Typography, useTheme } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";

import { useAppSelector } from "store/hooks";

const TripPriceCell = ({ booking, total }) => {
  const { tokens } = useTheme();
  const user = useAppSelector((state) => state.Auth?.user);
  const currencyCode = user?.business?.currencyCode || "USD";

  const showFlexBadge = !!booking.details.bookingStatuses?.flexEnabled;
  /**
   * We don't necessarily want to show this if it's undefined/falsy since that just means we don't know,
   * only show if explicitly not refundable from Nexus. We also want to override this if flexEnabled.
   */
  const showNonRefundableBadge =
    booking.details.bookingStatuses?.refundable === false && !showFlexBadge;

  const FlexBadge = () =>
    showFlexBadge ? (
      <BrandLogo
        name="flex--lockup"
        size={13}
        style={{ color: tokens.colors.foregroundTertiary }}
      />
    ) : null;

  const NonRefundableBadge = () =>
    showNonRefundableBadge ? (
      <Typography variant="body/xs" color="foregroundTertiary" paddingTop={2}>
        Non-refundable
      </Typography>
    ) : null;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={12}
        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        <Typography variant="body/sm" color="foregroundPrimary" data-testid="trip-total">
          {formatCurrency(total, true, currencyCode)}
        </Typography>
        <FlexBadge />
      </Box>
      <NonRefundableBadge />
    </>
  );
};

export default TripPriceCell;
