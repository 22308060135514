import { getClient } from "@hotel-engine/client";
import type { ITripsResult } from "@hotel-engine/types/trips";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { TripsQueryParams } from "./querySchema";
import { AxiosError } from "axios";
import TripsError from "../utils/TripsError";

export function fetchTripsNonAwait(params: TripsQueryParams) {
  return getClient()
    .get<ITripsResult>(endpoints.trips, { params })
    .catch((exception) => {
      if (exception instanceof AxiosError) {
        const error = exception.response?.data || {};
        const code = exception.response?.status || exception.status;

        return new TripsError({ ...error, code });
      }

      return new TripsError({
        title: (exception as Error)?.message || "Unknown error",
        code: 500,
        invalidParams: [],
        fieldErrors: [],
      });
    });
}
