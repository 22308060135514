import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import CopiableConfirmation from "@hotel-engine/app/ItineraryContent/shared/CopiableConfirmation";
import { Box, Typography } from "@hotelengine/atlas-web";
import { useTheme } from "styled-components";
import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";

const CarDetails = () => {
  const { colors, borderRadius } = useTheme();
  const { reservation } = useCarModificationsContext();
  const { getAcrissCategoryType } = useAcrissInfo();

  const carVariant = reservation?.car?.variant ? `${reservation?.car?.variant} or similar` : "";

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={24}
        gap={16}
        style={{ width: "100%", height: "246px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor="foregroundInverse"
          style={{
            width: "144px",
            height: "144px",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: colors.borderMinimal,
            borderRadius: borderRadius[8],
          }}
        >
          <Box display="flex" alignItems="center" padding={8} gap={6}>
            {!reservation?.rentalCompany?.logo ? (
              <Typography
                variant="uppercase/xs"
                color="foregroundSecondary"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {reservation.rentalCompany.name}
              </Typography>
            ) : (
              <Box paddingTop={2} paddingLeft={2}>
                <Box
                  as="img"
                  src={reservation.rentalCompany.logo}
                  width="auto"
                  height="10px"
                  alt="rental car logo"
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    borderRadius: borderRadius[4],
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ flex: 1 }}
            paddingBottom={12}
          >
            <Box
              as="img"
              src={reservation.car.image}
              width="100%"
              height="auto"
              alt="vehicle preview"
              padding={2}
              style={{
                objectFit: "contain",
                objectPosition: "top center",
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Typography variant="heading/xl" color="foregroundPrimary">
            {getAcrissCategoryType(reservation.car.category, reservation.car.type)}
          </Typography>
          <Typography variant="body/sm" color="foregroundSecondary">
            {carVariant}
          </Typography>
        </Box>
      </Box>
      <CopiableConfirmation
        confirmationNumber={reservation.carConfirmation}
        bookingNumber={reservation.bookingNumber}
      />
    </Box>
  );
};

export default CarDetails;
