import type { ICalendarTraveler } from "@hotel-engine/types/reservation";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { UseQueryOptions } from "react-query";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

export interface ICalendaryTripsParams {
  startDate: string;
  endDate: string;
  manual_bookings?: boolean;
  refundableOnly?: boolean;
  totalGt?: number;
  totalLt?: number;
  unverified?: boolean;
  pendingModificationOnly?: boolean;
  contractType?: string;
  departments?: string[];
  onlyMyTrips?: boolean;
  search?: string[];
  sort?: string;
}

export const useCalendarTripsQuery = (
  params: ICalendaryTripsParams,
  options?: UseQueryOptions<ICalendarTraveler[], IErrorResponse>
) => {
  const get = useApi("get");

  return useExtendedQuery<ICalendarTraveler[], IErrorResponse>(
    [endpoints.calendarTrips, params],
    () =>
      get<ICalendarTraveler[]>(endpoints.calendarTrips, {
        ...params,
        status: ["booked", "visiting", "completed", "cancelled"],
      }),
    options
  );
};
