import { initialMapOptions } from "./constants";

export function returnMapOptions(options: Partial<google.maps.MapOptions>) {
  return {
    ...initialMapOptions,
    ...options,
  };
}

/** Check if full screen is active */
export function isFullScreen() {
  return Boolean(document.fullscreenElement);
}

/** Check if full screen is active
 * if not get map element and requestFullscreen
 */
export function expandMap(map: google.maps.Map, controlElement: HTMLElement | null) {
  if (isFullScreen()) {
    void document.exitFullscreen();
    return false;
  } else {
    const el = map?.getDiv();

    if (!!controlElement) {
      const controls = map.controls[google.maps.ControlPosition.LEFT_TOP];
      if (!controls.getLength()) {
        controls.push(controlElement);
      }
    }

    // Expansion to full-screen won't work on actual mobile device browsers, as document.fullscreenEnabled is unsupported and returns undefined
    if (document.fullscreenEnabled) {
      void el?.requestFullscreen();
    }
    return;
  }
}
