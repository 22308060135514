import { useEffect } from "react";

import storage from "@hotel-engine/storage";
import config from "config";

/**
 * Initializes Google Analytics scripts on mount.
 */
export function useInitGoogleAnalytics(forceEnable = false) {
  useEffect(() => {
    // get nonce from an existing script tag implemented via the cloudflare worker
    const existingScript = document.querySelector("script[nonce]");
    const nonce = existingScript?.getAttribute("nonce") || "";

    const script = document.createElement("script");
    script.async = true;
    script.setAttribute("nonce", nonce);
    script.src = `https://www.googletagmanager.com/gtm.js?id=${config.gtmId}`;

    globalThis.dataLayer = globalThis.dataLayer || [];
    globalThis.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    const noScript = document.createElement("noscript");
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.gtmId}" height="0" width="0"
            style="display:none;visibility:hidden"></iframe>`;

    if (!globalThis.Cypress && (forceEnable || !!storage.getSecureItem("googleTagManager"))) {
      document.head.insertBefore(script, document.head.firstChild);
      globalThis.dataLayer = [];
      globalThis.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      globalThis.document.body.insertBefore(noScript, globalThis.document.body.firstChild);
    }

    return () => {
      globalThis.document.body.removeChild(noScript);
      globalThis.document.head.removeChild(script);
    };
  }, [forceEnable]);
}
