import styled, { css } from "styled-components";

export const SectionDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralN100};
  height: 1px;
  margin: ${({ theme }) => `${theme.spacing[16]} -${theme.spacing[16]}`};

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.md}) {
      margin: ${theme.spacing[0]} -${theme.spacing[40]}};
    }
  `}

`;
