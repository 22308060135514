import styled, { css } from "styled-components";

import { FormItem } from "@hotel-engine/common/Form";
import { Input } from "@hotel-engine/common/Inputs";
import { colors, sizes } from "@hotel-engine/styles";
import { Dimensions } from "@hotel-engine/utilities";

import InputField from "./InputField";
import type { ICardInputs } from "./SpreedlyCardInput";

export const CardholderName = styled(InputField)`
  margin-bottom: 16px;
`;

export const CardNumber = styled.div<ICardInputs>`
  border: ${({ cardNumberInvalid, theme }) =>
    cardNumberInvalid ? `1px solid ${colors.khmerCurry}` : `1px solid ${theme.colors.borderSubtle}`};
  background: ${({ disabled }) => disabled && colors.grey[50]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  flex: 2;
  height: 40px;
  padding: 0 10px;
  width: 100%;
`;

export const SecurityCode = styled.div<ICardInputs>`
  border: ${({ cvvInvalid, theme }) =>
    cvvInvalid ? `1px solid ${colors.khmerCurry}` : `1px solid ${theme.colors.borderSubtle}`};
  background: ${({ disabled }) => disabled && colors.grey[50]};
  pointer-events: ${({ disabled }) => disabled && "none"};
  display: ${({ hidden }) => hidden && "none"};
  height: 40px;
  padding-left: 11px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
`;

export const SplitFormGroup = styled.div<{
  $isRenderedInModal: boolean;
  $isAutopayModal: boolean;
}>`
  ${({ $isAutopayModal, $isRenderedInModal }) =>
    $isRenderedInModal && !$isAutopayModal && "margin-bottom: 24px;"}
  ${({ $isRenderedInModal }) =>
    $isRenderedInModal
      ? css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .ant-row:first-of-type {
            margin-right: 8px;
          }
        `
      : css`
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: ${sizes.breakpoints.lg}) {
            flex-wrap: wrap;
          }
        `};
`;

export const FormItemCC = styled(FormItem)<{ $isRenderedInModal: boolean }>`
  ${({ $isRenderedInModal }) =>
    $isRenderedInModal
      ? css`
          flex-basis: 49%;
        `
      : css`
          flex-basis: 32%;

          @media screen and (max-width: ${sizes.breakpoints.lg}) {
            flex-basis: 48%;
          }
        `};
`;

export const CreditCardInput = styled(Input)<ICardInputs>`
  background: ${({ disabled }) => disabled && colors.grey[50]};
  width: 100%;
`;

export const ZipCode = styled(InputField)<{ $isRenderedInModal: boolean }>`
  ${({ $isRenderedInModal }) =>
    $isRenderedInModal
      ? css`
          flex-basis: 100%;
        `
      : css`
          flex-basis: 32%;

          @media screen and (max-width: ${sizes.breakpoints.lg}) {
            flex-basis: 100%;
          }
        `};
`;

type IFromSection = { addMargin?: boolean };

export const FromSection = styled.div<IFromSection>(({ addMargin }) => [
  css`
    margin: 0;

    &.ant-form,
    .ant-form-item-label {
      line-height: ${({ theme }) => theme.legacy.lineHeight.sm};

      label {
        margin-bottom: 4px;
        display: block;
      }
    }
    .ant-row.ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-explain {
      font-weight: 400;
    }
    .ant-input.ant-input-lg {
      font-size: 14px;
    }
  `,

  addMargin &&
    css`
      margin-left: 35px;
      margin-right: 24px;
    `,
]);

export const spreedlyStyle = (error: boolean, existingPayment: boolean): string => {
  const baseStyle = `width: 100%; height: 40px; font-size: 14px; font-family: "Lato", helvetica, sans-serif; box-sizing: border-box; line-height: 20px; color: rgba(0, 0, 0, 0.65);`;
  return `${baseStyle} ${Dimensions.mobile && "font-weight: 300;"} ${
    existingPayment && "pointer-events: none; cursor: not-allowed;"
  } ${error && `color: ${colors.red}`}`;
};
