import { ampli } from "ampli";

import { Skeleton } from "@hotelengine/atlas-web";
import type { ITrip } from "@hotel-engine/types/trips";

import { useAppSelector } from "store/hooks";

import { TripsListItemContent } from "../TripsListItemContent";
import * as Styled from "./styles";

interface ITripsListItemProps {
  item: ITrip;
  loading: boolean;
  selected?: boolean;
  onSelection: (item: ITrip) => void;
  displayChevron?: boolean;
}

const TripsListItem = ({
  item,
  loading,
  selected,
  onSelection,
  displayChevron,
}: ITripsListItemProps) => {
  const user = useAppSelector((state) => state.Auth.user);

  return (
    <Skeleton isLoaded={!loading} style={{ width: "100%" }}>
      <Styled.TripsListItem
        onClick={() => {
          ampli.clickTripCard({
            tripId: item.id,
            userId: user?.id,
          });
          onSelection(item);
        }}
        selected={!!selected}
      >
        <Styled.GlobalListItemStyles />
        <TripsListItemContent trip={item} />
        {!!displayChevron && <Styled.Chevron name="chevron-right" size="md" />}
      </Styled.TripsListItem>
    </Skeleton>
  );
};

export default TripsListItem;
