import { Box, Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import type { IFlightCharges } from "@hotel-engine/types/itinerary";
import { capitalize } from "@hotel-engine/utilities/formatters";
import { Loader } from "@hotel-engine/components/Loader";
import { FlightsTriggerOnCancel } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

import type { FlightRefundInfo } from "../../../../hooks/usePreviewFlightRefund";
import * as Styled from "../../styles";
import * as S from "./styles";
import { useSupportPhone } from "../../../../hooks/useSupportPhone";

export interface IRefundInfoProps {
  charges: IFlightCharges;
  isLoading: boolean;
  refundInfo: FlightRefundInfo;
}

const RefundInfo = ({ charges, isLoading, refundInfo }: IRefundInfoProps) => {
  const { typographyVariants } = useTypographyScaling();

  const supportPhone = useSupportPhone();
  const formattedAmount = formatCurrency(refundInfo?.refundAmount || 0);

  const getContent = () => {
    if (isLoading) {
      return <Loader data-testid="loader" color="foregroundPrimary" />;
    }

    if (
      !refundInfo ||
      refundInfo?.triggerOnCancel === FlightsTriggerOnCancel.OpenSupportRequestToCancel
    ) {
      return (
        <S.CancelDescriptionParagraph variant={typographyVariants.body}>
          Requesting cancellation will notify our support team to reach out within 24 hours.
        </S.CancelDescriptionParagraph>
      );
    }

    if (refundInfo.triggerOnCancel === FlightsTriggerOnCancel.CancelWithNoRefund) {
      return (
        <S.CancelDescriptionParagraph variant={typographyVariants.body}>
          This is a non-refundable flight.
        </S.CancelDescriptionParagraph>
      );
    }

    if (refundInfo.triggerOnCancel === FlightsTriggerOnCancel.CancelWithUnknownRefund) {
      return (
        <Box display="flex" flexDirection="column" gap={16}>
          <S.CancelDescriptionParagraph variant={typographyVariants.body}>
            The final refund amount will be reflected on your payment method's billing statement.
          </S.CancelDescriptionParagraph>
          <S.CancelDescriptionParagraph variant={typographyVariants.body}>
            For an estimated refund quote, please contact Engine support at{" "}
            <Typography
              aria-label="Support phone"
              as="a"
              color="foregroundPrimary"
              href={`tel:${supportPhone}`}
              title="Support number"
              variant={typographyVariants.body}
            >
              {supportPhone}
            </Typography>
            .
          </S.CancelDescriptionParagraph>
        </Box>
      );
    }

    return (
      <Styled.InfoContainer>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            Refund method
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {refundInfo?.refundType}
          </Typography>
        </Styled.ListItem>
        {charges.fees.map((fee) => (
          <Styled.ListItem key={fee.description}>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              {capitalize(fee.description)}
            </Typography>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              {formatCurrency(fee.amount)}
            </Typography>
          </Styled.ListItem>
        ))}
        <Styled.ListItem>
          <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
            Total refund
          </Typography>
          <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
            {formattedAmount}
          </Typography>
        </Styled.ListItem>
      </Styled.InfoContainer>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent={isLoading || !refundInfo ? "center" : "flex-start"}
      alignItems="center"
      marginTop={24}
    >
      {getContent()}
    </Box>
  );
};

export default RefundInfo;
