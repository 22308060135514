import { Popover, PopoverContent, PopoverTrigger, Box, Button } from "@hotelengine/atlas-web";
import DropdownContent from "./DropdownContent";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";

export interface IFiltersDropdownProps {
  isCalendarView: boolean;
  isMapView: boolean;
}

const FiltersDropdown = ({ isCalendarView, isMapView }: IFiltersDropdownProps) => {
  const { state: tripsState } = useTripsLegacyContext();

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant="outlined"
          size="sm"
          leadingIcon="sliders"
          id="trips_filterDropdown"
          disabled={!!tripsState.error}
        >
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" style={{ maxWidth: "unset" }}>
        <Box style={{ maxHeight: 500, overflow: "auto" }}>
          <DropdownContent isCalendarView={isCalendarView} isMapView={isMapView} />
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default FiltersDropdown;
