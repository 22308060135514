import type { ICarLocation } from "@hotel-engine/types/cars/cars.shared";

export const getFullQueryAddress = (location: ICarLocation) => {
  return [location?.street, location?.city, location?.state, location?.country]
    .filter(Boolean)
    .join(", ");
};

export const getMapDisplayLocation = (location: ICarLocation) => {
  return [location?.city, location?.state, location?.country].filter(Boolean).join(", ");
};
