import { ampli } from "ampli";

import { Button, Typography } from "@hotelengine/atlas-web";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import type { ICarItinerary, IFlightItinerary } from "@hotel-engine/types/itinerary";

import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import ModalWrapper from "../../../../ModalWrapper";
import { PaymentTypeLabel } from "@hotel-engine/constants/cars";
import { useTranslation } from "react-i18next";
// import CancellationReason from "./CancellationReason";

export interface ICancelConfirmationModalProps {
  cancelRoomCount?: number;
  onConfirm: () => void;
  isFlight?: boolean;
  isCar?: boolean;
  isNonAPIRequest?: boolean;
}

/**
 * @prop cancelRoomCount?: The number of rooms being canceled, used for generating copy, defaults to 1 if not provided
 * @prop onConfirm: The action to be performed when Confirm is clicked, generally triggers the request submission
 */
const CancelConfirmationModal = ({
  cancelRoomCount,
  onConfirm,
  isFlight,
  isCar,
  isNonAPIRequest,
}: ICancelConfirmationModalProps) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const { t } = useTranslation("cars_trips");

  const { dispatch, reservation } = useBaseModificationsContext();
  //   const [cancellationReason, setCancellationReason] = useState<
  //     string | undefined
  //   >(undefined);

  const closeConfirmationModal = () => {
    dispatch({
      type: "SET_SHOW_CONFIRMATION_MODAL",
      payload: false,
    });
  };

  const handleSubmitCancellation = () => {
    if (isFlight) {
      ampli.clickConfirmFlightCancellation({
        userId: user?.id,
        flightBookingID: (reservation as IFlightItinerary)?.bookingNumber,
      });
    }

    if (isCar) {
      ampli.clickConfirmCarRentalCancellation({
        userId: user?.id,
        carBookingID: (reservation as ICarItinerary)?.bookingNumber,
        paymentType: PaymentTypeLabel[(reservation as ICarItinerary).paymentType] || "Unknown",
      });
    }

    onConfirm();
    closeConfirmationModal();
  };

  const getCancelRoomCopy = () => {
    if (!!cancelRoomCount) {
      return `${cancelRoomCount} room${cancelRoomCount > 1 ? "s" : ""}`;
    } else {
      return "this reservation";
    }
  };

  const getCancelCopy = () => {
    if (isFlight) {
      return "Please confirm you want to cancel this reservation for all passengers and flights as this can't be undone.";
    } else if (isCar) {
      return t("cancelModal.description");
    } else {
      return `Please confirm you want to cancel ${getCancelRoomCopy()} as this can't be undone.`;
    }
  };

  const modalBody = (
    <>
      <Typography variant="heading/lg" color="foregroundPrimary" as="h2">
        {isNonAPIRequest
          ? "Are you sure you would like to request cancellation?"
          : isCar
            ? t("cancelModal.title")
            : "Are you sure you want to cancel?"}
      </Typography>
      <Typography variant="body/md" color="foregroundPrimary" as="p">
        {getCancelCopy()}
        {isNonAPIRequest ? " This will notify our support team to reach out within 24 hours." : ""}
      </Typography>
      {/* Commenting this for now as we may use it eventually, but awaiting further requirements */}
      {/* <CancellationReason setCancellationReason={setCancellationReason} /> */}
      <Styled.ButtonContainer>
        <Button color="secondary" variant="outlined" onClick={closeConfirmationModal}>
          Go back
        </Button>
        <Button onClick={handleSubmitCancellation} color="destructive">
          Confirm
        </Button>
      </Styled.ButtonContainer>
    </>
  );

  return (
    <ModalWrapper dataTestId="cancel-confirm-modal" onCancel={closeConfirmationModal}>
      {modalBody}
    </ModalWrapper>
  );
};

export default CancelConfirmationModal;
