import { useToggle } from "@hotel-engine/hooks";
import type { ICarItinerary } from "@hotel-engine/types/itinerary";
import { Box, Button, Chip, Divider, Typography } from "@hotelengine/atlas-web";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { ampli } from "ampli";
import { useCarCardFeatures } from "pages/Cars/components/CarCard/useCarCardFeatures";
import { useAppSelector } from "store/hooks";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import { RentalCompanyImage } from "../../styles";
import { constructCarsEntryFromCarsItinerary } from "../helpers";
import BookingTime from "./components/BookingTime";
import CarDetails from "./components/CarDetails";

export interface IBookingDetailsProps {
  logoSrc?: string;
  details: ICarItinerary;
  initialExpanded?: boolean;
  hideToggleButton?: boolean;
}

const BookingDetails = ({
  logoSrc,
  details,
  initialExpanded = false,
  hideToggleButton = false,
}: IBookingDetailsProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const [isExpanded, toggleIsExpanded] = useToggle(initialExpanded);
  const showPostpaidRates = useIsFeatureFlagOn("cars_return_postpaid_rates");

  const carEntry = constructCarsEntryFromCarsItinerary(details);

  const features = useCarCardFeatures(carEntry, undefined, undefined, {
    showPostpaidRates,
  });
  const handleToggleDetails = () => {
    toggleIsExpanded();

    if (user && !isExpanded) {
      ampli.clickShowCarDetails({
        carBookingID: details.bookingNumber,
        userId: user.id,
      });
    }
  };

  const isFullyCancelled = !!details.isFullyCancelled;

  return (
    <CollapsibleContent
      toggleTestID="toggle-booking-details"
      toggleBtnLabel={
        <Box display="flex" flexDirection="column" gap={16}>
          {!!isFullyCancelled && (
            <Box display="flex" justifyContent="flex-start">
              <Chip
                label="CANCELLED"
                color="red"
                size="xs"
                leadingIcon="circle-exclamation"
                style={{ borderRadius: "4px" }}
              />
            </Box>
          )}
          <Box display="flex" gap={12} alignItems="center">
            <Typography variant="heading/lg">Your booking</Typography>
          </Box>
        </Box>
      }
      defaultOpen
    >
      <Box display="flex" flexDirection="column" padding={24} paddingTop={4} gap={12}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={8}
        >
          {!logoSrc ? (
            <Typography variant="body/md-strong" color="foregroundPrimary">
              {details.rentalCompany.name}
            </Typography>
          ) : (
            <RentalCompanyImage src={logoSrc} $size={60} width={60} />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={12} marginBottom={8}>
          <BookingTime time={details.startTime} type="pickup" />
          <BookingTime time={details.endTime} type="dropoff" />
        </Box>
        {!!isExpanded && (
          <Box>
            <Divider variant="dotted" />
            <CarDetails car={details.car} features={features} />
          </Box>
        )}

        {!Boolean(hideToggleButton) && (
          <Button
            variant="outlined"
            size="lg"
            onClick={handleToggleDetails}
            data-testid="toggle-details"
          >
            {!isExpanded ? "Show details" : "Hide details"}
          </Button>
        )}
      </Box>
    </CollapsibleContent>
  );
};

export default BookingDetails;
