import type { MapPosition } from "@hotel-engine/app/GoogleMap/types";
import { getClusterVariant } from "../GoogleSearchResultsMap/searchResultsHelpers";
import OverlayView from "../OverlayView";
import * as Styled from "./styles";

type PropertyClusterProps = {
  /** Data object used to get cluster variant */
  clusterObj: {
    /** Unique id for the cluster */
    clusterId: number;
    /** Array of properties that are preferred for that user */
    preferredPropertyIds?: number[];
    /** Array of properties that are hidden for that user */
    hiddenPropertyIds?: number[];
    /** Used to track hover state on the card results in the list and show on clusters */
    listHighlightedResultId?: number | null | undefined;
    /** Id of the selected result based on hover/click state */
    selectedResultId?: number;
    /** The number of properties in the cluster */
    point_count: number;
    /** If there is a specific property */
    specificLocationId?: string;
  };
  /** Callback to zoom in on that cluster */
  handleSelectCluster: () => void;
  /** Used for faded loading state */
  loading?: boolean;
  /** Google map instance */
  map: google.maps.Map;
  /** Used to position overlay containing cluster */
  position: MapPosition;
};

const PropertyCluster = ({
  clusterObj,
  handleSelectCluster,
  loading = false,
  map,
  position,
}: PropertyClusterProps) => {
  const { point_count } = clusterObj;
  const classNames = getClusterVariant(clusterObj);
  return (
    <OverlayView position={position} map={map}>
      <Styled.ClusterMarker
        $pointCount={point_count}
        $loading={loading}
        className={classNames}
        onClick={handleSelectCluster}
        data-testid="cluster-marker"
        variant="plain"
      >
        <Styled.ClusterText color="foregroundInverse" as="p">
          {point_count}
        </Styled.ClusterText>
      </Styled.ClusterMarker>
    </OverlayView>
  );
};

export default PropertyCluster;
