import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { Modal } from "@hotel-engine/common/Modal";
import { colors, sizes } from "@hotel-engine/styles";

export const Banner = styled.div`
  ${({ theme }) => theme.typography.desktop["body/sm"]};
  background-color: ${colors.grey[50]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  color: ${colors.black};
  display: flex;
  justify-content: space-around;
  @media (min-width: 454px) {
    max-height: 70px;
  }
  max-width: 436px;
  margin-top: 6px;
  padding: 6px;
  padding-right: 10px;
`;

export const BannerIcon = styled(Icon)`
  margin-right: 6px;
  margin-top: 3px;
`;

export const BannerText = styled.p`
  ${({ theme }) => theme.typography.desktop["body/sm"]};
  line-height: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${sizes.breakpoints.lg}) {
    flex-wrap: wrap;

    .ant-btn {
      max-width: 100%;
      &:nth-of-type(1) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const ButtonOptions = styled(Button)`
  ${({ theme }) => theme.typography.desktop["body/md"]};
  height: 40px;
  max-width: 190px;
  width: 100%;
`;

export const Header = styled.h1`
  ${({ theme }) => theme.typography.desktop["heading/xl"]};
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 30px;
`;

export const OptionsText = styled.p`
  ${({ theme }) => theme.typography.desktop["body/sm"]};
  align-content: center;
  color: ${colors.black};
  margin-bottom: 22px;
  margin-top: 4px;
`;

export const OrLine = styled.hr`
  align-self: center;
  border-top: 1px dashed ${colors.grey[200]};
  max-width: 182px;
  width: 100%;
`;

export const OrText = styled.p`
  margin: 8px;
`;

export const OrContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 20px;
  width: 100%;
`;

export const OverlappingContractModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${sizes.breakpoints.sm}) {
    padding: 0 !important;

    &.ant-modal {
      top: 0 !important;
    }
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
