import { Box } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
interface ISectionListProps<T> {
  sections: Array<{
    title: string;
    data: T[];
  }>;
  renderItem: (item: T) => React.ReactNode;
  renderSectionHeader: (title: string) => React.ReactNode;
  keyExtractor: (item: T) => string;
}

function SectionList<T>({
  sections,
  renderItem,
  renderSectionHeader,
  keyExtractor,
}: ISectionListProps<T>) {
  return (
    <Box display="flex" flexDirection="column" gap={16} marginTop={16}>
      {sections.map((section) => (
        <Box key={section.title} display="flex" flexDirection="column" gap={16}>
          {renderSectionHeader(section.title)}
          {section.data.map((item) => (
            <Box key={keyExtractor(item)}>{renderItem(item)}</Box>
          ))}
          <Styled.SectionDivider />
        </Box>
      ))}
    </Box>
  );
}

export default SectionList;
