import { ModalSteps } from "../constants";
import * as Styled from "../styles";
import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { Loader } from "@hotel-engine/components/Loader";

export interface ILoadingModal {
  /** function that handles closing modal */
  handleCancel: () => void;
  /** determines which text to display */
  modalStep: number;
  /** determines whether search results have been found */
  resultFound: boolean;
  /** determines whether the same room type was found for the property */
  sameRoomFound: boolean;
}

const LoadingModal = ({ handleCancel, modalStep, resultFound, sameRoomFound }: ILoadingModal) => {
  return (
    <>
      <Styled.LoadingBodyWrapper>
        <Loader size="lg" color="foregroundPrimary" />
        {modalStep === ModalSteps.InitialSearch ? (
          <>
            <Typography
              variant="heading/lg"
              color="foregroundPrimary"
              as="h2"
              marginTop={24}
              data-testid="loading-modal-header"
            >
              We are on it!
            </Typography>
            <Typography variant="body/md">We are processing your booking request.</Typography>
          </>
        ) : (
          <>
            <Typography variant="heading/lg" color="foregroundPrimary" as="h2" marginTop={24}>
              {sameRoomFound
                ? "We found you the same type of room."
                : "We found you a similar room."}
            </Typography>
            <Typography variant="body/md">
              {sameRoomFound
                ? "You can check out in a few moments."
                : "You can choose your new room in a few moments."}
            </Typography>
          </>
        )}
      </Styled.LoadingBodyWrapper>
      {!resultFound && (
        <Box display="flex" justifyContent="center" marginTop={24}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Box>
      )}
    </>
  );
};

export default LoadingModal;
