import { useRevalidator } from "@hotel-engine/lib/react-router-dom";
import { RouteAuthWrapper, RouteWithFeatureFlag } from "@hotel-engine/react-router";
import { useEffect } from "react";
import TripsLegacy from "./TripsLegacy";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useQueryClient } from "react-query";
import TripsPage from "./TripsPage";
import { TRIPS_FEATURE_FLAG } from "./data/loader";

export function TripsController() {
  const queryClient = useQueryClient();
  const { revalidate } = useRevalidator();

  /**
   * Bypass for feature flag access as there is currently no way to retrieve
   * feature flags in non-component/hooks. This will be removed once the
   * feature flag is fully rolled out.
   * TODO: The feature flags are in local storage when we breakpoint here.  There might be
   * a way to access them here. Check Amplitude docs
   */
  const isTripsFilteringEnabled = useIsFeatureFlagOn(TRIPS_FEATURE_FLAG);

  useEffect(() => {
    if (isTripsFilteringEnabled) {
      // override default staleTime of 5 minutes to prevent data from being garbage collected
      queryClient.setQueryDefaults([TRIPS_FEATURE_FLAG], {
        staleTime: Infinity,
        cacheTime: Infinity,
      });
      queryClient.setQueryData(TRIPS_FEATURE_FLAG, true);
      revalidate();
    }
  }, [isTripsFilteringEnabled, queryClient, revalidate]);

  return (
    <RouteAuthWrapper minRole="view_only_traveler">
      <RouteWithFeatureFlag featureFlag={TRIPS_FEATURE_FLAG}>
        {(isLoggedInAndFFEnabled) => (isLoggedInAndFFEnabled ? <TripsPage /> : <TripsLegacy />)}
      </RouteWithFeatureFlag>
    </RouteAuthWrapper>
  );
}
