import { Box, Typography } from "@hotelengine/atlas-web";
import { CollapsibleContent } from "../../../../../shared/CollapsibleContent";

export interface ITripInfoProps {
  customFields: { [key: string]: string }[];
}

export const TripInfo = ({ customFields }: ITripInfoProps) => {
  if (!customFields.length) return null;

  return (
    <CollapsibleContent
      toggleBtnLabel={
        <Typography data-testid="trip-info-title" variant="heading/lg" color="foregroundPrimary">
          Trip Info
        </Typography>
      }
      testID="trip-info-collapsible"
      toggleTestID="trip-info-toggle-btn"
    >
      <Box padding={24} paddingTop={16}>
        {customFields.map((customField) => (
          <Box key={customField.name} display="flex" justifyContent="space-between">
            <Typography variant="body/sm" color="foregroundPrimary" data-testid="policy-text">
              {customField.name}
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary" data-testid="policy-text">
              {customField.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </CollapsibleContent>
  );
};
