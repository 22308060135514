import type { ITrip } from "@hotel-engine/types/trips";
import { FlightCard } from "../FlightCard";
import { LodgingCard } from "../LodgingCard";
import CarCard from "../CarCard";
import { VALID_BOOKING_TYPES } from "pages/Trips/constants";
import useHideCars from "pages/Trips/hooks/useHideCars";

export const TripsListItemContent = ({ trip }: { trip: ITrip }) => {
  const shouldHideCars = useHideCars();
  const booking = trip.bookings[0];
  const travelers = booking.travelers;

  if (!VALID_BOOKING_TYPES.test(booking.bookingType)) {
    return null;
  }

  if (booking.bookingType === "flight") {
    const bookingNumber = booking.details.flightConfirmation;
    const slices = booking?.details?.slices;
    const item = slices?.[0];

    return (
      <FlightCard
        item={item}
        travelers={travelers}
        bookingNumber={bookingNumber}
        flightType={booking?.details?.flightTravelType}
      />
    );
  }

  if (trip.bookings[0].bookingType === "car") {
    if (shouldHideCars) return null;

    return <CarCard {...booking} />;
  }

  return <LodgingCard booking={booking} travelers={travelers} />;
};
