import * as yup from "yup";

export const TripsSearchSchema = yup.object().shape({
  search: yup
    .string()
    .required("Enter at least 2 characters to search")
    .min(2, (obj) => {
      const tooShort = obj.value.length < 2;
      return tooShort ? "Enter at least 2 characters to search" : "";
    }),
  search_facet: yup.string().required(),
});
