import styled from "styled-components";

export const TripsMapView = styled.div`
  display: flex;
  height: calc(100vh - 300px);
  padding-top: ${({ theme }) => theme.spacing[16]};
`;

export const NoTripsView = styled.div`
  height: calc(100vh - 300px);
  width:  calc(95vw);
  padding-top: ${({ theme }) => theme.spacing[16]};
`;

export const MapView = styled.div`
  flex: 1;
  flex-basis: 70%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
`;
