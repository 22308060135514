import { useCallback, useEffect, useState } from "react";

import { useGoogleGeocoder, usePrevious } from "@hotel-engine/hooks";
import GooglePlacesService, { type Response } from "@hotel-engine/services/GooglePlacesService";
import type { FlightTypes } from "@hotel-engine/types/itinerary";

import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";

interface IMapPositionWithAddress {
  lat: number;
  lng: number;
  address: string | undefined;
}

export type Locations = {
  origin: void | IMapPositionWithAddress | undefined;
  destination: void | IMapPositionWithAddress | undefined;
};

export interface ISliceLocation {
  originCode: string;
  destinationCode: string;
}

/** Setting true here bypasses the apiThrottle so that we can loop over the slices and quickly get
 * predictions for all of them, otherwise it will return the first set of predictions for every slice.
 */
const placesService = GooglePlacesService(true);

const useGoogleFlightLocations = (
  sliceLocations: ISliceLocation[],
  flightType: FlightTypes | undefined,
  isMobile: boolean
) => {
  const { getLocation } = useGoogleGeocoder();
  const { getAirportNameFromIataAirportCode } = useAirportInfo();

  const slicesToUse = flightType === "multi_city" ? sliceLocations : sliceLocations.slice(0, 1);

  const sliceAirports = slicesToUse.map((slice) => {
    return {
      originAirport: getAirportNameFromIataAirportCode(slice.originCode),
      destinationAirport: getAirportNameFromIataAirportCode(slice.destinationCode),
    };
  });

  const previousSlices = usePrevious(slicesToUse);

  const isNewListItem =
    previousSlices?.[0]?.originCode !== slicesToUse?.[0]?.originCode ||
    previousSlices?.[0]?.destinationCode !== slicesToUse?.[0]?.destinationCode;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [originAirportResults, setOriginAirports] = useState<Response[]>([]);
  const [destinationAirportResults, setDestinationAirports] = useState<Response[]>([]);
  const [locations, setLocations] = useState<Locations[]>([
    {
      origin: undefined,
      destination: undefined,
    },
  ]);

  const getPredictions = useCallback(async () => {
    return await Promise.all(
      sliceAirports.map(async (slice) => {
        const originPredictions = await placesService.request(slice.originAirport, setIsLoading);
        const destinationPredictions = await placesService.request(
          slice.destinationAirport,
          setIsLoading
        );

        return { originPredictions, destinationPredictions };
      }, {})
    );
  }, [sliceAirports]);

  /** If the origin or destination has no results and there is no error from Google, hit the Places
   * service to get results for the airports
   */
  useEffect(() => {
    if (
      !originAirportResults.length ||
      !destinationAirportResults.length ||
      (isNewListItem && !isError)
    ) {
      getPredictions()
        .then((res) => {
          setOriginAirports(res.map((item) => item.originPredictions) as Response[]);
          setDestinationAirports(res.map((item) => item.destinationPredictions) as Response[]);
        })
        .catch(() => setIsError(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationAirportResults.length, isError, isNewListItem, originAirportResults.length]);

  const getLocations = useCallback(async () => {
    return await Promise.all(
      originAirportResults.map(async (_, i) => {
        const originLocation = await getLocation(originAirportResults[i].predictions[0].place_id);
        const destinationLocation = await getLocation(
          destinationAirportResults[i].predictions[0].place_id
        );

        return { origin: originLocation, destination: destinationLocation };
      })
    );
  }, [destinationAirportResults, getLocation, originAirportResults]);

  /** Once we have predictions for the airports, we will grab the first one, which if we search by
   * the exact name of the airport from the bundles should be the correct airport nearly 100% of the time,
   * and hit the Geocoder API using the above helper to get the lat/lng of each airport which gets returned
   * from the hook
   */
  useEffect(() => {
    if (
      originAirportResults.length &&
      destinationAirportResults.length &&
      (!locations[0].origin || !locations[0].destination)
    ) {
      getLocations()
        .then((res) => {
          const locationsArray = res.map((item, i) => {
            return {
              origin: {
                lat: item.origin?.latitude || 0,
                lng: item.origin?.longitude || 0,
                address: isMobile
                  ? slicesToUse[i].originCode
                  : `${item.origin?.address} - ${slicesToUse[i].originCode}`,
              },
              destination: {
                lat: item.destination?.latitude || 0,
                lng: item.destination?.longitude || 0,
                address: isMobile
                  ? slicesToUse[i].destinationCode
                  : `${item.destination?.address} - ${slicesToUse[i].destinationCode}`,
              },
            };
          });

          setLocations(locationsArray);
        })
        .catch(() => setIsError(true));
    }
  }, [
    getLocations,
    originAirportResults.length,
    destinationAirportResults.length,
    isMobile,
    slicesToUse,
    locations,
  ]);

  return {
    locations,
    isLoading,
    isError,
  };
};

export default useGoogleFlightLocations;
