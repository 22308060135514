import type { FormikErrors } from "formik";
import * as yup from "yup";

import type { IOccupant } from "@hotel-engine/types/booking";
import type { IOverlappingContract } from "@hotel-engine/types/contractRate";
import type { IUser, IUserLoyaltyProgram } from "@hotel-engine/types/user";
import { type WexPromptsField, WexPromptsFields } from "@hotel-engine/types/paymentProfile";

export type GuestSearchMode = "search" | "selected" | "adding" | "hidden";

export type IncidentalVisibilityLegacy = "hidden" | "disabled" | "visible";

export interface ICustomFieldFormData {
  helpText?: "";
  id: number;
  name: string;
  options?: string[];
  required?: boolean;
  value: string | null;
  error: null | string;
  testId?: string;
  minTextLength: number | null;
  maxTextLength: number | null;
}

export interface ICheckoutGuest {
  user?: IUser | IOccupant;
  mode: GuestSearchMode;
}

export interface ICheckoutFormData {
  guestList: {
    primary: ICheckoutGuest;
    secondary: ICheckoutGuest;
    loyaltyInfo: IUserLoyaltyProgram | null;
    overlappingContracts?: IOverlappingContract[];
  }[];
  /** NOTE: Will not be needed once apply_to_all_guests_enhancement work is fully integrated and flag is removed. */
  applyToAll: boolean;
  /** END NOTE */
  indexOfGuestUsedForAllRooms?: number;
  /** Indicates that flex cancellation has been selected */
  isFlexEnabled: boolean;
  /** Indicates that FlexPro ff is is on and business is flexProEnrolled */
  isFlexProEnrolled?: boolean;
  /** Indicates that incidental charges have been selected */
  includeIncidentalCharges: boolean;
  /** Rewards points applied */
  customFields: ICustomFieldFormData[];
  isBillable?: boolean;
  directBillCode: string;
  directBillVerificationRequired: boolean;
  duplicateOverride: boolean;
  rewardsPoints: number;
  /** Travel credits applied */
  travelCredits: number;
  selectedPaymentId?: number;
  extensionConfirmed?: boolean;
  tripExtensionFlowType?: string;
  isWexPayment?: boolean;
  wexPrompts?: { field: WexPromptsField; value: string }[];
}

export const checkoutValidate = (roomCount: number) => {
  return yup
    .object()
    .shape({
      applyToAll: yup.boolean(),
      isFlexEnabled: yup.boolean(),
      rewardsPoints: yup.number(),
      travelCredits: yup.number(),
      includeIncidentalCharges: yup.boolean(),
      customFields: yup.array().of(
        yup.object().shape({
          value: yup
            .string()
            .when("required", ([required], schema) => {
              if (required) {
                return schema
                  .required("Required")
                  .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces");
              }
              return schema;
            })
            .when("minTextLength", ([minTextLength], schema) => {
              if (minTextLength) {
                return schema.min(
                  minTextLength,
                  `Must contain at least ${minTextLength} characters`
                );
              }
              return schema;
            })
            .when("maxTextLength", ([maxTextLength], schema) => {
              if (maxTextLength) {
                return schema.max(maxTextLength, `Must be ${maxTextLength} characters or less`);
              }
              return schema;
            }),
        })
      ),
      guestList: yup
        .array()
        .of(
          yup.object().shape({
            primary: yup.object().shape({
              user: yup.object().required("You must select a valid primary guest for each room"),
            }),
          })
        )
        .required("You must select a valid primary guest for each room")
        .test({
          message: "You must select a valid primary guest for each room",
          test: (arr) => arr?.length === roomCount,
        }),
      selectedPaymentId: yup.number().required("You must select a valid payment method"),
      duplicateOverride: yup.boolean(),
      isBillable: yup.boolean(),
      isWexPayment: yup.boolean().default(false),
      wexPrompts: yup.array().when("isWexPayment", {
        is: true,
        then: (schema) =>
          schema.of(
            yup.object().shape({
              field: yup.mixed<WexPromptsField>().oneOf(WexPromptsFields),
              value: yup.string().trim().required("This field is required"),
            })
          ),
      }),
    })
    .test("directBillCode", "Please enter direct bill code", (values) =>
      testDirectBillCode(values)
    );
};

const testDirectBillCode = (values) => {
  const isRequiredAndPopulated =
    values.directBillVerificationRequired &&
    typeof values.directBillCode === "string" &&
    values?.directBillCode?.length > 0;

  const isNotRequired = !values.directBillVerificationRequired;

  return (
    isRequiredAndPopulated ||
    isNotRequired ||
    new yup.ValidationError("Please enter direct bill code", null, "directBillCode")
  );
};

export type PrimaryGuestError = FormikErrors<{
  primary: ICheckoutGuest;
  secondary: ICheckoutGuest;
}>;

export const isPrimaryGuestError = (
  value: string | PrimaryGuestError | undefined
): value is PrimaryGuestError => {
  return !!value && typeof value !== "string";
};
