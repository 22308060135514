import type { ISupportCase } from "@hotel-engine/types/supportCase";
import moment from "moment-timezone";
import { Box, Timeline, Typography } from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import * as Styled from "./styles";
interface IActivityDetails {
  supportCase: ISupportCase | undefined;
}

const ActivityDetails = ({ supportCase }: IActivityDetails) => {
  const statusClosedAt = supportCase?.statusClosedAt;
  const statusInProgressAt = supportCase?.statusInProgressAt;
  const submittedBy = supportCase?.submittedBy;
  const createdAt = supportCase?.createdAt;
  const timeLineItems: React.ReactNode[] = [];

  if (!!statusClosedAt) {
    const timeFormat = moment(statusClosedAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem = (
      <Styled.ActivityTimelineItem title={`Case closed by ${COMPANY_NAME}`}>
        <Box display="flex" gap={4} alignItems="center">
          <Typography variant="body/xs" color="foregroundTertiary">
            {timeFormat}
          </Typography>
        </Box>
      </Styled.ActivityTimelineItem>
    );
    timeLineItems.push(timeLineItem);
  }

  if (!!statusInProgressAt) {
    const timeFormat = moment(statusInProgressAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem = (
      <Styled.ActivityTimelineItem title="Case in Progress">
        <Box display="flex" gap={4} alignItems="center">
          <Typography variant="body/xs" color="foregroundTertiary">
            {timeFormat}
          </Typography>
        </Box>
      </Styled.ActivityTimelineItem>
    );

    timeLineItems.push(timeLineItem);
  }

  if (!!submittedBy) {
    const timeFormat = moment(createdAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem = (
      <Styled.ActivityTimelineItem title={`Case submitted by ${submittedBy}`}>
        <Box display="flex" gap={4} alignItems="center">
          <Typography variant="body/xs" color="foregroundTertiary">
            {timeFormat}
          </Typography>
        </Box>
      </Styled.ActivityTimelineItem>
    );

    timeLineItems.push(timeLineItem);
  }

  return (
    <Box display="flex" flexDirection="column" gap={16}>
      <Typography variant="heading/md">Activity</Typography>
      <Timeline>{...timeLineItems}</Timeline>
    </Box>
  );
};

export default ActivityDetails;
