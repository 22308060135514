import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Button } from "@hotelengine/atlas-web";

import { Tag } from "./Tag/styles";

interface IButtonGroup {
  columnGap?: "xsmall" | "small" | "normal";
}

const iconButton = css`
  &:last-child {
    margin-right: ${({ theme }) => theme.spacing[0]};
  }

  & > ${Tag}, & > .icon {
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;

export const MobileSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
    `;

export const FiltersDot = styled.div`
    width: 6px;
    height: 6px;
    position: absolute;
    top: -2px;
    right: 4px;
    background-color: ${({ theme }) => theme.colors.foregroundPrimary};
    border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.foregroundInverse}`};
    border-radius: ${({ theme }) => theme.borderRadius.circle};
`;

export const TripsFiltersContainer = styled.div<{ $isMobile: boolean }>`
    margin: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme, $isMobile }) => theme.spacing[$isMobile ? 0 : 24]};
`;

export const TripsFilters = styled.div`
  ${flexbox({
    justifyContent: "space-between",
  })}


  & > button {
    ${iconButton};
  }

  ${({ theme }) =>
    css`
        @media screen and (min-width: ${theme.breakpoints.xl}) {
            align-items: baseline;
        }
  `}
`;

const ButtonGroup = styled.div<IButtonGroup>`
  ${flexbox({ alignItems: "center" })}
  column-gap: ${({ theme, columnGap }) =>
    columnGap === "xsmall"
      ? theme.spacing[2]
      : columnGap === "small"
        ? theme.spacing[8]
        : theme.spacing[20]};

  & button.status-button:hover:enabled,
  & button.status-button:focus,
  & button.status-button:focus:disabled {
    color: initial;
    border-color: initial;
  }

    #trips_datesPicker span {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.foregroundPrimary};
        border-color: ${({ theme }) => theme.colors.borderSubtle};
        background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    
    &:hover {
        border-color: ${({ theme }) => theme.colors.foregroundEverdark};
        color: ${({ theme }) => theme.colors.foregroundSecondary};
    }
  }

  ${({ theme }) =>
    css`
        @media screen and (max-width: ${theme.breakpoints.xl}) {
            margin-top: ${theme.spacing[12]}
        }
  `}
`;

const StatusButton = styled(Button).attrs({
  size: "sm",
  variant: "plain",
  color: "secondary",
})`
  height: 36px;
  border: none;
  padding: ${({ theme }) => theme.spacing[8]};
  border-radius: 0;

  &:not(.active):hover,
  &.active {
    padding-bottom: ${({ theme }) => theme.spacing[6]};
  }

  &:not(.active):hover,
  &:hover:not(:disabled) {
    background: transparent;
  }

  &:hover,
  &.active {
    border-bottom: ${({ theme }) => `${theme.borderWidth.wide} solid ${theme.colors.neutralN800}`};
    border-radius: 0;
  }
`;

export { ButtonGroup, StatusButton };
