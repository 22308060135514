import type { ClusterFeature } from "supercluster";

import type { ITrip } from "@hotel-engine/types/trips";

import type { FormattedTripsGeoPoint, ReservationWithCluster } from "../../types";
import { supercluster } from "../GoogleMapsClusters/supercluster";

export const returnTripsBounds = (bounds: google.maps.LatLngBounds, arr: ITrip[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (!!arr[i].bookings[0].details.property?.longitude) {
      const markerBounds = {
        lat: Number(arr[i].bookings[0].details.property?.latitude),
        lng: Number(arr[i].bookings[0].details.property?.longitude),
      };
      bounds.extend(markerBounds);
    }
  }
  return bounds;
};

export function formatTripsDataToGeoJsonPoints(data: ITrip[]): FormattedTripsGeoPoint[] {
  return data.map((res) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [
        res.bookings[0].details.property?.longitude,
        res.bookings[0].details.property?.latitude,
      ],
    },
    properties: { cluster: false, ...res },
  }));
}

export function returnClusters(bounds: google.maps.LatLngBoundsLiteral, currentZoom: number) {
  const mapClusters = supercluster.getClusters(
    [bounds.west, bounds.south, bounds.east, bounds.north],
    currentZoom
  );
  return mapClusters as ClusterFeature<ReservationWithCluster>[];
}
