import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

interface IButtonGroup {
  columnGap?: "xsmall" | "small" | "normal";
}

export const MobileSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
`;

export const TripsFiltersContainer = styled.div<{ $isMobile: boolean }>`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme, $isMobile }) => theme.spacing[$isMobile ? 0 : 24]};
`;

export const TripsFilters = styled.div`
  ${flexbox({
    justifyContent: "space-between",
  })}
`;

export const ButtonGroup = styled.div<IButtonGroup>`
  ${flexbox({ alignItems: "center" })}
  column-gap: ${({ theme }) => theme.spacing[8]};
  margin-top: ${({ theme }) => theme.spacing[12]};

  ${({ theme }) =>
    css`
        @media screen and (max-width: ${theme.breakpoints.xl}) {
            margin-top: ${theme.spacing[12]}
        }
  `}
`;

export const MobileFiltersScroll = styled(ButtonGroup)`

${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex: 1;
      overflow: scroll;
    }
  `}
`;
