import { FILTER_SHAPE_REGEX, filterSafeKeys } from "../data/querySchema";
import type { TripsQueryParams, TripsFilter } from "../data/querySchema";
import type { FlexibleTripsParams } from "../hooks/useTripsControl";
import type { ObjectLike } from "./mergeObjects";

/**
 * Checks whether a value is a non-null object (e.g., not null, not an array, not a primitive).
 *
 * @param value The value to check.
 * @returns True if the value is an object and not null or an array, otherwise false.
 */
function isNonNullObject(value: unknown): value is ObjectLike {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

/**
 * Transforms filter-specific keys by adding 'filter[]' prefix while leaving other parameters unchanged.
 * Only keys present in filterSafeKeys will be transformed. If 'filter' is an object, its own keys
 * that are considered safe will also be transformed accordingly.
 *
 * @param params - The trip parameters to transform.
 * @returns A new object where filter keys are prefixed with 'filter[]', and non-filter keys remain unchanged.
 */
export function transformFilterKeys(params: ObjectLike): TripsQueryParams {
  const output = {} as TripsQueryParams;

  for (const [param, value] of Object.entries(params)) {
    if (param === "filter" && isNonNullObject(value)) {
      for (const [subKey, subVal] of Object.entries(value)) {
        if (filterSafeKeys.includes(subKey as keyof TripsFilter)) {
          output[`filter[${subKey}]`] = subVal;
        }
      }

      continue;
    }

    const isSafeKey = filterSafeKeys.includes(param as keyof TripsFilter);

    output[isSafeKey ? `filter[${param}]` : param] = value;
  }

  return output;
}

/**
 * Decomposes object keys of the form `filter[someKey]` into `{ someKey: value }`,
 * preserving all other keys as they are.
 *
 * @param params The object containing potential `filter[]` keys.
 * @returns A new object with decomposed keys (e.g., `filter[group]` -> `group`).
 */
export function decomposeFilterKeys(params: ObjectLike): FlexibleTripsParams {
  const output = {} as FlexibleTripsParams;

  for (const [key, value] of Object.entries(params)) {
    const match = key.match(FILTER_SHAPE_REGEX);

    if (match) {
      output[match[1]] = value;
    } else {
      output[key] = value;
    }
  }

  return output;
}
