import type { DateRange as PickerDateRange } from "@hotelengine/atlas-web";
import { Box, DatePicker, Typography } from "@hotelengine/atlas-web";
import { useRef, useState } from "react";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import moment from "moment";

const DateRange = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const {
    state: { filters },
    dispatch,
  } = useTripsLegacyContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [_, setDates] = useState<PickerDateRange>({
    from: moment(filters.startTimeGt).toDate(),
    to: moment(filters.startTimeLt).toDate(),
  });

  const handleDateChange = (range: PickerDateRange) => {
    if (!range.from || !range.to) {
      setDates({ from: undefined, to: undefined });
      dispatch({
        type: "filter",
        filters: [{ key: "startTimeGt" }, { key: "startTimeLt" }],
      });

      return;
    }

    const startDate = moment(range.from).startOf("day").toISOString();
    const endDate = moment(range.to).endOf("day").toISOString();

    if (user) {
      ampli.clickDatesInTrips({
        endDate,
        startDate,
        userId: user.id,
      });
    }

    setDates(range);
    dispatch({
      type: "filter",
      filters: [
        { key: "startTimeGt", value: startDate },
        { key: "startTimeLt", value: endDate },
      ],
    });
  };

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Date range
      </Typography>
      <Box ref={containerRef} display="flex" gap={12} flexDirection="column" marginTop={8}>
        <DatePicker
          data-testid="trips_datesPicker"
          mode="range"
          calendarAlignment="center"
          formatString="LLL d"
          onValueChange={handleDateChange}
          rangePlaceholder={{ from: "Start date", to: "End date" }}
          isTooltipEnabled={false}
          hasLeadingAdornment
          isOnColor
        />
      </Box>
    </Box>
  );
};

export default DateRange;
