import { DataTableGrid, useDataTable } from "@hotelengine/atlas-web";
import { useEffect } from "react";
import useTripsControl from "../../hooks/useTripsControl";

const DataTableContent = ({
  tripIdentifier,
  rowSelectionState,
}: { tripIdentifier: string | null; rowSelectionState: Record<string, boolean> }) => {
  const { table } = useDataTable();
  const rowSelectionStateExists = !!Object.keys(rowSelectionState).length;
  const [params] = useTripsControl();

  useEffect(() => {
    const tableSortState = table?.getState().sorting[0];
    const isDesc = !!params?.sort?.startsWith("-");
    if (
      tableSortState?.desc !== isDesc ||
      tableSortState?.id !== params?.sort?.slice(isDesc ? 1 : 0)
    ) {
      table?.setSorting([{ id: params?.sort?.slice(isDesc ? 1 : 0) || "", desc: isDesc }]);
    }

    if (!tripIdentifier || !!rowSelectionStateExists) {
      table?.resetRowSelection();
    }

    if (!!rowSelectionStateExists && !!tripIdentifier) {
      table?.setRowSelection(rowSelectionState);
    }
  }, [params?.sort, rowSelectionState, rowSelectionStateExists, table, tripIdentifier]);

  return <DataTableGrid />;
};

export default DataTableContent;
