import { useEffect, useRef, useState } from "react";
import type { Dispatch, PropsWithChildren, ReactChild, SetStateAction } from "react";

import GoogleMapsService from "@hotel-engine/services/GoogleMapsService";

import { initialMapOptions } from "./constants";
import { returnMapOptions } from "./mapHelpers";
import * as Styled from "./styles";
import type {
  GoogleMapsControlOptions,
  Map,
  MapLocations,
  MapPosition,
  SetMapLoadingTimes,
} from "./types";
import GoogleMapsControls from "./components/GoogleMapsControls";
import { Box } from "@hotelengine/atlas-web";
import { Loader } from "@hotel-engine/components/Loader";

export type GoogleMapProps = {
  /** The child map to be passed into the common GoogleMap, this child will render in a callback with the `map` instance */
  children?: (map: Map) => ReactChild;
  /** Options for whether or not to show map controls and custom handlers */
  controlOptions?: GoogleMapsControlOptions;
  /** Any custom styles to be passed to that specific map */
  customMapStyle?: React.CSSProperties;
  /** Where in the app the map is, largely used for Amplitude tracking */
  mapLocation?: MapLocations;
  /** Used to pass the map center to the Google map in setup */
  mapOptions?: Partial<google.maps.MapOptions>;
  /** Map custom tools bar */
  mapTools?: (map: Map) => React.ReactNode;
  /** Custom click handler for the whole map, ex. clicking anywhere off of the preview panel will close it */
  onClick?: () => void;
  /** Custom drag end handler for the whole map */
  onDragEnd?: () => void;
  /** Used to track the current map center for movements that do not trigger a new query */
  setCurrentMapCenter?: Dispatch<SetStateAction<MapPosition>>;
  /** Used for Amplitude tracking */
  setMapLoadingTimes?: SetMapLoadingTimes;
  /** data-testid for cypress testing */
  dataTestId?: string;
};

export type GoogleMapState = {
  map: Map;
  isLoading: boolean;
  mapLoadingError: boolean;
};

const GoogleMap = ({
  children,
  controlOptions = {},
  mapLocation,
  mapOptions,
  mapTools,
  customMapStyle,
  onClick,
  onDragEnd,
  setCurrentMapCenter,
  setMapLoadingTimes,
  dataTestId = "google-map",
}: PropsWithChildren<GoogleMapProps>) => {
  const [{ isLoading, map, mapLoadingError }, setMapState] = useState<GoogleMapState>({
    isLoading: true,
    map: undefined,
    mapLoadingError: false,
  });

  const mapRef = useRef(null);
  const googleMapsService = GoogleMapsService();

  const {
    showExpand = true,
    showType = true,
    showZoom = true,
    showSearchControls = true,
  } = controlOptions;
  const showControls = showExpand || showType || showZoom || showSearchControls;

  const mergeState = (obj: Partial<GoogleMapState>) => {
    setMapState((prev) => {
      return {
        ...prev,
        ...obj,
      };
    });
  };

  /** Init map once Ref is applied */
  useEffect(() => {
    if (!!mapRef.current) {
      googleMapsService.initMap(
        {
          element: mapRef.current,
          options: mapOptions ? returnMapOptions(mapOptions) : initialMapOptions,
        },
        mergeState,
        setMapLoadingTimes
      );
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  useEffect(() => {
    if (!map || !onDragEnd) {
      return;
    }
    const listener = map.addListener("dragend", () => {
      onDragEnd();
    });
    return () => {
      listener?.remove();
    };
  }, [map, onDragEnd]);

  if (mapLoadingError) {
    return <Styled.MapErrorMessage>Failed to load map</Styled.MapErrorMessage>;
  }

  return (
    <Styled.MapWrapper className="google-map-wrapper" data-testid={dataTestId}>
      {!!showControls && (
        <GoogleMapsControls
          map={map as google.maps.Map}
          mapLocation={mapLocation}
          setCurrentMapCenter={setCurrentMapCenter}
          {...controlOptions}
        />
      )}
      {!!isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" paddingTop={48}>
          <Loader size="lg" />
        </Box>
      )}
      <Styled.Map onClick={onClick} ref={mapRef} style={customMapStyle} data-testid="map-div">
        {!!children && children(map)}
      </Styled.Map>
      {!!mapTools && mapTools(map)}
    </Styled.MapWrapper>
  );
};

export default GoogleMap;
