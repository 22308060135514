import { Icon } from "@hotelengine/atlas-web";
import { colors } from "@hotel-engine/styles";
import type { IResult } from "@hotel-engine/types/search";
import type { SearchResultMarkerAttributes, SearchResultMarkerVariant } from "../../types";

import LoyaltyBadge from "../../../../assets/LoyaltyBadge";
import { defaultPriceMarkerAttributes, zIndexOrder } from "../../constants";

export function returnPriceMarkerWidth(rate: string) {
  /** Based on a 3 character price (ex. $50) */
  const baseWidth = 42;

  if (rate.length <= 3) {
    return baseWidth;
  } else {
    /** For each character beyond 3, we want to add width to the marker.
     * The smaller offset if the character is '1' helps to prevent excess
     * width/padding on the sides of the marker.
     */
    const additionalChar = rate.substring(2).split("");
    let additionalWidth = 0;
    for (const char in additionalChar) {
      if (char === "1") {
        additionalWidth += 3;
      } else {
        additionalWidth += 8;
      }
    }

    return baseWidth + additionalWidth;
  }
}

export const getMarkerVariant = (
  result: IResult,
  specificPropertyId: number | undefined
): SearchResultMarkerAttributes => {
  if (result.hidden) {
    return getMarkerAttributes("standard");
  } else if (specificPropertyId === result.propertyId) {
    return result.isSoldOut
      ? getMarkerAttributes("specificSoldOut")
      : getMarkerAttributes("specific");
  } else if (result.preferred) {
    return getMarkerAttributes("preferred");
  } else if (!!result.favoriteId) {
    return getMarkerAttributes("favorite");
  } else if (!!result.loyaltyRewardName) {
    return getMarkerAttributes("rewards");
  } else {
    return getMarkerAttributes("standard");
  }
};

export const getMarkerAttributes = (
  variant: SearchResultMarkerVariant
): SearchResultMarkerAttributes => {
  switch (variant) {
    case "favorite": {
      return {
        color: colors.khmerCurry,
        hoverColor: colors.retroBlue,
        icon: (
          <Icon
            style={{
              paddingRight: "2px",
              paddingBottom: ".5px",
            }}
            name="heart--solid"
            color="foregroundInverse"
          />
        ),
        hidePrice: false,
        zIndex: zIndexOrder.priorThanNormal,
      };
    }
    case "preferred": {
      return {
        color: colors.retroBlue,
        hoverColor: colors.blue[400],
        icon: (
          <Icon
            style={{
              paddingRight: "2px",
              paddingBottom: ".5px",
            }}
            name="thumbs-up"
            color="foregroundInverse"
          />
        ),
        hidePrice: false,
        zIndex: zIndexOrder.priorThanNormal,
      };
    }
    case "rewards": {
      return {
        color: colors.grey[800],
        hoverColor: colors.retroBlue,
        icon: (
          <LoyaltyBadge
            width="12"
            height="12"
            style={{ marginRight: "2px", paddingBottom: ".5px" }}
          />
        ),
        hidePrice: false,
        zIndex: zIndexOrder.normal,
      };
    }
    case "specific": {
      return {
        color: colors.retroBlue,
        hoverColor: colors.blue[400],
        icon: (
          <Icon
            style={{
              paddingRight: "2px",
              paddingBottom: ".5px",
            }}
            name="star--solid"
            color="foregroundInverse"
          />
        ),
        hidePrice: false,
        zIndex: zIndexOrder.activeOrSpecific,
      };
    }
    case "specificSoldOut": {
      return {
        color: colors.khmerCurry,
        hoverColor: colors.khmerCurry,
        icon: (
          <Icon style={{ paddingBottom: ".5px" }} name="star--solid" color="foregroundInverse" />
        ),
        hidePrice: true,
        zIndex: zIndexOrder.activeOrSpecific,
      };
    }
    default: {
      return defaultPriceMarkerAttributes;
    }
  }
};
