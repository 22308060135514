import styled from "styled-components";

import { Icon } from "@hotelengine/atlas-web";
import { colors } from "@hotel-engine/styles";

export const NotificationWrapper = styled.div`
  text-align: center;
  padding: 40px 25px;
  h1 {
    ${({ theme }) => theme.typography.desktop["heading/lg"]};
    color: ${colors.errieBlack};
  }
  p {
    ${({ theme }) => theme.typography.desktop["body/sm"]};
    color: ${colors.errieBlack};
  }
  button {
    min-width: 190px;
  }
`;

export const NotificationIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  margin: 0 0 15px;
`;
