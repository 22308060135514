import { Box, SegmentedControl, SegmentedControlItem } from "@hotelengine/atlas-web";
import type { FilterBookingType } from "pages/Trips/contextLegacy";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import useHideCars from "../../../../hooks/useHideCars";
import useHideFlights from "../../../../hooks/useHideFlights";
import type { IconName } from "@hotelengine/atlas-core";
import { useBreakpoint } from "@hotel-engine/hooks";

const TripType = () => {
  const { dispatch, state } = useTripsLegacyContext();

  const shouldHideCars = useHideCars();
  const shouldHideFlights = useHideFlights();
  const isMobile = useBreakpoint("xxl", "max");

  const handleTripTypeChange = (type: FilterBookingType) => {
    dispatch({
      type: "filter",
      filters: [{ key: "booking_type", value: type }],
    });
  };

  if (shouldHideCars && shouldHideFlights) return null;

  const segments = [
    { value: "all", icon: "list", label: "All" },
    { value: "lodging", icon: "hotel--solid", label: "Hotels" },
  ];

  if (!shouldHideFlights) {
    segments.push({ value: "flight", icon: "plane--solid", label: "Flights" });
  }

  if (!shouldHideCars) {
    segments.push({ value: "car", icon: "car--solid", label: "Cars" });
  }

  const getSegments = () => {
    return (
      <SegmentedControl
        defaultValue={state.filters.booking_type || "all"}
        style={{ width: "100%" }}
        size="lg"
        onValueChange={(value) => handleTripTypeChange(value as FilterBookingType)}
      >
        {segments.map((item) => (
          <SegmentedControlItem
            value={item.value}
            key={item.value}
            data-testid={`select-${item.label.toLowerCase()}`}
            icon={!isMobile ? (item.icon as IconName) : undefined}
          >
            {item.label}
          </SegmentedControlItem>
        ))}
      </SegmentedControl>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={8}>
      {getSegments()}
    </Box>
  );
};

export default TripType;
