import { useEffect } from "react";

import storage from "@hotel-engine/storage";
import { useIsFeatureFlagOn } from "../Experiments";

function CheckCSPValue() {
  const isFFEnabled = useIsFeatureFlagOn("csp_test");

  useEffect(() => {
    if (isFFEnabled) {
      storage.setSecureItem("csp_test", "true");
    } else {
      storage.removeSecureItem("csp_test");
    }
  }, [isFFEnabled]);

  return null;
}

export default CheckCSPValue;
