import styled, { css } from "styled-components";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

import type { SearchResultMarkerAttributes } from "../../types";

export const PriceMarker = styled.div<{
  $width: number;
  $selected?: boolean;
  $dotMarker?: boolean;
  $markerAttr: SearchResultMarkerAttributes;
}>`
  ${({ $dotMarker, $selected, $width, $markerAttr }) => {
    const SOLD_OUT_WIDTH = 30;
    const leftOffset = $markerAttr.hidePrice
      ? SOLD_OUT_WIDTH
      : !!$markerAttr.icon
        ? $width + 10
        : $width;

    return $dotMarker
      ? css`
          width: 8px;
          height: 8px;
          border-radius: 50% /* disable theming (do not replicate) */;
          background: ${$selected ? `${colors.retroBlue}` : `${colors.grey[800]}`};
          outline: 2px solid ${colors.white};
          cursor: pointer;
        `
      : css`
          background: ${$selected ? `${$markerAttr.hoverColor}` : `${$markerAttr.color}`};
          border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
          font-style: normal;
          left: ${`-${leftOffset / 2}px`};
          margin-bottom: 0;
          padding: ${({ theme }) =>
            `${theme.legacy.space($markerAttr.hidePrice ? 0.7 : 0.4)} ${theme.legacy.space(0)}`};
          position: absolute;
          text-align: center;
          top: ${$dotMarker ? `${0}` : `${-33}px`};
          width: ${
            $markerAttr.hidePrice
              ? `${SOLD_OUT_WIDTH}px`
              : !!$markerAttr.icon
                ? `${$width + 10}px`
                : `${$width}px`
          };
          cursor: pointer;
          ${flexbox({
            alignItems: "center",
            justifyContent: "center",
          })};
        `;
  }}
`;

export const PropertyMarkerLegacy = styled.div`
  background: ${colors.grey[800]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  color: ${colors.white};
  font-style: normal;
  left: -18px;
  margin-bottom: 0;
  padding: ${({ theme }) => theme.legacy.space(1)};
  position: absolute;
  text-align: center;
  top: -40px;
`;

export const PropertyMarker = styled.div`
  background: ${({ theme }) => theme.colors.backgroundInverse};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, calc(-50% - ${({ theme }) => theme.spacing[16]}));
`;

export const CarMarker = styled.div`
  background: ${colors.grey[800]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  color: ${colors.white};
  font-style: normal;
  left: -19.5px;
  margin-bottom: 0;
  padding: ${({ theme }) =>
    `${theme.legacy.space(0.75)} ${theme.legacy.space(0.8)} ${theme.legacy.space(
      0.4
    )} ${theme.legacy.space(1)}`};
  position: absolute;
  text-align: center;
  top: -42px;
`;

export const PropertyAddressMarker = styled.address`
  background: ${colors.grey[800]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  font-style: normal;
  left: -150px;
  margin-bottom: 0;
  padding: ${({ theme }) => `${theme.legacy.space(3)} ${theme.legacy.space(2)}`};
  position: absolute;
  text-align: center;
  top: -91px;
  width: 300px;
`;

export const AirportMarker = styled.address<{ $inverted: boolean }>`
  background: ${colors.grey[800]};
  border-radius: ${({ theme }) => theme.borderRadius[2]};
  font-style: normal;
  width: min-content;
  transform: translateX(-50%);
  margin-bottom: 0;
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[12]}`};
  position: absolute;
  text-align: center;
  top: ${({ $inverted }) => ($inverted ? 6 : -32)}px;
`;

export const OverflowType = styled.p<{
  $selected?: boolean;
  $isProperty?: boolean;
}>`
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  ${({ $isProperty, $selected }) => {
    if (!$isProperty) {
      return css`
        font-weight: ${$selected ? 600 : "unset"}; /* disable theming (do not replicate) */
        letter-spacing: ${$selected ? "0px" : "0.4px"}; /* disable theming (do not replicate) */
      `;
    }
    return null;
  }}
`;
