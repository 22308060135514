import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";

import styled from "styled-components";

export const ManageTripContainer = styled<React.FC<BoxProps>>(Box)<{
  $isPreview: boolean;
}>`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[16]}`};
    border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
  `;
