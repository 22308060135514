import type { ISummaryOfChargesItemProps } from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { useFormatDate } from "@hotel-engine/hooks";
import type { IFlightCharges } from "@hotel-engine/types/itinerary";
import { formatCurrency } from "@hotel-engine/utilities";
import type { TypographyVariant } from "@hotelengine/atlas-web";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";

function sumArray(items: { amount: string }[]) {
  return items.reduce((sum, item) => {
    return sum + Number.parseFloat(item.amount);
  }, 0);
}

export const useFlightSummaryOfCharges = (charges?: IFlightCharges) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const paidOn = useFormatDate(charges?.paidOn, "MMM Do, YYYY", true) ?? "";
  const hidePaidOn = !paidOn || paidOn === "Invalid date";

  const formattedLineItems: { label: string; value: string }[] = useMemo(
    () =>
      charges?.lineItems.map((item) => {
        return {
          label: item.description,
          value: formatCurrency(item.amount, true, currencyCode),
        };
      }) || [],
    [charges?.lineItems, currencyCode]
  );

  const chargeDescriptionList: ISummaryOfChargesItemProps[] = useMemo(
    () =>
      [
        {
          label: "Billing name",
          value: charges?.bookedBy || "",
        },
        {
          label: "Payment method",
          value: charges?.paymentType || "",
        },
        {
          label: "Paid on",
          value: paidOn || "",
          hideItem: hidePaidOn,
        },
        {
          label: "Redeemed points",
          value: formatCurrency(
            -Math.abs(Number(charges?.redeemedRewardsPoints)),
            true,
            currencyCode
          ),
          hideItem: !Number(charges?.redeemedRewardsPoints),
        },
        {
          label: "Redeemed travel credits",
          value: formatCurrency(
            -Math.abs(Number(charges?.redeemedTravelCredits)),
            true,
            currencyCode
          ),
          hideItem: !Number(charges?.redeemedTravelCredits),
        },
        {
          label: "Travel credit comp",
          value: formatCurrency(charges?.travelCompAmount || "0", true, currencyCode),
          hideItem: !Number(charges?.travelCompAmount) || !charges?.travelCompDescription,
          isBold: true,
          labelColor: "sentimentPositiveDefault" as const,
          valueColor: "sentimentPositiveDefault" as const,
          typography: "body/md-strong" as TypographyVariant,
        },
        {
          label: "Travel credit comp description",
          value: charges?.travelCompDescription || "",
          hideItem: !Number(charges?.travelCompAmount) || !charges?.travelCompDescription,
        },
      ].filter((item) => item.value),
    [
      charges?.bookedBy,
      charges?.paymentType,
      hidePaidOn,
      paidOn,
      currencyCode,
      charges?.redeemedTravelCredits,
      charges?.redeemedRewardsPoints,
      charges?.travelCompAmount,
      charges?.travelCompDescription,
    ]
  );

  const chargeAmountList: ISummaryOfChargesItemProps[] = useMemo(
    () => [
      {
        label: "Flight fare",
        value: formatCurrency(charges?.subTotal || "0", true, currencyCode),
      },
      ...formattedLineItems,
      {
        label: "Taxes",
        value: formatCurrency(sumArray(charges?.taxes || []), true, currencyCode),
      },
      ...(charges?.fees || []).map((fee) => ({
        label: fee.description,
        value: formatCurrency(fee.amount, true, currencyCode),
        popoverContent:
          fee.description === "Booking Fee" ? "Booking fee is non-refundable." : undefined,
      })),
    ],
    [charges?.subTotal, charges?.taxes, charges?.fees, currencyCode, formattedLineItems]
  );

  const totalCharges = {
    label: "Total charges",
    value: charges?.totalCustomerCharge
      ? formatCurrency(charges?.totalCustomerCharge || "0", true, currencyCode)
      : formatCurrency(charges?.total || "0", true, currencyCode),
  };

  return {
    chargeDescriptionList,
    chargeAmountList,
    totalCharges,
  };
};
