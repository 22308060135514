import { Box, Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { Loader } from "@hotel-engine/components/Loader";

import { useTranslation } from "react-i18next";
import { CarRefundType } from "../../../hooks/usePreviewCarRefund";
import * as Styled from "./styles";

export interface IRefundInfoProps {
  isLoading: boolean;
  refundInfo: {
    cancellationFee?: string;
    refundAmount?: string;
    baseRental?: string;
    protection?: string;
    refundType: CarRefundType;
  } | null;
}

const RefundInfo = ({ isLoading, refundInfo }: IRefundInfoProps) => {
  const { typographyVariants } = useTypographyScaling();
  const { t } = useTranslation("cars_trips", { keyPrefix: "refund" });

  const formattedAmount = formatCurrency(refundInfo?.refundAmount || 0);
  const formattedCancellationFee = formatCurrency(refundInfo?.cancellationFee || 0);
  const formattedBaseRental = formatCurrency(refundInfo?.baseRental || 0);
  const formattedProtection =
    refundInfo?.protection !== "Cancelled"
      ? formatCurrency(refundInfo?.protection || 0)
      : refundInfo?.protection || 0;

  const showCancellationFee = !!(Number(refundInfo?.cancellationFee) > 0);

  if (Number(refundInfo?.refundAmount) + Number(refundInfo?.cancellationFee) === 0) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent={isLoading || !refundInfo ? "center" : "flex-start"}
      alignItems="center"
      paddingTop={16}
      paddingBottom={16}
    >
      {isLoading || !refundInfo ? (
        <Loader color="foregroundPrimary" testId="loader" />
      ) : refundInfo?.refundType === CarRefundType.Nonrefundable ? (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {t("feedback.nonRefundable")}
        </Typography>
      ) : refundInfo?.refundType === CarRefundType.Unknown ? (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {t("feedback.unknownRefund")}
        </Typography>
      ) : (
        <Styled.InfoContainer>
          <Styled.ListItem>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              {t("title.method")}
            </Typography>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              {refundInfo?.refundType}
            </Typography>
          </Styled.ListItem>
          {!!refundInfo.baseRental && (
            <Styled.ListItem>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {t("title.baseRental")}
              </Typography>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {formattedBaseRental}
              </Typography>
            </Styled.ListItem>
          )}
          {!!showCancellationFee && (
            <Styled.ListItem>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {t("title.fee")}
              </Typography>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {formattedCancellationFee}
              </Typography>
            </Styled.ListItem>
          )}
          {!!refundInfo.protection && (
            <Styled.ListItem>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {t("title.protection")}
              </Typography>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                {formattedProtection}
              </Typography>
            </Styled.ListItem>
          )}
          <Styled.ListItem>
            <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
              {t("title.totalRefund")}
            </Typography>
            <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
              {formattedAmount}
            </Typography>
          </Styled.ListItem>
        </Styled.InfoContainer>
      )}
    </Box>
  );
};

export default RefundInfo;
