import type { IUser } from "@hotel-engine/types/user";

import LodgingItineraryPanel from "./lodging";
import FlightItineraryPanel from "./flights";
import type { ITripPreview } from "../../contextLegacy";
import type { BookingType } from "@hotel-engine/types/itinerary";
import CarItineraryPanel from "./car";
import useHideCars from "pages/Trips/hooks/useHideCars";
import useHideFlights from "../../hooks/useHideFlights";

export interface ITripsItineraryPreviewPanelProps {
  tripId: string | number;
  onClose: (tripPreview: ITripPreview) => void;
  user: IUser;
  bookingType: BookingType;
}

const TripsItineraryPreviewPanel = ({
  onClose,
  tripId,
  user,
  bookingType,
}: ITripsItineraryPreviewPanelProps) => {
  const shouldHideCars = useHideCars();
  const shouldHideFlights = useHideFlights();

  if (bookingType === "flight") {
    if (shouldHideFlights) return null;

    return <FlightItineraryPanel itineraryId={tripId} onClose={onClose} />;
  }

  if (bookingType === "car") {
    if (shouldHideCars) return null;

    return <CarItineraryPanel itineraryId={tripId} onClose={onClose} />;
  }

  return (
    <LodgingItineraryPanel itineraryId={tripId} onClose={onClose} user={user} type={bookingType} />
  );
};

export default TripsItineraryPreviewPanel;
