import { useMemo, useState } from "react";
import type { SelectProps } from "@hotelengine/atlas-web";
import {
  BottomSheet,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetTrigger,
  Box,
  Button,
  Select,
  SelectItem,
  useTheme,
} from "@hotelengine/atlas-web";
import type { ITripsSearch } from "../..";

export const facetNameMap = {
  traveler: "Traveler",
  location: "Location",
  confirmation_number: "Confirmation number",
  all: "Search all",
};

export interface IFacetSelectProps {
  isOnColor: boolean;
  values: ITripsSearch;
  isLoading: boolean;
  handleSearchFacetChange: (value: string) => void;
  isMobile?: boolean;
  size?: SelectProps["size"];
}

const FacetSelect = ({
  isOnColor,
  values,
  isLoading,
  handleSearchFacetChange,
  isMobile,
  size,
}: IFacetSelectProps) => {
  const { tokens } = useTheme();

  const overlayIndexWithBottomSheetClosed = tokens.zIndex.modal;
  const overlayIndexWithBottomSheetOpen = tokens.zIndex.modal + tokens.zIndex.backdropAbove;
  const bottomSheetIndex =
    tokens.zIndex.modal + tokens.zIndex.backdropAbove + tokens.zIndex.backdropAbove;
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const selectedFacet = useMemo(
    () =>
      values.search_facet && Object.keys(facetNameMap).includes(values.search_facet)
        ? values.search_facet
        : "traveler",
    [values.search_facet]
  );

  /** Because we have a FullScreenSheet and a BottomSheet open at the same time,
   * we need to pull the overlay above the FullScreenSheet when the BottomSheet is opened
   * so that it can sit between the FullScreenSheet and the BottomSheet
   * so that the BottomSheet experience is correct.
   */
  const handleBottomSheetOpen = () => {
    setIsBottomSheetOpen(true);
    document
      .querySelector("[data-testid=dialog-overlay]")
      ?.setAttribute("style", `z-index: ${overlayIndexWithBottomSheetOpen};`);
  };

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
    document
      .querySelector("[data-testid=dialog-overlay]")
      ?.setAttribute("style", `z-index: ${overlayIndexWithBottomSheetClosed};`);
  };

  return isMobile ? (
    <BottomSheet isOpen={isBottomSheetOpen}>
      <BottomSheetTrigger
        size="xl"
        variant="outlined"
        trailingIcon="chevron-down"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 20,
          paddingRight: 20,
        }}
        onClick={handleBottomSheetOpen}
      >
        {facetNameMap[selectedFacet]}
      </BottomSheetTrigger>
      <BottomSheetContent
        onBlur={handleBottomSheetClose}
        style={{
          zIndex: bottomSheetIndex,
        }}
      >
        <BottomSheetHeader>
          <BottomSheetTitle>Select</BottomSheetTitle>
          <BottomSheetClose onClick={handleBottomSheetClose} />
        </BottomSheetHeader>
        <Box display="flex" flexDirection="column" paddingBottom={12} gap={8}>
          {Object.keys(facetNameMap).map((facet) => (
            <Button
              key={facet}
              variant="plain"
              style={{
                justifyContent: "left",
                paddingLeft: selectedFacet === facet ? 8 : 32,
              }}
              leadingIcon={selectedFacet === facet ? "check" : undefined}
              onClick={() => {
                handleSearchFacetChange(facet);
                handleBottomSheetClose();
              }}
            >
              {facetNameMap[facet]}
            </Button>
          ))}
        </Box>
      </BottomSheetContent>
    </BottomSheet>
  ) : (
    <Select
      isOnColor={!isOnColor}
      defaultValue={selectedFacet}
      isDisabled={isLoading}
      style={{ width: isMobile ? "100%" : 164 }}
      size={size || "md"}
      onValueChange={handleSearchFacetChange}
      value={selectedFacet}
      aria-label="Search by"
      name="Search by"
      data-testid="trips-search-facet"
    >
      <Box style={{ width: isMobile ? "100%" : 245 }}>
        {Object.keys(facetNameMap).map((facet) => (
          <SelectItem key={facet} value={facet}>
            {facetNameMap[facet]}
          </SelectItem>
        ))}
      </Box>
    </Select>
  );
};

export default FacetSelect;
