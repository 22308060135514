import { useState } from "react";

import { useFormikContext } from "formik";
import moment from "moment";
import type { Moment } from "moment";
import { SingleDatePicker } from "react-dates";

import { useBreakpoint } from "@hotel-engine/hooks";
import type { ISearchFormValues } from "@hotel-engine/types/search";
import { Icon } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { formatDateDictionary } from "@hotel-engine/constants/formatDateDictionary";
import { useAppSelector } from "store/hooks";

const CheckoutDateSelectorInputs = () => {
  const [focusedInput, setFocusedInput] = useState(false);
  const { initialValues, values, setFieldValue, errors } = useFormikContext<ISearchFormValues>();
  const checkInError = !!errors.checkIn;
  const checkOutError = !!errors.checkOut;

  const isMobile = useBreakpoint("md", "max");

  // Disallow dates that occur before the original checkIn or after the original checkOut dates
  const isDateOutOfRange = (day: Moment) => {
    const endDate = moment(initialValues.checkOut).add(1, "d");
    return day.isBefore(initialValues.checkIn) || day.isAfter(endDate);
  };

  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";

  const dateFormat = formatDateDictionary["ddd, MMM D"][preferredDateFormat];

  return (
    <>
      {/* Used to target the full screen portal CSS on mobile because it appends the DOM nodes outside of the physical scope of this component */}
      <Styled.CalendarStyleOverrides />
      <Styled.InputGroupWrapper checkInError={checkInError} checkOutError={checkOutError}>
        <Styled.InputWrapper>
          <label htmlFor="original-check-out-date">Original Checkout</label>
          <SingleDatePicker
            ariaLabel="Original Checkout Date"
            date={initialValues.checkOut}
            displayFormat={dateFormat}
            onFocusChange={() => null}
            onDateChange={() => null}
            customInputIcon={<Icon name="calendar" />}
            id="original-check-out-date"
            disabled
            noBorder
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <label htmlFor="new-check-out-date">Actual Checkout</label>
          <SingleDatePicker
            ariaLabel="Update Checkout Date"
            readOnly
            noBorder
            date={values.checkOut}
            focused={focusedInput}
            onFocusChange={({ focused }) => setFocusedInput(focused)}
            onDateChange={(endDate: Moment) => setFieldValue("checkOut", endDate)}
            // displayFormat="ddd, MMM D"
            displayFormat={dateFormat}
            hideKeyboardShortcutsPanel
            orientation={isMobile ? "vertical" : "horizontal"}
            withFullScreenPortal={isMobile}
            styles={{ backgroundColor: "red" }}
            numberOfMonths={isMobile ? 2 : 1}
            isOutsideRange={isDateOutOfRange}
            customInputIcon={<Icon name="calendar" />}
            id="new-check-out-date"
          />
        </Styled.InputWrapper>
      </Styled.InputGroupWrapper>
    </>
  );
};

export default CheckoutDateSelectorInputs;
