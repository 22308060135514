import * as Styled from "./styles";
import { Loader } from "@hotel-engine/components/Loader";

const SupportCaseSpinner = () => {
  return (
    <Styled.SpinnerContainer>
      <Loader size="lg" />
    </Styled.SpinnerContainer>
  );
};

export default SupportCaseSpinner;
