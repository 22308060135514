import { useEffect, useState } from "react";

import { Icon } from "@hotel-engine/common/Icon";
import { Loader } from "@hotel-engine/components/Loader";

import * as Styled from "./styles";

export type TBookingModalState =
  | "loading"
  | "pending"
  | "reserved"
  | "requestRooms"
  | "booked"
  | "failed"
  | "visiting";

export interface IBookingModalProps {
  /** status of reservation/rooms request */
  loadingStatus: TBookingModalState | undefined;
  /** name of the current property to display when rooms are being fetched */
  propertyName?: string;
}

const BookingModal = ({ loadingStatus, propertyName }: IBookingModalProps) => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    switch (loadingStatus) {
      case "loading":
        setModalContent(
          <>
            <Loader size="lg" color="foregroundInverse" />
            <Styled.Message>Connecting with the property...</Styled.Message>
          </>
        );
        break;
      case "pending":
      case "reserved": // hotel has reserved the booking, but contract is still incomplete
        setModalContent(
          <>
            <Loader size="lg" color="foregroundInverse" />
            <Styled.Message>Securing reservation</Styled.Message>
          </>
        );
        break;
      case "requestRooms":
        setModalContent(
          <>
            <Loader size="lg" color="foregroundInverse" />
            <Styled.Message>Connecting with the property...</Styled.Message>
          </>
        );
        setTimeout(() => {
          setModalContent(
            <>
              <Loader size="lg" color="foregroundInverse" />
              <Styled.Message>Just a sec...</Styled.Message>
              <Styled.Subtext>
                Loading your results{!!propertyName && ` for ${propertyName}`}
              </Styled.Subtext>
            </>
          );
        }, 1000);
        break;
      case "booked":
      case "visiting":
        setModalContent(
          <>
            <Icon icon={["fal", "check-circle"]} />
            <Styled.Message>Booking complete!</Styled.Message>
          </>
        );
        break;
      default:
        setModalContent(null);
        break;
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus]);

  return (
    <Styled.Modal
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={false}
      visible={!!modalContent}
      width="500px"
    >
      <Styled.Container data-testid="booking-modal">{modalContent}</Styled.Container>
    </Styled.Modal>
  );
};

export default BookingModal;
