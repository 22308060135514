import moment from "moment";
import type { ICalendarContract, ICalendarTraveler } from "@hotel-engine/types/reservation";
import { apiTripTypesLegacy } from "../../constants";
import type { TripsStatus } from "../../data/querySchema";

export const sortCalendarOptions: {
  label: string;
  sort: string;
}[] = [
  {
    label: "First Name: A-Z",
    sort: "first_name",
  },
  {
    label: "First Name: Z-A",
    sort: "-first_name",
  },
  {
    label: "Last Name: A-Z",
    sort: "last_name",
  },
  {
    label: "Last Name: Z-A",
    sort: "-last_name",
  },
  {
    label: "Department: A-Z",
    sort: "department",
  },
  {
    label: "Department: Z-A",
    sort: "-department",
  },
  {
    label: "Employee ID: A-Z",
    sort: "employee_id",
  },
  {
    label: "Employee ID: Z-A",
    sort: "-employee_id",
  },
];

export const sortContractsByEarliestCheckin = (contracts: ICalendarContract[]) => {
  const sortedContracts = [...contracts].sort((a, b) => moment(a.checkIn).diff(moment(b.checkIn)));

  return sortedContracts;
};

/**
 * Filters trips by status
 * @param data array of traveler trip data for the week in view
 * @param status status to filter by
 * @returns travelers & their contracts that match the input status.
 * Will not return travelers that have 0 contracts that match the input status.
 */
export const filterDataByStatus = (
  data: ICalendarTraveler[] | undefined,
  status: TripsStatus | undefined
): ICalendarTraveler[] => {
  if (!data) return [];
  else if (!status || status === "all") return data;

  return data
    .map((traveler) => ({
      ...traveler,
      contracts: traveler.contracts.filter(
        (contract) => contract.status === apiTripTypesLegacy[status]
      ),
    }))
    .filter((traveler) => !!traveler.contracts.length);
};

/**
 * Gets grid cell location (0-6) for contract start and end dates.
 * If date expands off-grid, it will be represented as -1.
 * @param param0 contract: current contract;
 * startOfWeek: moment object representing beginning of view week;
 * endOfWeek: moment object representing end of view week
 * @returns object representing the span of the checkin/out dates with start: -1-6 & end:-1-6
 */
export const getContractDateSpan = ({
  contract,
  startOfWeek,
  endOfWeek,
}: {
  contract: ICalendarContract;
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
}): { start?: number; end?: number } => {
  const gridDateSpan: { start?: number; end?: number } = {
    start: undefined,
    end: undefined,
  };

  // Without this, the date parses as the previous date.
  const checkInDate = moment(contract.checkIn).add(12, "hours");
  const checkInCount = checkInDate.dayOfYear();
  const weekStartCount = startOfWeek.dayOfYear();
  const startDateIsPrevious = checkInDate.isBefore(startOfWeek);
  const startDateIsSunday = checkInCount === weekStartCount;

  if (startDateIsPrevious) gridDateSpan.start = -1;
  else if (startDateIsSunday) gridDateSpan.start = 0;
  else gridDateSpan.start = checkInDate.day();

  // Without this, the date parses as the previous date.
  const checkOutDate = moment(contract.checkOut).add(12, "hours");
  const checkOutCount = checkOutDate.dayOfYear();
  const weekEndCount = endOfWeek.dayOfYear();
  const endDateIsNext = weekEndCount < checkOutCount;
  const endDateIsSunday = checkOutCount === weekStartCount;

  if (endDateIsNext) gridDateSpan.end = -1;
  else if (endDateIsSunday) gridDateSpan.end = 0;
  else gridDateSpan.end = checkOutDate.day();

  return gridDateSpan;
};

/**
 * Determines whether reservation is within active dates for calendar view
 * @param reservation the incoming reservation

 * @returns boolean - whether or not to show preview
 */
export const isReservationWithinActiveDates = (reservationCheckIn: string) => {
  const startOfRange = moment()
    .week(moment().week() - 2)
    .weekday(0)
    .subtract(12, "hours");
  const endOfRange = moment()
    .week(moment().week() + 6)
    .weekday(6);

  const isWithinActiveRange = moment(reservationCheckIn).isBetween(
    startOfRange,
    endOfRange,
    undefined,
    "[]"
  );

  return isWithinActiveRange;
};

/** returnRows
 * @param {array} visibleTrips: ICalendarTraveler[] after filter/sort
 *
 * @returns users array as a number of stacked or overlapping contracts.
 * The designs require ten rows as a default.
 * If multiple users have multiple overlapping trips each overlapping trip is considered as one row
 */

const emptyRowObj: ICalendarTraveler = {
  department: "",
  employeeId: "",
  firstName: "",
  lastName: "",
  contracts: [],
};

export const returnRows = (visibleTrips: ICalendarTraveler[]): ICalendarTraveler[] => {
  const rowCount = visibleTrips.reduce((acc, cur) => {
    /** For each user in array reset curCount and prevContract */
    let curCount = 1;
    let prevContract;
    /** Sort the contracts by checkIn data and for each one check for overlapping dates */
    cur.contracts
      .sort((a, b) => {
        if (a.checkIn < b.checkIn) {
          return -1;
        }
        if (a.checkIn > b.checkIn) {
          return 1;
        }
        return 0;
      })
      .forEach((contract) => {
        /** If no prevContract set the initial count and contract */
        if (!prevContract) {
          prevContract = contract;
          return;
        }
        /** If the current contract overlaps the previous contract add 1 */
        if (
          moment(contract.checkIn) <= moment(prevContract?.checkOut).subtract(1, "day") &&
          moment(prevContract?.checkIn) <= moment(contract.checkOut).subtract(1, "day")
        ) {
          prevContract = contract;
          curCount += 1;
        }

        /** Do not add to the count if the current contract does not overlap the previous contract. These contracts will be displayed in the same row */
        prevContract = contract;
        return;
      });
    /** Return the count of users overlapping contracts  */
    return acc + curCount;
  }, 0);

  const minimumRows = 10 - rowCount;

  const placeholders = minimumRows > 0 ? Array(minimumRows).fill(emptyRowObj) : [];

  const rows = [...visibleTrips, ...placeholders];

  return rows;
};
