import type React from "react";
import type { IconProps } from "@hotelengine/atlas-web";
import { Icon } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const Spinner = styled<React.FC<IconProps>>(Icon).attrs({
  name: "spinner-third",
  animationStyle: "spin",
})`
    width: 100%;
    margin: ${({ theme }) => `${theme.spacing[64]} auto ${theme.spacing[0]}`};
`;

export const MobileWrapper = styled.div`
    position: relative;
    z-index: ${({ theme }) => theme.legacy.zIndex.aboveBackdrop};
    height: 260px;
    width: 100%;
`;
