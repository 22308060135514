import { useEffect, useState } from "react";
import { useCarsPreviewCancellationQuery } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";
import type {
  ICarCancelData,
  IFreeCancellation,
  IPaidCancellation,
} from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

export enum CarRefundType {
  OriginalFormOfPayment = "Original form of payment",
  Unknown = "unknown",
  Nonrefundable = "nonrefundable",
}

export const carRefundTypeMap = {
  car_original_form_of_payment: "Original form of payment",
};

export const isFreeCancellation = (
  refundDetails: IFreeCancellation | IPaidCancellation | null
): refundDetails is IFreeCancellation => {
  return Object.keys(refundDetails || {}).includes("freeCancellation");
};

export const usePreviewCarRefund = (bookingNumber: string) => {
  const [cancelData, setCancelData] = useState<ICarCancelData>();

  const carCancelCalc = useCarsPreviewCancellationQuery({
    id: bookingNumber,
  });

  useEffect(() => {
    if (carCancelCalc.isSuccess) {
      setCancelData(carCancelCalc.data);
    }
  }, [carCancelCalc]);

  const buildInfoObject = () => {
    if (!cancelData?.refundDetails) {
      return null;
    } else {
      const { refundDetails } = cancelData;

      if (refundDetails?.insurance || !isFreeCancellation(refundDetails)) {
        const allFees =
          refundDetails.fees?.reduce((acc, cur) => {
            return acc + Number(cur.value);
          }, 0) || 0;

        return {
          refundType: CarRefundType.OriginalFormOfPayment,
          refundAmount: refundDetails.combined?.total?.value,
          baseRental: refundDetails?.car?.total?.value,
          protection: refundDetails?.insurance
            ? refundDetails?.insurance.cancelled
              ? "Cancelled"
              : refundDetails?.insurance?.total.value
            : undefined,
          cancellationFee: String(allFees),
          isFree: allFees === 0,
          showRefundInfo: !refundDetails.combined?.freeCancellation,
        };
      }

      return {
        refundType: CarRefundType.OriginalFormOfPayment,
        refundAmount: "0",
        cancellationFee: "0",
        isFree: true,
      };
    }
  };

  return {
    refundInfo: buildInfoObject(),
    isLoading: carCancelCalc.isLoading,
    isError: carCancelCalc.isError,
  };
};
