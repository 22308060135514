import { upperFirst } from "lodash";
import type { FormikErrors } from "formik";

import * as Styled from "../../styles";
import type { ITripsSearch } from "../..";
import type { ITripsResponse } from "pages/Trips/hooks/useTripsResult";

export interface ISearchFormControlProps {
  result: ITripsResponse;
  errors: FormikErrors<ITripsSearch>;
  children: React.ReactNode;
}

const SearchFormControl = ({ result, errors, children }: ISearchFormControlProps) => {
  const is422 = result?.error?.code === 422;
  const error = result?.error?.fieldErrors.find(({ name }) => /search/.test(name));
  const validationError = upperFirst(error?.reasons.join(","));

  return (
    <Styled.SearchInputFormControl
      label=""
      status={error && is422 && !errors.search ? "error" : "default"}
      helperText={errors.search}
      errorText={validationError || ""}
    >
      {children}
    </Styled.SearchInputFormControl>
  );
};

export default SearchFormControl;
