import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { GetAssistance } from "./components/GetAssistance";
import { CancelPolicy } from "./components/CancelPolicy";
import { TripInfo } from "./components/TripInfo";
import { RefundBreakdowns } from "@hotel-engine/app/ItineraryContent/flights/components/AdditionalInfo/components/RefundBreakdowns";
import useIsFlightCancelled from "@hotel-engine/app/ItineraryContent/flights/hooks/useIsFlightCancelled";

import * as Styled from "./styles";

const AdditionalInfo = () => {
  const { isPreview, reservation } = useFlightModificationsContext();
  const { isFlightCancelled } = useIsFlightCancelled();
  const shouldShowRefundBreakdowns = !!reservation.refundBreakdowns.length;

  if (!reservation) return null;

  const modifyCancelPolicy = reservation.cancellationPolicy;
  const bookingFee = reservation.charges.fees.find(
    (fee) => fee.description === "Booking Fee"
  )?.amount;

  return (
    <Styled.AdditionalInfoPaddedContainer $isPreview={isPreview}>
      <TripInfo customFields={reservation.customFields} />
      {!isFlightCancelled && <GetAssistance itinerary={reservation} />}
      <CancelPolicy policyCopy={modifyCancelPolicy} />

      {!!shouldShowRefundBreakdowns && (
        <RefundBreakdowns refunds={reservation.refundBreakdowns} bookingFee={bookingFee} />
      )}
    </Styled.AdditionalInfoPaddedContainer>
  );
};

export default AdditionalInfo;
