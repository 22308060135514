import type { ReactElement } from "react";
import { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import Button from "@hotel-engine/common/Button";
import Image from "@hotel-engine/common/Image";
import { Popover } from "@hotel-engine/common/Popover";
import { useUpdateDirectBillOnboardingMethod } from "@hotel-engine/react-query/directBillOnboarding/useUpdateDirectBillOnboardingMethod";
import type { IDirectBill } from "@hotel-engine/types/booking";
import { ampli } from "ampli";
import config from "config";
import { useAppSelector } from "store/hooks";

import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
  IExpressBookPaymentProfile,
} from "@hotel-engine/types/expressBook";
import { usePricingCalculation } from "pages/Checkout/LegacyLodging/hooks/usePricingCalculation";
import {
  directBill,
  DirectBillItem,
  formatDirectBillBalanceMessage,
} from "pages/Checkout/LegacyLodging/PaymentMethods/DirectBillItem";
import { ExpressBookPaymentItem } from "./components/ExpressBookPaymentItem/ExpressBookPaymentItem";
import { isRoomRefundable } from "../../helpers";

import * as Styled from "./styles";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";

// TODO: See if we need to export these or not
export const isDirectBill = (
  paymentMethod?: IExpressBookPaymentProfile | IDirectBill
): paymentMethod is IDirectBill => {
  return paymentMethod?.id === 0;
};

export const isPaymentMethodValid = (payment?: IExpressBookPaymentProfile) => {
  return payment && !!payment.billingPostalCode && !payment.isExpired;
};

interface IExpressBookPaymentMethodsProps {
  contractRate: IExpressBookContractRate;
  propertyId: number | string;
  searchId?: number | string;
  paymentProfiles: Array<IExpressBookPaymentProfile>;
}

export const ExpressBookPaymentMethods = ({
  contractRate,
  propertyId,
  searchId,
  paymentProfiles,
}: IExpressBookPaymentMethodsProps) => {
  const [paymentMethodMode, setPaymentMethodMode] = useState<"editing" | "selected" | "changing">(
    "selected"
  );
  const [directBillAccess, setDirectBillAccess] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<IExpressBookCheckoutForm>();
  const lastPaymentId = useAppSelector((state) => state.UserPrefs.lastPaymentId);
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const { customerTotal } = usePricingCalculation(currencyCode, true);
  const { redirectToCheckout } = useExpressBook();

  /** Determines need to return default payment method on paymentProfileQuery load
   * Check for existence of paymentId
   * Check if paymentId is 0 (Direct Bill - should always be 0)
   * if paymentId does not exist
   * Check paymentProfileQuery results for valid payment method
   * if no payment profile getDefaultPayment
   * */
  useEffect(() => {
    const hasPaymentId = values.selectedPaymentId || values.selectedPaymentId === 0;
    if (!hasPaymentId) {
      const paymentProfile = paymentProfiles.find(
        (paymentMethod) => paymentMethod.id === values.selectedPaymentId
      );

      if (!paymentProfile) {
        getDefaultPayment(paymentProfiles);
      }
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProfiles]);

  let selectedPayment: IExpressBookPaymentProfile | IDirectBill | undefined;
  if (paymentProfiles) {
    selectedPayment = [...paymentProfiles, directBill].find(
      (payment) => payment.id === values.selectedPaymentId
    );
  }

  const directBillQuery = useUpdateDirectBillOnboardingMethod();
  const hasDirectBill = user?.directBill && user?.business?.directBillEnabled;
  const hasViewedCheckoutPage = user?.directBillOnboarding?.checkOutPage;

  // Shows direct bill onboarding popover
  const showDirectBillPopover =
    !hasViewedCheckoutPage && hasDirectBill && !isDirectBill(selectedPayment);

  // gets and sets the selected payment option. used on load and after any payment method updates
  const getDefaultPayment = (paymentMethods = paymentProfiles) => {
    if (!paymentMethods) return;

    const userDefaultPayment = paymentMethods?.find((p: IExpressBookPaymentProfile) => p.default);

    // user chosen default payment
    if (isPaymentMethodValid(userDefaultPayment)) {
      setFieldValue("selectedPaymentId", userDefaultPayment?.id);
      return;
    }

    // last used payment method
    const lastUsedPayment = paymentMethods?.find(
      (p: IExpressBookPaymentProfile) => p.id === lastPaymentId
    );
    if (isPaymentMethodValid(lastUsedPayment)) {
      setFieldValue("selectedPaymentId", lastUsedPayment?.id);
      return;
    }

    const isInsufficientBalance =
      !!user?.business.availableCredit && user?.business.availableCredit < customerTotal;
    const isDirectBillDisabled =
      (isRoomRefundable(contractRate.cancelBy) && user?.business.directBillVerificationRequired) ||
      isInsufficientBalance;

    // direct bill payment method
    if (hasDirectBill && !isDirectBillDisabled) {
      setFieldValue("selectedPaymentId", directBill.id);
      return;
    }

    const validPaymentProfile = paymentMethods?.find((p: IExpressBookPaymentProfile) =>
      isPaymentMethodValid(p)
    );

    // check for any valid payment method as a fallback
    if (validPaymentProfile) {
      setFieldValue("selectedPaymentId", validPaymentProfile.id);
      return;
    }

    // If we have invalid payment methods, show them as options, otherwise show the add payment method form
    paymentMethods.length ? setPaymentMethodMode("changing") : setPaymentMethodMode("editing");
    setFieldValue("selectedPaymentId", undefined);
  };

  // Travel Policy - Direct Bill Access based on config and customer total
  const isDirectBillBlocked = user?.directBillConfiguration === "blocked";
  const isDirectBillRequired = user?.directBillConfiguration === "required";
  const isDirectBillOptional = user?.directBillConfiguration === "optional";

  useEffect(() => {
    const creditLimitAvailable =
      (!!user?.business.availableCredit && user?.business.availableCredit) || 0;
    const hasSufficientBalance = customerTotal < creditLimitAvailable;

    if (isDirectBillRequired && hasSufficientBalance) {
      setDirectBillAccess(true);
      setFieldValue("selectedPaymentId", directBill.id);
      setPaymentMethodMode("selected");
    } else if (
      (isDirectBillRequired || isDirectBillOptional) &&
      !hasSufficientBalance &&
      isDirectBill(selectedPayment)
    ) {
      setDirectBillAccess(false);
      setPaymentMethodMode("changing");
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTotal]);

  // update the selected payment method
  const handlePaymentSelect = (id: number) => {
    setFieldValue("selectedPaymentId", id);
    setPaymentMethodMode("selected");
  };

  const handleChangePayment = () => {
    ampli.clickChangePaymentMethodOnExpressBookModal({
      searchId: searchId ? Number(searchId) : undefined,
      propertyId: Number(propertyId),
    });
    setPaymentMethodMode("changing");
    directBillQuery.mutate({ checkOutPage: true });
  };

  const handleAddPayment = () => {
    ampli.clickAddPaymentMethodOnExpressBookModal({
      searchId: searchId ? Number(searchId) : undefined,
      propertyId: Number(propertyId),
    });

    redirectToCheckout(values);
  };

  let title = selectedPayment?.name ?? "";
  let label = "";
  let description = "";
  let subDescription: ReactElement | undefined = undefined;

  if (selectedPayment) {
    if (isDirectBill(selectedPayment)) {
      description = formatDirectBillBalanceMessage(user);
    } else {
      title = selectedPayment.nickname || selectedPayment.name;
      label = selectedPayment.default ? "Default" : "";

      description = `ending in ${
        selectedPayment.last4 ||
        // We need to account for credit card last four digits after EDITING is complete
        selectedPayment.cardNumber.replaceAll("-", "")
      }`;

      subDescription = selectedPayment?.isExpired ? (
        <Styled.Expired id="expired-payment-method">(Expired)</Styled.Expired>
      ) : undefined;
    }
  }

  return (
    <Styled.ExpressBookPaymentContainer>
      <Styled.PaymentMethodContainer isDirectBillRequired={directBillAccess}>
        {!!selectedPayment && paymentMethodMode === "selected" && (
          <Popover
            align={{
              offset: ["-5%", "0%"],
            }}
            content={
              <Styled.DirectBillPopover>
                Remember to add Direct Bill as your payment method
              </Styled.DirectBillPopover>
            }
            data-testid="direct-bill-popover"
            key="direct-bill-popover"
            placement="topRight"
            visible={showDirectBillPopover}
          >
            <Styled.PaymentMethodCard
              title={title}
              label={label}
              description={description}
              subDescription={subDescription}
              singleAction={
                isDirectBillRequired && isDirectBill(selectedPayment) ? (
                  <Styled.RequiredByTravelPolicyTagContainer>
                    <Styled.RequiredByTravelPolicyTag>
                      Required by travel policy
                    </Styled.RequiredByTravelPolicyTag>
                  </Styled.RequiredByTravelPolicyTagContainer>
                ) : (
                  <Button
                    type="link"
                    onClick={handleChangePayment}
                    data-testid="eb-payment-change-btn"
                  >
                    Change
                  </Button>
                )
              }
              img={
                <Image
                  src={`${config.cdnHost}/assets/creditcards/${selectedPayment.type
                    .split(" ")
                    .join("")}.png`}
                  alt={`${selectedPayment.type}`}
                  fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                  width={40}
                  height={24}
                  setContainerSize={false}
                />
              }
            />
          </Popover>
        )}
      </Styled.PaymentMethodContainer>

      {paymentMethodMode === "changing" && (
        <div>
          {!!hasDirectBill && !isDirectBillBlocked && (
            <DirectBillItem onPaymentSelect={handlePaymentSelect} />
          )}
          {!!paymentProfiles &&
            paymentProfiles?.map((paymentItem) => {
              return (
                <ExpressBookPaymentItem
                  data-testid={`eb-payment-item-${paymentItem.id}`}
                  key={paymentItem.id}
                  onPaymentSelect={handlePaymentSelect}
                  payment={paymentItem}
                />
              );
            })}
        </div>
      )}

      {paymentMethodMode !== "editing" && (!directBillAccess || !!isDirectBillOptional) && (
        <Styled.AddPaymentButton type="link" onClick={handleAddPayment}>
          <Styled.PlusIcon name="plus" />
          Add Payment Method
        </Styled.AddPaymentButton>
      )}
    </Styled.ExpressBookPaymentContainer>
  );
};
