import { Box, Typography } from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";
import Departments from "../Departments";
import DateRange from "../DateRange";
import PriceRange from "../PriceRange";
import SortingBy from "../SortingBy";
import TripGroups from "../TripGroups";
import TripType from "../TripType";
import type { IFiltersDropdownProps } from "..";

const DropdownContent = ({ isCalendarView, isMapView }: IFiltersDropdownProps) => {
  const isMobile = useBreakpoint("md", "max");

  return (
    <Box
      display="flex"
      backgroundColor="backgroundPrimary"
      flexDirection="column"
      gap={24}
      padding={6}
      paddingTop={0}
    >
      {!isMobile && (
        <Typography variant="heading/lg" color="foregroundPrimary" margin={0}>
          Filters
        </Typography>
      )}
      {!isCalendarView && !isMapView && <TripType />}
      <PriceRange />
      <DateRange />
      <TripGroups />
      {!!isCalendarView && <SortingBy />}
      {/* Unsure about implementation on these, it will likely happen later as the original new trips endpoint didn't include it */}
      {/* <Divider /> */}
      {/* <TripDetails /> */}
      <Departments />
    </Box>
  );
};

export default DropdownContent;
