import { Box } from "@hotelengine/atlas-web";
import type { IFlightItinerarySlice } from "@hotel-engine/types/itinerary";

import SliceInfo from "../SliceInfo";

export interface IFlightSliceProps {
  isLastSlice: boolean;
  slice: IFlightItinerarySlice;
  sliceIndex: number;
}

const FlightSlice = ({ isLastSlice, slice, sliceIndex }: IFlightSliceProps) => {
  return (
    <Box data-testid="flight-slice">
      <SliceInfo slice={slice} sliceIndex={sliceIndex} isLastSlice={isLastSlice} />
    </Box>
  );
};

export default FlightSlice;
