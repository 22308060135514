import config from "config";
import { useAppSelector } from "store/hooks";

export const useSupportPhone = () => {
  const user = useAppSelector((state) => state.Auth.user);

  const { supportPhone, currencyCode } = user?.business || {
    supportPhone: null,
    currencyCode: "USD",
  };

  return supportPhone ? supportPhone : config.supportNumber(currencyCode);
};
