import { Fragment, useCallback, useMemo, useState } from "react";
import { ampli } from "ampli";
import moment from "moment";

import { Box, Chip, Icon, Typography } from "@hotelengine/atlas-web";

import { useFormatDate } from "@hotel-engine/hooks";
import useIsFlightCancelled from "@hotel-engine/app/ItineraryContent/flights/hooks/useIsFlightCancelled";
import type { IFlightItinerarySegment, IFlightItinerarySlice } from "@hotel-engine/types/itinerary";
import { useIsWithin24HrsOfFlight } from "@hotel-engine/app/ItineraryContent/flights/hooks/useIsWithin24HrsOfFlight";
import { useActiveSliceIndex } from "@hotel-engine/app/ItineraryContent/flights/hooks/useActiveSliceIndex";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useAirlineLinks } from "@hotel-engine/app/ItineraryContent/flights/hooks/useAirlineLinks";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { useAppSelector } from "store/hooks";

import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import { getItineraryIndicatorProps } from "./getItineraryIndicatorProps";
import { getLayoverDurationString } from "./getLayoverDurationString";
import { FlightSegmentSummary } from "./components/FlightSegmentSummary";
import { FlightDetailsBlock } from "./components/FlightDetailsBlock";
import { LayoverBlock } from "./components/LayoverBlock";
import { TravelerInfo } from "./components/TravelerInfo";

import * as Styled from "./styles";

export interface ISliceInfoProps {
  slice: IFlightItinerarySlice;
  sliceIndex: number;
  isLastSlice?: boolean;
}

const SliceInfo = ({ slice, sliceIndex, isLastSlice = false }: ISliceInfoProps) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const { reservation } = useFlightModificationsContext();
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const { isFlightCancelled } = useIsFlightCancelled();
  const navigate = useNavigate();
  const sliceDate = useFormatDate(
    moment.parseZone(slice.segments[0].origin.flightTime),
    "ddd, MMM D"
  );
  const { isWithinCheckInWindow } = useIsWithin24HrsOfFlight(slice);
  const { getAirlineLink } = useAirlineLinks();
  const checkIn = getAirlineLink("checkIn", slice.segments[0].airlineCode);

  const activeSliceIndex = useActiveSliceIndex();
  const shouldDefaultOpen = activeSliceIndex === sliceIndex;

  const handleCheckIn = useCallback(() => {
    openNewTab(checkIn, "_blank", navigate);
  }, [checkIn, navigate]);

  const itineraryIndicatorProps = useMemo(() => {
    return getItineraryIndicatorProps(slice);
  }, [slice]);

  const handleToggleFlightDetails = () => {
    setAreDetailsVisible((currValue) => !currValue);

    if (!areDetailsVisible) {
      ampli.clickShowFlightDetails({
        userId: user?.id,
        flightBookingID: reservation?.bookingNumber,
        sliceOrigin: slice.segments[0].origin.airportCode,
        sliceDestination: slice.segments[0].destination.airportCode,
      });
    }
  };

  const travelerInfo = useMemo(() => {
    const getSeatDescriptionFromSegment = (segment: IFlightItinerarySegment) => {
      return `${segment.origin.airportCode} - ${segment.destination.airportCode}`;
    };
    return slice.segments[0].travelerSegmentInfo.map((traveler, travelerIdx) => {
      return {
        fullName: traveler.fullName,
        eTicketNumber: traveler.eTicketNumber,
        type: traveler.type,
        baggage: traveler.baggage,
        seats: slice.segments.map((segment) => {
          return {
            segmentName: getSeatDescriptionFromSegment(segment),
            seatDesignator: segment.travelerSegmentInfo[travelerIdx].seat || "-",
          };
        }),
      };
    });
  }, [slice.segments]);

  const segmentCount = useMemo(() => slice.segments.length, [slice.segments.length]);
  const departureSegment = useMemo(() => slice.segments[0], [slice.segments]);
  const arrivalSegment = useMemo(
    () => slice.segments[segmentCount - 1],
    [segmentCount, slice.segments]
  );

  const isOneSegment = segmentCount === 1;
  const isOneStop = segmentCount === 2;
  const stopsCopy = isOneSegment
    ? "Nonstop"
    : `${slice.segments.length - 1} stop${isOneStop ? "" : "s"}`;

  const sliceToggleLabel = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column">
        {!!isFlightCancelled && (
          <Box
            display="flex"
            justifyContent="flex-start"
            marginBottom={16}
            data-testid="slice-cancel-indicator-chip"
          >
            <Chip
              label="CANCELLED"
              color="red"
              size="xs"
              leadingIcon="circle-exclamation"
              style={{ borderRadius: "4px" }}
            ></Chip>
          </Box>
        )}
        {!!isWithinCheckInWindow && !isFlightCancelled && (
          <Box
            display="flex"
            justifyContent="flex-start"
            marginBottom={16}
            data-testid="slice-checkin-indicator-chip"
          >
            <Chip label="TIME TO CHECK IN" color="green" size="xs" leadingIcon="clock"></Chip>
          </Box>
        )}
        <Box display="flex" flexDirection="row" alignItems="center" gap={8}>
          <Typography variant="heading/lg">{departureSegment.origin.airportCode}</Typography>
          <Icon name="arrow-right" />
          <Typography variant="heading/lg">{arrivalSegment.destination.airportCode}</Typography>
        </Box>
        <Box>
          <Typography variant="body/md-strong">{sliceDate}</Typography>
          <Typography variant="body/md">
            &nbsp;&nbsp;{itineraryIndicatorProps.totalFlightTime}{" "}
          </Typography>
          <Typography variant="body/md">&bull;&nbsp;</Typography>
          <Typography variant="body/md">{stopsCopy}</Typography>
        </Box>
      </Box>
    );
  }, [
    arrivalSegment.destination.airportCode,
    departureSegment.origin.airportCode,
    isFlightCancelled,
    isWithinCheckInWindow,
    itineraryIndicatorProps.totalFlightTime,
    sliceDate,
    stopsCopy,
  ]);

  const fullDetailSlices = useMemo(() => {
    if (slice.segments.length === 1) {
      return (
        <Box>
          <FlightSegmentSummary
            departureSegment={slice.segments[0]}
            itineraryIndicatorProps={itineraryIndicatorProps}
          />
          <FlightDetailsBlock segment={slice.segments[0]} />
          {travelerInfo.map((travler, travelerIdx) => {
            return (
              <TravelerInfo
                traveler={travler}
                travelerIdx={travelerIdx}
                key={`${travler.eTicketNumber}`}
              />
            );
          })}
        </Box>
      );
    }

    const lastIdx = slice.segments.length - 1;
    return (
      <Box>
        {slice.segments.map((segment, segmentIdx) => {
          // Reuses the logic, but we set the segments array to be just our active segment for processing
          const segmentItineraryProps = getItineraryIndicatorProps({
            ...slice,
            segments: [segment],
          });
          if (segmentIdx === lastIdx) {
            return (
              <Fragment key={`${segmentIdx}__segment`}>
                <FlightSegmentSummary
                  departureSegment={segment}
                  itineraryIndicatorProps={segmentItineraryProps}
                />
                <FlightDetailsBlock segment={segment} />
              </Fragment>
            );
          }

          const arrivalTime = segment.destination.arrivalTime;
          const nextDepartureTime = slice.segments[segmentIdx + 1].origin.flightTime;
          const layoverDurationString = getLayoverDurationString(arrivalTime, nextDepartureTime);

          return (
            <Fragment key={`${segmentIdx}__segment`}>
              <FlightSegmentSummary
                departureSegment={segment}
                itineraryIndicatorProps={segmentItineraryProps}
              />
              <FlightDetailsBlock segment={segment} />
              <LayoverBlock
                layoverDuration={layoverDurationString}
                layoverAirportIata={segment.destination.airportCode}
              />
            </Fragment>
          );
        })}
        {travelerInfo.map((travler, travelerIdx) => {
          return (
            <TravelerInfo
              traveler={travler}
              travelerIdx={travelerIdx}
              key={travler.eTicketNumber}
            />
          );
        })}
      </Box>
    );
  }, [itineraryIndicatorProps, slice, travelerInfo]);

  return (
    <CollapsibleContent
      toggleTestID="flight-slice-toggle"
      toggleBtnLabel={sliceToggleLabel}
      defaultOpen={shouldDefaultOpen}
      suppressBottomMargin={isLastSlice}
    >
      <Styled.SliceDetailsContainer data-testid="flight-slice-expandable-content">
        {areDetailsVisible ? (
          <Box data-testid="flight-slice-full-details">{fullDetailSlices}</Box>
        ) : (
          <Box data-testid="flight-slice-summary-details">
            <FlightSegmentSummary
              departureSegment={departureSegment}
              itineraryIndicatorProps={itineraryIndicatorProps}
            />
          </Box>
        )}
        <Box>
          <Styled.SliceDetailsButton
            size="lg"
            variant="outlined"
            color="secondary"
            data-testid="flight-details-toggle"
            onClick={handleToggleFlightDetails}
          >
            {areDetailsVisible ? "Hide" : "Show"} details
          </Styled.SliceDetailsButton>

          {!!isWithinCheckInWindow && !isFlightCancelled && (
            <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
              <Styled.SliceDetailsButton
                size="lg"
                variant="filled"
                color="primary"
                trailingIcon="arrow-up-right-from-square"
                onClick={handleCheckIn}
                data-testid="slice-checkin-button"
                style={{ width: "100%", marginTop: 12 }}
              >
                Check-in now
              </Styled.SliceDetailsButton>
              <Typography variant="body/xs" color="foregroundSecondary">
                Check-in directly with airline.
              </Typography>
            </Box>
          )}
        </Box>
      </Styled.SliceDetailsContainer>
    </CollapsibleContent>
  );
};

export default SliceInfo;
