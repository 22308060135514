import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const sidebarMdDesktopWidth = 380;

export const PreviewPanel = styled.div<{ $isFlight?: boolean }>`
  ${flexbox({
    direction: "column",
  })}

  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: 0;
  box-shadow: none;
  max-height: 100vh;
  height: ${({ $isFlight }) => !$isFlight && "100%"};
  min-width: 100%;
  position: relative;
  width: ${sidebarMdDesktopWidth}px;
  transition: all 0.175s linear;
`;

export const ScrollContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
`;

export const PreviewPanelContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    width: 450px;
    bottom: ${({ theme }) => theme.spacing[0]};
    display: flex;
    left: auto;
    position: sticky;
    right: -40px;
    top: ${({ theme }) => theme.spacing[0]};
    width: 450px;
    z-index: ${({ theme }) => theme.zIndex.backdropAbove};
  `;
