import { endpoints } from "@hotel-engine/react-query/constants";
import { useQueryClient } from "react-query";
import { useAppSelector } from "store/hooks";
import type { ITripsResult } from "@hotel-engine/types/trips";

const useHideFlights = () => {
  const queryClient = useQueryClient();
  const user = useAppSelector(({ Auth }) => Auth.user);
  const data = queryClient.getQueryData<ITripsResult>(endpoints.trips);
  const bookings = data?.trips.flatMap((trip) => trip.bookings) || [];
  const hasFlightTrips = bookings.some(({ bookingType }) => bookingType === "flight");
  const doesBusinessHideFlights = user?.business.hideFlights;

  const shouldHideFlights = doesBusinessHideFlights && !hasFlightTrips;

  return shouldHideFlights;
};

export default useHideFlights;
