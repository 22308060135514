import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type {
  IAuthStrategies,
  IAuthStrategyLookupResponse,
} from "@hotel-engine/types/authStrategy";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import config from "../../../config";

export const useAuthStrategiesLookupQuery = (
  email: string | null | undefined,
  options?: UseQueryOptions<IAuthStrategyLookupResponse[], IErrorResponse>
): IAuthStrategies => {
  const get = useApi("get");
  const encodedEmail = encodeURIComponent(email || "");
  const endpoint = `${endpoints.authStrategies}/lookup?email=${encodedEmail}`;

  const { data, error, isFetching, isFetched } = useExtendedQuery(
    endpoint,
    () => get<IAuthStrategyLookupResponse[]>(endpoint),
    options
  );

  const authStrategies: IAuthStrategies = {
    // loading info
    isFetching,
    isFetched,

    // data accessors
    all: data ?? [],
    get: (index) => data && data[index],

    // data descriptors
    hasMany: !!(data && data.length > 1),
    hasSome: !!(data && data.length >= 1),

    // error info
    error: error?.response?.data,

    // returns exactly one auth strategy
    // undefined when the number of auth strategies != 1
    singularStrategy: data?.length === 1 ? data[0] : undefined,

    // returns exactly one auth0-powered auth strategy
    // undefined when the number of auth0 auth strategies != 1
    singularAuth0Strategy: data?.length === 1 && !!data[0].auth0Connection ? data[0] : undefined,

    showSsoOrPassword: false,
  };

  authStrategies.showSsoOrPassword =
    (!!authStrategies.singularStrategy && !authStrategies.singularAuth0Strategy) ||
    (!!authStrategies.singularAuth0Strategy &&
      authStrategies.singularAuth0Strategy.auth0Connection === config.auth0ImpersonationConnection);

  return authStrategies;
};
