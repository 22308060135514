import type React from "react";
import styled from "styled-components";
import { buttonReset, ellipsisText } from "@hotel-engine/styles";
import { TripsPreviewPanel } from "../styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const Layout = styled.div`
  ${flexbox()}
  gap: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[16]}`};
  width: 100%;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  ${flexbox({ direction: "column" })}
`;

export const CalendarNavBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const NavButtonGroup = styled.div`
  ${flexbox({
    alignItems: "center",
  })}
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const WeekText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "heading/lg",
  color: "foregroundPrimary",
})`
    display: grid;
    align-items: center;
    justify-items: start;
    justify-items: flex-start;
    margin-left: ${({ theme }) => theme.spacing[24]};
`;

export const WeekHeader = styled.div`
  display: grid;
  grid-template-columns: 1.4fr repeat(7, 1fr);
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  border-radius: ${({ theme }) => `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`};
  align-items: center;
  justify-items: center;

  /* Travellers header is left aligned */
  div:nth-child(1) {
    text-align: left;
  }
`;

export const HeaderCell = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/md-strong",
  color: "foregroundPrimary",
})<{ IsToday?: boolean }>`
  width: 100%;
  text-align: center;
  padding: ${({ theme }) => theme.spacing[16]};
  background-color: ${({ theme, IsToday }) =>
    IsToday ? theme.colors.backgroundTertiary : theme.colors.backgroundPrimary};
  border-top: ${({ theme, IsToday }) =>
    IsToday
      ? `${theme.borderWidth.wide} solid ${theme.colors.foregroundPrimary}`
      : `${theme.borderWidth.wide} solid ${theme.colors.borderTransparent}`};
  
  &:first-child {
      border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
    }
    
    &:last-child {
      border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  }

  &:not(:first-child) {
    border-left: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  }
`;

export const HeaderDateNum = styled.span`
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

export const CalendarRows = styled.section`
  border-bottom: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  border-left: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  border-right: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  border-radius: ${({ theme }) => `0px 0px ${theme.borderRadius.md} ${theme.borderRadius.md}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const CalendarRow = styled.div`
  display: grid;
  grid-template-columns: 1.4fr repeat(7, 1fr);
  grid-template-rows: 1fr;
  min-height: ${({ theme }) => theme.spacing[48]};
  overflow: hidden;
  position: relative;

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  }

  &:last-child {
    border-radius: ${({ theme }) => `0px 0px ${theme.borderRadius.md} ${theme.borderRadius.md}`};
  }

  &.empty {
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  }
`;

export const TravelerCell = styled.div`
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[16]}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};

  ${flexbox({ justifyContent: "center", direction: "column" })};
  grid-row: span 1000; // high number to guarantee it will always span all rows in implicit grid
`;

export const WeekGridCellContainer = styled.div`
  display: grid;
  grid-template-columns: 1.4fr repeat(7, 1fr);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Cell = styled.div<{ index: number }>`
  border-left: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  grid-column: ${({ index }) => `${index + 2} / ${index + 3}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const PropertyName = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/sm-strong",
})<{ status: string; selected: boolean }>`
  ${ellipsisText};
  transition: 0.2s ease color;
`;

export const Contract = styled.button<{
  end?: number;
  selected: boolean;
  start?: number;
  status: string;
}>`
  ${buttonReset};
  z-index: ${({ theme }) => theme.zIndex.modal};
  background-color: ${({ theme, status, selected }) => {
    if (selected) return theme.colors.foregroundPrimary;
    else if (["cancelled", "completed"].includes(status)) return theme.colors.neutralN200;
    else return theme.colors.accentBlue;
  }};
  margin: ${({ theme, start, end }) => {
    if (start === 0 && (end ?? 0) < 6)
      return `${theme.spacing[8]} ${theme.spacing[0]} ${theme.spacing[8]} ${theme.spacing[8]}`;
    else if (end === 6 && (start ?? 0) > 0)
      return `${theme.spacing[8]} ${theme.spacing[8]} ${theme.spacing[8]} ${theme.spacing[0]}`;
    else return `${theme.spacing[8]} ${theme.spacing[0]}`;
  }};
  height: ${({ theme }) => theme.spacing[32]};
  border-radius: ${({ start, end, theme }) => {
    if (start === -1 && end === -1) return "0 0 0 0";
    else if (start === -1 && end !== -1)
      return `0 ${theme.borderRadius.xs} ${theme.borderRadius.xs} 0`;
    else if (start !== -1 && end === -1)
      return `${theme.borderRadius.xs} 0 0 ${theme.borderRadius.xs}`;
    return theme.borderRadius.xs;
  }};
  transition: 0.2s ease all;
  grid-column: ${({ start, end }) => {
    const columnStart = (start === -1 ? 0 : start ?? 0) + 2;
    const columnEnd = (end === -1 ? 6 : end ?? 0) + 3;
    return `${columnStart} / ${columnEnd}`;
  }};
  align-self: center;
  ${flexbox({ alignItems: "center" })};
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};
  overflow: hidden;
  color: ${({ theme, selected, status }) => {
    if (selected) return theme.colors.foregroundInverse;
    else if (["cancelled", "completed"].includes(status)) return theme.colors.foregroundPrimary;
    else return theme.colors.foregroundInverse;
  }};

  ${PropertyName} {
    color: ${({ theme, selected, status }) => {
      if (selected) return theme.colors.foregroundInverse;
      else if (["cancelled", "completed"].includes(status)) return theme.colors.foregroundPrimary;
      else return theme.colors.foregroundInverse;
    }};
  }

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.foregroundPrimary};

    ${PropertyName} {
      color: ${({ theme }) => theme.colors.foregroundInverse};
    }
  }
`;

export const ListTripsPreviewPanel = styled(TripsPreviewPanel)<{
  showPreview: boolean;
}>`
  display: ${({ showPreview }) => (showPreview ? "flex" : "none")};
  position: absolute;
  left: unset;
  top: unset;
  right: 0px;
`;

export const SkeletonTravelerCell = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};

  ${flexbox({ justifyContent: "center", direction: "column" })};
`;

export const SkeletonTravelerCellRow = styled.div`
  height: 14px;
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-right: ${({ theme }) => theme.spacing[16]};

  :first-of-type {
    margin-top: ${({ theme }) => theme.spacing[0]};
    max-width: 155px;
    width: 78.6%;
  }
`;

export const SkeletonCell = styled.div`
  border-left: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}`};
  box-sizing: content-box;
  height: 30px;
  grid-column-end: 9;
  grid-column-start: 2;
  padding: ${({ theme }) => theme.spacing[8]};
`;
