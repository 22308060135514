import * as Styled from "../styles";
import { Icon } from "@hotelengine/atlas-web";

const PropertyMarker = () => {
  return (
    <Styled.PropertyMarker data-testid="property-marker">
      <Icon color="foregroundInverse" name="hotel--solid" size="lg" />
    </Styled.PropertyMarker>
  );
};

export default PropertyMarker;
