import { Form } from "formik";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const ReviewContentsWrapper = styled.main`
  padding: 24px;
  h1 {
    ${({ theme }) => theme.typography.desktop["heading/lg"]};
    color: ${colors.errieBlack};
  }
  p {
    ${({ theme }) => theme.typography.desktop["body/sm"]};
    color: ${colors.errieBlack};
  }
`;

export const FormikForm = styled(Form)`
  width: 100%;
`;

export const ReviewForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StarCopy = styled.p`
  margin: 20px 0 24px;
  color: ${colors.grey[700]};
  ${({ theme }) => theme.typography.desktop["body/sm"]};
`;

export const ReviewFormWrapper = styled.div`
  padding: 24px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReviewLabel = styled.p`
  color: ${colors.grey[800]};
  ${({ theme }) => theme.typography.desktop["heading/lg"]};
`;

export const ReviewFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0 0;
`;
