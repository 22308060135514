import {
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
} from "@hotel-engine/app/ItineraryPreviewPanel/constants";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useEffect } from "react";
import useTripsControl from "./useTripsControl";

function useResponsiveViewAdjustment() {
  const isMobileScreen = useBreakpoint("xxl", "max");
  const [params, tripsControl] = useTripsControl();
  const view = params.view;

  useEffect(() => {
    if (isMobileScreen && view !== "list") {
      tripsControl.setParams({ view: "list" });
      tripsControl.unsetParams(PREVIEW_BOOKING_NUMBER, PREVIEW_BOOKING_TYPE);
    }
  }, [isMobileScreen, tripsControl, view]);
}

export default useResponsiveViewAdjustment;
