import { Box, Typography } from "@hotelengine/atlas-web";
import type { IBooking } from "@hotel-engine/types/trips";
import type { IUser } from "@hotel-engine/types/user";

import { getEndDate } from "../../helpers/parseTripDate";
import * as Styled from "../../styles";

interface ITripEndTimeCellProps {
  booking: IBooking;
  user: IUser | null;
}

const TripEndTimeCell = ({ booking, user }: ITripEndTimeCellProps) => {
  const isCheckoutVerificationEnabled = user?.business?.checkoutVerificationEnabled;
  const checkoutVerificationStatus = booking.details?.bookingStatuses?.checkoutVerification;
  const statusIsDefined = checkoutVerificationStatus != null;
  const showCheckoutVerificationBadge =
    booking.bookingType === "lodging" && isCheckoutVerificationEnabled && statusIsDefined;
  const isCheckoutVerified = checkoutVerificationStatus === true;

  const CheckoutVerificationBadge = () =>
    showCheckoutVerificationBadge ? (
      <Typography variant="body/xs" color="foregroundTertiary" paddingTop={2} paddingLeft={2}>
        {isCheckoutVerified ? "Verified" : "Unverified"}
      </Typography>
    ) : null;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Styled.PrimaryText>
        {getEndDate({ booking, preferredDateFormat: user?.business?.preferredDateFormat || "mdy" })}
      </Styled.PrimaryText>
      <CheckoutVerificationBadge />
    </Box>
  );
};

export default TripEndTimeCell;
