import { Skeleton } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

export const CalendarSkeleton = () => (
  <>
    {Array.from(Array(10).keys()).map((_, i) => (
      <Styled.CalendarRow key={`calRow-${i}`} data-testid="trips-skeleton-row">
        <Styled.SkeletonTravelerCell>
          <Styled.SkeletonTravelerCellRow>
            <Skeleton height={12} />
          </Styled.SkeletonTravelerCellRow>
          <Styled.SkeletonTravelerCellRow>
            <Skeleton height={12} />
          </Styled.SkeletonTravelerCellRow>
        </Styled.SkeletonTravelerCell>
        <Styled.WeekGridCellContainer>
          <Styled.SkeletonCell>
            <Skeleton height={32} />
          </Styled.SkeletonCell>
        </Styled.WeekGridCellContainer>
      </Styled.CalendarRow>
    ))}
  </>
);
