import * as Styled from "../../styles";

const NotificationError = () => {
  return (
    <Styled.NotificationWrapper>
      <Styled.NotificationIcon name="circle-exclamation" color="sentimentNeutralDefault" />
      <h1>An Error Occurred</h1>
      <p>Please reload the page and try again.</p>
    </Styled.NotificationWrapper>
  );
};

export default NotificationError;
