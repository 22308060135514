import type { ShouldRevalidateFunctionArgs } from "@hotel-engine/lib/react-router-dom";
import {
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
} from "@hotel-engine/app/ItineraryPreviewPanel/constants";

function didOpenPreviewPanel({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) {
  const currentHasBookingNumber = currentUrl.searchParams.has(PREVIEW_BOOKING_NUMBER);
  const nextHasBookingNumber = nextUrl.searchParams.has(PREVIEW_BOOKING_NUMBER);
  const currentHasType = currentUrl.searchParams.has(PREVIEW_BOOKING_TYPE);
  const nextHasType = nextUrl.searchParams.has(PREVIEW_BOOKING_TYPE);

  return !currentHasBookingNumber && !currentHasType && nextHasBookingNumber && nextHasType;
}

function didPreviewPanelParametersChange({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) {
  const currentBookingNumber = currentUrl.searchParams.get(PREVIEW_BOOKING_NUMBER);
  const nextBookingNumber = nextUrl.searchParams.get(PREVIEW_BOOKING_NUMBER);
  const currentType = currentUrl.searchParams.get(PREVIEW_BOOKING_TYPE);
  const nextType = nextUrl.searchParams.get(PREVIEW_BOOKING_TYPE);

  if (!currentBookingNumber || !currentType || !nextBookingNumber || !nextType) {
    return false;
  }

  return currentBookingNumber !== nextBookingNumber || currentType !== nextType;
}

function didClosePreviewPanel({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) {
  const currentHasBookingNumber = currentUrl.searchParams.has(PREVIEW_BOOKING_NUMBER);
  const nextHasBookingNumber = nextUrl.searchParams.has(PREVIEW_BOOKING_NUMBER);
  const currentHasType = currentUrl.searchParams.has(PREVIEW_BOOKING_TYPE);
  const nextHasType = nextUrl.searchParams.has(PREVIEW_BOOKING_TYPE);

  /**
   * Check if any non-preview panel parameters were removed from the URL.
   * This helps distinguish between:
   * 1. Only closing the preview panel (previewPanel params removed)
   * 2. A full query params reset (all params removed, including previewPanel)
   */
  const previewPanelKeys = [PREVIEW_BOOKING_NUMBER, PREVIEW_BOOKING_TYPE];
  const searchParamsKeys = Array.from(currentUrl.searchParams.keys());
  const otherParamsRemoved = searchParamsKeys.some((key) => {
    if (previewPanelKeys.includes(key)) return false;

    return !nextUrl.searchParams.has(key);
  });

  if (otherParamsRemoved) {
    return false;
  }

  return currentHasBookingNumber && currentHasType && (!nextHasBookingNumber || !nextHasType);
}

function didChangeSearchFacetWithNoSearch({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) {
  const currentSearchFacet = currentUrl.searchParams.get("filter[search_facet]");
  const nextSearchFacet = nextUrl.searchParams.get("filter[search_facet]");
  const searchFacetDidChange = currentSearchFacet !== nextSearchFacet;
  const searchValue = nextUrl.searchParams.get("filter[search]");

  return !!nextSearchFacet && searchFacetDidChange && !searchValue;
}

export function shouldRevalidate(args: ShouldRevalidateFunctionArgs) {
  /**
   * Prevents revalidation when:
   *
   * Preview panel:
   * 1. Opening the preview panel
   * 2. Changing preview panel parameters
   * 3. Closing the preview panel
   *
   * Search facet:
   * 1. Changing the search facet with no search value
   *
   * This ensures the main trips list doesn't reload unnecessarily during
   * certain interactions.
   */
  if (
    [
      didOpenPreviewPanel,
      didPreviewPanelParametersChange,
      didClosePreviewPanel,
      didChangeSearchFacetWithNoSearch,
    ].some((fn) => fn(args))
  ) {
    return false;
  }

  return true;
}
