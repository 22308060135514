import {
  ModificationTypes,
  useCarModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CarBookingCancellation } from "./components/CarBookingCancellation";
import { CarInsuranceCancellation } from "./components/CarInsuranceCancellation";

const CarCancellation = () => {
  const {
    state: { modificationView },
  } = useCarModificationsContext();
  const isCancellingInsurance = modificationView === ModificationTypes.CancelInsurance;

  return isCancellingInsurance ? <CarInsuranceCancellation /> : <CarBookingCancellation />;
};

export default CarCancellation;
