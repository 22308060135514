import { usePreviewCancelCarInsuranceQuery } from "@hotel-engine/react-query/reservation/useCarInsurancePolicy";

export enum CarRefundType {
  OriginalFormOfPayment = "Original form of payment",
  Unknown = "unknown",
  Nonrefundable = "nonrefundable",
}

export function usePreviewCarInsuranceRefund(insuranceId?: string) {
  const {
    data: insuranceRefundData,
    isLoading: isInsuranceLoading,
    isError,
  } = usePreviewCancelCarInsuranceQuery(insuranceId, {
    enabled: !!insuranceId,
  });

  const buildInfoObject = () => {
    if (!insuranceRefundData || !insuranceId) {
      return null;
    }

    return {
      refundType: CarRefundType.OriginalFormOfPayment,
      refundAmount: String(insuranceRefundData?.total.value),
      protection: String(insuranceRefundData?.total.value),
      cancellationFee: "0",
      isFree: true,
    };
  };

  return {
    refundInfo: buildInfoObject(),
    isLoading: isInsuranceLoading,
    isError: isError,
  };
}
