import moment from "moment";

import type { ISearchFormValues } from "@hotel-engine/types/search";
import type { LoyaltyProgramFilterKey } from "store/Search/SearchFilters/types";

export const initialSearchValues: ISearchFormValues = {
  checkIn: moment().startOf("day"),
  checkOut: moment().startOf("day").add(1, "days"),
  defaultCheckIn: moment(),
  defaultCheckOut: moment().add(1, "days"),
  selectedLocation: null,
  locationInput: "",
  roomCount: 1,
  guestCount: 2,
  radiusUpdated: false,
};

/** Determines earliest check-in date, based on whether the last timezone on Earth has crossed over to the next day (occurs at noon UTC) */
export const earliestAllowedCheckinDate = moment().utc().subtract(12, "hours");

export const SEARCH_HEIGHT = 64;
export const searchHeight = `${SEARCH_HEIGHT}px`;

export const MAX_SUGGESTIONS_PER_TYPE = 3;
export const MIN_CHARACTERS_TO_SEARCH = 3;
/** This is used for queries like saved locations in autocomplete where we want to ensure we are fetching all results. Just using a very large number is the simplest solution we came up with at this time that gets the job done that we need it to. If the need arises in the future we can re-evaluate if there is a better solution. */
export const RESULTS_LIMIT = 1000;

export enum SearchTypeOptions {
  General = "general",
  ByProperty = "by_property",
  MapUpdate = "map_update",
  TripExtension = "trip_extension",
  Rooms = "rooms",
}

export const ALL_FILTER_KEY: LoyaltyProgramFilterKey = "all";

export const ALTERNATIVE_STAYS_VALUES = [
  "cabin",
  "campsite",
  "campsites",
  "chalet",
  "chalets",
  "cottage",
];
export const APARTMENTS_VALUES = ["apartment", "condo", "condominium resort", "condos"];
export const VACATION_HOMES_VALUES = ["residence", "townhouse", "vacation home", "villa", "villas"];

export const SEARCH_FILTERS = {
  facility: {
    dining: {
      label: "Dining",
    },
    fitnessCenter: {
      label: "Fitness center",
    },
    meetingSpace: {
      label: "Meeting space",
    },
    petFriendly: {
      label: "Pet friendly",
    },
    swimmingPool: {
      label: "Swimming pool",
    },
    selfServiceLaundry: {
      label: "Self service laundry",
    },
    truckParking: {
      label: "Truck/RV parking",
    },
    ecoFriendly: {
      label: "Eco-friendly",
    },
  },
  popular: {
    clientProgram: {
      label: "Client program",
    },
    doubleRewards: {
      label: "2X rewards",
    },
    breakfast: {
      label: "Free breakfast options",
    },
    parking: {
      label: "Free parking options",
    },
    wifi: {
      label: "Free wifi",
    },
    gsaOrLower: {
      label: "GSA or less",
    },
    incidentalCoverage: {
      label: "Incidental coverage",
    },
    refundable: {
      label: "Refundable options",
    },
  },
  stayTypes: {
    all: {
      label: "All",
      values: ["All"],
    },
    hotelsAndMore: {
      label: "Hotels, motels + more",
      values: ["hotel", "motel", "resort", "bed & breakfast"],
    },
    apartmentsAndMore: {
      label: "Apartments, entire homes + more",
      values: [
        "aparthotel",
        ...ALTERNATIVE_STAYS_VALUES,
        ...APARTMENTS_VALUES,
        ...VACATION_HOMES_VALUES,
      ],
    },
  },
  propertyTypes: {
    alternativeStays: {
      label: "Alternative stays",
      values: ALTERNATIVE_STAYS_VALUES,
    },
    apartments: {
      label: "Apartments",
      values: APARTMENTS_VALUES,
    },
    vacationHomes: {
      label: "Vacation homes",
      values: VACATION_HOMES_VALUES,
    },
  },
  room: {
    airConditioning: {
      label: "Air conditioning",
    },
    coffeeMaker: {
      label: "Coffee machine",
    },
    internet: {
      label: "High speed internet",
    },
    kitchen: {
      label: "Kitchen / kitchenette",
    },
    refrigerator: {
      label: "Refrigerator",
    },
  },
  services: {
    dryCleaning: {
      label: "Dry cleaning",
    },
    shuttle: {
      label: "Free shuttle",
    },
    spa: {
      label: "Spa",
    },
    valetParking: {
      label: "Valet parking",
    },
  },
  propertyAmenities: {
    dining: {
      label: "Dining",
    },
    fitnessCenter: {
      label: "Fitness center",
    },
    meetingSpace: {
      label: "Meeting space",
    },
    petFriendly: {
      label: "Pet friendly",
    },
    swimmingPool: {
      label: "Swimming pool",
    },
    selfServiceLaundry: {
      label: "Self service laundry",
    },
    truckParking: {
      label: "Truck/RV parking",
    },
    ecoFriendly: {
      label: "Eco-friendly",
    },
    dryCleaning: {
      label: "Dry cleaning",
    },
    shuttle: {
      label: "Free shuttle",
    },
    spa: {
      label: "Spa",
    },
    valetParking: {
      label: "Valet parking",
    },
  },
} as const;

/**
 * These keys are standardized throughout the stack to help associate logs
 * in different applications with one another.
 * Request ID: Identifier for a request between two applications, such as Members and FSS
 * Session ID: Identifier used for grouping multiple logs and requests together.
 *             This is synonymous with the session ID.
 * Trace ID: Identifier for a request that is passed for all downstream logs and requests.
 */
export enum DataDogTracingAttributes {
  BusinessId = "io-engine-business-id",
  RequestId = "io-engine-request-id",
  SessionId = "io-engine-session-id",
  TraceId = "io-engine-trace-id",
  UserId = "io-engine-user-id",
}

export enum LssRequestHeaders {
  RequestId = "x-engine-lss-request-id",
  SessionId = "x-engine-lss-session-id",
  TraceId = "x-engine-lss-trace-id",
}
