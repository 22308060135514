import qs from "qs";
import type { ObjectLike } from "./mergeObjects";

export type PrimitiveValue = string | number | boolean;

export type ParsedValue = PrimitiveValue | PrimitiveValue[];

const NUMERIC_PATTERN = /^(?:-?\d+|\d*\.\d+)$/;

const BOOLEAN_PATTERN = /true|false/i;

/**
 * Converts a string into a number or boolean
 * Returns the original string when no specific conversion is matched.
 *
 * @param value - The string to be parsed.
 * @returns A number, boolean, or the original string.
 */
function parseValue(value: string): ParsedValue {
  if (NUMERIC_PATTERN.test(value)) {
    return Number(value);
  }

  if (BOOLEAN_PATTERN.test(value)) {
    return /true/i.test(value);
  }

  return value;
}

/**
 * Recursively traverses the provided value and converts all string entries
 * by applying parseValue. Arrays and objects are processed depth-first.
 *
 * @param inputValue - Any value, including arrays and objects.
 * @returns A new structure with converted entries where applicable.
 */
function transformValues<T>(inputValue: T): unknown {
  if (Array.isArray(inputValue)) {
    return inputValue.map(transformValues);
  }

  if (inputValue && typeof inputValue === "object") {
    const newObject: ObjectLike = {};

    for (const key of Object.keys(inputValue)) {
      newObject[key] = transformValues((inputValue as Record<string, unknown>)[key]);
    }

    return newObject;
  }

  if (typeof inputValue === "string") {
    return parseValue(inputValue);
  }

  return inputValue;
}

/**
 * Checks if the provided value is an object with purely numeric keys.
 * If so, it converts it into an array, preserving the numeric indices.
 *
 * @param topLevelValue - The value to be inspected.
 * @returns The same value or a newly formed array if all keys are numeric.
 */
function convertNumericObjectToArray(topLevelValue: unknown): unknown {
  if (Array.isArray(topLevelValue)) {
    return topLevelValue;
  }

  if (!topLevelValue || typeof topLevelValue !== "object") {
    return topLevelValue;
  }

  const objectKeys = Object.keys(topLevelValue);

  if (objectKeys.length === 0) {
    return topLevelValue;
  }

  if (objectKeys.every((key) => /^\d+$/.test(key))) {
    const convertedArray: unknown[] = [];

    for (const key of objectKeys) {
      convertedArray[Number(key)] = (topLevelValue as Record<string, unknown>)[key];
    }

    return convertedArray;
  }

  return topLevelValue;
}

/**
 * Deserializes a query string into a structure using qs.parse, then applies
 * additional transformations such as converting strings to numbers, booleans,
 * and comma-split arrays. If the top-level structure only has numeric keys,
 * it is converted into an array, enabling support for cases like "[]=foo&[]=bar".
 *
 * @param queryString - The raw query string, for example: "?offset=0&filter[group]=past".
 * @returns A structure (object or array) with parsed and transformed values.
 */
function deserializeQueryParams<T extends ObjectLike = ObjectLike>(queryString: string): T {
  if (!queryString) {
    return {} as T;
  }

  const parsedObject = qs.parse(queryString);
  const transformedObject = transformValues(parsedObject);

  return convertNumericObjectToArray(transformedObject) as T;
}

export default deserializeQueryParams;
