import type { TokensSchema } from "@hotelengine/atlas-web";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import type { IconName } from "@hotelengine/atlas-core";
import { useIsMobile } from "../../../../Cars/hooks/useIsMobile";
import { ECancellationType } from "@hotel-engine/types/cars/cars.shared";
import { CancellationTypeLabel } from "@hotel-engine/constants/cars";

export interface ICancellationPolicy {
  cancelType: ECancellationType;
  isPlain?: boolean;
  hideTitle?: boolean;
  policyItems: string[];
}

function CancellationPolicies({ children, isPlain }: PropsWithChildren<{ isPlain?: boolean }>) {
  const isMobile = useIsMobile();

  if (isPlain) return <Box paddingLeft={24}>{children}</Box>;

  return (
    <CollapsibleContent
      toggleBtnLabel={
        <Typography data-testid="cancel-policy" variant="heading/md" color="foregroundPrimary">
          View policy
        </Typography>
      }
      testID="cancel-policy-collapsible"
      toggleTestID="cancel-content-toggle-btn"
      suppressBottomMargin
    >
      <Box padding={16} paddingTop={isMobile ? 0 : 16}>
        {children}
      </Box>
    </CollapsibleContent>
  );
}

export function CancellationPolicy({
  cancelType,
  policyItems,
  isPlain,
  hideTitle,
}: ICancellationPolicy) {
  const policyIconProps = useMemo((): {
    name: IconName;
    color: keyof TokensSchema["colors"];
  } => {
    if (cancelType === ECancellationType.Unavailable) {
      return {
        name: "circle-exclamation",
        color: "sentimentCriticalDefault",
      };
    }

    return {
      name: "circle-check",
      color: "sentimentPositiveDefault",
    };
  }, [cancelType]);

  return (
    <>
      {!hideTitle && <Typography variant="heading/lg">Cancellation policy</Typography>}

      <Box display="flex" alignItems="center" marginBottom={8} marginTop={8}>
        <Box paddingTop={4} paddingRight={8}>
          <Icon name={policyIconProps.name} color={policyIconProps.color} />
        </Box>
        <Typography variant="body/sm-strong">{CancellationTypeLabel[cancelType]}</Typography>
      </Box>

      {policyItems?.length > 0 && (
        <CancellationPolicies isPlain={isPlain}>
          {policyItems.map((item, index) => (
            <Box key={index} marginBottom={8} marginLeft={0}>
              <Typography variant="body/sm" color="foregroundSecondary">
                {item}
              </Typography>
            </Box>
          ))}
        </CancellationPolicies>
      )}
    </>
  );
}
