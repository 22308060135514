import type { CSSProperties } from "react";
import { Tooltip, Typography, type TypographyVariant } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import * as Styled from "./styles";

export interface ISummaryOfChargesItemProps {
  label: string;
  value: string;
  popoverContent?: string;
  labelColor?: "foregroundPrimary" | "foregroundSecondary" | "sentimentPositiveDefault";
  valueColor?: "foregroundPrimary" | "sentimentPositiveDefault";
  isBold?: boolean;
  style?: CSSProperties;
  typography?: TypographyVariant;
  hideItem?: boolean;
}

const SummaryOfChargesItem = ({
  label,
  value,
  popoverContent,
  labelColor = "foregroundPrimary",
  valueColor = "foregroundPrimary",
  isBold,
  style,
  typography,
  hideItem = false,
}: ISummaryOfChargesItemProps) => {
  const { isPreview } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  if (hideItem) return null;

  return (
    <Styled.FieldLine
      style={{
        ...style,
        gridTemplateColumns: isPreview ? "180px 1fr" : "200px 1fr",
      }}
    >
      {!!popoverContent ? (
        <Tooltip side="top" align={isPreview ? "end" : "start"} content={popoverContent}>
          <Styled.DottedLine color={labelColor}>
            <Typography
              variant={typography || typographyVariants.body}
              color={labelColor}
              style={{
                cursor: !!popoverContent ? "pointer" : "default",
                fontWeight: isBold ? 600 : "normal",
                width: "fit-content",
              }}
            >
              {label}
            </Typography>
          </Styled.DottedLine>
        </Tooltip>
      ) : (
        <Typography
          variant={typography || typographyVariants.body}
          color={labelColor}
          style={{ fontWeight: isBold ? 600 : "normal" }}
        >
          {label}
        </Typography>
      )}
      <Typography
        variant={typography || typographyVariants.body}
        color={valueColor}
        style={{ fontWeight: isBold ? 600 : "normal" }}
      >
        {value}
      </Typography>
    </Styled.FieldLine>
  );
};

export default SummaryOfChargesItem;
