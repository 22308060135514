import { useBreakpoint } from "@hotel-engine/hooks";
import * as Styled from "./styles";
import SearchForm from "./SearchForm";
import DateFilters from "./DateFilters";
import DepartmentFilters from "./DepartmentFilters";
import { FilterOptions } from "./FilterOptions";
import useTripsControl from "pages/Trips/hooks/useTripsControl";

interface ITripsFiltersProps {
  isCalendarView?: boolean;
  isPreviewOpen?: boolean;
}

const TripsFilters = ({ isCalendarView = false, isPreviewOpen }: ITripsFiltersProps) => {
  const hideFiltersBreakpoint = isPreviewOpen ? "xxxxl" : "xxl";
  const isMobile = useBreakpoint(hideFiltersBreakpoint, "max");
  const isOnColor = useBreakpoint("xxl", "max");
  const [params] = useTripsControl();
  const isListView = params.view === "list";

  const searchInput = (
    <Styled.MobileSearchContainer>
      <SearchForm isMobile={isMobile} isOnColor={isOnColor} />
    </Styled.MobileSearchContainer>
  );

  return (
    <Styled.TripsFiltersContainer $isMobile={isOnColor}>
      {!!isMobile && searchInput}
      <Styled.TripsFilters>
        <Styled.MobileFiltersScroll columnGap="xsmall">
          {!isCalendarView && <DateFilters />}
          <DepartmentFilters />
          {!!isListView && <FilterOptions />}
        </Styled.MobileFiltersScroll>
        <Styled.ButtonGroup columnGap="small" style={{ alignItems: "flex-start" }}>
          {!isMobile && !!isListView && searchInput}
        </Styled.ButtonGroup>
      </Styled.TripsFilters>
    </Styled.TripsFiltersContainer>
  );
};

export default TripsFilters;
