import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import { capitalize } from "../../../../helpers";
import { Tag } from "../Tag";
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";
import * as Styled from "./styles";
import { useEffect } from "react";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";

export const MobileTripsDropdown = ({ counts, handleStatusClick, status }) => {
  const { tokens } = useGlobalTheme();
  const isSmallMobileScreen = useBreakpoint("md", "max");
  const { state: tripsState } = useTripsLegacyContext();

  const filterStatuses = ["today", "upcoming", "past", "all"];
  const smallMobileFilterStatuses = ["today", "upcoming", "past"];

  useEffect(() => {
    if (isSmallMobileScreen && status === "all") {
      handleStatusClick("upcoming");
    }
  }, [handleStatusClick, isSmallMobileScreen, status]);

  if (isSmallMobileScreen) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ flex: 1 }}
      >
        {smallMobileFilterStatuses.map((filterStatus) => (
          <Styled.GroupButton
            variant="plain"
            color="everdark"
            onClick={() => handleStatusClick(filterStatus)}
            key={filterStatus}
            $isSelected={status === filterStatus}
            isDisabled={!!tripsState.error}
          >
            {capitalize(filterStatus)}
          </Styled.GroupButton>
        ))}
      </Box>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="group-dropdown">
        <Button
          variant="filled"
          trailingIcon="chevron-down"
          size="sm"
          color="everlight"
          style={{ borderColor: tokens.colors.borderSubtle }}
          isDisabled={!!tripsState.error}
        >
          {`${capitalize(status)}`}{" "}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {filterStatuses.map((filterStatus) => {
          return (
            <DropdownMenuItem
              key={`${filterStatus}-mobile`}
              data-testid={`${filterStatus}-group`}
              id={filterStatus}
              onClick={() => handleStatusClick(filterStatus)}
            >
              <div>{capitalize(filterStatus)}</div>
              <Tag active={status === filterStatus}>{counts[filterStatus]}</Tag>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
