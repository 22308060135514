import { useCallback, useEffect, useState } from "react";

import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import BookingDetails from "@hotel-engine/app/ItineraryContent/cars/components/BookingDetails";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { ToastErrorMessage } from "@hotel-engine/app/ToastErrorMessage";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import {
  Alert,
  Box,
  Button,
  Icon,
  type TokensSchema,
  Typography,
  toast,
} from "@hotelengine/atlas-web";

import Insurance from "@hotel-engine/app/ItineraryContent/cars/components/HelpAndPolicies/Insurance";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { endpoints } from "@hotel-engine/react-query/constants";
import {
  useCancelCarInsuranceQuery,
  useGetCarInsuranceQuery,
} from "@hotel-engine/react-query/reservation/useCarInsurancePolicy";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import config from "config";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useAppSelector } from "store/hooks";
import { usePreviewCarInsuranceRefund } from "../../../../hooks/usePreviewCarInsuranceRefund";
import CancellationTitle from "../../../common/CancellationTitle";
import RefundInfo from "../../../common/RefundInfo";
import * as Styled from "../styles";
import { formatCurrency } from "@hotel-engine/utilities";
import { ampli } from "ampli";

const getStatusModalVariation = (
  successfullyCancelled: boolean,
  errorCancelling: null | "insurance" | "insuranceOnly" | "bookingOnly"
) => {
  if (successfullyCancelled) {
    return "success";
  }
  if (!!errorCancelling) {
    return errorCancelling;
  }
  return "info";
};

export function CarInsuranceCancellation() {
  const { typographyVariants } = useTypographyScaling();
  const { isPreview, reservation, dispatch, refetchReservation } = useCarModificationsContext();
  const isMobile = useBreakpoint("md", "max");
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = useAppSelector((state) => state.Auth.user?.business.currencyCode) || "USD";
  const supportNumber = config.supportNumber(currencyCode);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { t } = useTranslation("cars_trips");
  const { data: insurancePolicy, isError: errorGettingCarInsurance } = useGetCarInsuranceQuery(
    reservation?.insurancePolicyId,
    { enabled: !!reservation?.insurancePolicyId }
  );

  const {
    refundInfo,
    isLoading,
    isError: isRefundCalcError,
  } = usePreviewCarInsuranceRefund(reservation?.insurancePolicyId);

  useEffect(() => {
    if (isRefundCalcError) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [isRefundCalcError]);

  useEffect(() => {
    if (errorGettingCarInsurance) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [errorGettingCarInsurance]);

  const [showCoverageConfirmationModal, setShowCoverageConfirmationModal] = useState(false);
  const [successfullyCancelled, setSuccessfullyCancelled] = useState(false);
  // Variants are, insurance for when error when cancelling only insurance.
  // insuranceOnly when cancelling the whole booking and only fails to cancel insurance, but not booking.
  // bookingOnly when cancelling the whole booking and only fails to cancel the booking, but not insurance.
  const [errorCancelling, setErrorCancelling] = useState<
    null | "insurance" | "insuranceOnly" | "bookingOnly"
  >("insuranceOnly");

  const cancelCarInsurance = useCancelCarInsuranceQuery();

  const mode = getStatusModalVariation(successfullyCancelled, errorCancelling);
  const colorVariants: Record<typeof mode, string> = {
    success: "sentimentPositive",
    insurance: "sentimentCautionary",
    insuranceOnly: "sentimentCautionary",
    bookingOnly: "sentimentCautionary",
    info: "sentimentCritical",
  };

  const twoButtons = mode === "insurance" || mode === "info";

  const formattedRefundAmout = formatCurrency(String(refundInfo?.protection), true, currencyCode);

  const onCancel = useCallback(() => {
    ampli.clickCancelCoverageConfirm({
      policyReferenceNumber: insurancePolicy?.policyId,
      contractNumber: reservation.bookingNumber,
      userId: user?.id,
    });

    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: true, isError: false },
    });
    if (mode === "info") {
      setErrorCancelling(null);

      if (!insurancePolicy) return;

      cancelCarInsurance.mutate(
        {
          id: reservation?.insurancePolicyId as string,
        },
        {
          onSuccess: async () => {
            setSuccessfullyCancelled(true);

            await queryClient.invalidateQueries(endpoints.contracts);
            await queryClient.invalidateQueries(endpoints.trips);
            await queryClient.invalidateQueries(endpoints.reservations);
            await queryClient.invalidateQueries([
              endpoints.carInsurancePolicies,
              insurancePolicy.policyId,
            ]);

            await queryClient.invalidateQueries([
              endpoints.carBookings,
              { id: reservation?.bookingNumber },
            ]);
          },
          onError: () => {
            dispatch({
              type: "SET_MODIFICATION_STATUS",
              payload: { isLoading: false, isError: true },
            });
            setErrorCancelling("insurance");
          },
        }
      );
    } else {
      setErrorCancelling(null);
      setSuccessfullyCancelled(false);
      openNewTab("https://engine.com/contact-us", "_blank", navigate);
    }
  }, [
    insurancePolicy,
    reservation.bookingNumber,
    reservation?.insurancePolicyId,
    user?.id,
    dispatch,
    mode,
    cancelCarInsurance,
    queryClient,
    navigate,
  ]);

  const contentCancel = (
    <Box
      gap={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={isMobile ? 12 : 0}
      data-testid="insurance-cancel-bottom-sheet"
    >
      <Box
        style={{ borderRadius: "999px", width: "56px", height: "56px" }}
        padding={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={`${colorVariants[mode]}Minimal` as keyof TokensSchema["colors"]}
      >
        <Icon
          name={successfullyCancelled ? "circle-check" : "circle-exclamation"}
          style={{ height: "100%", width: "100%" }}
          color={`${colorVariants[mode]}Default` as keyof TokensSchema["colors"]}
        />
      </Box>
      <Typography variant="heading/lg" style={{ textAlign: "center" }} color="foregroundPrimary">
        {t(`helpAndPolicies.insurance.cancelModal.${mode}.title`)}
      </Typography>
      <Typography variant="body/md" style={{ textAlign: "center" }} color="foregroundPrimary">
        {t(`helpAndPolicies.insurance.cancelModal.${mode}.description`, {
          supportNumber,
        })}
      </Typography>

      {!!successfullyCancelled && (
        <Box
          display="flex"
          flexDirection="column"
          gap={8}
          paddingTop={8}
          paddingBottom={8}
          backgroundColor="sentimentPositiveMinimal"
          paddingLeft={12}
          paddingRight={12}
          style={{ borderRadius: "12px", width: "100%" }}
        >
          <Box display="flex" gap={8} alignItems="center">
            <Icon
              name="circle-check"
              style={{ flexShrink: 0 }}
              color="sentimentPositiveDefault"
              size="md"
            />
            <Typography variant="body/sm-strong" color="foregroundPrimary">
              {t(`helpAndPolicies.insurance.cancelModal.success.abstract.title`)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={6} marginLeft={24}>
            <Typography variant="body/sm" color="foregroundPrimary">
              {t(`helpAndPolicies.insurance.cancelModal.success.abstract.body`)}
            </Typography>
            <Box display="flex" gap={4} alignItems={"center"}>
              <Typography variant="body/sm-strong" color="foregroundPrimary">
                {t(`helpAndPolicies.insurance.cancelModal.success.abstract.total`)}{" "}
                {formattedRefundAmout}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={16} style={{ width: "100%" }}>
        {(mode === "insurance" || mode === "info") && (
          <Button
            variant="filled"
            color={mode === "info" ? "destructive" : "everdark"}
            isLoading={cancelCarInsurance.isLoading}
            size="md"
            data-testid="insurance-cancel-coverage"
            style={{ flexBasis: "50%" }}
            onClick={onCancel}
          >
            {t(`helpAndPolicies.insurance.cancelModal.${mode}.button`)}
          </Button>
        )}

        <Button
          variant={!twoButtons ? "filled" : "outlined"}
          size="md"
          color={!twoButtons ? "primary" : "secondary"}
          style={{ flexBasis: !twoButtons ? "100%" : "50%" }}
          data-testid="insurance-file-a-claim"
          onClick={() => {
            setShowCoverageConfirmationModal(false);

            if (successfullyCancelled) {
              void refetchReservation();
              dispatch({
                type: "SET_MODIFICATION_STATUS",
                payload: { isLoading: false, isSubmitted: true },
              });
            }
          }}
        >
          {t(`helpAndPolicies.insurance.cancelModal.${mode}.close`)}
        </Button>
      </Box>
    </Box>
  );

  const dialogSheetWrapperCancelInsurance = (
    <ResponsiveAtlasDialog
      title=""
      content={contentCancel}
      showClose={false}
      isOpen={showCoverageConfirmationModal}
      triggerComponent={
        <Box>
          <Button
            variant="outlined"
            size="md"
            color="destructive"
            data-testid="insurance-trigger-cancel-bottom-sheet"
            style={{ width: "100%" }}
            onClick={() => {
              setShowCoverageConfirmationModal(true);
              setErrorCancelling(null);
            }}
          >
            {t("helpAndPolicies.insurance.modal.cancelCoverage")}
          </Button>
        </Box>
      }
      isModal
    />
  );

  return (
    <Box backgroundColor="backgroundSecondary" paddingTop={!isPreview ? 16 : 0}>
      <CancellationTitle isCarInsurance />

      <Box marginBottom={16}>
        <Typography
          variant={typographyVariants.body}
          color="foregroundPrimary"
          as="p"
          style={{ textAlign: "center" }}
        >
          {t("helpAndPolicies.insurance.cancelModal.header.warning")}
        </Typography>
      </Box>

      <Box marginBottom={24}>
        <Alert
          sentiment="positive"
          variant="outlined"
          size="sm"
          title={t("helpAndPolicies.insurance.cancelModal.header.freeCancellation")}
        />
      </Box>

      <Box marginBottom={24}>
        <Insurance hideManage />
      </Box>

      <BookingDetails
        logoSrc={reservation.rentalCompany?.logo}
        details={reservation}
        initialExpanded
        hideToggleButton
      />

      <Styled.StickyContainer $isPreview={isPreview}>
        <RefundInfo isLoading={isLoading} refundInfo={refundInfo} />

        {dialogSheetWrapperCancelInsurance}
      </Styled.StickyContainer>
    </Box>
  );
}
