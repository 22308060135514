import type React from "react";
import styled, { css } from "styled-components";

import { visuallyHidden } from "@hotel-engine/styles";
import type { BoxProps, ChipProps } from "@hotelengine/atlas-web";
import { Box, Chip } from "@hotelengine/atlas-web";

export const EmailTag = styled<React.FC<ChipProps>>(Chip)`
  margin: ${({ theme }) => theme.spacing[4]};
`;

export const ListItem = styled.span<{ $isHidden?: boolean }>`
  transition: all 0.3s;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  ${({ $isHidden }) => $isHidden && visuallyHidden}
  width: 100%;
`;

export const NewEmailInput = styled.input<{ $isHidden: boolean }>`
  transition: all 0.3s;
  border: 0;
  padding: ${({ theme }) => theme.spacing[2]};
  width: 100%;
  align-items: center;
  ::placeholder {
    color: ${({ theme }) => theme.colors.inputForegroundPlaceholder};
  }
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  &:focus {
    border: 0;
  }
  ${({ $isHidden }) => $isHidden && visuallyHidden}
`;

interface IListLike {
  tabIndex: number;
  onClick: (ev: React.MouseEvent) => void;
  onMouseDown: (ev: React.MouseEvent) => void;
  onBlur: (ev: React.MouseEvent) => void;
  onKeyUp: (ev: React.KeyboardEvent) => void;
  onFocus: (ev: React.FocusEvent) => void;
  $hasFocus: boolean;
  $isValid: boolean;
}

export const PillContainer = styled<React.FC<BoxProps>>(Box)<IListLike>`
  margin-top: 6px;
  padding: ${({ theme }) => theme.spacing[8]};
  transition: all 0.3s;
  min-height: ${({ theme }) => theme.spacing[40]};
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderDefault}`};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  overflow: hidden;
  &:hover {
    ${({ $isValid, theme }) =>
      css`
        border: 1px solid ${$isValid ? theme.colors.foregroundPrimary : theme.colors.accentRed};
      `}
  }
  & > div {
    width: 100%;
    min-height: 40px;
    padding: 8px;
  }
  & > div > div {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
  }
  & > div > div > span:last-child {
    flex-grow: 1;
  }
  ${({ $hasFocus, $isValid }) =>
    $isValid &&
    $hasFocus &&
    css`
      border: ${({ theme }) =>
        `${theme.borderWidth.normal} solid ${theme.colors.foregroundPrimary}`};
    `}

  ${({ $isValid, theme }) =>
    !$isValid &&
    css`
      border: ${`${theme.borderWidth.normal} solid ${theme.colors.accentRed}`};
    `}
`;

// used to track width of 14px font size width so that input can expand / contract based on value
// must match PillInput
export const InvisibleText = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  padding: 0px 8px 2px 8px;
  color: black;
  z-index: ${({ theme }) => theme.legacy.zIndex.belowBackdrop};
  position: absolute;
`;

export const PillInput = styled.input`
  max-height: ${({ theme }) => theme.legacy.lineHeight.md};
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  background-color: transparent;

  box-shadow: 0px 0px 0px 2px transparent;
  border: 1px solid transparent;
  list-style: none;
  margin: 4px;
  vertical-align: top;
`;
