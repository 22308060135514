import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { ellipsisText } from "@hotel-engine/styles";

const avatarSizes = ["xsmall", "small", "med", "large", "xlarge"] as const;

type AvatarDimensionsOptions = (typeof avatarSizes)[number];

type AvatarDimensionsMapping = { [K in AvatarDimensionsOptions]: number };

/** This come from the Avatar styles in src/@hotel-engine/common/Avatar/styles.ts */
const avatarDimensionsMapping: AvatarDimensionsMapping = {
  xsmall: 24,
  small: 32,
  med: 40,
  large: 60,
  xlarge: 120,
};

export const SearchGuestOption = styled.div`
  display: flex;
  align-items: center;
`;

export const GuestInfo = styled.div<{
  adjacentElementSize: AvatarDimensionsOptions;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: ${({ adjacentElementSize }) =>
    `calc(100% - ${avatarDimensionsMapping[adjacentElementSize]}px)`};
`;

export const GuestInfoRow = styled.div<{
  $spaceBetween?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ $spaceBetween }) => $spaceBetween && `justify-content: space-between;`}
`;

export const OptionTypography = styled.p`
  ${ellipsisText}
`;

export const RecentGuestHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;

export const AddAGuestButton = styled(Button)`
  display: block;
  width: 100%;
  text-align: start;
  border: none;

  :hover {
    background-color: transparent;
  }
`;
