import currency from "currency.js";
import { useFormikContext } from "formik";
import moment from "moment";
import { Typography, Icon } from "@hotelengine/atlas-web";

import { Popover } from "@hotel-engine/common/Popover";
import {
  formatCurrency,
  formatDate,
  formatNumber,
  isUserEligibleToViewAndUseRewards,
} from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import config from "config";
import { defaultFlexPercentage } from "pages/Checkout/LegacyLodging/constants";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";

import * as Styled from "./styles";
import { generatePricingCalculation } from "./helpers";
import type { IRoomRate } from "@hotel-engine/types/room";
import { ampli } from "ampli";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

interface IExpressBookPricingDetails {
  contractRate: IExpressBookContractRate;
  expressBookCheckIn: string;
  expressBookCheckOut: string;
  roomRate: IRoomRate;
  roomCount?: string;
}

export const ExpressBookPricingDetails = ({
  contractRate,
  expressBookCheckIn,
  expressBookCheckOut,
  roomRate,
  roomCount = "1", // DEFAULT THIS TO 1 UNTIL FURTHER NOTICE FOR EXPRESS BOOK
}: IExpressBookPricingDetails) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { expressBookAnalytics } = useExpressBook();
  const preferredDateFormat = user?.business.preferredDateFormat || "mdy";
  const currencyCode = user?.business?.currencyCode || "USD";
  const form = useFormikContext<IExpressBookCheckoutForm>();
  const formattedTotal = generatePricingCalculation(currencyCode, form.values, contractRate, user);

  const isInBookingDetailsTreatmentGroup =
    useExperiment("flex_multi_room_booking_details_ab_test", {
      value: "control",
    }).data.value === "treatment";

  /**
   * note: this flag was initially called flexpass before the rebrand to flexpro
   *  todo remove flexProEnabled once flexpass is fully rolled out
   */
  const flexProEnabled = useIsFeatureFlagOn("flexpass");

  const showFlexPro =
    flexProEnabled && user?.business.flexAllowedBusiness && user?.business.flexProEnrolled;

  const memberSavingsTotal = contractRate.memberSavingsTotal;
  const showMemberSavings = !!memberSavingsTotal;

  const totalRoomsCost = formatCurrency(contractRate.subtotal, true, currencyCode);
  const formattedMemberSavingsTotal = formatCurrency(
    contractRate.memberSavingsTotal + contractRate.subtotal,
    true,
    currencyCode
  );
  const nightCount = moment(expressBookCheckOut).diff(moment(expressBookCheckIn), "days");
  const roomCopy = parseInt(roomCount) > 1 ? "rooms" : "room";
  const nightCopy = nightCount > 1 ? "nights" : "night";

  const defaultFlexCost: number =
    Math.round(contractRate.total * defaultFlexPercentage * 100) / 100;

  const flexDisplayRate =
    showFlexPro && defaultFlexCost > contractRate.flexCancellationCost
      ? defaultFlexCost
      : contractRate.flexCancellationCost;

  const buildNightlyRatesRows = () => {
    if (roomRate?.nightlyRates?.length) {
      return roomRate?.nightlyRates?.map((nightlyRate, index) => (
        <Styled.NightlyBreakdownNight key={index}>
          <Styled.NightlyBreakdownRow>
            <Typography variant="body/sm">
              {formatDate(nightlyRate.date, "MM/DD/YYYY", preferredDateFormat)}
            </Typography>
            <Typography variant="body/sm">
              {`${formatCurrency(
                nightlyRate.rate,
                false,
                currencyCode
              )} x ${roomCount} ${roomCopy}`}
            </Typography>
          </Styled.NightlyBreakdownRow>
        </Styled.NightlyBreakdownNight>
      ));
    }
    const nightsRows: JSX.Element[] = [];
    let dateOfNight = moment(expressBookCheckIn);
    for (let index = 0; index < nightCount; index++) {
      nightsRows.push(
        <Styled.NightlyBreakdownNight key={index}>
          <Styled.NightlyBreakdownRow>
            <Typography variant="body/sm">
              {formatDate(dateOfNight, "MM/DD/YYYY", preferredDateFormat)}
            </Typography>
            <Typography variant="body/sm">
              {`${formatCurrency(
                contractRate.rate,
                true,
                currencyCode
              )} x ${roomCount} ${roomCopy}`}
            </Typography>
          </Styled.NightlyBreakdownRow>
        </Styled.NightlyBreakdownNight>
      );
      dateOfNight = dateOfNight.add(1, "day");
    }
    return nightsRows;
  };
  const nightlyRateBreakdown = () => (
    <div>
      <Styled.NightlyRateBreakdownTitle>
        <Typography variant="heading/sm">Nightly Rate Breakdown</Typography>
      </Styled.NightlyRateBreakdownTitle>
      <Styled.NightlyRateDivider />
      <Styled.NightlyBreakdownBody>{buildNightlyRatesRows()}</Styled.NightlyBreakdownBody>
      <Styled.NightlyRateDivider />
      <Styled.NightlyBreakdownFooter>
        <Typography variant="heading/sm">
          {`${roomCount} ${roomCopy}, ${nightCount}
          ${nightCopy}`}
        </Typography>
        <Typography variant="heading/sm">{totalRoomsCost}</Typography>
      </Styled.NightlyBreakdownFooter>
    </div>
  );

  const getEarnedPoints = () => {
    const costBeforeTaxesAndFees = contractRate.subtotal;

    let earnedPoints = Math.ceil(
      costBeforeTaxesAndFees * (user?.rewardsProfile?.currentTier.pointsPerDollar || 0)
    );

    if (contractRate?.doubleRewards) earnedPoints *= 2;

    return Math.max(0, earnedPoints);
  };

  const showBookingDetailsTreatmentGroup =
    !!isInBookingDetailsTreatmentGroup &&
    Number(roomCount) > 1 &&
    contractRate.flexCancellationCost > 0;

  const bookingDetailsTreatmentGroupText = `(${formatCurrency(
    contractRate.flexCancellationCost / Number(roomCount),
    true,
    currencyCode
  )} x ${roomCount} rooms) `;

  return (
    <Styled.ExpressBookPricingDetailsContainer>
      <Styled.PricingDetailsCollapse
        bordered={false}
        expandIconPosition="right"
        expandIcon={({ isActive }) => {
          return <Styled.CollapseIcon $isOpen={isActive} />;
        }}
      >
        <Styled.PricingDetailsCollapsePanel
          header={
            <Styled.CollapseHeader
              data-testid="eb-pricing-details-collapse"
              onClick={() => {
                ampli.clickPricingDetailsOnExpressBookModal({
                  propertyId: expressBookAnalytics.propertyId,
                  searchId: expressBookAnalytics.searchId,
                });
              }}
            >
              Pricing Details
            </Styled.CollapseHeader>
          }
          key="1"
        >
          <Styled.MiniTable className="pricing-summary" data-testid="eb-pricing-summary">
            <Styled.Row className="pricing-summary-row">
              <div>
                <span>{`${roomCount} ${roomCopy}, ${nightCount}
          ${nightCopy}`}</span>
                {!showMemberSavings && (
                  <Popover
                    overlayStyle={{
                      width: "280px",
                    }}
                    placement="topLeft"
                    content={nightlyRateBreakdown()}
                  >
                    {" "}
                    <Icon name="circle-question" size="sm" color="accentGray" />
                  </Popover>
                )}
              </div>
              <span data-testid="room-price">
                {showMemberSavings ? formattedMemberSavingsTotal : totalRoomsCost}
              </span>
            </Styled.Row>
            {!!showMemberSavings && (
              <Typography variant="body/sm" color="accentGreen">
                <Styled.Row className="pricing-summary-row">
                  <span>{COMPANY_NAME} Member Credit</span>
                  <span>-{formatCurrency(memberSavingsTotal, true, currencyCode)}</span>
                </Styled.Row>
              </Typography>
            )}
            {!!showMemberSavings && (
              <Styled.Row className="pricing-summary-row">
                <div>
                  <span>Your Subtotal:</span>
                  <Popover
                    overlayStyle={{
                      width: "280px",
                    }}
                    placement="topLeft"
                    content={nightlyRateBreakdown()}
                  >
                    {" "}
                    <Icon name="circle-question" size="sm" color="accentGray" />
                  </Popover>
                </div>
                <span>{formatCurrency(contractRate.subtotal, true, currencyCode)}</span>
              </Styled.Row>
            )}
            {!!form.values.isFlexEnabled && (
              <Styled.Row className="pricing-summary-row">
                {!!showFlexPro ? (
                  <Styled.FlexProLogo
                    alt="Flex Pro Logo"
                    src={`${config.cdnHost}/assets/flex-pro/flex-pro-logo.svg`}
                  />
                ) : (
                  <span>
                    Flex
                    {!isInBookingDetailsTreatmentGroup && Number(roomCount) > 1
                      ? ` x ${roomCount} rooms`
                      : ""}
                    :
                  </span>
                )}
                <Styled.FlexContainer>
                  <Styled.FlexTextContainer>
                    <Styled.FlexText showFlexPro={showFlexPro}>
                      {!!showBookingDetailsTreatmentGroup ? bookingDetailsTreatmentGroupText : ""}
                      <Styled.FlexCost>
                        {formatCurrency(flexDisplayRate, true, currencyCode)}
                      </Styled.FlexCost>
                    </Styled.FlexText>
                    {!!showFlexPro && (
                      <Styled.ZeroText>{formatCurrency(0, true, currencyCode)}</Styled.ZeroText>
                    )}
                  </Styled.FlexTextContainer>
                </Styled.FlexContainer>
              </Styled.Row>
            )}
            <Styled.Row className="pricing-summary-row">
              <div>
                <span>
                  Taxes &amp; Fees:
                  <Popover
                    overlayStyle={{
                      width: "280px",
                    }}
                    placement="topLeft"
                    content={
                      <div>
                        <span>
                          Fees are inclusive of {COMPANY_NAME} overhead costs and incurred hotel and
                          supplier fees. Taxes are charged by the hotel for tax obligations.
                        </span>
                      </div>
                    }
                  >
                    {" "}
                    <Icon name="circle-question" size="sm" color="accentGray" />
                  </Popover>
                </span>
              </div>
              <span data-testid="taxes-and-fees">
                {formatCurrency(contractRate.tax, true, currencyCode)}
              </span>
            </Styled.Row>
            {!!contractRate.prepaidFees && (
              <Styled.Row className="pricing-summary-row">
                <div>
                  <span>Property Charge</span>
                  <Popover
                    overlayStyle={{
                      width: "280px",
                    }}
                    placement="topLeft"
                    content={
                      <div>
                        <span>These are fees charged by the hotel at the time of booking.</span>
                      </div>
                    }
                  >
                    {" "}
                    <Icon
                      name="circle-question"
                      size="sm"
                      color="accentGray"
                      data-testid="roomsBreakdownTooltip"
                    />
                  </Popover>
                </div>
                <span>{formatCurrency(contractRate.prepaidFees || 0, true, currencyCode)}</span>
              </Styled.Row>
            )}
            {!!contractRate.convenienceFee && (
              <Styled.Row>
                <span>Convenience Fee</span>
                <span>{formatCurrency(contractRate.convenienceFee, true, currencyCode)}</span>
              </Styled.Row>
            )}
            {!!form.values.includeIncidentalCharges && (
              <Styled.Row className="pricing-summary-row">
                <span>Incidentals Coverage</span>
                <span>{formatCurrency(contractRate.incidentalsFee, true, currencyCode)}</span>
              </Styled.Row>
            )}
            {!!contractRate.publicFee && (
              <Styled.Row>
                <span>Booking Fee</span>
                <span>{formatCurrency(contractRate.publicFee, true, currencyCode)}</span>
              </Styled.Row>
            )}
          </Styled.MiniTable>
        </Styled.PricingDetailsCollapsePanel>
      </Styled.PricingDetailsCollapse>
      <Styled.PricingDetailsTotalContainer>
        <Styled.Row className="pricing-summary-row">
          <span>
            <strong>
              {user?.business.hotelCollect ? "Room Total:" : `Total Due Now (${currencyCode}):`}
            </strong>
          </span>
          <span data-testid="total-due">
            <strong>{formattedTotal}</strong>
          </span>
        </Styled.Row>
        {!!roomRate?.saveAmount && !!(roomRate?.saveAmount > 0) && !showMemberSavings && (
          <Styled.Row>
            <Typography variant="body/sm" color="accentGreen">
              You Save
            </Typography>
            <span>
              {formatCurrency(
                currency(roomRate.saveAmount).multiply(roomCount).multiply(nightCount).value,
                true,
                currencyCode
              )}
            </span>
          </Styled.Row>
        )}
        {!!(contractRate.mandatoryFeeAmount || null) && (
          <Styled.Row className="pricing-summary-row">
            <div>
              <span>
                {user?.business.hotelCollect ? "Additional property fee:" : "Due at Check-In:"}
              </span>
              <Popover
                overlayStyle={{
                  width: "280px",
                }}
                placement="topLeft"
                content={
                  <div>
                    <span>
                      {user?.business.hotelCollect
                        ? "The amount due to the property at check-in for extra services such as parking."
                        : "These are fees due to the property at the time of check-in and will be charged by the front desk when you or your guest arrive."}
                    </span>
                  </div>
                }
              >
                {" "}
                <Icon name="circle-question" size="sm" color="accentGray" />
              </Popover>
            </div>
            <span>{formatCurrency(contractRate.mandatoryFeeAmount || 0, true, currencyCode)}</span>
          </Styled.Row>
        )}
        {!!user && isUserEligibleToViewAndUseRewards(user) && getEarnedPoints() > 0 && (
          <Styled.Row>
            <Styled.RewardsBanner data-testid="eb-rewards-banner">
              <i className="rewards-logo"></i>
              <span>
                You will earn <strong>{formatNumber(getEarnedPoints())}</strong> reward points
              </span>
            </Styled.RewardsBanner>
          </Styled.Row>
        )}
      </Styled.PricingDetailsTotalContainer>
    </Styled.ExpressBookPricingDetailsContainer>
  );
};
