import type { ICarItinerary, IFlightItinerary } from "@hotel-engine/types/itinerary";

import type { IFlightItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/flights";
import type { IReservationBaseWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/lodging";
import type { ICarItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/car";
import type { ITripPreview } from "pages/Trips/contextLegacy";

import type { IReservationBase } from "../../types/reservation";
import FlightItineraryContent from "./flights";
import LodgingItineraryContent from "./lodging";
import CarItineraryContent from "./cars";

export interface ILocationState {
  editCustomFields?: boolean;
  confirmation?: boolean;
  propertyQuery?: {
    propertyId: number;
    location: string;
    checkIn: string;
    checkOut: string;
    guestCount: string;
    roomCount: string;
    s: string;
  };
}

export interface IItineraryContentProps {
  /** Used to manage location specific logic depending on whether the user is on the Trips page or Itinerary page */
  isPreview: boolean;
  /** The reservation being displayed */
  itinerary:
    | IReservationBase
    | ICarItinerary
    | IFlightItinerary
    | IReservationBaseWithId
    | IFlightItineraryWithId
    | ICarItineraryWithId;
  /** Used to refetch the reservation after modifications are made within the panel */
  refetchReservation: () => Promise<void> | void;
  /** Used to render loader while full reservation is being fetched on the Trips page */
  isLoading?: boolean;
  /** Only used when on Trips page preview panel close button is pressed */
  onClose?: ((tripPreview: ITripPreview) => void) | ((reservation: IReservationBase) => void);
  /** Used to determine which variant of itinerary to render */
  bookingType: "flight" | "lodging" | "car";
}

const ItineraryContent = ({
  isPreview,
  itinerary,
  refetchReservation,
  isLoading,
  onClose,
  bookingType,
}: IItineraryContentProps) => {
  if (bookingType === "flight") {
    return (
      <FlightItineraryContent
        itinerary={itinerary as IFlightItinerary | IFlightItineraryWithId}
        isPreview={isPreview}
        refetchReservation={refetchReservation}
        onClose={onClose as (tripPreview: ITripPreview) => void}
        isLoading={isLoading}
      />
    );
  } else if (bookingType === "car") {
    return (
      <CarItineraryContent
        itinerary={itinerary as ICarItinerary | ICarItineraryWithId}
        isPreview={isPreview}
        refetchReservation={refetchReservation}
        onClose={onClose as (tripPreview: ITripPreview) => void}
        isLoading={isLoading}
      />
    );
  } else {
    return (
      <LodgingItineraryContent
        itinerary={itinerary as IReservationBase | IReservationBaseWithId}
        isPreview={isPreview}
        refetchReservation={refetchReservation}
        onClose={onClose}
        isLoading={isLoading}
      />
    );
  }
};

export default ItineraryContent;
