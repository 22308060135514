import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { createPortal } from "react-dom";

type IPortalProps = {
  selector: string;
  children: ReactNode;
};

export default function Portal({ children, selector }: IPortalProps) {
  const [container, setContainer] = useState(document.querySelector(selector));

  useEffect(() => {
    setContainer(document.querySelector(selector));
  }, [selector]);

  return container ? createPortal(children, container) : null;
}
