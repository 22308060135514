import styled from "styled-components";

import { Icon } from "@hotelengine/atlas-web";

export const ProgramLengthWarningContainer = styled.div`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xs};
  padding: 4px 6px;
  display: flex;
  align-content: center;
`;

export const WarningIcon = styled(Icon)`
  margin-right: 8px;
  align-self: center;
`;
