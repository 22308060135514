import { Box, Typography } from "@hotelengine/atlas-web";

import { getWrapperNIconProps } from "./helpers";
import { StyledIcon } from "./styles";
import type { ILoaderProps } from "./types";

/**
 *
 * This is a light wrapper around Atlas Icon component with a slowed-down animation speed and additional props for styling and tooltips.
 *
 * @param {IconProps["color"]} color - The color of the loader (optional)
 * @param {string} className - The class name of the loader (optional)
 * @param {IconProps["size"]} size - The size of the loader (optional)
 * @param {"default" | "full-page" | "centered"} type - The type of loader (optional)
 * @param {Object} customSpinnerSize - The custom size of the loader -- will take precedence over size prop if set (optional)
 * @param {string} customSpinnerSize.height - The height of the loader
 * @param {string} customSpinnerSize.width - The width of the loader
 * @param {string} tip - Helper text to display with loader (optional)
 * @param {TypographyVariant} tipSize - The size of the tip text (optional)
 * @param {"row" | "column"} tipPosition - The position of the tip text (optional)
 * @param {string} testId - The test id of the loader (optional)
 */

export const Loader = ({
  color,
  className,
  size = "md",
  type = "default",
  customSpinnerSize,
  tip,
  tipSize = "body/md",
  tipPosition = "row",
  testId = "loader",
}: ILoaderProps) => {
  const isCustomSpinnerSize = !!(
    customSpinnerSize !== undefined &&
    "height" in customSpinnerSize &&
    "width" in customSpinnerSize
  );

  const { wrapperProps, iconStyles } = getWrapperNIconProps({
    type,
    tipPosition,
    isCustomSpinnerSize,
    customSpinnerSize,
  });

  return (
    <Box {...wrapperProps} gap={!!tip ? 16 : 0}>
      <StyledIcon
        data-testid={testId}
        name="spinner-third"
        animationStyle="spin"
        color={color}
        className={className}
        size={isCustomSpinnerSize ? undefined : size}
        style={iconStyles}
      />
      {!!tip && (
        <Typography role="status" as="span" color={color} variant={tipSize}>
          {tip}
        </Typography>
      )}
    </Box>
  );
};
