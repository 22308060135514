import type {
  ICheckoutVerification,
  INotificationReferrer,
} from "@hotel-engine/types/notifications";
import type { IPropertyReviewInfo } from "@hotel-engine/types/property";

import * as Styled from "../../styles";
import { NotificationCompleteButton } from "../NotificationCompleteButton";

const successCopy = {
  checkoutVerification: {
    title: "We verified your stay",
    text: "Thanks for verifying your checkout. This information helps your company improve bookings, follow general reservation patterns and may assist in recouping unused days.",
  },
  propertyReview: {
    title: "Review Submitted!",
    text: "Thank you for your feedback. Your review will provide quality feedback to our hotel partners as well as improve your future search results.",
  },
};

const alreadySubmittedCopy = {
  checkoutVerification: {
    title: "We already verified your stay",
    text: "Thank you for your verification. This information helps your company improve bookings, follow general reservation patterns and may assist in recouping unused days.",
  },
  propertyReview: {
    title: "Thanks for your review!",
    text: "Looks like you already reviewed your stay. We appreciate you sharing your thoughts. It helps us build an even stronger lodging performance network.",
  },
};

interface INotificationSuccessProps {
  isCheckoutVerification?: boolean;
  isAlreadySubmitted?: boolean;
  propertyInfo: IPropertyReviewInfo | ICheckoutVerification;
  dismissModal: () => void;
  referrer: INotificationReferrer;
}

export const NotificationSuccess = ({
  isCheckoutVerification,
  isAlreadySubmitted,
  dismissModal,
  referrer,
}: INotificationSuccessProps) => {
  const submissionType = isCheckoutVerification ? "checkoutVerification" : "propertyReview";

  const copy = isAlreadySubmitted ? alreadySubmittedCopy : successCopy;

  return (
    <Styled.NotificationWrapper>
      {!isCheckoutVerification && isAlreadySubmitted ? (
        <Styled.NotificationIcon name="message-lines" color="sentimentNeutralDefault" />
      ) : (
        <Styled.NotificationIcon name="circle-check" color="sentimentPositiveStrong" />
      )}
      <h1>{copy[submissionType].title}</h1>
      <p>{copy[submissionType].text}</p>

      <NotificationCompleteButton dismissModal={dismissModal} referrer={referrer} />
    </Styled.NotificationWrapper>
  );
};
