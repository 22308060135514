import { useState } from "react";

import { Box, SegmentedControl, SegmentedControlItem, Typography } from "@hotelengine/atlas-web";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import GoogleLocationDetailMap from "@hotel-engine/app/GoogleMap/components/GoogleLocationDetailMap";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import useGoogleCarLocations from "@hotel-engine/app/GoogleMap/components/GoogleCarMap/useGoogleCarLocations";
import { getFullQueryAddress } from "@hotel-engine/app/GoogleMap/components/GoogleCarMap/helpers";
import { useAirportAccess } from "pages/Cars/hooks/useAirportAccess";

import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import { RentalCompanyImage } from "../../styles";
import type { LocationType } from "../ContactInformation";
import { ContactInformation } from "../ContactInformation";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";
import type { ICarDetails } from "@hotel-engine/types/cars/cars.shared";

const Location = ({
  location,
  rentalCompany,
  startTime,
  endTime,
}: Pick<ICarDetails, "location" | "rentalCompany" | "startTime" | "endTime">) => {
  const user = useAppSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const [locationValue, setLocationValue] = useState("pick-up");
  const useDropOff = locationValue === "drop-off";
  const displayLocation = useDropOff ? location?.dropOff : location?.pickUp;

  const pickUpAddress = getFullQueryAddress(location?.pickUp);
  const dropOffAddress = getFullQueryAddress(location?.dropOff);
  const { locations } = useGoogleCarLocations(pickUpAddress, dropOffAddress);

  const { getAirportAccess } = useAirportAccess();

  const areLocationsTheSame =
    locations.pickUp?.lat === locations.dropOff?.lat &&
    locations.pickUp?.lng === locations.dropOff?.lng;

  const handleGetDirections = () => {
    const url = `https://www.google.com/maps/dir//${getFullQueryAddress(displayLocation)}`;
    openNewTab(url, "_blank", navigate);
  };

  if (!locations.pickUp || !locations.dropOff || !displayLocation.street) return null;

  return (
    <CollapsibleContent
      toggleTestID="toggle-location"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Location</Typography>
        </Box>
      }
      defaultOpen
    >
      <Box padding={24} paddingTop={4} display="flex" flexDirection="column" gap={16}>
        {!areLocationsTheSame && (
          <SegmentedControl
            onValueChange={(value) => {
              setLocationValue(value);

              if (user) {
                ampli.clickTogglePickupDropoff({
                  selectedOption: value,
                  userId: user.id,
                });
              }
            }}
            size="lg"
            style={{ width: "100%" }}
          >
            <SegmentedControlItem icon="car-circle-arrow-up" value="pick-up">
              Pick-up
            </SegmentedControlItem>
            <SegmentedControlItem icon="car-circle-arrow-down" value="drop-off">
              Drop-off
            </SegmentedControlItem>
          </SegmentedControl>
        )}
        <GoogleLocationDetailMap
          mapCenter={useDropOff ? locations.dropOff : locations.pickUp}
          icon={useDropOff ? "car-circle-arrow-down" : "car-circle-arrow-up"}
        />
        <Box display="flex" flexDirection="column" gap={4} marginTop={8} marginBottom={8}>
          <Box display="flex" alignItems="center" gap={8} marginBottom={12}>
            {!rentalCompany?.logo ? (
              <Typography variant="body/md-strong" color="foregroundPrimary">
                {rentalCompany?.name}
              </Typography>
            ) : (
              <RentalCompanyImage src={rentalCompany?.logo} $size={60} width={60} />
            )}
          </Box>
          {!!displayLocation.pickUpType && (
            <Typography variant="body/sm" color="foregroundPrimary">
              {getAirportAccess(displayLocation.pickUpType)}
            </Typography>
          )}
          <Typography variant="body/sm" color="foregroundPrimary">
            {getFullQueryAddress(displayLocation)}
          </Typography>
          <Box display="flex" alignItems="center" marginTop={8}>
            <Typography
              variant="link/sm"
              style={{ cursor: "pointer" }}
              onClick={handleGetDirections}
            >
              Get directions
            </Typography>
          </Box>
        </Box>
        {!!displayLocation?.contactInfo?.phoneNumber && !!location?.hours && (
          <ContactInformation
            location={displayLocation}
            hours={location.hours}
            rentalCompany={rentalCompany}
            locationType={areLocationsTheSame ? "both" : (locationValue as LocationType)}
            handleGetDirections={handleGetDirections}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </Box>
    </CollapsibleContent>
  );
};

export default Location;
