import { Box, Icon, Typography, useTheme } from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

const IncidentalBanner = () => {
  const { tokens } = useTheme();

  return (
    <Box
      backgroundColor="backgroundPrimary"
      padding={16}
      marginTop={8}
      gap={8}
      style={{
        borderRadius: tokens.borderRadius[16],
        borderWidth: tokens.borderWidth.normal,
        borderColor: tokens.colors.accentBlue,
        borderStyle: "solid",
      }}
      data-testid="loyalty-banner"
    >
      <Box display="flex" flexDirection="row" gap={4}>
        <Box paddingTop={4} gap={8} style={{ flex: 0.08 }}>
          <Icon name="circle-info" color="accentBlue" size="md" />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} style={{ flex: 1 }}>
          <Typography variant="heading/sm">Incidental deposit</Typography>
          <Typography variant="body/sm">
            You have selected to have {COMPANY_NAME} provide an incidental deposit for this
            reservation. The guest should not need to provide a credit card at check-in. Any charges
            received on or after checkout will be passed through to your company&apos;s invoice when
            recognized.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default IncidentalBanner;
