import { useEffect } from "react";
import { Formik } from "formik";

import { useSearchParams } from "@hotel-engine/lib/react-router-dom";
import { useBreakpoint } from "@hotel-engine/hooks";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";
import { Chip, SegmentedControl, SegmentedControlItem, Typography } from "@hotelengine/atlas-web";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

import TripsCalendarViewLegacy from "../TripsCalendarViewLegacy";
import TripsListViewLegacy from "../TripsListViewLegacy";
import TripsMapViewLegacy from "../TripsMapViewLegacy";
import { TripsFiltersLegacy, TripsItineraryPreviewPanel } from "../components";
import { TripsSearchSchema } from "../components/TripsFiltersLegacy/SearchForm/schema";
import useSearchForTrips from "../components/TripsFiltersLegacy/SearchForm/hooks/useSearchForTrips";
import type { ITripsSearch } from "../components/TripsFiltersLegacy/SearchForm";
import { tripsSearchInitialValues } from "../components/TripsFiltersLegacy/SearchForm";
import { viewTypes, type ViewType } from "../constants";
import * as Styled from "./styles";
import { useTripsLegacyContext } from "../contextLegacy";
import type { ITripPreview } from "../contextLegacy";

export type ITripsContentProps = {
  handleView: (view: ViewType) => void;
  view: ViewType;
};

const TripsContent = ({ handleView, view }: ITripsContentProps) => {
  const [qs, setSearchParams] = useSearchParams();
  const { tokens } = useGlobalTheme();
  const user = useAppSelector((state) => state.Auth.user);
  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const { showPreview } = tripsState;
  const isMobileScreen = useBreakpoint("xxl", "max");
  const isSmallMobileScreen = useBreakpoint("md", "max");

  const canViewCalendarView = ["admin", "coordinator"].includes(user?.role ?? "");
  const tripIdentifier = qs.get("booking_number");

  useEffect(() => {
    // Mobile screen sizes should switch to list view if they are on another view
    if (isMobileScreen && view !== viewTypes.LARGE_LIST) {
      handleView(viewTypes.LARGE_LIST);
      tripsDispatch({
        type: "filter",
        filters: [{ key: "booking_type" }],
      });
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileScreen]);

  const { triggerSearch } = useSearchForTrips();

  const handleOnSearchChange = (values: ITripsSearch) => {
    triggerSearch(values.search);
  };

  const handleItemPreviewClose = (_record: ITripPreview) => {
    setSearchParams({});
  };

  const handleViewChange = (value: string) => {
    const currentView = value as ViewType;

    if (user) {
      const sendAmpliClickEventForViewType = {
        [viewTypes.LARGE_LIST]: () => ampli.clickListView({ userId: user.id }),
        [viewTypes.SMALL_LIST]: () => ampli.clickListView({ userId: user.id }),
        [viewTypes.MAP]: () => ampli.clickMapView({ userId: user.id }),
        [viewTypes.CALENDAR]: () => ampli.clickCalendarView({ userId: user.id }),
      }[currentView];

      sendAmpliClickEventForViewType();
    }

    if (currentView === viewTypes.CALENDAR || currentView === viewTypes.MAP) {
      tripsDispatch({
        type: "filter",
        filters: [{ key: "booking_type", value: "lodging" }],
      });
    } else {
      tripsDispatch({
        type: "filter",
        filters: [{ key: "booking_type" }],
      });
    }

    handleView(currentView);
  };

  if (!user) {
    return null;
  }

  const shouldShowPreview = !!tripIdentifier && !!showPreview && !isMobileScreen;

  const activeView = {
    list: view === viewTypes.LARGE_LIST || view === viewTypes.SMALL_LIST,
    map: view === viewTypes.MAP,
    calendar: view === viewTypes.CALENDAR,
  };

  const getSegmentChildren = () => {
    const children = [
      <SegmentedControlItem
        value={viewTypes.LARGE_LIST}
        key={viewTypes.LARGE_LIST}
        icon="list"
        id="listView"
      />,
      <SegmentedControlItem value={viewTypes.MAP} key={viewTypes.MAP} icon="map" id="mapView" />,
    ];

    if (!!canViewCalendarView && !isMobileScreen) {
      children.push(
        <SegmentedControlItem
          value={viewTypes.CALENDAR}
          key={viewTypes.CALENDAR}
          icon="calendar"
          id="calendarView"
        />
      );
    }

    return children;
  };

  return (
    <Formik
      onSubmit={handleOnSearchChange}
      initialValues={tripsSearchInitialValues}
      validationSchema={TripsSearchSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Styled.ViewWrapper>
        <Styled.Trips $mapView={activeView.map} $showPreview={shouldShowPreview}>
          <Styled.UpperContainer $isMobile={isSmallMobileScreen}>
            <Styled.Header>
              <Typography variant="heading/xl" color="foregroundPrimary">
                Trips
              </Typography>
              <SegmentedControl
                shape="rectangle"
                style={{
                  backgroundColor: tokens.colors.backgroundPrimary,
                  border: `${tokens.borderWidth.normal} solid ${tokens.colors.borderSubtle}`,
                }}
                defaultValue={viewTypes.LARGE_LIST}
                onValueChange={(value) => handleViewChange(value)}
                value={view}
              >
                {getSegmentChildren()}
              </SegmentedControl>
            </Styled.Header>
            <TripsFiltersLegacy
              isPreviewOpen={!!showPreview}
              isCalendarView={view === viewTypes.CALENDAR && canViewCalendarView}
              isMapView={view === viewTypes.MAP}
              user={user}
            />
            {!!(view === viewTypes.MAP && !isSmallMobileScreen) && (
              <Chip
                label="Showing hotels only in map view"
                size="md"
                color="everlight"
                variant="strong"
                leadingIcon="circle-exclamation"
                style={{
                  padding: `${tokens.spacing[20]} ${tokens.spacing[12]}`,
                  border: `${tokens.borderWidth.normal} solid ${tokens.colors.sentimentHelpfulSubtle}`,
                }}
              />
            )}
          </Styled.UpperContainer>
          <Styled.Inner>
            {/* Render List View */}
            {(view === viewTypes.LARGE_LIST || view === viewTypes.SMALL_LIST) && (
              <TripsListViewLegacy />
            )}
            {/* Render Map View */}
            {view === viewTypes.MAP && <TripsMapViewLegacy />}
            {/* Render Calendar View */}
            {view === viewTypes.CALENDAR && !!canViewCalendarView && (
              <TripsCalendarViewLegacy user={user} />
            )}
          </Styled.Inner>
        </Styled.Trips>
        <Styled.ListTripsPreviewPanel $showPreview={shouldShowPreview}>
          {!!showPreview?.id && (
            <TripsItineraryPreviewPanel
              tripId={showPreview.id}
              onClose={(record) => handleItemPreviewClose(record)}
              user={user}
              bookingType={showPreview.bookingType}
            />
          )}
        </Styled.ListTripsPreviewPanel>
      </Styled.ViewWrapper>
    </Formik>
  );
};

export default TripsContent;
