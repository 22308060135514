import { ampli } from "ampli";

import { Box, Button, Divider, Typography } from "@hotelengine/atlas-web";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { MultiAirlineLogo } from "@hotel-engine/assets/flights/MultiAirlineLogo";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import ModalWrapper from "../../../shared/ModficationViews/components/ModalWrapper";
import { ModificationTypes, useFlightModificationsContext } from "../../../ModificationsContext";
import { useAirlineLinks } from "../../hooks/useAirlineLinks";
import useIsFlightCancelled from "../../hooks/useIsFlightCancelled";
import { ManageFlightDescription } from "@hotel-engine/app/ItineraryContent/flights/components/ManageFlight/ManageFlightDescription";

const ManageFlightModal = ({ onCancel }) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const navigate = useNavigate();
  const {
    reservation,
    isPreview,
    dispatch: modificationsDispatch,
  } = useFlightModificationsContext();
  const { dispatch: tripsDispatch } = useTripsLegacyContext();
  const isTripsFilteringImprovementsOn = useIsFeatureFlagOn("trips_filtering_improvements");

  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();
  const { getAirlineLink } = useAirlineLinks();

  const { isPendingCancellation } = useIsFlightCancelled();
  const isFlightCancellable = !isPendingCancellation && !reservation.isFullyCancelled;
  const airlineCodes = reservation.airlineCodes;

  const clickCancelFlight = () => {
    ampli.clickNeedToCancel({
      userId: user?.id,
      flightBookingID: reservation?.bookingNumber,
    });

    modificationsDispatch({
      type: "SET_MODIFICATION_VIEW",
      payload: ModificationTypes.Cancel,
    });

    /** If we are within the TripsContext we need to set isActive to set up the management flow for clicking other
     * Trips while a modification is active.
     */
    if (isPreview && !isTripsFilteringImprovementsOn) {
      tripsDispatch({
        type: "setActiveModification",
        activeModification: {
          isActive: true,
          isNavigating: false,
        },
      });
    }

    document.getElementById("itinerary-scroll-container")?.scrollTo(0, 0);
    onCancel();
  };

  const airlineButtons = reservation.airlineCodes.map((code) => {
    const airlineName = getAirlineNameFromIataAirlineCode(code);
    const airlineLink = getAirlineLink("main", code);

    const goToAirlineWebsite = () => {
      openNewTab(airlineLink, "_blank", navigate);

      ampli.clickVisitAirlineWebsiteForMoreOptions({
        userId: user?.id,
        contractId: reservation?.bookingNumber,
        baseUrl: airlineLink,
      });
    };

    return (
      <Button
        variant="outlined"
        color="secondary"
        key={code}
        trailingIcon="arrow-up-right-from-square"
        style={{ width: "100%" }}
        onClick={goToAirlineWebsite}
      >
        Visit {airlineName}
      </Button>
    );
  });

  return (
    <ModalWrapper
      dataTestId="manage-flight-modal"
      closable={true}
      onCancel={onCancel}
      title="Manage trip"
    >
      <Styled.ButtonsContainer>
        <Box display="flex" flexDirection="row" gap={16} marginBottom={16}>
          <Styled.CompanyLogosContainer>
            <MultiAirlineLogo airlineCodes={airlineCodes.slice(0, 2)} size={isPreview ? 40 : 48} />
          </Styled.CompanyLogosContainer>
          <Box display="flex" flexDirection="column" gap={16}>
            <ManageFlightDescription airlineCodes={airlineCodes} />
          </Box>
        </Box>
        {airlineButtons}
        {!!isFlightCancellable && (
          <>
            <Divider variant="dotted" />
            <Button
              variant="filled"
              color="primary"
              style={{ width: "100%" }}
              onClick={clickCancelFlight}
            >
              <Typography variant="body/md-strong">Need to cancel?</Typography>
            </Button>
          </>
        )}
      </Styled.ButtonsContainer>
    </ModalWrapper>
  );
};

export default ManageFlightModal;
