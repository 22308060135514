import { Alert, Box, Typography } from "@hotelengine/atlas-web";
import type { IBusiness } from "@hotel-engine/types/business";
import { formatCurrency, formatDate } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import type { IRefundBreakdown } from "@hotel-engine/types/reservation";

interface IRefundDetailsItem {
  business: IBusiness | undefined;
  refundBreakdown: IRefundBreakdown;
}

const RefundDetailsItem = ({ business, refundBreakdown }: IRefundDetailsItem) => {
  const { typographyVariants, isPreview } = useTypographyScaling();

  const showFlexClawback =
    !!refundBreakdown.lastClawbackAt && !!(Number(refundBreakdown.clawedBackAmount || 0) > 0);

  return (
    <Styled.RefundDetailsItem>
      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund method
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {refundBreakdown.originalFormOfPayment ? "Original form of payment" : "Travel credit"}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Paid on
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatDate(
            refundBreakdown.createdAt,
            "MMM D YYYY",
            business?.preferredDateFormat || "mdy"
          )}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund amount
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatCurrency(refundBreakdown.totalRefund || "", true, business?.currencyCode)}
        </Typography>
      </Styled.FieldLine>

      {!!showFlexClawback && (
        <Box display="flex" flexDirection="column" gap={16}>
          <Styled.FieldLine>
            <Typography variant={typographyVariants.body} color="foregroundSecondary">
              Refund adjustment (
              {formatDate(
                refundBreakdown.lastClawbackAt,
                "MMM D YYYY",
                business?.preferredDateFormat || "mdy"
              )}
              )
            </Typography>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              -
              {formatCurrency(
                refundBreakdown.clawedBackAmount || "",
                true,
                refundBreakdown.clawedBackCurrency
              )}
            </Typography>
          </Styled.FieldLine>
          <Alert title="Flex refund adjustment" sentiment="critical" size={isPreview ? "sm" : "md"}>
            Your flex refund has been adjusted due to unused nights found to be ineligible.
          </Alert>
        </Box>
      )}
    </Styled.RefundDetailsItem>
  );
};

export default RefundDetailsItem;
