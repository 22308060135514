import { Box, Button, Typography } from "@hotelengine/atlas-web";

import { usePaginationControls } from "./usePaginationControls";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { Loader } from "@hotel-engine/components/Loader";

const TripsListPagination = () => {
  const { isNextDisabled, page } = usePaginationControls();
  const result = useTripsResult();
  const [params, tripsControl] = useTripsControl();

  const getPaginationInfo = () => {
    if (!result) return null;

    const offset = params.offset || 0;
    const totalItems = result.data?.trips?.length || 0;
    const displayStartItem = totalItems === 0 ? "" : offset + 1;
    const displayEndItem = totalItems === 0 ? "" : offset + totalItems;

    return (
      <Typography
        variant="body/md"
        color="foregroundPrimary"
        style={{ minWidth: 75, textAlign: "center" }}
      >
        {result.isLoading ? <Loader size="lg" /> : `${displayStartItem} - ${displayEndItem}`}
      </Typography>
    );
  };

  return result.error ? null : (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={16}>
      <Box display="flex" alignItems="center" gap={16}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={page < 1 || result.isLoading}
          onClick={() => {
            tripsControl.setParams({
              offset: (page - 1) * (result.data?.limit || 0),
            });
          }}
        >
          Back
        </Button>
        {getPaginationInfo()}
        <Button
          variant="outlined"
          color="secondary"
          disabled={isNextDisabled || result.isLoading}
          onClick={() => {
            tripsControl.setParams({
              offset: (page + 1) * (result.data?.limit || 0),
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TripsListPagination;
