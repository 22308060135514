import { useState } from "react";
import * as Styled from "./styles";
import ICalendarLink from "react-icalendar-link";
import {
  getCalendarEventLink,
  getIscCalendarEvent,
  isIOSChrome,
} from "@hotel-engine/utilities/helpers";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { useAppSelector } from "store/hooks";
import type { ICurrency } from "@hotel-engine/constants";
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  RadioGroup,
  RadioGroupItem,
  toast,
  Typography,
} from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

type CalendarPlatform = "google" | "outlook" | "ics";
export interface IAddToCalendarModalProps {
  reservation: IReservationBase;
  onClose: () => void;
}

export const AddToCalendarModal = ({ reservation, onClose }: IAddToCalendarModalProps) => {
  const [calendarPlatform, setCalendarPlatform] = useState<CalendarPlatform>("google");
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business.currencyCode || "USD";

  const onCloseModal = () => {
    onClose();
  };

  const options = {
    google: {
      label: "Google Calendar",
      platform: "google",
      button: (
        <CalendarLinkButton
          calendarLink={getCalendarEventLink(reservation, currencyCode, "google", COMPANY_NAME)}
        />
      ),
    },
    outlook: {
      label: "Outlook Calendar",
      platform: "outlook",
      button: (
        <CalendarLinkButton
          calendarLink={getCalendarEventLink(reservation, currencyCode, "outlook", COMPANY_NAME)}
        />
      ),
    },
    ics: {
      label: "Download .ics File",
      platform: "ics",
      button: <IcsDownloadButton reservation={reservation} currencyCode={currencyCode} />,
    },
  } as const;

  return (
    <Dialog isOpen onOpenChange={(open) => !open && onCloseModal()}>
      <DialogContent>
        <DialogTitle>Add to calendar</DialogTitle>
        <DialogClose data-testid="close-modal" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={12}
          marginBottom={24}
        >
          <Typography variant="body/md">
            Which calendar would you like to add this trip to?
          </Typography>
        </Box>
        <RadioGroup
          onValueChange={(e) => setCalendarPlatform(e as CalendarPlatform)}
          value={calendarPlatform}
          style={{ width: "100%" }}
          isBoxed
        >
          {Object.values(options).map((option) => (
            <RadioGroupItem key={option.platform} value={option.platform}>
              {option.label}
            </RadioGroupItem>
          ))}
        </RadioGroup>
        <Styled.Actions>{options[calendarPlatform].button}</Styled.Actions>
      </DialogContent>
    </Dialog>
  );
};

const CalendarLinkButton = ({
  calendarLink,
}: {
  calendarLink: string | null;
}) => {
  const handleSubmit = () => {
    // when there's an error creating the calendar link, the helper returns null instead of the link
    if (!calendarLink) {
      toast({
        title: "Whoops!",
        description: "There was an error creating calendar link.",
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  };

  if (!calendarLink) {
    return <Button onClick={handleSubmit}>Submit</Button>;
  }

  return (
    <Button asChild>
      <Typography variant="body/md" as="a" href={calendarLink} target="_blank" rel="noreferrer">
        Submit
      </Typography>
    </Button>
  );
};

const IcsDownloadButton = ({
  reservation,
  currencyCode,
}: {
  reservation: IReservationBase;
  currencyCode: ICurrency;
}) => {
  const calendarEvent = getIscCalendarEvent(reservation, currencyCode, COMPANY_NAME);

  const handleSubmit = () => {
    if (isIOSChrome()) {
      toast({
        title: "Whoops!",
        description: "This file type doesn't open in Google Chrome. Please use Safari instead.",
        icon: "circle-exclamation",
        sentiment: "cautionary",
      });
    }
  };

  return (
    <Button onClick={handleSubmit} asChild>
      {isIOSChrome() ? (
        <Typography variant="body/md" color="foregroundInverse">
          Submit
        </Typography>
      ) : (
        <ICalendarLink
          event={calendarEvent}
          filename={`hotelEngine-${reservation.propertyConfirmationNumber || reservation.id}.ics`}
        >
          <Typography variant="body/md">Submit</Typography>
        </ICalendarLink>
      )}
    </Button>
  );
};
