import { Icon } from "@hotelengine/atlas-web";

import * as Styles from "./styles";

export interface IStarsProps {
  count: number;
  handleClick: (val: number) => void;
  setHoverOn: (val: number) => number;
  hoverOn: number;
}

const renderStar = (isFilled: boolean, order: number) => {
  return isFilled ? (
    <Icon name="star--solid" color="accentBlue" data-testid={`solid-star-${order}`} />
  ) : (
    <Icon name="star" color="accentGrayLight" data-testid={`outline-star-${order}`} />
  );
};

const ratingValues = [1, 2, 3, 4, 5];

const Stars = ({ count, handleClick, setHoverOn, hoverOn }: IStarsProps) => (
  <Styles.Stars>
    {ratingValues.map((rating) => (
      <Styles.SingleStar
        data-testid={`icon-single-star-${rating}`}
        onClick={() => handleClick(rating)}
        role="button"
        type="button"
        key={`SingleStar${rating}`}
        onMouseEnter={() => setHoverOn(rating)}
        onMouseLeave={() => setHoverOn(-1)}
      >
        {/** fill in all stars up to the count or if there is anything hovering over */}
        {renderStar(rating - 1 < count || (hoverOn !== -1 && hoverOn >= rating), rating)}
      </Styles.SingleStar>
    ))}
  </Styles.Stars>
);

export default Stars;
