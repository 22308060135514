import type { Dispatch } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { queryClient } from "@hotel-engine/contexts";
import { useCarsSubmitCancelQuery } from "@hotel-engine/react-query/bookings/useSubmitCancelQuery";
import { endpoints } from "@hotel-engine/react-query/constants";
import { captureMessage } from "@hotel-engine/utilities";

import type { ICarItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/car";

import { toast } from "@hotelengine/atlas-web";
import type { IErrorResponse } from "../../../../../../../types/errors";
import type { CarRefundType } from "./usePreviewCarRefund";

interface IUseCancelCarParams {
  /** Current contract */
  reservation: ICarItineraryWithId;
  /** Car insurance policy ID (From CG) */
  policyId?: string;
  /** Dispatch from ModificationsContext */
  dispatch: Dispatch;
  /** shows success modal if multi-room cancellation */
  showStatusModal: () => void;
  /** Total refund amount and type updated as rooms are selected for cancellation */
  refundInfo: {
    totalRefundAmount: number | null;
    refundType: CarRefundType | undefined;
    penaltyFee?: string;
    showRefundInfo?: boolean;
  };
  setErrorCancelling: (
    errorCancelling: null | "insurance" | "insuranceOnly" | "bookingOnly"
  ) => void;
}

export const useCancelCar = ({
  dispatch,
  showStatusModal,
  refundInfo,
  setErrorCancelling,
  reservation,
  policyId,
}: IUseCancelCarParams) => {
  const {
    state: { modificationView },
  } = useCarModificationsContext();
  const setLoading = () => {
    showStatusModal();
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: true, isError: false },
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: "SET_MODIFICATION_INFO",
      payload: {
        refundAmount: Number(refundInfo.totalRefundAmount),
        refundType: refundInfo.refundType,
        modificationType: modificationView,
        penaltyFee: Number(refundInfo?.penaltyFee),
        bookingType: "car",
        showRefundInfo: refundInfo?.showRefundInfo,
      },
    });
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isSubmitted: true },
    });
  };

  const handleError = (error?: IErrorResponse) => {
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isError: true },
    });
    if (error?.status === 422) {
      setErrorCancelling("bookingOnly");
    } else {
      dispatch({ type: "SET_SHOW_STATUS_MODAL", payload: false });
      toast({
        title: "Error cancelling car",
        description: "An error occurred while trying to cancel this car. Try again later.",
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }

    if (error) {
      captureMessage("Error cancelling car", {
        contractId: reservation.tripId,
        error,
      });
    }
  };

  const cancelCar = useCarsSubmitCancelQuery({ id: reservation.bookingNumber, bookingType: "car" });

  const submitCancelCar = () => {
    setLoading();

    try {
      cancelCar.mutate(
        {
          bookingNumber: reservation.bookingNumber,
          bookingType: "car",
        },
        {
          onSuccess: async () => {
            handleSuccess();
            await queryClient.invalidateQueries(endpoints.contracts);
            await queryClient.invalidateQueries(endpoints.trips);
            await queryClient.invalidateQueries(endpoints.reservations);

            if (policyId) {
              await queryClient.invalidateQueries([endpoints.carInsurancePolicies, policyId]);
            }

            return;
          },
          onError: async (error) => {
            return handleError(error);
          },
        }
      );
    } catch (error) {
      return handleError(error as IErrorResponse);
    }
  };

  return { submitCancelCar };
};
