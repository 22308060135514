import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used.
 *
 * The `List` component wraps antd's `<List />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal List component does not have any of it's own
 * props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be
 * in alignment with their type definitions and prevents type errors that may arise from maintaining an override
 * interface.
 *
 * @see {@link https://3x.ant.design/components/list/ AntD List Documentation}
 */
export const List = Styled.List;

export const ListItem = Styled.ListItem;
