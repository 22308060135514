import { Alert, Box, Card, CardContent, Typography } from "@hotelengine/atlas-web";
import type { IFlightRefund } from "@hotel-engine/types/itinerary";
import SummaryOfChargesItem from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { useFlightRefundDetails } from "@hotel-engine/app/ItineraryContent/flights/components/AdditionalInfo/components/RefundBreakdowns/useFlightRefundDetails";

export interface IRefundBreakdownsProps {
  refunds: IFlightRefund[];
  bookingFee?: string;
}

export const RefundBreakdowns = ({ refunds, bookingFee }: IRefundBreakdownsProps) => {
  const { breakdowns, alert } = useFlightRefundDetails({ refunds, bookingFee });
  return (
    <Card padding={24}>
      <CardContent style={{ padding: 0 }}>
        <Box marginBottom={20}>
          <Typography data-testid="cancel-policy" variant="heading/lg" color="foregroundPrimary">
            Refund details
          </Typography>
        </Box>
        {breakdowns.map((group, i) => (
          <Box key={`refund-group-${group.key}`} data-testid={`refund-group-${i}`}>
            {group.items.map((item) => (
              <Box
                key={`refund-${item.label}-${item.value}`}
                data-testid={`refund-${item.label}-${item.value}`}
                display="flex"
                flexDirection="column"
              >
                <SummaryOfChargesItem
                  {...item}
                  style={{ marginTop: 4, marginBottom: 4 }}
                  labelColor="foregroundPrimary"
                  valueColor="foregroundPrimary"
                />
              </Box>
            ))}
          </Box>
        ))}

        <Box paddingTop={24}>
          <Alert sentiment="helpful" title="" data-testid="refund-details-alert">
            {alert}
          </Alert>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RefundBreakdowns;
