import styled, { css } from "styled-components";

import { Menu as HEMenu, MenuItem } from "@hotel-engine/common/Menu";
import { Radio } from "@hotel-engine/common/Radio";
import { buttonReset, colors, ellipsisText } from "@hotel-engine/styles";
import Tag from "@hotel-engine/common/Tag";

const imageMargin = "16px";

export const PaymentItemRadio = styled(Radio)`
  display: flex;
  align-items: center;
  width: 100%;
  span.ant-radio + * {
    width: calc(100% - ${imageMargin});
  }

  border-top: 1px dashed ${colors.grey[100]};

  &:last-child {
    border-bottom: 1px dashed ${colors.grey[100]};
  }
`;

export const PaymentItemContainer = styled.div<{ $disabled: boolean }>`
  align-items: center;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xs};
  cursor: pointer;
  display: flex;
  padding: 24px 0;
  justify-content: space-between;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const ActionNeeded = styled.button`
  ${buttonReset}
  color: ${colors.red[400]};
  display: flex;
  align-items: center;
  font-size: 12px;

  span {
    margin-left: 5px;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  ${ellipsisText}
`;

export const PaymentImage = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: ${imageMargin};
  width: 36px;

  img {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  margin: 0 10px;
  margin-left: 16px;
  text-transform: capitalize;
  flex-direction: column;
  ${ellipsisText}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    > div {
      display: flex;
    }
  }
`;

export const Name = styled.label`
  font-weight: 500;
  font-size: 16px;
  ${ellipsisText}
`;

export const Summary = styled.span`
  font-size: 12px;
`;

export const ActionMenu = styled(HEMenu)`
  width: 145px;
`;

export const ActionItem = styled(MenuItem)<{ $isDeleteLink?: boolean }>`
  align-items: center;
  display: flex;
  font-size: 16px;
  padding: 7px 12px;

  span {
    font-size: 14px;
    margin-left: 12px;
  }

  ${({ $isDeleteLink }) =>
    $isDeleteLink &&
    css`
      color: ${colors.red[400]};
    `}

  &:hover {
    background-color: ${colors.grey[50]};
  }
`;

export const DropdownTrigger = styled.button`
  align-items: center;
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  background-color: ${colors.white};
  border: thin solid ${colors.grey[200]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  color: ${colors.grey[800]};

  &:hover {
    border: thin solid ${colors.retroBlue};
    cursor: pointer;
  }
`;

export const CardTag = styled(Tag)`
  cursor: auto;
  margin-left: 8px;
  border-radius: 100px /* disable theming (do not replicate) */;
`;
