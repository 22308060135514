import type { IBooking } from "@hotel-engine/types/trips";
import * as Styled from "../../styles";
import { Box, Icon } from "@hotelengine/atlas-web";
import type { ICarLocation } from "@hotel-engine/types/cars/cars.shared";
import WrapInStrong from "../../../components/WrapInStrong";

const TripLocationCell = ({
  data,
  searchValue,
}: {
  data: IBooking;
  searchValue: string;
}) => {
  const bookingType = data.bookingType;
  const details = data.details;

  if (bookingType === "flight") {
    if (!details?.slices) {
      return <Styled.PrimaryText>No flight details available</Styled.PrimaryText>;
    }

    const isOneWay = details.flightTravelType === "one_way" || details.slices.length === 1;
    const isMultiCity = details.flightTravelType === "multi_city";

    const slicesToUse = isMultiCity ? details.slices : details.slices.slice(0, 1);

    const codesBySlice = slicesToUse.map((slice) => {
      const originCode = slice.segments[0].origin.airportCode;
      const destinationCode = slice.segments[slice.segments.length - 1].destination.airportCode;

      return { originCode, destinationCode };
    });

    return (
      <>
        {codesBySlice.map((slice, i) => (
          <Box
            display="inline-flex"
            flexDirection="row"
            alignItems="center"
            gap={4}
            marginRight={4}
            key={slice.originCode}
          >
            <Styled.PrimaryText>{slice.originCode}</Styled.PrimaryText>
            <Icon
              name={isOneWay || isMultiCity ? "arrow-right" : "arrow-right-arrow-left"}
              size="sm"
            />
            <Styled.PrimaryText>
              {slice.destinationCode}
              {i < codesBySlice.length - 1 && <Styled.PrimaryText>, </Styled.PrimaryText>}
            </Styled.PrimaryText>
          </Box>
        ))}
      </>
    );
  }

  if (bookingType === "car") {
    if (!details?.location) {
      return <Styled.PrimaryText>No car details available</Styled.PrimaryText>;
    }

    const getCarLocation = (location: ICarLocation) => {
      return `${location.city}, ${location.state}`;
    };

    const location = details.location;
    const sameLocation = location.pickUp.id === location.dropOff.id;
    const sameState = location.pickUp.state === location.dropOff.state;

    return (
      <Styled.TripDetailWrapper style={{ width: 182 }}>
        {!sameLocation && !!sameState && (
          <>
            <Styled.PrimaryText>{location.pickUp.name}</Styled.PrimaryText>
            <Icon name="arrow-right" size="sm" />
            <Styled.PrimaryText>{getCarLocation(location.dropOff)}</Styled.PrimaryText>
          </>
        )}
        {!sameLocation && !sameState && (
          <Box display="flex" flexDirection="column" gap={8}>
            <Box display="flex" flexDirection="column">
              <Styled.SubText>Pick-up</Styled.SubText>
              <Styled.PrimaryText>{getCarLocation(location.pickUp)}</Styled.PrimaryText>
            </Box>
            <Box display="flex" flexDirection="column">
              <Styled.SubText>Drop-off</Styled.SubText>
              <Styled.PrimaryText>{getCarLocation(location.dropOff)}</Styled.PrimaryText>
            </Box>
          </Box>
        )}
        {!!sameLocation && !!sameState && (
          <Styled.PrimaryText>{getCarLocation(location.pickUp)}</Styled.PrimaryText>
        )}
      </Styled.TripDetailWrapper>
    );
  }

  if (bookingType === "lodging") {
    if (!details?.property) {
      return <Styled.PrimaryText>No location details available</Styled.PrimaryText>;
    }

    const locationCopy = [
      details?.property.city,
      details?.property.state,
      details?.property.country,
    ]
      .filter(Boolean)
      .join(", ");

    return (
      <Styled.TripDetailWrapper>
        <Styled.PrimaryText>
          <WrapInStrong term={searchValue} target={locationCopy} />
        </Styled.PrimaryText>
      </Styled.TripDetailWrapper>
    );
  }
  return null;
};

export default TripLocationCell;
