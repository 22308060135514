import { Box, Button, Typography } from "@hotelengine/atlas-web";
import type { IModifications } from "@hotel-engine/types/reservation";
import { routes, supportCenterRouteSources } from "@hotel-engine/constants";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { CollapsibleContent } from "../../../../../shared/CollapsibleContent";
import { useModificationsLocalStorage } from "./useModificationsLocalStorage";

export interface IModificationStatusesProps {
  modifications: IModifications;
  propertyName: string;
}

const ModificationStatuses = ({ modifications, propertyName }: IModificationStatusesProps) => {
  const navigate = useNavigate();
  const modificationTypes = Object.keys(modifications.all);
  const modificationCount = modificationTypes.reduce((acc, curr) => {
    return (acc += modifications.all[curr]);
  }, 0);

  const { showList, setShowList } = useModificationsLocalStorage(modificationCount);

  const getTypeCopy = (type: string) => {
    const typeCount = modifications.all[type];
    return `${typeCount} ${type}${typeCount > 1 ? "s" : ""}`;
  };

  const goToSupportCenter = () => {
    navigate(
      `${routes.supportCenter}?source=${supportCenterRouteSources.modificationStatuses}&property_query=${propertyName}`
    );
  };

  return (
    <CollapsibleContent
      toggleTestID="toggle-modification-requests"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Modification requests</Typography>
        </Box>
      }
      defaultOpen={!!showList}
      onChangeState={() => setShowList(!showList)}
    >
      <Box paddingLeft={24} paddingRight={24} paddingBottom={24}>
        <Box marginBottom={24} display="flex" flexDirection="column" gap={8}>
          {modificationTypes.map((type) => (
            <Typography variant="body/md" color="foregroundSecondary" key={type}>
              {getTypeCopy(type)}
            </Typography>
          ))}
        </Box>
        <Button variant="outlined" size="lg" style={{ width: "100%" }} onClick={goToSupportCenter}>
          View in Support Center
        </Button>
      </Box>
    </CollapsibleContent>
  );
};

export default ModificationStatuses;
