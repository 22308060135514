import { type ReactNode } from "react";

import { AmplitudeContextProvider } from "@hotel-engine/contexts/Amplitude";
import config from "config";

import {
  useDatadog,
  useInitGoogleAnalytics,
  useInitSalesforceChat,
  useInitSentry,
  useScript,
  useInitMIAWSalesforceChat,
  useInitSift,
} from "../hooks";
import { usePendo } from "../hooks/usePendo";
import LegacyScriptLoader from "../LegacyScriptLoader";
import Logrocket from "logrocket";
import * as Sentry from "@sentry/react";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useSendLogrocketToAmplitude } from "./useSendLogrocketToAmplitude";
import { useLogrocketInit } from "../hooks/useLogrocketInit";

const linkPendoAndLogRocket = (sessionURL: string, retryCount = 0) => {
  if (retryCount > 4) {
    return;
  }

  if (pendo) {
    pendo.track("Session Replay Created", {
      sessionURL,
    });
  } else {
    setTimeout(() => linkPendoAndLogRocket(sessionURL, retryCount + 1), 100);
  }
};

/**
 * This includes all of the hooks that handle third party scripts.
 */
function Plugins() {
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  // fonts
  useScript("https://kit.fontawesome.com/8fbb5c99da.js");
  // spreedly
  useScript("https://core.spreedly.com/iframe/iframe-v1.min.js?hecachebuster=12345");
  useInitSentry();

  useDatadog();
  useInitGoogleAnalytics(true);
  usePendo();
  useLogrocketInit();
  useSendLogrocketToAmplitude();
  useInitSift();
  /**
   * Load any scripts that do not require initialization
   */

  // for now we will feature flag old vs new chat experience on means new experience off means old
  // old experience will need to be removed along with feature flag and related logic once full rollout happens
  useInitSalesforceChat(MIAWRollout.isFetched && !sfMIAWFeatureFlagEnabled);
  useInitMIAWSalesforceChat(sfMIAWFeatureFlagEnabled);

  Logrocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope()?.setTag("sessionURL", sessionURL);
    linkPendoAndLogRocket(sessionURL);
  });

  return null;
}

export default function ThirdPartyScripts({
  children,
}: {
  children?: ReactNode;
}) {
  const ScriptLoader = config.useLegacyScriptLoader ? LegacyScriptLoader : Plugins;

  return (
    <AmplitudeContextProvider>
      <ScriptLoader />
      {children}
    </AmplitudeContextProvider>
  );
}
