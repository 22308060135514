import { FormControl, TextInput, Typography } from "@hotelengine/atlas-web";
import { Form, useFormikContext } from "formik";
import type { IChangePasswordFormValues } from "../../types";
import * as Styled from "./styles";

const ChangePasswordForm = () => {
  const { errors, setFieldValue, validateField } = useFormikContext<IChangePasswordFormValues>();

  return (
    <Form>
      <Styled.Container>
        <FormControl label="Current password">
          <TextInput
            name="currentPassword"
            type="password"
            placeholder="Enter your current password"
            data-testid="current-password-field"
            isInvalid={!!errors.currentPassword}
            onChange={(e) => setFieldValue("currentPassword", e.target.value)}
            onBlur={() => validateField("currentPassword")}
          />
          <Typography variant="body/sm" color="uiDestructive">
            {errors.currentPassword}
          </Typography>
        </FormControl>
        <FormControl label="New password">
          <TextInput
            name="newPassword"
            type="password"
            placeholder="Choose a strong, unique password"
            data-testid="new-password-field"
            isInvalid={!!errors.newPassword}
            onChange={(e) => setFieldValue("newPassword", e.target.value)}
            onBlur={() => validateField("newPassword")}
          />
          <Typography variant="body/sm" color="uiDestructive">
            {errors.newPassword}
          </Typography>
        </FormControl>
        <FormControl label="Confirm New Password">
          <TextInput
            name="passwordConfirmation"
            type="password"
            placeholder="Match your new password"
            data-testid="confirm-password-field"
            isInvalid={!!errors.passwordConfirmation}
            onChange={(e) => setFieldValue("passwordConfirmation", e.target.value)}
            onBlur={() => validateField("passwordConfirmation")}
          />
          <Typography variant="body/sm" color="uiDestructive">
            {errors.passwordConfirmation}
          </Typography>
        </FormControl>
        {Object.keys(errors)
          .filter((i) => i === "general")
          .map((e, i) => {
            return (
              <Typography key={i} color="accentRed" variant="body/sm">
                {errors[e]}
              </Typography>
            );
          })}
      </Styled.Container>
    </Form>
  );
};

export default ChangePasswordForm;
