import styled, { css } from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { ButtonProps } from "@hotelengine/atlas-web";
import { Alert, Box, Divider, Typography, Button } from "@hotelengine/atlas-web";

export const Row = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "center",
  })};
  margin-bottom: 12px;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      margin-bottom: 0;
    }
  `}
`;

export const SupportAlertBanner = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  flex-grow: 0;
`;

export const FieldDivider = styled(Divider)`
  color: ${({ theme }) => theme.colors.accentGray};
  margin: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[0]}`};
`;

export const ItineraryButton = styled<React.FC<ButtonProps>>(Button)`
  margin-top: ${({ theme }) => theme.spacing[16]};
  width: 100%;
`;

export const StrikethroughText = styled(Typography).attrs({})`
  text-decoration: line-through;
  margin-right: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.foregroundDisabled};
`;

export const DetailsWrapper = styled(Box).attrs({})`
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;
