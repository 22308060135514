import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import { Avatar, Typography } from "@hotelengine/atlas-web";
import { SelectOption } from "@hotel-engine/common/Select";
import { colors } from "@hotel-engine/styles";
import type { IOccupant, IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";
import type { IUser } from "@hotel-engine/types/user";
import { getNameOrEmail } from "@hotel-engine/utilities";

import * as Styled from "../styles";
import { Icon } from "@hotel-engine/common/Icon";

export const buildUserOption = ({
  propertyLoyaltyRewards,
  roomIsLoyaltyEligible,
  suggestedOccupant,
  user,
}: {
  propertyLoyaltyRewards: IPropertyLoyaltyRewards;
  roomIsLoyaltyEligible: boolean;
  suggestedOccupant: IOccupant | IUser;
  user: IUser;
}) => {
  const { id, firstName, lastName, email, userLoyaltyRewards } = suggestedOccupant;

  const matchingLoyaltyReward =
    roomIsLoyaltyEligible &&
    userLoyaltyRewards?.find((reward) => reward.loyaltyRewardId === propertyLoyaltyRewards.id);

  const renderUserOption = () => (
    <Styled.GuestInfo adjacentElementSize="small">
      <Styled.GuestInfoRow>
        <Typography
          variant="heading/sm"
          as={Styled.OptionTypography}
          margin={0}
          marginRight={4}
          marginLeft={8}
          data-testid="guest-select-option-name"
        >
          {firstName} {lastName}
          {user?.id === id && " (you)"}
        </Typography>
        {!!matchingLoyaltyReward && (
          <span data-testid="member-dropdown-loyalty-badge">
            <LoyaltyBadge
              fill={colors.black}
              style={{
                margin: "0 4px",
                verticalAlign: "middle",
                width: "12px",
                height: "12px",
              }}
            />
          </span>
        )}
      </Styled.GuestInfoRow>
      <Styled.GuestInfoRow $spaceBetween>
        <Typography
          variant="body/xs"
          as={Styled.OptionTypography}
          margin={0}
          marginRight={4}
          marginLeft={8}
          color={firstName && lastName ? "accentGray" : "borderDisabled"}
          data-private
        >
          {email}
        </Typography>
        {(!firstName || !lastName) && (
          <Typography
            variant="body/xs"
            as={Styled.OptionTypography}
            margin={0}
            marginRight={4}
            marginLeft={8}
            color="accentGray"
            data-private
          >
            {<Icon style={{ marginRight: "6px" }} icon={["fal", "exclamation-triangle"]} />}
            First and last name are required for all guests
          </Typography>
        )}
      </Styled.GuestInfoRow>
    </Styled.GuestInfo>
  );

  return (
    <SelectOption
      key={`user-${id}`}
      value={JSON.stringify(suggestedOccupant)}
      data-testid="guest-select-option"
      disabled={!firstName || !lastName}
    >
      <Styled.SearchGuestOption>
        <Avatar size={32} name={getNameOrEmail(suggestedOccupant) ?? ""} />
        {renderUserOption()}
      </Styled.SearchGuestOption>
    </SelectOption>
  );
};
