import { useFormikContext } from "formik";
import { useAppSelector } from "store/hooks";
import { useParams } from "@hotel-engine/lib/react-router-dom";
import { usePropertyQuery } from "@hotel-engine/react-query/property/usePropertyQuery";
import type { ICheckoutFormData, IncidentalVisibilityLegacy } from "pages/Checkout/validation";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";
import type { IUser } from "@hotel-engine/types/user";

const computeIncidentalsStatus = (
  user: IUser | null,
  property?: IPropertyLegacy,
  values?: IExpressBookCheckoutForm | ICheckoutFormData
) => {
  if (user?.incidentalsConfiguration === "blocked") {
    return "hidden";
  } else if (
    //If cc incidentals flag is on and incidentalsBillingAllowed is true determine based on property
    user?.business?.incidentalsBillingAllowed
  ) {
    return property?.allowIncidentals ? "visible" : "disabled";
    //if allowIncidentals is true and selectedPaymentId is 0 (direct bill) determine based on property
    //Remove the entire check below with `cc_incidentals` IN FEN-2802
  } else if (user?.business.allowIncidentals && values?.selectedPaymentId === 0) {
    return property?.allowIncidentals ? "visible" : "disabled";
    //default to "hidden"
  } else {
    return "hidden";
  }
};

// Determines the visibility of the incidental charges feature based on business and property configuration //
export const useIncidentalVisibilityLegacy = (): IncidentalVisibilityLegacy => {
  const { values } = useFormikContext<ICheckoutFormData>();
  const user = useAppSelector((store) => store.Auth.user);
  const { propertyId } = useParams<{ propertyId: string }>();
  const { data: property } = usePropertyQuery(Number(propertyId));

  return computeIncidentalsStatus(user, property, values);
};

export const useIncidentalVisibilityExpressBook = (
  property: IPropertyLegacy,
  expressBookFormValues: IExpressBookCheckoutForm
): IncidentalVisibilityLegacy => {
  const user = useAppSelector((store) => store.Auth.user);

  return computeIncidentalsStatus(user, property, expressBookFormValues);
};
