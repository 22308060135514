import { useEffect } from "react";

import { RadioGroup, RadioGroupItem, toast, Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { ToastErrorMessage } from "@hotel-engine/app/ToastErrorMessage";

import { useCancelBooking } from "../../hooks/useCancelBooking";
import { useCancelRefundCalculator } from "../../hooks/useCancelRefundCalculator";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import CancelInfoBox from "../common/CancelInfoBox";
import CancellationButton from "../common/CancellationButton";
import CancellationTitle from "../common/CancellationTitle";
import RoomSelection from "../common/RoomSelection";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useMultiCancelRoomsTracker } from "./useMultiCancelRoomsTracker";
import { useQueryClient } from "react-query";
import { endpoints } from "@hotel-engine/react-query/constants";

const MultiRoomCancellation = () => {
  const queryClient = useQueryClient();
  const {
    state: { showConfirmationModal, modificationStatus },
    reservation,
    dispatch,
  } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  const {
    formValues,
    numberOfRoomsToCancel,
    radioStatuses,
    setNumberOfRoomsCancelled,
    setRoomsToCancel,
    showCancelAllRooms,
    showValidationMessage,
    setShowValidationMessage,
    switchToChangeAllRooms,
    switchToChangeCertainRooms,
  } = useMultiCancelRoomsTracker();

  const {
    cancelRefundAmount,
    cancelRefundType,
    isError: isRefundCalcError,
    isLoading: isRefundCalcLoading,
  } = useCancelRefundCalculator({
    reservation,
    cancelRooms: formValues.cancelRooms,
    modificationStatus,
  });

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelMultiRoomBooking } = useCancelBooking({
    reservation,
    cancelRoomsValue: formValues.cancelRooms,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
    },
    dispatch,
  });

  useEffect(() => {
    if (isRefundCalcError) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [isRefundCalcError]);

  const handleCancelMultiRoom = async () => {
    /** This needs to be set to local state here because the form will reset once
     *  the reservation refetches, but we need to save this value for the
     * confirmation modal
     */
    setNumberOfRoomsCancelled(numberOfRoomsToCancel);
    await submitCancelMultiRoomBooking();
    await queryClient.invalidateQueries(endpoints.trips, { exact: false });
  };

  const getCancelButtonText = () => {
    if (!radioStatuses.checkCertainRooms) {
      return "Cancel all rooms";
    } else {
      const usePlural = numberOfRoomsToCancel === 0 || numberOfRoomsToCancel > 1;
      return `Cancel ${numberOfRoomsToCancel > 0 ? numberOfRoomsToCancel : "selected"} room${
        usePlural ? "s" : ""
      }`;
    }
  };

  return (
    <>
      <CancellationTitle />
      <RadioGroup style={{ marginBottom: 24 }}>
        <RadioGroupItem
          value="cancel-all-rooms"
          checked={radioStatuses.checkAllRooms}
          onClick={switchToChangeAllRooms}
          isDisabled={!showCancelAllRooms}
        >
          Cancel all rooms
        </RadioGroupItem>
        <RadioGroupItem
          value="cancel-certain-rooms"
          data-testid="cancel-certain-rooms"
          checked={radioStatuses.checkCertainRooms}
          onClick={switchToChangeCertainRooms}
        >
          Select certain rooms to cancel
        </RadioGroupItem>
      </RadioGroup>
      {!!radioStatuses.checkCertainRooms && (
        <RoomSelection setRoomsToCancel={setRoomsToCancel} rooms={formValues.cancelRooms} />
      )}
      <CancelInfoBox
        cancelRefundAmount={cancelRefundAmount}
        cancelRefundType={cancelRefundType}
        cancelRooms={formValues.cancelRooms}
        isLoading={isRefundCalcLoading}
      />
      {!!showValidationMessage && (
        <Typography
          variant={typographyVariants.body}
          color="foregroundDestructive"
          as="p"
          style={{ marginTop: 24 }}
        >
          Please select a room
        </Typography>
      )}
      <CancellationButton
        text={getCancelButtonText()}
        isRefundCalcLoading={isRefundCalcLoading}
        setShowValidationMessage={
          radioStatuses.checkCertainRooms && numberOfRoomsToCancel === 0
            ? setShowValidationMessage
            : undefined
        }
      />
      {!!showConfirmationModal && (
        <CancelConfirmationModal
          onConfirm={handleCancelMultiRoom}
          cancelRoomCount={numberOfRoomsToCancel}
        />
      )}
    </>
  );
};

export default MultiRoomCancellation;
