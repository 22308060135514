import { MAX_GUESTS_SHOW } from "@hotel-engine/constants/trips";
import type { ITraveler } from "@hotel-engine/types/trips";
import { Tooltip, Typography } from "@hotelengine/atlas-web";

import { generateGuestList, generateGuestNames } from "./helpers/generateGuestList";
import * as Styled from "./styles";
import WrapInStrong from "../../../components/WrapInStrong";

interface ITravelerProps {
  travelers: ITraveler[];
  searchValue?: string;
}

const Travelers = ({ travelers, searchValue = "" }: ITravelerProps) => {
  if (!travelers?.length) return null;

  const allTravelers = travelers.flat();
  const guestCount = allTravelers.length;
  const guestList = generateGuestList(allTravelers);
  const guestNames = generateGuestNames(allTravelers, MAX_GUESTS_SHOW);
  const otherGuestsCount = guestCount - MAX_GUESTS_SHOW;

  return (
    <Tooltip content={guestList} align="start">
      <div>
        <div>
          <Typography variant="body/sm" color="foregroundPrimary">
            <WrapInStrong term={searchValue} target={guestNames} />
          </Typography>
        </div>
        {otherGuestsCount > 0 && (
          <Styled.GuestsLabel>+{otherGuestsCount} others</Styled.GuestsLabel>
        )}
      </div>
    </Tooltip>
  );
};

export default Travelers;
