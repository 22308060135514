import config from "config";
import { useAppSelector } from "store/hooks";

import SupportChatLink from "@hotel-engine/app/SupportChatLink";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { Box, BrandLogo, Typography, useTheme } from "@hotelengine/atlas-web";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { COMPANY_NAME, COMPANY_BRANDMARK } from "@hotel-engine/constants/companyNames";

import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import type { Item } from "../../../shared/CollapsibleList";
import { CollapsibleList } from "../../../shared/CollapsibleList";
import * as Styled from "./styles";
import { useFlexReservationShorten } from "@hotel-engine/hooks/useFlexReservationShorten";

export interface IAdditionalInfoProps {
  supportPhone: string | null;
}

const AdditionalInfo = ({ supportPhone }: IAdditionalInfoProps) => {
  const { reservation } = useModificationsContext();
  const { tokens } = useTheme();

  const currencyCode = useAppSelector((state) => state.Auth.user?.business.currencyCode) || "USD";
  const {
    flexCutoffTimes: {
      copy: { flexOriginalPaymentCopy, flexTravelCreditCopy },
    },
  } = useFlexReservationShorten(reservation);
  const supportNumber = supportPhone || config.supportNumber(currencyCode);
  const showFlexPolicy = reservation.flexEnabled;
  const policyTitle = showFlexPolicy ? "Flex policy" : "Cancellation policy";
  const user = useAppSelector((state) => state.Auth.user);
  const refundToOriginalPayment = user?.business.refundToOriginalFormOfPayment;
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const chatLinkReplacementFeatureOn = useIsFeatureFlagOn("chat-link-replacement-members");

  const policyContent = showFlexPolicy
    ? refundToOriginalPayment
      ? flexOriginalPaymentCopy
      : flexTravelCreditCopy
    : reservation.cancellationPolicy;

  const isViewOnlyTraveler = user?.role === "view_only_traveler";

  const showPolicy = !!(reservation.cancellationPolicy || showFlexPolicy) && !isViewOnlyTraveler;

  const onCallSupport = () => {
    globalThis.open(`tel:${supportNumber.replace(/\D/g, "")}`);
  };

  const needHelpContent = (
    <Styled.NeedHelpContainer>
      <Box style={{ minWidth: 36 }}>
        <BrandLogo
          name={COMPANY_BRANDMARK}
          size={36}
          style={{
            width: 36,
            borderRadius: tokens.borderRadius.xs,
          }}
        />
      </Box>
      <Box>
        {!!chatLinkReplacementFeatureOn && !sfMIAWFeatureFlagEnabled ? (
          <Styled.InfoSection>
            <Typography variant="heading/md" color="foregroundPrimary">
              Get support
            </Typography>
            <Typography variant="body/md" color="foregroundPrimary">
              Reach out to Member Support via {<SupportChatLink text="live chat" />}, available
              24/7.
            </Typography>
          </Styled.InfoSection>
        ) : (
          <Styled.InfoSection>
            <Typography variant="body/md" color="foregroundPrimary">
              Contact {COMPANY_NAME} support to manage or change your booking at
              <Typography
                variant="link/md"
                color="foregroundPrimary"
                onClick={onCallSupport}
                data-testid="support-number"
                style={{ cursor: "pointer" }}
              >
                {" "}
                {supportNumber}.
              </Typography>
            </Typography>
            <Typography variant="body/md" color="foregroundPrimary">
              For faster support, mention #{reservation.id}.
            </Typography>
          </Styled.InfoSection>
        )}
      </Box>
    </Styled.NeedHelpContainer>
  );

  const list: Item[] = [];

  if (showPolicy) {
    list.push({
      title: policyTitle,
      content: policyContent || "",
    });
  }

  if (reservation?.hotelFeeDescription) {
    list.push({
      title: "Hotel terms",
      content: (
        <Styled.HotelFeeDescriptionContainer
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(reservation.hotelFeeDescription),
          }}
        />
      ),
    });
  }

  const content = <CollapsibleList list={list} />;

  return (
    <>
      <CollapsibleContent
        toggleTestID="toggle-need-help"
        toggleBtnLabel={
          <Box display="flex" gap={12} alignItems="center">
            <Typography variant="heading/lg">Need help?</Typography>
          </Box>
        }
      >
        {needHelpContent}
      </CollapsibleContent>
      {!!(list.length > 0) && (
        <CollapsibleContent
          toggleTestID="toggle-things-to-know"
          toggleBtnLabel={
            <Box display="flex" gap={12} alignItems="center">
              <Typography variant="heading/lg">Things to know</Typography>
            </Box>
          }
        >
          {content}
        </CollapsibleContent>
      )}
    </>
  );
};

export default AdditionalInfo;
