import { Box, Typography } from "@hotelengine/atlas-web";
import { useFormatDate } from "@hotel-engine/hooks";
import moment from "moment";

export interface IBookingTimeProps {
  time: string;
  type: "pickup" | "dropoff";
}

const BookingTime = ({ time, type }: IBookingTimeProps) => {
  const momentDate = useFormatDate(moment.parseZone(time), "ddd, MMM D, YYYY");
  const momentTime = moment(time).parseZone().format("h:mm a");

  return (
    <Box>
      <Typography variant="uppercase/2xs" color="foregroundSecondary">
        {type === "pickup" ? "Pick-up" : "Drop-off"}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body/md-strong" color="foregroundPrimary">
          {momentDate}
        </Typography>
        <Typography variant="body/md" color="foregroundPrimary">
          {momentTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default BookingTime;
