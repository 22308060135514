import { type ReactElement, useMemo, useCallback } from "react";
import { Link } from "@hotel-engine/lib/react-router-dom";

import { useSupportCaseUpdatesCountQuery } from "@hotel-engine/react-query/supportCenter/useSupportCaseUpdatesCountQuery";
import { PARTNER_OFFERS, SUPPORT_CENTER } from "@hotel-engine/constants";
import {
  TopNavMenuTrigger,
  TopNavMenuContent,
  TopNavCardList,
  TopNavCard,
  TopNavBadge,
} from "@hotelengine/atlas-web";
import type { IconName } from "@hotelengine/atlas-core";
import type { ILinks } from "@hotel-engine/app/Header/constants";

import { StyledTopNavMenu } from "./styles";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

export type SubMenuCard = {
  id: string;
  title: string;
  icon: IconName;
  href?: string;
  onClick?: () => void;
  content?: string | ReactElement;
  showOnMobile?: boolean;
};

export type SubMenuCards = SubMenuCard[];

interface ITopNavMenu {
  triggerLabel: string;
  cards: SubMenuCards;
  activePathId: ILinks["id"] | null;
  isMobile: boolean;
  // per design some use cases (such as a "More" dropdown) contain unrelated submenu items and should not be highlighted
  // for default behavior leave undefined
  triggerIsActiveDisabled?: true;
}

export const TopNavMenu = ({
  triggerLabel,
  cards,
  activePathId,
  isMobile,
  triggerIsActiveDisabled,
}: ITopNavMenu) => {
  const { data, refetch } = useSupportCaseUpdatesCountQuery();
  const isPartnerOffersAllowed = useIsFeatureFlagOn("builtfirst-partner-offers-marketplace");
  const supportCaseUpdatesCount = data?.supportCaseUpdatesCount ?? 0;

  const badgeMap = useMemo(
    () => ({
      [SUPPORT_CENTER]: supportCaseUpdatesCount,
    }),
    [supportCaseUpdatesCount]
  );

  const triggerCanBeActive = useMemo(() => {
    if (typeof triggerIsActiveDisabled === "boolean") {
      return !triggerIsActiveDisabled;
    }
    return triggerIsActiveDisabled;
  }, [triggerIsActiveDisabled]);

  const triggerBadge = useMemo(
    () => Object.values(badgeMap).some((count) => count > 0),
    [badgeMap]
  );

  const handleSupportCaseClick = useCallback(() => {
    const cb = setTimeout(async () => {
      void refetch();
    }, 3000);

    return () => {
      clearTimeout(cb);
    };
  }, [refetch]);

  const onClickMap = useMemo(
    () => ({
      [SUPPORT_CENTER]: handleSupportCaseClick,
    }),
    [handleSupportCaseClick]
  );

  const subMenuItems = useMemo(() => {
    return cards
      .filter((card) => !isMobile || (isMobile && card.showOnMobile))
      .filter((card) => card.id !== PARTNER_OFFERS || isPartnerOffersAllowed);
  }, [cards, isMobile, isPartnerOffersAllowed]);

  if (!subMenuItems.length) return null;

  return (
    <StyledTopNavMenu>
      <TopNavMenuTrigger
        label={triggerLabel}
        isActive={triggerCanBeActive}
        badge={triggerBadge ? <TopNavBadge color="red" /> : undefined}
      />
      <TopNavMenuContent>
        <TopNavCardList>
          {subMenuItems.map(({ title, icon, href, content, id }) => (
            <TopNavCard
              data-testid={`top-nav-card-${id}`}
              badge={
                badgeMap[id] ? <TopNavBadge color="red" text={String(badgeMap[id])} /> : undefined
              }
              isActive={activePathId?.includes(id)}
              as={Link}
              to={href ?? "#"}
              key={title}
              title={title}
              icon={icon}
              onClick={onClickMap[id]}
            >
              {content}
            </TopNavCard>
          ))}
        </TopNavCardList>
      </TopNavMenuContent>
    </StyledTopNavMenu>
  );
};
