import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { Alert, Box, Divider, Typography } from "@hotelengine/atlas-web";
import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import { useFlightSummaryOfCharges } from "./useFlightSummaryOfCharges";
import * as Styled from "./styles";

const SummaryOfCharges = () => {
  const { isPreview, reservation } = useFlightModificationsContext();
  const { chargeDescriptionList, chargeAmountList, totalCharges } = useFlightSummaryOfCharges(
    reservation?.charges
  );

  if (!reservation?.charges) {
    return null;
  }

  return (
    <Styled.SummaryOfChargesPaddedContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Payment Info
      </Typography>

      {chargeDescriptionList.map((item) => {
        if (item.label === "Travel credit comp description" && !item?.hideItem) {
          return (
            <div key={`basic-${item.label}`}>
              <Box marginTop={4} marginBottom={4}>
                <Alert
                  sentiment="helpful"
                  variant="outlined"
                  size="sm"
                  leadingIcon={null}
                  title="A travel credit comp has been issued for this booking."
                >
                  {item.value}
                </Alert>
              </Box>
            </div>
          );
        }

        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem
                labelColor="foregroundPrimary"
                valueColor="foregroundPrimary"
                {...item}
              />
            </Box>
          </div>
        );
      })}

      <Box marginTop={16} marginBottom={16}>
        <Divider variant="dotted" />
      </Box>

      {chargeAmountList.map((item) => {
        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem
                labelColor="foregroundPrimary"
                valueColor="foregroundPrimary"
                {...item}
              />
            </Box>
          </div>
        );
      })}

      <Box marginTop={16} marginBottom={16}>
        <Divider />
      </Box>
      <SummaryOfChargesItem
        {...totalCharges}
        labelColor="foregroundPrimary"
        typography="body/md-strong"
        isBold
      />
    </Styled.SummaryOfChargesPaddedContainer>
  );
};

export default SummaryOfCharges;
