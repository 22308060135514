import styled from "styled-components";

import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

const MarketingBannerContainer = styled.div`
  ${mediaQuery(
    "xl",
    "max"
  )(`
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    padding: 16px;
    margin-top: 24px;
  `)}
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: ${({ theme }) => theme.spacing[20]};
  margin-top: ${({ theme }) => theme.spacing[20]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
`;

export { MarketingBannerContainer };
