import { useEffect } from "react";

import { useBlocker, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

/** This can be used within the ModificationsContext to intercept route changes while in an active modification */
export const useNavigationBlocker = () => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: { modificationView, navigationRoute },
  } = useBaseModificationsContext();

  const isTripsFilteringImprovementsOn = useIsFeatureFlagOn("trips_filtering_improvements");

  /**
   * This is here to intercept any route changes that happen while
   * an active modification flow is open and show the NavigationModal
   */
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isDifferentRoute = currentLocation.pathname !== nextLocation.pathname;
    const isDifferentBooking = currentLocation.search !== nextLocation.search;
    const shouldBlockNavigationWFFOn =
      !!modificationView && (isDifferentRoute || isDifferentBooking);
    const shouldBlockNavigationWFFOff = !!modificationView && isDifferentRoute;

    const shouldBlockNavigation = isTripsFilteringImprovementsOn
      ? shouldBlockNavigationWFFOn
      : shouldBlockNavigationWFFOff;

    if (shouldBlockNavigation) {
      dispatch({
        type: "SET_SHOW_NAVIGATION_MODAL",
        payload: true,
      });
      dispatch({
        type: "SET_NAVIGATION_ROUTE",
        payload: `${nextLocation.pathname}${
          isTripsFilteringImprovementsOn ? nextLocation.search : ""
        }`,
      });
    }

    // Return true to block navigation
    return shouldBlockNavigation;
  });

  /** If the user was mid-modification and clicked a new route, then confirmed they want to abandon the modification
   * this useEffect will then navigate them to the route they clicked after the NavigationModal is closed
   */
  useEffect(() => {
    if (!modificationView && navigationRoute) {
      if (blocker && blocker.proceed) {
        blocker.proceed();
      }

      dispatch({
        type: "SET_NAVIGATION_ROUTE",
        payload: undefined,
      });

      /** This can be removed along with the actions around navigationRoute once the trips_filtering_improvements flag is removed.
       * It's currently refreshing the trips request when navigating to a deferred trip selection, once this is removed it no longer will.
       */
      navigate(navigationRoute);
    }
  }, [modificationView, navigationRoute, navigate, dispatch, blocker]);
};
