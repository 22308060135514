import styled from "styled-components";

import { Modal as BaseModal } from "@hotel-engine/common/Modal";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const Container = styled.div`
  ${flexbox({
    alignItems: "center",
    direction: "column",
    justifyContent: "center",
  })}
  color: ${colors.white};

  .svg-inline--fa {
    font-size: 48px;
  }
`;

export const Modal = styled(BaseModal)`
  .ant-modal-content {
    background-color: ${colors.black};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
`;

export const Message = styled.p`
  ${({ theme }) => theme.typography.desktop["heading/lg"]};
  text-align: center;
  margin: 32px 0 8px;
  color: ${colors.white};
`;

export const Subtext = styled.p`
  ${({ theme }) => theme.typography.desktop["body/sm"]};
  text-align: center;
  color: ${colors.white};
  margin-bottom: 0;
`;
