import type { WrapperNIconPropsHelperArgs } from "./types";

export function getWrapperNIconProps({
  type,
  tipPosition,
  isCustomSpinnerSize,
  customSpinnerSize,
}: WrapperNIconPropsHelperArgs) {
  const wrapperProps: Record<string, string | number | Record<string, string> | undefined> = {};
  let iconStyles = {};

  if (type === "centered" || type === "full-page" || tipPosition === "column") {
    wrapperProps.display = "flex";
    wrapperProps.justifyContent = "center";
    wrapperProps.alignItems = "center";
  }

  if (type === "full-page") {
    wrapperProps.style = { width: "100%", height: "80vh" };
  }

  if (isCustomSpinnerSize && customSpinnerSize !== undefined) {
    iconStyles = {
      height: `${customSpinnerSize.height}px`,
      width: `${customSpinnerSize.width}px`,
    };
  }

  if (tipPosition === "column") {
    wrapperProps.flexDirection = "column";
  }

  return { wrapperProps, iconStyles };
}
