import { useCallback, useEffect } from "react";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useCustomFieldsQuery } from "@hotel-engine/react-query/customFields/useCustomFieldsQuery";
import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import ItineraryContent from "@hotel-engine/app/ItineraryContent";
import { Unsafe } from "@hotel-engine/data";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { useRevalidator } from "@hotel-engine/lib/react-router-dom";

import { useAppSelector } from "store/hooks";

import * as Styled from "../styles";

export interface IReservationBaseWithId extends IReservationBase {
  tripId: number;
}

export interface ITripsItineraryPreviewPanelProps {
  itineraryId: string | number;
  onClose: () => void;
}

const LodgingItineraryPanel = ({ onClose, itineraryId }: ITripsItineraryPreviewPanelProps) => {
  const user = useAppSelector((state) => state.Auth.user);

  const { revalidate } = useRevalidator();

  const {
    data,
    isLoading: isResLoading,
    refetch: refetchItinerary,
  } = useReservationQuery({
    id: String(itineraryId),
  });

  const tripItinerary = {
    tripId: itineraryId,
    ...data,
    flexEnabled: !!data?.flexEnabled,
  } as IReservationBaseWithId;

  const { isLoading: customFieldsLoading } = useCustomFieldsQuery({
    departmentId: user?.department.id,
    role: user?.role,
  });

  const { state: postCheckoutActionsState } = usePostCheckoutActions();
  const { completedPostCheckoutActions } = postCheckoutActionsState;

  useEffect(() => {
    if (!!completedPostCheckoutActions?.length) {
      refetchItinerary().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [completedPostCheckoutActions?.length, refetchItinerary]);

  const handleScroll = useCallback(() => {
    const panel = document.getElementById("preview-panel");

    if (!!panel) {
      panel.style.height = `calc(100vh - 90px + ${`${globalThis.scrollY}px`})`;
    }
  }, []);

  useEffect(() => {
    globalThis.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Styled.PreviewPanelContainer>
      <Styled.PreviewPanel id="preview-panel" data-testid="preview-panel">
        {!!tripItinerary && (
          <Styled.ScrollContainer
            // This id is needed for post-modification scroll to top
            id="itinerary-scroll-container"
          >
            <ItineraryContent
              isLoading={customFieldsLoading || isResLoading}
              itinerary={tripItinerary}
              isPreview={true}
              onClose={onClose}
              refetchReservation={revalidate}
              bookingType="lodging"
            />
          </Styled.ScrollContainer>
        )}
      </Styled.PreviewPanel>
    </Styled.PreviewPanelContainer>
  );
};

export default LodgingItineraryPanel;
