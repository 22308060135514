import { useCallback, useEffect, useState } from "react";

import type {
  IAuthStrategyLookupResponse,
  IAuthStrategies,
} from "@hotel-engine/types/authStrategy";

import * as CommonStyled from "../styles";
import BusinessSelect from "./BusinessSelect";
import SSOButton from "./SSOButton";
import * as Styled from "./styles";
import type { AuthorizationParams } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";

import { Loader } from "@hotel-engine/components/Loader";
import { Button, Typography } from "@hotelengine/atlas-web";
import type { IUser } from "@hotel-engine/types/user";

interface ISignInWithEmailPlusSSOProps {
  /** Available auth strategies for this account */
  authStrategies: IAuthStrategies;
  lookupEmail: string | null | undefined;
  user: IUser | null;
}

const OtherOption = () => (
  <Styled.OtherOptionBox>
    <Styled.Doted />
    <Styled.OtherOptionText variant="body/md">OR</Styled.OtherOptionText>
    <Styled.Doted />
  </Styled.OtherOptionBox>
);

export const SignInWithEmailPlusSSO = ({
  authStrategies,
  lookupEmail,
  user,
}: ISignInWithEmailPlusSSOProps) => {
  const { email, accountType } = { ...user };
  const isPersonalAccount = accountType === "personal";
  const isSAML = (strategy): boolean => strategy.protocol === "SAML";
  const [selectedAuthStrategy, setSelectedAuthStrategy] = useState<
    IAuthStrategyLookupResponse | undefined
  >(undefined);

  const { loginWithRedirect } = useAuth0();
  const redirectUserToAuth0 = useCallback(async () => {
    const returnTo = encodeURIComponent(globalThis.location.href);
    const redirectPath = `${globalThis.location.origin}/?return=${returnTo}`;
    const authorizationParams: AuthorizationParams = {
      login_hint: lookupEmail || "",
      redirect_uri: redirectPath,
      prompt: "login",
    };
    if (authStrategies.singularAuth0Strategy?.auth0Connection) {
      // if there is only one auth strategy and it has an auth0 connection, use it
      authorizationParams.connection = authStrategies.singularAuth0Strategy.auth0Connection;
    }
    await loginWithRedirect({
      authorizationParams,
    });
  }, [authStrategies, loginWithRedirect, lookupEmail]);

  /**
   * This effect is because the authStrategies may be undefined
   * waiting for the query to resolve
   */
  useEffect(() => {
    const handleResolved = async () => {
      if (authStrategies.showSsoOrPassword) {
        setSelectedAuthStrategy(authStrategies.singularStrategy);
      } else if (!authStrategies.hasMany) {
        await redirectUserToAuth0();
      }

      // Accounts with Auth0 enabled *and* multiple SSO strategies will
      // show those options in a dropdown and an option to login via Auth0
    };

    if (authStrategies.isFetched) {
      void handleResolved();
    }
  }, [
    authStrategies.isFetched,
    authStrategies.singularAuth0Strategy,
    authStrategies.singularStrategy,
    authStrategies.hasMany,
    authStrategies.showSsoOrPassword,
    redirectUserToAuth0,
  ]);
  const handleSignInWithPassword = () => redirectUserToAuth0();

  const handleBusinessSelect = (strategyIndex: string): void => {
    setSelectedAuthStrategy(authStrategies.get(Number(strategyIndex)));
  };

  const multipleMatchingBusiness = authStrategies.hasMany && isPersonalAccount;
  const showOtherOption = (selectedAuthStrategy || multipleMatchingBusiness) && isPersonalAccount;
  const ssoRequired =
    authStrategies.all.some((strategy) => strategy.isRequired && isSAML(strategy)) &&
    isPersonalAccount;
  const formContainerClassName = showOtherOption ? "" : " fixed-submit-btn";

  if (!authStrategies.hasMany && !authStrategies.showSsoOrPassword) {
    return (
      <Styled.LoadingContainer>
        <Typography variant="body/md-strong">Loading...</Typography>
        <Loader size="lg" />
      </Styled.LoadingContainer>
    );
  }

  return (
    <>
      {!!ssoRequired && (
        <div className="form-title">
          <CommonStyled.FormTitle>Sign in with SSO</CommonStyled.FormTitle>
        </div>
      )}
      <div className={`form-container${formContainerClassName}`}>
        {multipleMatchingBusiness ? (
          <BusinessSelect
            authStrategies={authStrategies}
            handleBusinessSelect={handleBusinessSelect}
          />
        ) : null}
        {showOtherOption ? (
          <SSOButton selectedAuthStrategy={selectedAuthStrategy} email={email} />
        ) : null}
        {showOtherOption && !ssoRequired ? <OtherOption /> : null}
        {!ssoRequired ? (
          <Button
            variant="outlined"
            style={{ width: "100%" }}
            id="login-submit"
            onClick={() => handleSignInWithPassword()}
            size="lg"
          >
            Sign in with password
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default SignInWithEmailPlusSSO;
