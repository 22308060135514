import styled, { createGlobalStyle } from "styled-components";

import { colors } from "@hotel-engine/styles";

export const GlobalBottomSheetStyles = createGlobalStyle`
  :root {
    --rsbs-overlay-rounded: 14px;
  }
`;

export const BottomSheetContent = styled.div`
  padding: 24px;
  margin-top: 4px;
`;

export const TermsText = styled.p`
  ${({ theme }) => theme.typography.desktop["heading/xs"]};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[400]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
`;

export const SmallLink = styled.a`
  ${({ theme }) => theme.typography.desktop["body/xs"]};
  color: ${colors.retroBlue};
`;
