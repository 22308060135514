import type { IFilters } from "pages/Trips/contextLegacy";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import { useAppSelector } from "store/hooks";
import { ampli } from "ampli";

const keys = {
  pendingModificationOnly: true,
  contractType: "multi_room",
  refundableOnly: true,
  manual_bookings: true,
  unverified: true,
};

function useSelectionHandler() {
  const { user } = useAppSelector((state) => state.Auth);
  const {
    state: { filters },
    dispatch,
  } = useTripsLegacyContext();

  const handleSelection = ({ key }: { key: keyof IFilters }, value?: unknown | unknown[]) => {
    if (user) {
      ampli.clickCheckboxFilterInTrips({
        filterName: key,
        selectedFilter: !filters[key],
        userId: user.id,
      });
    }

    if (Array.isArray(value)) {
      dispatch({
        type: "arrayFilter",
        key: key,
        values: value,
      });
    } else {
      dispatch({
        type: "filter",
        filters: [{ key: key, value: keys[key] }],
      });
    }
  };

  return handleSelection;
}

export default useSelectionHandler;
