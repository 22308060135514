export interface IInvalidParam {
  key: string;
  value: unknown;
  reasons: string[];
}

export interface IFieldError {
  name: string;
  reasons: string[];
}

export interface INexusError {
  title: string;
  invalidParams?: IInvalidParam[];
  fieldErrors?: IFieldError[];
  code: number;
}

/**
 * Custom error class for handling trip-related errors.
 *
 * @implements {INexusError}
 */
export default class TripsError extends Error implements INexusError {
  public title: string;
  public invalidParams: IInvalidParam[];
  public fieldErrors: IFieldError[];
  public code: number;

  constructor(error: INexusError) {
    super(error.title);

    this.name = "TripsError";
    this.title = error.title;
    this.code = error.code;
    this.invalidParams = error.invalidParams || [];
    this.fieldErrors = error.fieldErrors || [];

    Object.setPrototypeOf(this, TripsError.prototype);
  }

  public getAllReasons(): string[] {
    const invalidParams = this.invalidParams.flatMap((param) => param.reasons);
    const fieldErrors = this.fieldErrors.flatMap((field) => field.reasons);

    return [...invalidParams, ...fieldErrors];
  }

  public getReasonsByParam(key: string): string[] {
    const invalidParams = this.invalidParams.find((param) => param.key === key);

    return invalidParams ? invalidParams.reasons : [];
  }

  public getReasonsByField(fieldName: string): string[] {
    const fieldError = this.fieldErrors.find((field) => field.name === fieldName);

    if (!fieldError) return [];

    return fieldError.reasons;
  }
}
