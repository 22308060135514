import type { BookingType } from "@hotel-engine/types/itinerary";
import { useSearchParams } from "@hotel-engine/lib/react-router-dom";

import useHideCars from "pages/Trips/hooks/useHideCars";
import useHideFlights from "pages/Trips/hooks/useHideFlights";

import LodgingItineraryPanel from "./lodging";
import FlightItineraryPanel from "./flights";
import CarItineraryPanel from "./car";
import { PREVIEW_BOOKING_NUMBER, PREVIEW_BOOKING_TYPE } from "./constants";
import { useBreakpoint } from "../../hooks";

const ItineraryPreviewPanel = () => {
  const isMobileScreen = useBreakpoint("xxl", "max");

  const [qs, setSearchParams] = useSearchParams();
  const bookingNumber = qs.get(PREVIEW_BOOKING_NUMBER);
  const bookingType = qs.get(PREVIEW_BOOKING_TYPE) as BookingType;

  const onClose = () => {
    qs.delete(PREVIEW_BOOKING_NUMBER);
    qs.delete(PREVIEW_BOOKING_TYPE);
    setSearchParams(qs);
  };

  const shouldHideCars = useHideCars();
  const shouldHideFlights = useHideFlights();

  if (!bookingNumber || !bookingType || isMobileScreen) {
    return null;
  }

  if (bookingType === "flight") {
    if (shouldHideFlights) return null;

    return <FlightItineraryPanel itineraryId={bookingNumber} onClose={onClose} />;
  }

  if (bookingType === "car") {
    if (shouldHideCars) return null;

    return <CarItineraryPanel itineraryId={bookingNumber} onClose={onClose} />;
  }

  return <LodgingItineraryPanel itineraryId={bookingNumber} onClose={onClose} />;
};

export default ItineraryPreviewPanel;
