import { truncate } from "lodash";
import { Link } from "@hotel-engine/lib/react-router-dom";

import { Box, BrandLogo, Typography } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";

import { useUser } from "@hotel-engine/hooks";
import * as Styled from "./styles";
import { User } from "@hotel-engine/types/user";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import FocusableLink from "@hotel-engine/app/FocusableLink/FocusableLink";

const height = 32;

export function Logo() {
  const user = useUser();
  const maxChars = globalThis.innerWidth < 940 ? 14 : 30;

  return (
    <FocusableLink as={Link} $hasUnderlineOnHover={false} to={routes.dashboard} style={{ height }}>
      {User.isPersonalTravel(user) ? (
        <BrandLogo
          name="engine--lockup"
          size={height}
          role="img"
          aria-label={`${COMPANY_NAME}-logo`}
        />
      ) : typeof user.business.logoUrl === "string" ? (
        <Styled.Logo src={user.business.logoUrl} alt={user.business.name} size={height} />
      ) : (
        <Box display="flex" alignItems="center" gap={8}>
          <BrandLogo name="engine" size={height} role="img" aria-label={`${COMPANY_NAME}-logo`} />
          <Typography variant="body/sm" color="foregroundPrimary" style={{ whiteSpace: "nowrap" }}>
            {truncate(user.business.name, { length: maxChars })}
          </Typography>
        </Box>
      )}
    </FocusableLink>
  );
}
