import { useEffect, useRef } from "react";

import { ampli } from "ampli";
import { isEmpty } from "ramda";
import { useAppSelector } from "store/hooks";

import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { useParams } from "@hotel-engine/lib/react-router-dom";

import preventPathTraversal from "../../helpers/preventPathTraversal";
import type { ITripsSearch } from "..";

function useSearchForTrips() {
  const user = useAppSelector((state) => state.Auth.user);
  const { status } = useParams();
  const [params, tripsControl] = useTripsControl();
  const result = useTripsResult();
  // This will start as true so that we cannot trigger on initial page load
  const zeroResultsTriggered = useRef(true);

  useEffect(() => {
    if (
      !!user &&
      result.data?.trips.length === 0 &&
      !result.isLoading &&
      !zeroResultsTriggered.current
    ) {
      ampli.searchTripsZeroResultsReturned({
        userId: user.id,
        business_id: user.business.id,
        role: user.role,
        platform: "web",
        typedText: params.search,
        viewUrl: globalThis?.location.href,
        tripsTab: status as string,
        searchResponse: JSON.stringify(result),
      });

      zeroResultsTriggered.current = true;
    }
  }, [params.search, result, status, user]);

  const getUpdatedQuery = (typedText: string) => {
    const sanitizedQuery = preventPathTraversal(typedText);
    if (isEmpty(sanitizedQuery)) return "";

    return sanitizedQuery;
  };

  const triggerSearch = (values: ITripsSearch) => {
    const trimmedInput = values.search.trim();

    tripsControl.setParams({ offset: 0, search: trimmedInput, search_facet: values.search_facet });

    zeroResultsTriggered.current = false;

    if (user) {
      ampli.editSearchByNameOrLocationInTrips({
        typedText: trimmedInput,
        userId: user.id,
      });
    }

    if (values.search_facet) {
      ampli.bookingTableSearchedWithFilter({
        filterType: values.search_facet,
      });
    }
  };

  return { triggerSearch, getUpdatedQuery };
}

export default useSearchForTrips;
