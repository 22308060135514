import { RequestsTab } from "@hotel-engine/types/specialBookingRequest";
import { TrendsTabs } from "@hotel-engine/types/trends";

// Top level routes
export const ACCOUNT_UNLOCK = "account-unlock";
export const ACTIVITY = "activity";
export const AUTOPAY_CANCEL = "autopay-cancel";
export const AUTOPAY_FAQ = "autopay-overview";
export const AUTOPAY_SETUP = "autopay-setup";
export const BALANCE = "balance";
export const BILLING = "billing";
export const BILLING_ISSUE = "billing-issue";
export const BOOK = "book";
export const BOOKERS = TrendsTabs.BOOKERS;
export const CARS = "cars";
export const CHANGE_PASSWORD = "change-password";
export const CHECKOUT_VERIFICATIONS = "checkout-verifications";
export const COMPANY_SETTINGS = "company-settings";
export const CREDIT_LINE_INCREASE = "credit-line-increase";
export const CREDIT_CARD_TRANSACTIONS = "credit-card-transactions";
export const DASHBOARD = "dashboard";
export const DEPARTMENTS = TrendsTabs.DEPARTMENTS;
export const DIRECT_BILL_FAQ = "direct-bill-overview";
export const EXTEND_TRIPS = "extend-trips";
export const FAVORITE_HOTELS = "favorite-hotels";
export const FAVORITE_PLACES = "favorite-places";
export const FLEX_FAQ = "flex-overview";
export const FLEXPASS_FAQ = "flexpro-overview";
export const FLIGHTS = "flights";
export const GROUPS = "groups";
export const INCIDENTALS_FAQ = "incidentals-overview";
export const ITINERARY = "itinerary";
export const LOCKED_OUT = "locked-out";
export const LOGGED_OUT = "loggedout";
export const LOGIN = "login";
export const MEMBERS = "members";
export const MODIFY_RESERVATION = "modify-reservation";
export const MY_PROPERTIES = "my-properties";
export const OPTIONS_REVIEW = "options-review";
export const ORDERS = "orders";
export const PAC_LOGIN = "pac-login";
export const PAYMENT = "payment";
export const PAYMENT_DETAILS = "payment-details";
export const PAYMENT_HISTORY = "payment-history";
export const PAYMENT_METHODS = "payment-methods"; // also a settings sub
export const PROPERTIES = "properties";
export const PROPERTY_REVIEWS = "property-reviews";
export const REQUEST_ADMIN_ACCESS = "request-admin-access";
export const RESULTS = "results";
export const REWARDS = "rewards";
export const REWARDS_ENROLLMENT = "rewards-enrollment";
export const REWARDS_FAQ = "rewards-faq";
export const REWARDS_TOC = "rewards-toc";
export const SEARCH = "search";
export const SETTINGS = "settings";
export const SHARE_OPTIONS = "share-options";
export const SPECIAL_BOOKING_REQUEST = "special-booking-request";
export const SUPPORT_CENTER = "support-center";
export const PARTNER_OFFERS = "partner-offers";
export const TOP_LOCATIONS = TrendsTabs.TOP_LOCATIONS;
export const TOP_PROPERTIES = TrendsTabs.TOP_PROPERTIES;
export const TRAVEL_CREDITS = "travel-credits";
export const TRAVELERS = TrendsTabs.TRAVELERS;
export const TRENDS = "trends";
export const TRENDS_ALL = TrendsTabs.ALL;
export const TRENDS_TRAVEL_POLICIES = TrendsTabs.TRAVEL_POLICIES;
export const TRIPS = "trips";
export const USER_NOTIFICATIONS = "settings/notifications";

//GROUPS sub-routes
export const SBR_CONFIRMED = RequestsTab.CONFIRMED;
export const SBR_DASHBOARD = "dashboard";
export const SBR_DRAFTS = RequestsTab.DRAFTS;
export const SBR_OPEN = RequestsTab.OPEN;

// trips sub-routes
export const TODAY = "today";
export const ACTIVE = "active";
export const ALL = "all"; // also used for /members
export const CANCELLED = "cancelled";
export const PAST = "past";
export const UPCOMING = "upcoming";

// Members sub-routes
export const DETAILS = "details";

// company settings sub-routes
export const FLEX = "flex";
export const FLEXPASS = "flexpass";
export const USER_PERMISSIONS = "user-permissions";
export const ROLES_AND_PERMISSIONS = "roles-and-permissions";
export const CONTROLS = "controls";
export const FLEXPRO = "flexpro";
export const LOGO = "logo";
export const NOTIFICATIONS = "notifications";
export const TRAVEL_POLICIES = "travel-policies";
export const TRAVEL_POLICIES_LANDING = "travel-policies-info";
export const BILLING_AND_PAYMENTS = "billing-and-payments";
export const INTEGRATIONS = "integrations";
export const SSO_VERIFICATION = "verified";
export const PREFERENCES = "preferences";
export const COMPANY_BRANDING = "company-branding";
export const SAVED_LOCATIONS = "saved-locations";
export const CUSTOM_FIELDS = "custom-fields";
export const SYSTEM_EMAILS = "system-emails";
export const NOTIFICATION_SETTINGS = "notification-settings";

// settings sub-routes
export const GUEST_INFORMATION = "guest-information";
export const LOYALTY = "loyalty";
export const PROFILE = "profile";

// refer friends
export const INVITE_FRIENDS = "invite-friends";
export const REFER_FRIENDS = "refer-a-friend";
export const REFER_FRIENDS_PROGRAM_DETAILS = "program-details";

// join
export const ACCEPT = "accept";
export const BUSINESS = "business";
export const CONFIRM = "confirm";
export const REGISTERING = "registering";
export const JOIN = "join";
export const PARTNERS = "partners";
export const FINISH = "finish";

// sign up
export const SIGN_UP = "sign_up";

// personal travel
export const PERSONAL_ACCOUNT_CONFIRM = "personal-account-confirm";
export const PERSONAL_TRAVEL_2FV = "personal-travel-2fv";
export const PERSONAL_TRAVEL_LANDING_PAGE = "personal-travel";
export const PERSONAL_TRAVEL_UNCONFIRMED = "personal-travel-unconfirmed";

// Trends
export const FINANCIALS = "financials";

/** Represents all of the valid url routes for the members web-app */
export const routes = {
  accountUnlock: `/${ACCOUNT_UNLOCK}`,
  autopayCancel: `/${AUTOPAY_CANCEL}`,
  autopayFAQ: `/${AUTOPAY_FAQ}`,
  autopaySetup: `/${AUTOPAY_SETUP}`,
  balance: `/${BALANCE}`,
  billing: `/${BILLING}`,
  billingIssue: `/${BILLING_ISSUE}`,
  cars: `/${CARS}`,
  changePassword: `/${CHANGE_PASSWORD}`,
  checkoutVerifications: `/${CHECKOUT_VERIFICATIONS}`,
  confirm: `/${CONFIRM}`,
  companySettings: {
    base: `/${COMPANY_SETTINGS}`,
    flex: `/${COMPANY_SETTINGS}/${FLEX}`,
    flexPass: `/${COMPANY_SETTINGS}/${FLEXPASS}`,
    userPermissions: `/${COMPANY_SETTINGS}/${USER_PERMISSIONS}`,
    rolesAndPermissions: `/${COMPANY_SETTINGS}/${ROLES_AND_PERMISSIONS}`,
    customFields: `/${COMPANY_SETTINGS}/${CUSTOM_FIELDS}`,
    flexPro: `/${COMPANY_SETTINGS}/${FLEXPRO}`,
    integrations: {
      base: `/${COMPANY_SETTINGS}/${INTEGRATIONS}`,
      ssoVerification: `/${COMPANY_SETTINGS}/${INTEGRATIONS}/${SSO_VERIFICATION}`,
    },
    savedLocations: `/${COMPANY_SETTINGS}/${SAVED_LOCATIONS}`,
    travelPolicies: `/${COMPANY_SETTINGS}/${TRAVEL_POLICIES}`,
    travelPolicyDetails: `/${COMPANY_SETTINGS}/${TRAVEL_POLICIES}/${DETAILS}`,
    travelPoliciesLanding: `/${TRAVEL_POLICIES_LANDING}`,
    billingAndPayments: `/${COMPANY_SETTINGS}/${BILLING_AND_PAYMENTS}`,
    preferences: `/${COMPANY_SETTINGS}/${PREFERENCES}`,
    companyBranding: `/${COMPANY_SETTINGS}/${COMPANY_BRANDING}`,
    systemEmails: `/${COMPANY_SETTINGS}/${SYSTEM_EMAILS}`,
    notificationSettings: `/${COMPANY_SETTINGS}/${NOTIFICATION_SETTINGS}`,
  },
  creditCardTransactions: `/${CREDIT_CARD_TRANSACTIONS}`,
  creditLineIncrease: `/${CREDIT_LINE_INCREASE}`,
  dashboard: "/",
  directBillFAQ: `/${DIRECT_BILL_FAQ}`,
  extendTrips: `/${EXTEND_TRIPS}`,
  favoriteHotels: `/${FAVORITE_HOTELS}`,
  favoritePlaces: `/${FAVORITE_PLACES}`,
  flexFAQ: `/${FLEX_FAQ}`,
  flexPassFAQ: `/${FLEXPASS_FAQ}`,
  flightsBooking: `/${FLIGHTS}/${BOOK}`,
  flightsSearch: `/${FLIGHTS}/${SEARCH}`,
  flightsSeatmapPortal: `/${FLIGHTS}/seatmap-portal`,
  groups: {
    base: `/${GROUPS}`,
    confirmed: `/${GROUPS}/${SBR_DASHBOARD}/${SBR_CONFIRMED}`,
    dashboard: `/${GROUPS}/${SBR_DASHBOARD}`,
    drafts: `/${GROUPS}/${SBR_DASHBOARD}/${SBR_DRAFTS}`,
    open: `/${GROUPS}/${SBR_DASHBOARD}/${SBR_OPEN}`,
    property: `/${GROUPS}/property`,
  },
  groupsNew: {
    landingPage: `/${GROUPS}`,
    base: `/new/${GROUPS}`,
    dashboard: `/new/${GROUPS}/${SBR_DASHBOARD}`,
    tripDetails: (sbrId: string | number) => `/new/${GROUPS}/dashboard/trip-details/${sbrId}`,
    tripDraft: {
      roomInfo: (sbrId: string | number) =>
        `/${GROUPS}/${SBR_DASHBOARD}/${SBR_DRAFTS}/requests/${sbrId}/roomInfo`,
    },
  },
  incidentalsFAQ: `/${INCIDENTALS_FAQ}`,
  itinerary: `/${ITINERARY}`,
  join: {
    accept: `/${ACCEPT}`,
    base: `/${JOIN}`,
    business: `/${BUSINESS}`,
    registering: {
      fastLane: `/${REGISTERING}/fl`,
      groups: `/${REGISTERING}/groups`,
    },
    legacyReferFriends: `/${JOIN}/${INVITE_FRIENDS}`, // Remove once backend reflects referFriends route for email links
    partners: `/${PARTNERS}`,
    referFriends: `/${JOIN}/${REFER_FRIENDS}`,
    finish: `/${JOIN}/${FINISH}`,
  },
  lockedOut: `/${LOCKED_OUT}`,
  loggedOut: `/${LOGGED_OUT}`,
  login: `/${LOGIN}`,
  members: {
    all: `/${MEMBERS}/${ALL}`,
    base: `/${MEMBERS}`,
    details: `/${MEMBERS}/${DETAILS}`,
  },
  modifyReservation: `/${MODIFY_RESERVATION}`,
  myProperties: `/${MY_PROPERTIES}`,
  myPropertiesFavorites: `/${MY_PROPERTIES}/favorites`,
  myPropertiesHidden: `/${MY_PROPERTIES}/hidden`,
  myPropertiesPreferred: `/${MY_PROPERTIES}/preferred`,
  myPropertiesReviews: `/${MY_PROPERTIES}/reviews`,
  optionsReview: `/${OPTIONS_REVIEW}`,
  orders: `/${ORDERS}`,
  pacLogin: `/${PAC_LOGIN}`,
  paymentDetails: `/${PAYMENT_DETAILS}`,
  paymentHistory: `/${PAYMENT_HISTORY}`,
  paymentMethods: `/${PAYMENT_METHODS}`,
  personalAccountConfirm: `/${PERSONAL_ACCOUNT_CONFIRM}`,
  personalTravel2fv: `/${PERSONAL_TRAVEL_2FV}`,
  personalTravelLanding: `/${PERSONAL_TRAVEL_LANDING_PAGE}`,
  personalTravelUnconfirmed: `/${PERSONAL_TRAVEL_UNCONFIRMED}`,
  properties: `/${PROPERTIES}`,
  propertyReviews: `/${PROPERTY_REVIEWS}`,
  referFriends: {
    base: `/${REFER_FRIENDS}`,
    programDetails: `/${REFER_FRIENDS}/${REFER_FRIENDS_PROGRAM_DETAILS}`,
  },
  requestAdminAccess: `/${REQUEST_ADMIN_ACCESS}`,
  results: `/${RESULTS}`,
  rewards: `/${REWARDS}`,
  rewardsEnrollment: `/${REWARDS_ENROLLMENT}`,
  rewardsFaq: `/${REWARDS_FAQ}`,
  rewardsToc: `/${REWARDS_TOC}`,
  search: `/${SEARCH}`,
  settings: {
    base: `/${SETTINGS}`,
    guestInformation: `/${SETTINGS}/${GUEST_INFORMATION}`,
    loyalty: `/${SETTINGS}/${LOYALTY}`,
    paymentMethods: `/${SETTINGS}/${PAYMENT_METHODS}`,
    profile: `/${SETTINGS}/${PROFILE}`,
    accountSecurity: `/${SETTINGS}/account-security`,
  },
  shareOptions: `/${SHARE_OPTIONS}`,
  signUp: `/${SIGN_UP}`,
  joinGroups: `/${JOIN}/${GROUPS}`,
  supportCenter: `/${SUPPORT_CENTER}`,
  partnerOffers: `/${PARTNER_OFFERS}`,
  travelCredits: `/${TRAVEL_CREDITS}`,
  trends: {
    all: `/${TRENDS}/${TRENDS_ALL}`,
    base: `/${TRENDS}`,
    bookers: `/${TRENDS}/${BOOKERS}`,
    departments: `/${TRENDS}/${DEPARTMENTS}`,
    topLocations: `/${TRENDS}/${TOP_LOCATIONS}`,
    topProperties: `/${TRENDS}/${TOP_PROPERTIES}`,
    travelers: `/${TRENDS}/${TRAVELERS}`,
    travelPolicies: `/${TRENDS}/${TRAVEL_POLICIES}`,
    financials: `/${TRENDS}/${FINANCIALS}`,
  },
  trips: {
    active: `/${TRIPS}/${ACTIVE}`,
    today: `/${TRIPS}/${TODAY}`,
    all: `/${TRIPS}/${ALL}`,
    base: `/${TRIPS}`,
    cancelled: `/${TRIPS}/${CANCELLED}`,
    past: `/${TRIPS}/${PAST}`,
    upcoming: `/${TRIPS}/${UPCOMING}`,
  },
  userNotifications: `/${USER_NOTIFICATIONS}`,
} as const;

const CONFIRM_PERSONAL_2FV = "confirmPersonal2fv";
const LAUNCH_PERSONAL_FLOW = "launchPersonalFlow";

export const searchParams = {
  confirmPersonal2fv: CONFIRM_PERSONAL_2FV,
  launchPersonalFlow: LAUNCH_PERSONAL_FLOW,
};
