import { Formik, Form } from "formik";

import { InternationalPhoneInput } from "@hotel-engine/app/InternationalPhoneInput";
import { useTwoFactorVerificationCodeAuthenticatedRequest } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeRequest";
import { IntlPhoneNumberSchema } from "@hotel-engine/utilities";
import { getPersonalToken } from "@hotel-engine/utilities/auth";
import { ModalNavBar } from "pages/Dashboard/components/PersonalAccountCreationCta/ModalNavBar";
import { useAppSelector } from "store/hooks";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

import { Box, Button, Typography } from "@hotelengine/atlas-web";

export interface ISignUpWithGoogleStep {
  onSubmit: (phone: string) => void;
  /** Indicates the component is rendering in a modal on the Dashboard on a Business account */
  isInModal?: boolean;
  onClose?: () => void;
}

export interface IPhoneVerification {
  phone: string;
}

const initialValues: IPhoneVerification = {
  phone: "",
};

export const VerifyTwoFactorStep = ({ onSubmit, isInModal, onClose }: ISignUpWithGoogleStep) => {
  const user = useAppSelector((store) => store.Auth.user);

  const isBusinessAccount = user?.accountType === "business";
  const personalToken = getPersonalToken();
  const authToken = isBusinessAccount && personalToken ? { token: personalToken } : undefined;
  const twoFactorAuth = useTwoFactorVerificationCodeAuthenticatedRequest(authToken);

  const onPhoneNumberSubmit = (values: IPhoneVerification, actions) => {
    // regex - replaces any characters that isn't a digit to ""
    const strippedPhone = values.phone.replace(/[\D]/g, "");

    twoFactorAuth.mutate(
      {
        phone: strippedPhone,
      },
      {
        onSuccess: () => {
          // progress to next step: verifying the verification code

          onSubmit(strippedPhone);
        },
        onError: (error) => {
          return actions.setFieldError(
            "phone",
            error.response?.data.errors[0] || "An error occurred, please try again."
          );
        },
      }
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={16}>
        {!!isInModal && !!onClose && <ModalNavBar onClose={onClose} />}

        <Typography variant="heading/xl" style={{ textAlign: isInModal ? "start" : "center" }}>
          Set Up Two-Step Verification
        </Typography>

        <Typography variant="heading/sm" style={{ textAlign: isInModal ? "start" : "center" }}>
          Security is critical at {COMPANY_NAME}. To validate your account, we will text you a
          verification code.
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={onPhoneNumberSubmit}
          validationSchema={() => IntlPhoneNumberSchema()}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <Box display="flex" flexDirection="column" gap={8}>
                  <InternationalPhoneInput label="Phone Number" />
                  <Typography variant="body/xs" color="foregroundSecondary">
                    Text or data rates may apply.
                  </Typography>
                  <Button type="button" variant="filled" onClick={submitForm}>
                    Send Code
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
