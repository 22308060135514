import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotelengine/atlas-web";
import { buttonReset } from "@hotel-engine/styles";

export const RemoveGuest = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 8px;
`;

export const AdditionalGuestButton = styled(Button)`
  ${buttonReset};
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.accentBlueStrong};
`;
export const AdditionalGuestButtonIcon = styled(Icon)`
  margin-right: 10px;
`;
