import type { IReservationRoom } from "@hotel-engine/types/booking";
import {
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { SectionDivider } from "@hotel-engine/app/ItineraryContent/styles";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { MissingRooms } from "../../../../MissingRooms";
import type { ICancelRooms } from "../../../hooks/useCancelFormValues";
import * as Styled from "./styles";

export interface IRoomSelectionProps {
  setRoomsToCancel: (contractNumber: string | null, enabled: boolean) => void;
  rooms: ICancelRooms;
}

const RoomSelection = ({ setRoomsToCancel, rooms }: IRoomSelectionProps) => {
  const { reservation } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();
  const checkedRooms = Object.keys(rooms).filter((key) => rooms[key].enabled);

  const cancellableRooms: IReservationRoom[] = reservation.rooms.filter(
    (room) => room.modificationActions.cancellable
  );
  const nonCancellableRooms: IReservationRoom[] = reservation.rooms.filter(
    (room) => !room.modificationActions.cancellable
  );

  const additionalGuestsComponent = (room: IReservationRoom) => {
    const additionalGuests = room.occupants.slice(0);
    const additionalGuestCount = additionalGuests.length;
    const usePlural = additionalGuestCount > 1;

    if (!additionalGuestCount) return null;

    return (
      <Popover>
        <PopoverTrigger>
          <Styled.AdditionalGuests
            variant={typographyVariants.body}
            color="foregroundPrimary"
            data-testid="additional-guests-copy"
          >
            {additionalGuestCount} additional guest{usePlural ? "s" : ""}
          </Styled.AdditionalGuests>
        </PopoverTrigger>
        <PopoverContent>
          <>
            {additionalGuests.map((guest) => (
              <div key={guest.occupantId}>
                <Typography variant={typographyVariants.body} color="foregroundPrimary">
                  {guest.firstName} {guest.lastName}{" "}
                  {!!guest.email && (
                    <Typography variant={typographyVariants.body} color="foregroundSecondary">
                      ({guest.email})
                    </Typography>
                  )}
                </Typography>
              </div>
            ))}
          </>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <Styled.CancellableRoomsContainer>
        <SectionDivider />
        {cancellableRooms.map((room, i) => (
          <Styled.RoomContainer key={room.contractNumber}>
            <Checkbox
              data-testid={`checkbox-${room.contractNumber}`}
              isChecked={checkedRooms.includes(room.contractNumber)}
              onCheckedChange={(e) => setRoomsToCancel(room.contractNumber, Boolean(e))}
            >
              <Styled.CheckboxContent>
                <Styled.InnerContent>
                  <Typography variant={typographyVariants.heading} color="foregroundPrimary">
                    Room {room.roomPosition}
                  </Typography>
                  <Typography variant={typographyVariants.heading} color="foregroundSecondary">
                    •
                  </Typography>
                  <Typography variant={typographyVariants.body} color="foregroundSecondary">
                    {room.propertyConfirmationNumber
                      ? room.propertyConfirmationNumber
                      : room.contractNumber}
                  </Typography>
                </Styled.InnerContent>
                <Typography variant={typographyVariants.body} color="foregroundPrimary">
                  {room.occupants[0].firstName} {room.occupants[0].lastName}
                </Typography>
                <Typography variant={typographyVariants.body} color="foregroundSecondary">
                  {room.occupants[0].email}
                </Typography>
              </Styled.CheckboxContent>
            </Checkbox>
            {additionalGuestsComponent(room)}
            {!!(i + 1 < cancellableRooms.length) && <SectionDivider variant="dotted" />}
          </Styled.RoomContainer>
        ))}
        <SectionDivider />
      </Styled.CancellableRoomsContainer>
      {!!nonCancellableRooms.length && <MissingRooms rooms={nonCancellableRooms} />}
    </>
  );
};

export default RoomSelection;
