import { useAppSelector } from "store/hooks";

import { Icon, Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";

import type { RefundType } from "../../../hooks/useCancelRefundCalculator";
import { useCancellationType } from "../../../hooks/useCancellationType";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { InfoItem } from "./InfoItem";
import * as Styled from "./styles";
import type { ICancelRooms } from "../../../hooks/useCancelFormValues";
import { getAdjustedRefundType } from "./helpers";

export interface ICancelInfoBoxProps {
  cancelRefundAmount: string | number | undefined;
  cancelRefundType: RefundType | undefined;
  cancelRooms: ICancelRooms;
  isLoading: boolean;
}

/** Common component used to display the information about the cancellation, refunds, etc,
 * @prop cancelRefundAmount - value returned from useCancelRefundCalculator
 * @prop cancelRefundType - value returned from useCancelRefundCalculator
 * @prop cancelRooms - optional - for multi room cancellation selecting certain rooms
 */
const CancelInfoBox = ({
  cancelRefundAmount,
  cancelRefundType,
  cancelRooms,
  isLoading,
}: ICancelInfoBoxProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { reservation } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();
  const { isFlexType, isSingleRoomType } = useCancellationType();
  const numberOfRoomsToCancel = Object.values(cancelRooms).filter((room) => room.enabled).length;
  const shouldShowRoomBreakdown =
    !isSingleRoomType && numberOfRoomsToCancel !== reservation.roomCount;

  /** This is used for multi room when no rooms are selected */
  const hideDetails = (!cancelRefundAmount || !cancelRefundType) && !isLoading;

  if (!reservation?.occupants?.length) return null;

  const singleRoomTitle = (
    <InfoItem
      leftText="Room 1"
      rightText={`${reservation.occupants[0][0].firstName} ${reservation.occupants[0][0].lastName}`}
    />
  );
  const allMultiRoomTitle = <InfoItem leftText="All rooms" />;

  const refundDetails = (
    <>
      <InfoItem
        dataTestId="cancel-refund-total"
        leftText="Total refund"
        rightText={formatCurrency(String(cancelRefundAmount), true, user?.business.currencyCode)}
      />
      <InfoItem leftText="Refund method" rightText={getAdjustedRefundType(cancelRefundType)} />
    </>
  );

  const basicInfoState = (
    <Styled.InfoSection>
      {!!isSingleRoomType ? singleRoomTitle : allMultiRoomTitle}
      {refundDetails}
    </Styled.InfoSection>
  );

  const multiRoomSelectionState = (
    <>
      <Styled.InfoSection>
        {Object.keys(cancelRooms).map((room) => {
          if (!cancelRooms[room].enabled) return null;

          const roomIndex = reservation.rooms.findIndex(
            (resRoom) => resRoom.contractNumber === room
          );

          return (
            <InfoItem
              key={room}
              leftText={`Room ${roomIndex + 1}`}
              rightText={`${reservation.occupants[roomIndex]?.[0].firstName} ${reservation.occupants[roomIndex]?.[0].lastName}`}
            />
          );
        })}
      </Styled.InfoSection>
      <Styled.InfoDivider />
      <Styled.InfoSection>{refundDetails}</Styled.InfoSection>
    </>
  );

  const flexMessage = (
    <>
      <Styled.InfoDivider />
      <Styled.FlexMessageContainer data-testid="covered-by-flex-message">
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          Cancellation covered by
        </Typography>
        <Icon name="he-flex" color="foregroundPrimary" />
        <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
          Flex
        </Typography>
      </Styled.FlexMessageContainer>
    </>
  );

  return !!hideDetails ? null : (
    <Styled.InfoBox>
      {isLoading ? (
        <Icon
          name="spinner-third"
          color="foregroundPrimary"
          animationStyle="spin"
          style={{ margin: "24px auto", width: "100%" }}
          data-testid="cancel-info-loader"
        />
      ) : (
        <>
          {!shouldShowRoomBreakdown ? basicInfoState : multiRoomSelectionState}
          {!!isFlexType && flexMessage}
        </>
      )}
    </Styled.InfoBox>
  );
};

export default CancelInfoBox;
