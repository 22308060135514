import { Button, Divider, Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

export interface IActionButtonLayoutProps {
  renderList: IButtonListItem[];
  showFlexMessage: boolean;
}

export interface IButtonListItem {
  action: () => void;
  dataTestId: string;
  displayCriteria: boolean;
  text: string;
  isLoading?: boolean;
}

const ActionButtonLayout = ({ renderList, showFlexMessage }: IActionButtonLayoutProps) => {
  const filteredList = renderList.filter((item) => item.displayCriteria);
  const hasDisplayCriteria = filteredList.length;

  return (
    <Styled.ModificationActions>
      {!!hasDisplayCriteria && (
        <Styled.ButtonGroup>
          {filteredList.map((item, itemIndex) => {
            return item.displayCriteria ? (
              <>
                {item.dataTestId === "cancel-booking" && (
                  <Divider
                    variant="dotted"
                    key={"divider_" + item.dataTestId + item.text + item.displayCriteria}
                  />
                )}
                <Button
                  onClick={item.action}
                  data-testid={item.dataTestId}
                  key={"button_" + item.dataTestId + item.text + item.displayCriteria}
                  size="lg"
                  variant={itemIndex === filteredList.length - 1 ? "filled" : "outlined"}
                  disabled={item.isLoading}
                  isLoading={item.isLoading}
                >
                  {item.text}
                </Button>
              </>
            ) : null;
          })}
        </Styled.ButtonGroup>
      )}
      {showFlexMessage ? (
        <Styled.FlexMessage>
          <Typography variant="body/sm">Modifications are covered by </Typography>
          <Icon
            id="shield-check"
            name="he-flex"
            color="foregroundPrimary"
            data-testid="flex-pro-logo"
          />{" "}
          <Typography variant="body/sm-strong">Flex</Typography>
        </Styled.FlexMessage>
      ) : null}
    </Styled.ModificationActions>
  );
};

export default ActionButtonLayout;
