import { type UseMutationOptions, useMutation } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { IErrorResponse } from "../../types/errors";
import { useExtendedQuery } from "../useExtendedQuery";

export interface ICancelParams {
  id: number | string;
}

type TCancellationItem = {
  currencyCode: string;
  value: string;
};

export interface IPaidCancellation {
  fees?: TCancellationItem[];
  car?: {
    freeCancellation?: boolean;
    total?: TCancellationItem;
    fees?: TCancellationItem[];
  };
  insurance?: {
    cancelled: boolean;
    total: TCancellationItem;
  };
  credits?: {
    amount: string;
    type: string;
  }[];
  combined: { total: TCancellationItem; freeCancellation?: boolean };
  total: TCancellationItem;
}

export interface IFreeCancellation {
  freeCancellable: boolean;
}

export enum FlightsTriggerOnCancel {
  OpenSupportRequestToCancel = "open-support-request-to-cancel",
  CancelWithRefund = "cancel-with-refund",
  CancelWithNoRefund = "cancel-with-no-refund",
  CancelWithUnknownRefund = "cancel-with-unknown-refund",
}

export interface ICancelData {
  bookingId: string;
  bookingType: string;
  triggerOnCancel: FlightsTriggerOnCancel;
  refundDetails: IPaidCancellation | null;
}

export interface ICarCancelData extends Pick<ICancelData, "bookingId" | "bookingType"> {
  refundDetails: IPaidCancellation | null;
}

export interface IFlightCancelData extends ICancelData {
  us24HourCancellableWindowEnds?: string;
}

export interface ICancelCalcBody {
  bookingNumber: string;
  bookingType: "flight" | "lodging" | "car";
}

export const usePreviewCancellationQuery = (
  params: ICancelParams,
  options?: UseMutationOptions<ICancelData, IErrorResponse, ICancelCalcBody>
) => {
  const post = useApi("post");

  return useMutation(
    [endpoints.trips, params],
    (body) => post<ICancelData>(`${endpoints.trips}/${params.id}/cancellation`, body),
    options
  );
};

export const useCarsPreviewCancellationQuery = (params: ICancelParams) => {
  const get = useApi("get");

  return useExtendedQuery([endpoints.carBookings, params], () =>
    get<ICancelData>(`${endpoints.carBookings}/${params.id}/cancellations`)
  );
};
