import { ampli } from "ampli";
import { useRef, useEffect } from "react";

function useAmplitudeViewTripsOnMount() {
  const initialPageVisit = useRef(true);

  useEffect(() => {
    if (initialPageVisit.current) {
      initialPageVisit.current = false;
      ampli.viewTrips();
    }
  }, []);
}

export default useAmplitudeViewTripsOnMount;
