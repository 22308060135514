import { Typography } from "@hotelengine/atlas-web";

import { useAppSelector } from "store/hooks";
import type { MapPosition } from "@hotel-engine/app/GoogleMap/types";
import OverlayView from "@hotel-engine/app/GoogleMap/components/OverlayView";
import type { ITrip } from "@hotel-engine/types/trips";
import { formatCurrency } from "@hotel-engine/utilities";
import {
  defaultClusterOptions,
  defaultPriceMarkerAttributes,
  initialMapOptions,
} from "../../../constants";
import BaseMarker from "../BaseMarker";
import { returnPriceMarkerWidth } from "../markerHelpers";
import * as Styled from "../styles";

type GoogleMapsTripsMarkerProps = {
  /** the map object this marker is being applied to */
  map: google.maps.Map;
  /** the lat, lng of the marker */
  position: MapPosition;
  /** Name and Address values for the specific property */
  propertyInfo: {
    name: string;
    address: string;
  };
  /** The full Reservation object associated with the marker */
  trip: ITrip;
  /** Zooms the user directly to the property and shows the property specific marker */
  onSelection: () => void;
};

const GoogleMapsTripsMarker = ({
  map,
  position,
  propertyInfo,
  trip,
  onSelection,
}: GoogleMapsTripsMarkerProps) => {
  const currencyCode = useAppSelector((state) => state.Auth.user?.business?.currencyCode) || "USD";

  const rateToDisplay = formatCurrency(
    Math.round(+(trip.bookings[0].details.charges.total || 0)),
    false,
    currencyCode
  );

  const markerWidth = returnPriceMarkerWidth(rateToDisplay);

  const currentZoom = map?.get("zoom") || initialMapOptions.zoom;

  return (
    <OverlayView position={position} map={map} zIndex={defaultPriceMarkerAttributes.zIndex}>
      <BaseMarker markerAttr={defaultPriceMarkerAttributes}>
        {currentZoom < defaultClusterOptions.maxZoom ? (
          <Styled.PriceMarker
            $markerAttr={defaultPriceMarkerAttributes}
            $width={markerWidth}
            onClick={onSelection}
          >
            <Typography as={Styled.OverflowType} variant="body/sm" color="foregroundInverse">
              {rateToDisplay}
            </Typography>
          </Styled.PriceMarker>
        ) : (
          <Styled.PropertyAddressMarker onClick={onSelection}>
            <Typography
              as={Styled.OverflowType}
              $isProperty
              variant="heading/xs"
              color="foregroundInverse"
              data-testid="pin-property-name"
            >
              {propertyInfo.name}
            </Typography>
            <Typography
              as={Styled.OverflowType}
              variant="body/xs"
              color="foregroundInverse"
              $isProperty
            >
              {propertyInfo.address}
            </Typography>
          </Styled.PropertyAddressMarker>
        )}
      </BaseMarker>
    </OverlayView>
  );
};

export default GoogleMapsTripsMarker;
