import styled, { css } from "styled-components";

interface IFocusableLink {
  color?: string;
  $hasUnderline?: boolean;
  $hasUnderlineOnHover?: boolean;
}

const FocusableLink = styled.a<IFocusableLink>`
  ${({ theme }) => css`
    &:focus-visible {
      box-shadow: 0 0 0 1px ${theme.colors.focusInnerRing}, 0 0 0 2px ${theme.colors.focusOuterRing};
    }
  `}
  // overrides AntD's hover styling
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color]} !important;
    `}
  ${({ $hasUnderline }) =>
    $hasUnderline &&
    css`
      text-decoration: underline;
    `}
  ${({ $hasUnderlineOnHover }) =>
    $hasUnderlineOnHover &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

FocusableLink.defaultProps = {
  color: undefined,
  $hasUnderline: false,
  $hasUnderlineOnHover: true,
};

export default FocusableLink;
