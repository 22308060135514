import styled from "styled-components";
import { Checkbox } from "@hotelengine/atlas-web";

const DepartmentCheckbox = styled(Checkbox)`
    span {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export { DepartmentCheckbox };
