import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { Loader } from "@hotel-engine/components/Loader";

import { useTripsLegacyContext } from "../../contextLegacy";
import { usePaginationControls } from "./usePaginationControls";

const TripsListPagination = () => {
  const { isNextDisabled, showInMemoryPagination, cachedTrips } = usePaginationControls();
  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const { counts, status, loading, trips } = tripsState;

  const getPaginationInfo = () => {
    const currentPage = tripsState.page > 0 ? tripsState.page : 1;
    const pageSize = tripsState.limit > 0 ? tripsState.limit : 25;
    const startItem = (currentPage - 1) * pageSize + 1;
    const totalItems = showInMemoryPagination ? cachedTrips.length : counts[status] || 0;
    const endItem = Math.min(currentPage * pageSize, totalItems);
    const displayStartItem = totalItems === 0 ? "" : startItem;
    const displayEndItem = totalItems === 0 ? "" : trips.length ? endItem : "";

    return (
      <Typography
        variant="body/md"
        color="foregroundPrimary"
        style={{ minWidth: 75, textAlign: "center" }}
      >
        {loading ? <Loader size="lg" /> : `${displayStartItem} - ${displayEndItem}`}
      </Typography>
    );
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={16}>
      <Box display="flex" alignItems="center" gap={16}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={tripsState.page <= 1 || loading}
          onClick={() => {
            if (tripsState.page > 1) {
              tripsDispatch({
                type: "pagination",
                page: tripsState.page - 1,
                pageSize: tripsState.limit,
              });
            }
          }}
        >
          Back
        </Button>
        {getPaginationInfo()}
        <Button
          variant="outlined"
          color="secondary"
          disabled={isNextDisabled || loading}
          onClick={() => {
            tripsDispatch({
              type: "pagination",
              page: tripsState.page + 1,
              pageSize: tripsState.limit,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TripsListPagination;
