import { Box, Typography } from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

const AIRLINES_CONTACT_INFO = {
  UA: {
    name: "United Airlines",
    phone: "+1 (800) 864-8331",
  },
  WN: {
    name: "Southwest Airlines",
    phone: "+1 (800) 435-9792",
  },
};

function getAirlinesToCallContactInfos(airlineCodes: string[]) {
  return Object.entries(AIRLINES_CONTACT_INFO).filter(([code]) => airlineCodes.includes(code));
}

export const ManageFlightDescription = ({ airlineCodes }: { airlineCodes: string[] }) => {
  const multipleAirlines = airlineCodes.length > 1;
  const contactsInfoToInclude = getAirlinesToCallContactInfos(airlineCodes);
  const needsToCall = contactsInfoToInclude.length > 0;

  return (
    <Box display="flex" flexDirection="column" gap={16}>
      <Typography variant="body/md" color="foregroundPrimary">
        If you need to cancel this booking please do this through Engine.
      </Typography>

      <Typography variant="body/md" color="foregroundPrimary">
        To change this booking please {needsToCall ? "call" : "contact"} the airline
        {multipleAirlines ? "s" : ""} directly.
      </Typography>

      <Typography variant="body/md" color="foregroundPrimary" data-testid="changes-reflect-text">
        Please note that any modifications made to your trip outside of {COMPANY_NAME} may not
        reflect in the app.
      </Typography>

      {contactsInfoToInclude.map(([code, contactInfo]) => (
        <Box display="flex" flexDirection="column" key={code}>
          <Typography variant="body/md" color="foregroundPrimary" data-testid={`call-${code}`}>
            Call {contactInfo.name}
          </Typography>

          <Typography
            variant="body/md"
            color="foregroundPrimary"
            as="a"
            href={`tel:${contactInfo.phone}`}
          >
            {contactInfo.phone}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
