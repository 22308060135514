import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import styled, { css } from "styled-components";
import { ellipsisText } from "@hotel-engine/styles";

import type { TVariants } from "./";
import { Card as AtlasCard, Chip, type CardProps } from "@hotelengine/atlas-web";

type ICard = CardProps & {
  noBorder?: boolean;
  error?: boolean;
  $variant?: TVariants;
};

export const Card = styled<React.FC<ICard>>(AtlasCard)`
  ${flexbox({
    alignItems: "center",
  })}
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  transition: all 0.3s;
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.borderDestructive};
      background-color: ${({ theme }) => theme.colors.sentimentCriticalMinimal};
    `}

  ${({ $variant }) =>
    $variant === "no-margin" &&
    css`
      margin: ${({ theme }) => theme.spacing[0]};
    `}

  .card-content-wrapper {
    ${flexbox({
      alignItems: "center",
      justifyContent: "space-between",
    })}
    width: 100%;
    padding: ${({ theme }) => theme.spacing[24]};
  }

  .image {
    ${flexbox({
      alignItems: "center",
      justifyContent: "center",
    })}
    margin-right: ${({ theme }) => theme.spacing[16]};

    img {
      width: 40px;
    }
  }

  .label {
    border-radius: ${({ theme }) => theme.borderRadius.pill};
  }

  .card-body {
    ${flexbox()}
    max-width: 80%;

    .copy-content {
      ${flexbox({
        direction: "column",
      })}
    }
  }

  .title-wrapper {
    ${flexbox({
      alignItems: "center",
    })}
  }

  .card-title {
    ${ellipsisText};
    display: block;
    font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
    font-weight: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }

  .status {
    color: ${({ theme }) => theme.colors.borderDestructive};
  }

  .bullet {
    color: ${({ theme }) => theme.colors.uiSecondary};
    margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[4]}`};
  }
`;

export const Actions = styled.div`
  button {
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[10]}`};
  }
`;

export const CardTag = styled(Chip)`
  cursor: auto;
  margin-left: ${({ theme }) => theme.spacing[8]};
`;
