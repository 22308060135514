import { useState } from "react";
import type { IconProps } from "@hotelengine/atlas-web";
import { Loader } from "@hotel-engine/components/Loader";
import {
  Typography,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Box,
  Tooltip,
  BottomSheet,
  BottomSheetContent,
} from "@hotelengine/atlas-web";
import * as Styled from "./styles";

interface IFilterPillProps {
  /** An array of a primary and optional secondary label to be displayed on the pill */
  labels: React.ReactNode[];
  /** Whether the filter is applied or not, used to manage icon and styling state, as well as the reset option */
  isApplied: boolean;
  /** Can be used to override the isApplied state in cases where you have filters applied but do not want to show the reset option (see Trips) */
  hideResetIcon?: boolean;
  /** Can be used to override the logic to display the chevron */
  hideChevron?: boolean;
  /** Used to control the disabled state of the entire pill */
  isDisabled?: boolean;
  /** Component(s) to be rendered within the PopoverContent/BottomSheetContent */
  children?: React.ReactNode;
  /** Called when clicking Reset in the PopoverContent/BottomSheetContent or when clicking the reset (x) in the isApplied state */
  onReset: () => void;
  /** An additional action that can be performed when toggling the PopoverContent/BottomSheetContent open */
  onToggle?: () => void;
  /** The action to be performed when clicking Done in the PopoverContent/BottomSheetContent */
  onSubmit?: () => void;
  /** If true, will evaluate the return of onSubmit as a boolean to determine whether to close the content or keep open, can be used for displaying
   * a validation state on the form without closing the content
   */
  keepOpenIfInvalid?: boolean;
  /** An icon to be displayed before the label in the pill */
  leadingIcon?: IconProps["name"];
  /** A breakpoint to determine if and when to change from a Popover to a BottomSheet */
  isMobile?: boolean;
  /** Optional loading state to replace label with spinner */
  isLoading?: boolean;
  /** If true, will show the reset button enabled */
  isTemporaryApplied?: boolean;
}

const FilterPill = ({
  labels,
  onToggle,
  onReset,
  onSubmit,
  isApplied,
  isDisabled = false,
  children,
  leadingIcon,
  hideResetIcon,
  hideChevron,
  keepOpenIfInvalid,
  isMobile,
  isLoading,
  isTemporaryApplied = false,
  ...btnProps
}: IFilterPillProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const button = (
    <Styled.FilterButton
      onClick={() => {
        isMobile && setIsOpen(true);
        onToggle?.();
      }}
      $isApplied={!!(isApplied && !hideResetIcon)}
      color={!!(isApplied && !hideResetIcon) ? "secondary" : "primary"}
      trailingIcon={
        !!children && (!isApplied || hideResetIcon) && !hideChevron
          ? isOpen
            ? "chevron-up"
            : "chevron-down"
          : undefined
      }
      leadingIcon={leadingIcon}
      disabled={isDisabled}
      {...btnProps}
    >
      {isLoading ? (
        <Box paddingLeft={4} paddingRight={4}>
          <Loader />
        </Box>
      ) : (
        <>
          {labels[0]}
          {!!labels[1] && (
            <Typography paddingLeft={4} variant="body/sm-light">
              {labels[1]}
            </Typography>
          )}
          {!!isApplied && !hideResetIcon && (
            <Styled.ResetIcon
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              icon="circle-xmark"
              size="xs"
              variant="plain"
              disabled={isDisabled}
              data-testid="filter-pill-reset-icon"
            />
          )}
        </>
      )}
    </Styled.FilterButton>
  );

  const wrappedButton = isDisabled ? <Tooltip content="no results">{button}</Tooltip> : button;

  if (!children) {
    return <Styled.Container>{wrappedButton}</Styled.Container>;
  }

  const innerContent = (
    <>
      <Styled.ScrollableContent>{children}</Styled.ScrollableContent>
      <Box paddingTop={32} display="flex" justifyContent="space-between" gap={12}>
        <Styled.ResetButton
          onClick={onReset}
          disabled={isDisabled || (!isApplied && !isTemporaryApplied)}
        >
          Reset
        </Styled.ResetButton>
        <Styled.DoneButton
          data-testid="apply-filters"
          type="button"
          onClick={() => {
            if (keepOpenIfInvalid) {
              const isValid = onSubmit?.();
              isValid && setIsOpen(false);
            } else {
              setIsOpen(false);
              onSubmit?.();
            }
          }}
          disabled={isDisabled}
        >
          Done
        </Styled.DoneButton>
      </Box>
    </>
  );

  return (
    <Styled.Container>
      {isMobile ? (
        <BottomSheet isOpen={isOpen} onIsOpenChange={setIsOpen}>
          {wrappedButton}
          <BottomSheetContent style={{ padding: 24, maxWidth: "unset" }}>
            {innerContent}
          </BottomSheetContent>
        </BottomSheet>
      ) : (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger>{wrappedButton}</PopoverTrigger>
          <PopoverContent style={{ padding: 24, maxWidth: "unset" }}>{innerContent}</PopoverContent>
        </Popover>
      )}
    </Styled.Container>
  );
};

export default FilterPill;
