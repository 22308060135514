import { useEffect, useRef, useState } from "react";

import { useNavigate, useLocation, useParams } from "@hotel-engine/lib/react-router-dom";

import PageLayout from "@hotel-engine/app/PageLayout";
import { routes } from "@hotel-engine/constants";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useBreakpoint } from "@hotel-engine/hooks";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

import { viewTypes } from "./constants";
import type { ViewType } from "./constants";
import TripsContentLegacy from "./TripsContentLegacy";
import { TripsLegacyProvider } from "./contextLegacy";

const TripsLegacy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const { status } = useParams<{ status: string }>();
  const isMobileScreen = useBreakpoint("lg", "max");
  const initialPageVisit = useRef(true);

  const [view, setView] = useState<ViewType>(viewTypes.LARGE_LIST || "default");

  const location = useLocation<{
    monthly_stats_email?: string;
    booking_number?: string;
  }>();

  // Track if user arrived here from the Monthly Stats Email
  const queryParams = new URLSearchParams(location.search);
  const isMonthlyStatsEmailClick = queryParams.get("monthly_stats_email");
  useEffect(() => {
    if (isMonthlyStatsEmailClick && user?.id) {
      ampli.clickMonthlyStatsEmailTrips({ userId: user.id });
      navigate(`${routes.trips.base}/all`, { replace: true });
    }
  }, [navigate, isMonthlyStatsEmailClick, user?.id]);

  useEffect(() => {
    // Mobile screen sizes should switch to list view if they are on another view
    if (isMobileScreen && view !== viewTypes.LARGE_LIST) {
      handleView(viewTypes.LARGE_LIST);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileScreen]);

  useEffect(() => {
    // Amplitude page visit tracking
    if (initialPageVisit.current) {
      initialPageVisit.current = false;
      ampli.viewTrips();
    }
  }, []);

  const handleView = (viewType) => {
    setView(viewType);

    if (viewType === viewTypes.CALENDAR) {
      const bookingNumber = queryParams.get("booking_number");

      navigate(
        `${routes.trips.base}/all${bookingNumber ? `?booking_number=${bookingNumber}` : ""}`
      );
    }
  };

  if (!user) {
    return null;
  }

  return (
    <PageLayout
      className="trips-page"
      title={`Trips | ${COMPANY_NAME}`}
      contentWidth="100%"
      bodyBackgroundColor="backgroundSecondary"
      hasPadding={false}
    >
      <TripsLegacyProvider params={{ status }} user={user} view={view}>
        <TripsContentLegacy handleView={handleView} view={view} />
      </TripsLegacyProvider>
    </PageLayout>
  );
};

export default TripsLegacy;
