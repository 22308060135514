import type { FunctionComponent, ReactElement, SyntheticEvent } from "react";

import { useFormikContext } from "formik";

import { Dropdown } from "@hotel-engine/common/Dropdown";
import { Icon as LegacyIcon } from "@hotel-engine/common/Icon";
import { Icon } from "@hotelengine/atlas-web";
import config from "config";
import type {
  IExpressBookCheckoutForm,
  IExpressBookPaymentProfile,
} from "@hotel-engine/types/expressBook";

import * as Styled from "./styles";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";

export interface IExpressBookPaymentItemProps {
  onPaymentSelect: (id: number) => void;
  payment: IExpressBookPaymentProfile;
}

export const ExpressBookPaymentItem: FunctionComponent<IExpressBookPaymentItemProps> = ({
  onPaymentSelect,
  payment,
}: IExpressBookPaymentItemProps) => {
  const { values } = useFormikContext<IExpressBookCheckoutForm>();
  const { redirectToCheckout } = useExpressBook();

  const onPaymentMethodChange = () => {
    redirectToCheckout(values);
  };

  const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const defaultImgUrl = `${config.cdnHost}/assets/creditcards/default.png`;

    if (e.currentTarget.src === defaultImgUrl) {
      return e;
    }

    return (e.currentTarget.src = defaultImgUrl);
  };

  const displayName = payment.nickname || payment.name;
  const summary = `ending in ${payment.last4}`;
  const isSelected = values.selectedPaymentId === payment.id;

  let actionCallout: ReactElement | null = null;
  if (payment.isExpired) {
    actionCallout = <Styled.ActionNeeded>(expired)</Styled.ActionNeeded>;
  } else if (!payment.billingPostalCode) {
    actionCallout = (
      <Styled.ActionNeeded type="button" onClick={onPaymentMethodChange}>
        <Icon name="circle-exclamation" color="sentimentCriticalDefault" />
        <span>Update address</span>
      </Styled.ActionNeeded>
    );
  }

  return (
    <Styled.PaymentItemRadio
      checked={isSelected}
      disabled={payment.isExpired}
      onClick={() => onPaymentSelect(payment.id)}
      id={displayName}
    >
      <Styled.PaymentItemContainer
        data-testid={`cc-${payment.isExpired ? "expired" : "active"}`}
        $disabled={payment.isExpired}
        key={payment.id}
      >
        <Styled.LeftColumn>
          <Styled.PaymentImage>
            <img
              src={`${config.cdnHost}/assets/creditcards/${payment.type}.png`}
              alt="Payment Item"
              onError={handleImgError}
            />
          </Styled.PaymentImage>
          <Styled.Info>
            <div>
              <Styled.Name htmlFor={displayName}>{displayName}</Styled.Name>
              {!!payment.default && <Styled.CardTag>Default</Styled.CardTag>}
            </div>
            <Styled.Summary>{summary}</Styled.Summary>
            {actionCallout}
          </Styled.Info>
        </Styled.LeftColumn>
        <Dropdown
          overlay={
            <Styled.ActionMenu>
              <Styled.ActionItem onClick={onPaymentMethodChange}>
                <LegacyIcon fixedWidth icon={["far", "edit"]} />
                <span>Edit Card</span>
              </Styled.ActionItem>
              <Styled.ActionItem $isDeleteLink onClick={onPaymentMethodChange}>
                <LegacyIcon fixedWidth icon={["far", "minus-circle"]} />
                <span>Delete Card</span>
              </Styled.ActionItem>
            </Styled.ActionMenu>
          }
          trigger={["click"]}
        >
          <Styled.DropdownTrigger data-testid="show-actions-menu">
            <LegacyIcon icon={["fas", "ellipsis-h"]} />
          </Styled.DropdownTrigger>
        </Dropdown>
      </Styled.PaymentItemContainer>
    </Styled.PaymentItemRadio>
  );
};
