import { useFormikContext } from "formik";

import { Popover } from "@hotel-engine/common/Popover";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { IDirectBill } from "@hotel-engine/types/booking";
import { formatCurrency } from "@hotel-engine/utilities";
import config from "config";
import { useContractRoomData } from "pages/Checkout/LegacyLodging/hooks/useContractRoomData";
import { usePricingCalculation } from "pages/Checkout/LegacyLodging/hooks/usePricingCalculation";
import type { ICheckoutFormData } from "pages/Checkout/validation";
import { useAppSelector } from "store/hooks";

import { PaymentImage, PaymentItemContainer } from "../PaymentItem/styles";
import * as Styled from "../PaymentItem/styles";
import type { IUser } from "@hotel-engine/types/user";

export const directBill: IDirectBill = {
  id: 0,
  name: "Direct Bill",
  type: "directbill",
};

export const creditDescriptionOptions = {
  "-1": "Credit limit exceeded",
  "0": "Credit limit met",
  "1": "Available",
};

export const formatDirectBillBalanceMessage = (user: IUser | null) => {
  const showBalance = user?.business.showCreditRoles.includes(user?.role);
  if (!showBalance) return "";

  const currencyCode = user?.business?.currencyCode || "USD";
  const creditAmount = user?.business.availableCredit;

  const formattedCreditAmount = creditAmount
    ? formatCurrency(creditAmount, true, currencyCode)
    : "";

  const creditSign = !!creditAmount ? Math.sign(creditAmount) : 0;
  const creditDescription = creditDescriptionOptions[creditSign];

  return `${creditDescription} ${formattedCreditAmount}`;
};

export const DirectBillItem = ({
  onPaymentSelect,
}: {
  onPaymentSelect: (id: number) => void;
}) => {
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const isMobile = useBreakpoint("lg", "max");
  const { customerTotal } = usePricingCalculation(currencyCode, true);
  const { values } = useFormikContext<ICheckoutFormData>();

  const isSelected = values.selectedPaymentId === 0;
  const { contractRate } = useContractRoomData();

  const renderSummary = () => {
    if (!contractRate?.isCancellable && user?.business.directBillVerificationRequired) {
      return (
        <Popover content="Only available for refundable rooms">
          <Styled.PopoverIcon name="circle-exclamation" size="md" />
        </Popover>
      );
    }

    if (isInsufficientBalance) {
      return (
        <Popover
          content={`${user?.business.name} doesn't have enough funds to cover this booking.`}
        >
          <Styled.PopoverIcon
            name="circle-exclamation"
            size="md"
            color="sentimentCriticalDefault"
          />
        </Popover>
      );
    }

    return (
      <Popover content="Your company will be invoiced">
        <Styled.PopoverIcon name="circle-question" />
      </Popover>
    );
  };

  const isInsufficientBalance =
    !!user?.business.availableCredit && user?.business.availableCredit < customerTotal;

  const disabled =
    (!contractRate?.isCancellable && user?.business.directBillVerificationRequired) ||
    isInsufficientBalance;

  const formattedBalanceMessage = formatDirectBillBalanceMessage(user);

  return (
    <Styled.PaymentItemRadio
      checked={isSelected}
      disabled={disabled}
      onClick={() => onPaymentSelect(directBill.id)}
      id="directBill"
    >
      <PaymentItemContainer $disabled={disabled}>
        {!isMobile && (
          <PaymentImage>
            <img src={`${config.cdnHost}/assets/creditcards/directbill.png`} alt="Direct Bill" />
          </PaymentImage>
        )}
        <Styled.Info>
          <Styled.Name htmlFor="directBill">
            Direct Bill
            {renderSummary()}
          </Styled.Name>
          <Styled.Summary>{formattedBalanceMessage}</Styled.Summary>
        </Styled.Info>
      </PaymentItemContainer>
    </Styled.PaymentItemRadio>
  );
};
