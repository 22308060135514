import moment from "moment";
import { useFormatDate } from "@hotel-engine/hooks";
import type { FlightRefundInfo } from "../../hooks/usePreviewFlightRefund";

const useCancellationDateFormat = (date: Parameters<typeof useFormatDate>[0]) => {
  const isSameYear = moment().isSame(moment.parseZone(date), "years");
  const dateFormat = isSameYear ? "ddd, MMM D \\at h:mma" : "ddd, MMM D, YYYY \\at h:mma";
  return useFormatDate(date, dateFormat);
};

export const useCancellationMessage = ({
  refundInfo,
}: {
  refundInfo: FlightRefundInfo;
}) => {
  const us24HourForCancellation =
    refundInfo?.us24HourCancellableWindowEnds &&
    moment.parseZone(refundInfo?.us24HourCancellableWindowEnds);
  const us24HourCancellableDateFormatted = useCancellationDateFormat(us24HourForCancellation);

  const now = moment();

  if (us24HourForCancellation && now.isBefore(us24HourForCancellation)) {
    return `Cancel free of charge until ${us24HourCancellableDateFormatted}.`;
  }

  return null;
};
