import styled, { css } from "styled-components";

import { Typography } from "@hotelengine/atlas-web";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";

interface IFooterContainerProps {
  isFooterAbsolute: boolean;
}

export const EditLink = styled<React.FC<TypographyProps>>(Typography)`
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing[4]};

  &:hover {
    text-decoration: none;
  }
`;

export const FooterContainer = styled.div<IFooterContainerProps>`
  ${flexbox({ alignItems: "center", justifyContent: "flex-end" })}
  column-gap: ${({ theme }) => theme.spacing[16]};
  padding-top: ${({ theme }) => theme.spacing[16]};

  ${({ isFooterAbsolute }) =>
    isFooterAbsolute &&
    css`
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: ${({ theme }) => theme.spacing[16]};
      border-top: 1px solid ${colors.grey[100]};
    `};
`;
