import { createContext, useContext, useMemo } from "react";
import { useSearchParams } from "@hotel-engine/hooks";
import type { FlightLocation, Passengers } from "@hotel-engine/types/flights/flights.form.types";
import type { KnownCabinClass } from "@hotelengine/core-booking-web";
import { TripType } from "@hotelengine/core-booking-web";
import { StopFilterOptions } from "store/Flights/FlightsFilters/flights.filters.constants";

export interface IParamSliceCriteria {
  origin: FlightLocation | null;
  destination: FlightLocation | null;
  departureDate: string;
}

export interface IFlightSearchParams {
  searchCriteriaId: string;
  tripType: TripType;
  slicesCriteria: IParamSliceCriteria[];
  passengers: Passengers;
  stops: StopFilterOptions;
  cabinClass: KnownCabinClass | null;
}

export const initialParams = {
  tripType: TripType.roundTrip,
  slicesCriteria: [],
  passengers: {
    adult: 1,
    child: { count: 0, childrenAges: [] },
    infant: 0,
  },
  searchCriteriaId: "",
  stops: StopFilterOptions.any,
  cabinClass: null,
};

const FlightSearchParamsContext = createContext<IFlightSearchParams>(initialParams);

export const FlightSearchParamsProvider = ({ children }) => {
  const urlParams = useSearchParams<IFlightSearchParams>();

  const parsed = useMemo(() => {
    return Object.keys(urlParams).reduce((acc, key) => {
      try {
        // some values are stringified JSON objects, others are strings
        const res = JSON.parse(urlParams[key]);
        return { ...acc, [key]: res };
      } catch (e) {
        return { ...acc, [key]: urlParams[key] };
      }
    }, {} as IFlightSearchParams);
  }, [urlParams]);

  return (
    <FlightSearchParamsContext.Provider value={parsed}>
      {children}
    </FlightSearchParamsContext.Provider>
  );
};

export const useFlightSearchParamsContext = () => useContext(FlightSearchParamsContext);
