import styled from "styled-components";

import SkeletonLoader from "@hotel-engine/common/SkeletonLoader";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { globalTheme } from "@hotel-engine/styles/themes/global.theme";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const Container = styled.article<{ disabled?: number }>`
  border: 1px solid ${colors.grey[200]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  ${({ disabled }) => disabled && "opacity: .85;"}
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[16]};
  position: relative;

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing[24]};
  }
`;

export const Loader = styled(SkeletonLoader)`
  margin-bottom: 16px;

  &first-of-type {
    margin-top: 24px;
  }
`;

export const LinkButton = styled.div`
  padding: ${({ theme }) => theme.spacing[0]};
  &:hover{
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    cursor: pointer;
  }
`;

export const PreferenceContainer = styled.div`

.settings-row {
    ${flexbox({ justifyContent: "flex-start" })}

    div {
      ${flexbox()}

      &:nth-child(1) {
        font-weight: ${globalTheme.legacy.fontWeight.medium};
        ${flexbox({ grow: 3, shrink: 1, basis: 0 })}
      }

      &:nth-child(2) {
        ${flexbox({
          grow: 3,
          shrink: 1,
          basis: 0,
          justifyContent: "flex-end",
        })};
      }

      &:nth-child(3) {
        ${flexbox({
          grow: 1,
          shrink: 1,
          basis: 0,
          justifyContent: "center",
        })}
      }
    }
  }

  ${mediaQuery("lg")(`
    .settings-row {
            div {
                &:nth-child(1) {
                    display: flex;
                    flex-grow: 2;
                    flex-shrink: 1;
                    flex-basis: 0;
                }

                &:nth-child(2) {
                    flex-grow: 2;
                    flex-shrink: 1;
                    flex-basis: 0;
                    justify-content: flex-start;
                }

                &:nth-child(3) {
                    justify-content: flex-end;
                }

            }

    `)}

`;
