import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import OnboardingModal from "@hotel-engine/app/modals/OnboardingModal";
import { useUpdateDirectBillOnboardingMethod } from "@hotel-engine/react-query/directBillOnboarding/useUpdateDirectBillOnboardingMethod";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import storage from "@hotel-engine/storage";
import { useAppSelector } from "store/hooks";
import useAdminExperienceFlag from "../EnhanceAdminExperience/useAdminExperienceFlag";
import {
  getAdminOnboardingContents,
  personalTravelerOnboardingContent,
  travelerOnboardingContents,
} from "./constants";
import { FlexProAnnouncementModal } from "./FlexProAnnouncementModal";

const OnboardingModals = ({
  setDirectBillPopoverVisible,
}: { setDirectBillPopoverVisible: Dispatch<SetStateAction<boolean>> }) => {
  const user = useAppSelector((state) => state.Auth.user);
  const directBillQuery = useUpdateDirectBillOnboardingMethod();
  const companyFlexProFeatureEnabled = useIsFeatureFlagOn("flexpass");
  const flexProAnnouncementModalEnabled = useIsFeatureFlagOn("flexpro-announcement-modal");
  const { initialDisplayCriteria } = useAdminExperienceFlag();
  const [personalTravelOnboardingVisible, setPersonalTravelOnboardingVisible] = useState(
    !storage.getItem(`doNotShowPTOnboardingModal_${user?.id}`)
  );

  const showFlexProModal =
    companyFlexProFeatureEnabled &&
    flexProAnnouncementModalEnabled &&
    initialDisplayCriteria === false &&
    user?.role === "admin" &&
    !!user.business.flexAllowedBusiness &&
    !user.business.flexProEnrolled;

  const showDirectBillOnboarding =
    user?.directBill &&
    user?.business?.directBillEnabled &&
    !user?.directBillOnboarding?.onboardingModal;

  const showPersonalTravelOnboarding =
    user?.accountType === "personal" && user?.firstTimeSignIn && personalTravelOnboardingVisible;

  return (
    <>
      {!!showFlexProModal && <FlexProAnnouncementModal />}
      {!!showDirectBillOnboarding && (
        <OnboardingModal
          contents={
            user?.role === "admin"
              ? getAdminOnboardingContents(COMPANY_NAME)
              : travelerOnboardingContents
          }
          onClose={() => {
            setDirectBillPopoverVisible(true);
            directBillQuery.mutate({ onboardingModal: true });
          }}
        />
      )}
      {!!showPersonalTravelOnboarding && (
        <OnboardingModal
          contents={personalTravelerOnboardingContent(COMPANY_NAME, COMPANY_NAME)}
          onClose={() => {
            // Assures user won't see modal more than once on their device
            storage.setItem(`doNotShowPTOnboardingModal_${user?.id}`, "true");
            setPersonalTravelOnboardingVisible(false);
          }}
        />
      )}
    </>
  );
};

export default OnboardingModals;
