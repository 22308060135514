import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ampli } from "ampli";

import { useNavigate, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import { useCalendarTripsQuery } from "@hotel-engine/react-query/reservation/useCalendarTripsQuery";
import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { IUser } from "@hotel-engine/types/user";
import { formatDate } from "@hotel-engine/utilities";
import { Box, Button, Chip, IconButton, Typography } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

import { useAppSelector } from "store/hooks";

import { TravelerRow } from "./TravelerRow";
import { NoTripsMessageLegacy } from "../components";
import { filterDataByStatus, isReservationWithinActiveDates, returnRows } from "./helpers";
import * as Styled from "./styles";
import { CalendarSkeleton } from "./CalendarSkeleton";
import { useTripsLegacyContext } from "../contextLegacy";

export const calendarViewTripTypes = {
  all: "all",
  visiting: "today",
  completed: "past",
  booked: "upcoming",
} as const;

export type TripsFilterStatus = (typeof calendarViewTripTypes)[keyof typeof calendarViewTripTypes];

export interface ITripsCalendarViewProps {
  /** Active user */
  user: IUser;
}

const TripsCalendarView = ({ user }: ITripsCalendarViewProps) => {
  const [_, setSearchParams] = useSearchParams();
  const { tokens } = useGlobalTheme();
  const [selectedContract, setSelectedContract] = useState<string>();
  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const {
    filters: { department_names: departmentNames, ...filtersCleared },
    showPreview,
    status: currentFilterStatus,
    sortCalendar,
  } = tripsState;
  // Holds the current week in state
  const [currentWeek, setCurrentWeek] = useState(moment().week());

  // Used for offset calculations and display purposes
  const startOfWeek = moment().week(currentWeek).weekday(0).startOf("day");
  const endOfWeek = moment().week(currentWeek).weekday(6).startOf("day");
  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";
  const navigate = useNavigate();

  const {
    data: calendarTripData = [],
    isFetched: calendarIsFetched,
    meta: calendarMetadata,
  } = useCalendarTripsQuery({
    startDate: startOfWeek.format("YYYY-MM-DD"),
    endDate: endOfWeek.format("YYYY-MM-DD"),
    ...filtersCleared,
    search: filtersCleared.search ? [String(filtersCleared.search)] : undefined,
    departments: departmentNames,
    sort: sortCalendar,
  });

  /** This will watch the selectedContract set in state and refetch the current previewedReservation for that contractNumber whenever it updates */
  const { data: previewedReservation } = useReservationQuery({
    id: selectedContract,
  });

  const setQueryParams = (id: string) => {
    if (id !== showPreview?.id) {
      setSearchParams({ booking_number: String(id) });
    } else {
      setSearchParams({});
    }
  };

  const handleSelectTrip = (id: string) => {
    if (id === selectedContract) {
      setSelectedContract(undefined);
    } else {
      setSelectedContract(id);
    }

    tripsDispatch({
      type: "rowSelection",
      record: { id: id, bookingType: "lodging" },
    });

    setQueryParams(id);
  };

  /** This will check if an incoming reservation from props or tripsState is within the active date range of the calendar view. If it is, it will set the current week to the week of the reservation and setSelectedContract to the contractNumber of the incoming reservation. If it is not, it will unset the reservation from tripsState so the panel will not show. */
  const setIncomingReservation = (res: IReservationBase) => {
    if (isReservationWithinActiveDates(res.checkIn)) {
      setCurrentWeek(moment(res.checkIn).week());
      setSelectedContract(res.id);
    } else {
      tripsDispatch({
        type: "rowSelection",
        record: { id: res.id, bookingType: "lodging" },
      });

      setQueryParams(res.id);
    }
  };

  /** When a trip is selected, or an incoming trip is within the active date range, this will wait for the reservation query to get the full reservation for that contractNumber, check to make sure it is a different reservation than the one currently being displayed, and then updated tripsState with the reservation to show in the preview panel */
  useEffect(() => {
    if (previewedReservation?.id) {
      setIncomingReservation(previewedReservation);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewedReservation]);

  useEffect(() => {
    if (!showPreview?.id) {
      setSelectedContract(undefined);
    } else if (!!showPreview?.id) {
      setSelectedContract(String(showPreview?.id));
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreview?.id]);

  useEffect(() => {
    // sets numbers in status filter bar
    if (calendarMetadata) {
      const { active, cancelled, past, total, upcoming } = calendarMetadata;
      tripsDispatch({
        type: "updateStatusCounts",
        today: active,
        upcoming,
        past,
        cancelled,
        total,
      });
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarMetadata]);

  const visibleTrips = useMemo(() => {
    const statusMap = {
      today: "active",
      upcoming: "upcoming",
      past: "past",
      all: "all",
    };

    return filterDataByStatus(
      calendarTripData,
      statusMap[currentFilterStatus] as TripsFilterStatus
    );
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCalendar, calendarTripData, currentFilterStatus]);

  const rows = returnRows(visibleTrips);

  const hasCalendarEntries = visibleTrips.some((c) => !!c.contracts.length);

  const handleSetPast = (val: number) => {
    setCurrentWeek(val);

    if (val === moment().week() || val - (moment().week() - 1) === 0) {
      navigate(`${routes.trips.base}/all`);
    }
  };

  return (
    <Styled.Layout>
      <Styled.CalendarContainer>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Styled.CalendarNavBar>
            <Styled.NavButtonGroup>
              <Button
                data-testid="todays-week-button"
                aria-label="Jump to Today"
                onClick={() => setCurrentWeek(moment().week())}
                leadingIcon="calendar"
                variant="outlined"
              >
                Today
              </Button>
              <IconButton
                data-testid="week-backward-button"
                aria-label="Previous Week"
                disabled={moment().week() - (currentWeek - 1) > 2}
                onClick={() => {
                  handleSetPast(currentWeek - 1);
                  user && ampli.clickTripsPriorWeek({ userId: user.id });
                }}
                icon="chevron-left"
                color="primary"
                variant="outlined"
              />
              <IconButton
                data-testid="week-forward-button"
                aria-label="Next Week"
                disabled={currentWeek + 1 - moment().week() > 6}
                onClick={() => {
                  setCurrentWeek(currentWeek + 1);
                  user && ampli.clickTripsNextWeek({ userId: user.id });
                }}
                icon="chevron-right"
                color="primary"
                variant="outlined"
              />
            </Styled.NavButtonGroup>
            <Styled.WeekText data-testid="selected-week-text">
              {formatDate(startOfWeek, "ddd, MMM D", preferredDateFormat)} -{" "}
              {formatDate(endOfWeek, "ddd, MMM D", preferredDateFormat)}
            </Styled.WeekText>
          </Styled.CalendarNavBar>
          <Chip
            label={`Showing hotels only in calendar view`}
            size="md"
            color="everlight"
            variant="strong"
            leadingIcon="circle-exclamation"
            style={{
              marginBottom: tokens.spacing[24],
              padding: `${tokens.spacing[20]} ${tokens.spacing[12]}`,
              border: `${tokens.borderWidth.normal} solid ${tokens.colors.sentimentHelpfulSubtle}`,
            }}
          />
        </Box>
        <Styled.WeekHeader>
          <Styled.HeaderCell>Travelers</Styled.HeaderCell>
          {new Array(7).fill("").map((__, index) => {
            const date = moment().week(currentWeek).weekday(index).format("D");
            const weekday = moment().week(currentWeek).weekday(index).format("ddd");

            return (
              <Styled.HeaderCell
                IsToday={moment().week(currentWeek).weekday(index).isSame(moment(), "day")}
                key={index}
              >
                <Styled.HeaderDateNum>{date}</Styled.HeaderDateNum>
                <Typography variant="body/md" color="foregroundPrimary">
                  {weekday}
                </Typography>
              </Styled.HeaderCell>
            );
          })}
        </Styled.WeekHeader>
        <Styled.CalendarRows>
          {!calendarIsFetched ? (
            <CalendarSkeleton />
          ) : hasCalendarEntries ? (
            rows.map((traveler, index) => {
              const key = `${Date.now()}-${index}`;
              const selectedItem = showPreview?.id;
              return (
                <TravelerRow
                  endOfWeek={endOfWeek}
                  key={key}
                  selectedContract={String(selectedItem)}
                  setSelectedContract={handleSelectTrip}
                  startOfWeek={startOfWeek}
                  traveler={traveler}
                />
              );
            })
          ) : (
            <NoTripsMessageLegacy isCalendarView />
          )}
        </Styled.CalendarRows>
      </Styled.CalendarContainer>
    </Styled.Layout>
  );
};

export default TripsCalendarView;
