import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import type { IOccupant } from "@hotel-engine/types/booking";

import * as Styled from "./styles";
import {
  dedupeTravelers,
  getTravelerList,
  getTravelerSheet,
  MAX_TRAVELERS_DISPLAYED,
} from "./helpers";

interface ITravelerInfoProps {
  travelers: IOccupant[];
  padTop?: boolean;
}

const Travelers = ({ travelers, padTop = true }: ITravelerInfoProps) => {
  if (!travelers) return null;

  const dedupedTravelers = dedupeTravelers(travelers);
  const showOverflowUI = travelers.length > MAX_TRAVELERS_DISPLAYED;

  const content = <Box paddingTop={24}>{getTravelerSheet(dedupedTravelers)}</Box>;

  const dialogTrigger = (
    <Styled.TravelerOverflowContent accessibilityLabel="button">
      <Box display="flex" flexDirection="row" alignItems="center" gap={12}>
        <Styled.OverflowIconBackground>
          <Icon name="user-group--solid" color="foregroundEverlight" size="md" />
        </Styled.OverflowIconBackground>
        <Typography variant="body/md" color="foregroundPrimary">
          {travelers.length} guests
        </Typography>
      </Box>
      <Icon name="chevron-right" color="foregroundEverdark" size="md" />
    </Styled.TravelerOverflowContent>
  );

  return !showOverflowUI ? (
    <Styled.TravelerCollapsibleContainer $padTop={padTop}>
      {getTravelerList(dedupedTravelers)}
    </Styled.TravelerCollapsibleContainer>
  ) : (
    <Box marginTop={24}>
      <ResponsiveAtlasDialog
        triggerComponent={dialogTrigger}
        title={`Travelers (${dedupedTravelers.length})`}
        content={content}
        isModal
      />
    </Box>
  );
};

export default Travelers;
