import { useAppSelector } from "store/hooks";

import { FixedSizeList } from "react-window";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import { useEffect, useState } from "react";
import type { IDepartmentRecord } from "@hotel-engine/types/department";
import { Checkbox, TextInput, Button, Box, Typography } from "@hotelengine/atlas-web";
import { useDepartmentsQuery } from "@hotel-engine/react-query/departments/useDepartmentsQuery";
import useSelectionHandler from "../useSelectionHandler";

// Item height plus marginBottom applied to item Box container
const DEPARTMENT_HEIGHT = 28 + 4;

const Departments = () => {
  const userIsAdmin = useAppSelector((state) => state.Auth.user?.role === "admin");
  const [searchValue, setDepartmentSearchValue] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentRecord[]>([]);
  const {
    state: { filters },
  } = useTripsLegacyContext();
  const handleSelection = useSelectionHandler();

  const departmentContainerHeight =
    filteredDepartments.length < 5 ? filteredDepartments.length * DEPARTMENT_HEIGHT : 160;

  const { data: departmentData } = useDepartmentsQuery({
    options: { enabled: userIsAdmin },
  });

  useEffect(() => {
    if (!!departmentData) {
      const searchPattern = new RegExp(searchValue, "gi");
      const departments = departmentData.filter(({ name }) => !!name.match(searchPattern));

      setFilteredDepartments(departments);
    }
  }, [departmentData, searchValue]);

  if (!userIsAdmin) return null;

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Departments
      </Typography>
      <Box display="flex" gap={12} flexDirection="column" marginTop={12}>
        {!!filters.department_names?.length && (
          <Button
            onClick={() => {
              handleSelection({ key: "department_names" }, filters.department_names);
              setDepartmentSearchValue("");
            }}
            className="dropdown-clear-button"
          >
            Clear
          </Button>
        )}
        {!!(filteredDepartments.length > 5 || searchValue) && (
          <TextInput
            data-testid="departmentSearch"
            onChange={(e) => setDepartmentSearchValue(e.target.value)}
            placeholder="Search"
            value={searchValue}
          />
        )}
        <FixedSizeList
          height={departmentContainerHeight}
          itemCount={filteredDepartments.length}
          itemData={filteredDepartments}
          itemKey={(index, data) => `${data[index].id}-${index}`}
          itemSize={DEPARTMENT_HEIGHT}
          overscanCount={30}
          width="100%"
        >
          {({ data, index, style }) => {
            if (!data) return null;

            const department = data[index];
            const selected = filters.department_names?.includes(department.name);

            return (
              <Box marginBottom={4} style={style} key={`department-${index}`}>
                <Checkbox
                  aria-labelledby={department.id}
                  isChecked={selected}
                  onCheckedChange={() =>
                    handleSelection({ key: "department_names" }, [department.name])
                  }
                >
                  {department.name}
                </Checkbox>
              </Box>
            );
          }}
        </FixedSizeList>
      </Box>
    </Box>
  );
};

export default Departments;
