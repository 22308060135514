import type { ITrip, ITripSectionList } from "@hotel-engine/types/trips";
import { format, parseISO } from "date-fns";

const now = new Date();

export function groupTripsByDay(trips: ITrip[]): ITripSectionList[] {
  const groupedTrips: { [day: string]: ITrip[] } = {};

  trips.forEach((trip) => {
    trip.bookings.forEach((booking) => {
      const date =
        booking.details.checkIn ||
        booking.details.slices?.[0]?.segments[0].origin.flightTime ||
        booking.details?.startTime;

      if (date) {
        const tripDate = parseISO(date);
        const sameYear = tripDate.getFullYear() !== now.getFullYear();

        const dayFormat = format(tripDate, sameYear ? "MMMM d, yyyy" : "MMM d");
        if (!groupedTrips[dayFormat]) {
          groupedTrips[dayFormat] = [];
        }
        groupedTrips[dayFormat].push({
          ...trip,
        });
      }
    });
  });

  return Object.entries(groupedTrips)
    .sort(([dateA], [dateB]) => parseISO(dateA).getTime() - parseISO(dateB).getTime())
    .map(([title, data]) => ({
      title,
      data,
    }));
}
