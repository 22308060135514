import type { ReactElement, ReactNode } from "react";
import React, { useEffect, useState } from "react";

import { getNestedProperty } from "@hotel-engine/common/FormikFields/helpers";
import { emailRegEx } from "@hotel-engine/utilities";
import { FormControl } from "@hotelengine/atlas-web";

import usePillsRulesEngine, { EnumPillClickState } from "./helpers/EffectRulesEngine";
import {
  EMAIL_NOT_UNIQUE_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  MAX_EMAILS_MESSAGE,
  NO_ERROR_MESSAGE,
  validateAll,
} from "./helpers/validation";
import * as Styled from "./styles";
import { noop } from "lodash";

const EMAIL_INPUT_KEY = "EMAIL_INPUT_KEY";

export interface IEmailsField {
  /** data for the specific field */
  field: {
    /** name of the field*/
    name: string;
    /** function to execute onBlur */
    onBlur: (eventOrPath: React.FormEvent<HTMLInputElement> | string) => void;
    /** function to execute onChange */
    onChange: (eventOrPath: React.FormEvent<HTMLInputElement> | string) => void;
    /** value of the field */
    value?: string;
  };
  /** data for the form */
  form: {
    /** validation errors */
    errors: { email?: string; emails?: string[]; additionalEmail?: string[] };
    /** is the user submitting */
    submitCount: number;
    /** are the fields touched */
    touched: { email?: boolean; emails?: boolean; additionalEmails?: boolean };
    /** values for the whole form */
    values: {
      email?: string;
      emails?: IEmailPillValue[];
      additionalEmails?: IEmailPillValue[];
    };
    /** can set field for any type in the formik initialValues */
    setFieldValue: (
      k: string,
      val?: string | string[] | IEmailPillValue | IEmailPillValue[]
    ) => void;
    /** tells formik to check validation for a specific field */
    setFieldTouched: (k: string, val?: boolean) => void;
  };
  /** if tracking submit attempts outside the same form, manually pass in that number */
  submitAttempts?: number;
  /** grab placeholder text */
  placeholder: string;
  /** you must present a value */
  value: IEmailPillValue[];
  className?: string;
  label: string | ReactElement;
  required?: boolean;
  match?: string;
  onChange: (e?: Event) => void;
  matchError?: boolean;
  help?: ReactNode;
  maxEmails: number;
  isWithAutoComplete?: boolean;
  setShowAlreadyInvitedError?: React.Dispatch<React.SetStateAction<boolean>>;
  hideHelpMessage?: boolean;
  existingEmails?: IEmailPillValue[];
}

export interface IEmailPillValue {
  key: string;
  value: string;
  valid: boolean;
  clickState: EnumPillClickState;
  lastErrorMessage: string;
}

/**
 * The `BatchEmailInput` component is a wrapper around a custom component.
 * It requires the use of formik and their `<Field />` component.
 *
 * @remarks Props are spread onto the HTMLInputElement
 * @example <Field component={InputField} label="Username" placeholder="username" name="username" value={values.somefield}/>
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=870%3A4 Design Specs}
 */
export const BatchEmailInput = ({
  field,
  form: { errors, submitCount, touched, values, setFieldValue, setFieldTouched },
  matchError,
  submitAttempts,
  placeholder,
  value,
  className,
  label,
  required,
  match,
  onChange,
  help: propsHelp,
  maxEmails,
  isWithAutoComplete,
  setShowAlreadyInvitedError = noop,
  hideHelpMessage = false,
  existingEmails,
}: IEmailsField) => {
  // rule engine
  const {
    clickState: { resetClickState, setClickState },

    pillManagement: {
      emailInputMode,
      resetFocus,
      allowPillClickable,
      allowPillEditable,
      recordTextSelection,
    },

    emailState: {
      setMyEmails,
      myEmails,
      emailsModified,
      emailsNotModified,
      hasEmailObject,
      resetEmailsObject,
      updateSingleEmailObject,
      updateEmailObject,
      removeFromEmailObject,
    },

    nodeReferences: { pillInputRef, newEmailInputRef, nodeEmailInputRef, ghostTextRef },

    updateOnRules: { updateMoreButtonAccumulator, updateEmailInputAccumulator },

    focusMimic: {
      outerContainerShouldBeFocused,
      outerContainerShouldNotBeFocused,
      isOuterContainerFocused,
    },

    newEmailInputControl: { expandNewEmailInput, isNewEmailInputIsReady },

    waterline: {
      resetWaterline,
      incrementWaterline,
      validUnderwater,
      invalidUnderwater,
      invalidOverTheWater,
    },
  } = usePillsRulesEngine({
    form: { touched, setFieldValue, setFieldTouched },
    value,
    maxEmails,
    existingEmails,
  });
  // state for managing backspace pill removing
  const [backspaceCount, setBackspaceCount] = useState(0);
  const [highlightedPill, setHighlightedPill] = useState<string>();

  // holds the value for a new user-defined email
  const [inputEmailValue, setInputEmailValue] = useState<IEmailPillValue>({
    value: "",
    key: `email-pill-${Math.random()}`,
    valid: false,
    clickState: EnumPillClickState.EMAIL_TEXT_CLICKABLE,
    lastErrorMessage: "",
  });

  // listen to touched.emails, important if user loses focus on new-email input while its empty but then user decideds to comes back into focus again
  useEffect(() => {
    if (touched.emails) {
      emailsModified();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched.emails]);

  // upon mounting find the label and insert handler to focus on email inputRef
  // formik doesn't allow me to do this without placing the name directly in the input which I cannot since that would put the whole validation lifecycle topsy-turvy
  useEffect(() => {
    const emailLabel = globalThis.document.querySelector("[for='emails']");

    emailLabel?.addEventListener("click", () => {
      newEmailInputRef?.current?.focus();
    });
    expandNewEmailInput();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // synchronizes myEmails to props.value when email has been submitted
  useEffect(() => {
    // if value changes then `setMyEmails` to `values.emails`
    // this helps us show users failed emails when the form is submitted

    if (value && value.length > 0 && values.emails) {
      if (Array.isArray(value)) {
        if (isWithAutoComplete) {
          setInputEmailValue((emailValue) => ({ ...emailValue, value: "" }));
        }
      }

      setMyEmails(values.emails);
    }

    // reset field if submit count increases and preempt further reset thereafter
    // will not infinitely update
    if (value && value.length === 0) {
      if (myEmails.length !== 0) {
        // resets emails list to empty
        setMyEmails([]);

        // will allow previously used emails to be entered as valid pills once more
        resetEmailsObject();

        // internal touched variable set to false
        emailsNotModified();

        // waterline gets set back to 25
        resetWaterline();
      }

      // new-email input with placeholder expands
      expandNewEmailInput();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, values.emails]);

  const resetHighlightedPill = () => {
    setBackspaceCount(0);
    setHighlightedPill(undefined);
  };

  useEffect(() => {
    // backspace highlights previous pill, second backspace removes it
    if (backspaceCount === 1) {
      if (0 < doNotShowEmails.length) {
        // last pill is the show more pill
        setHighlightedPill("show-more-pill");
      } else {
        setHighlightedPill(myEmails[myEmails.length - 1].key);
      }
    }

    if (backspaceCount === 2) {
      if (0 < doNotShowEmails.length) {
        // last pill is the show more pill
        handleDeleteOverflow();
      } else {
        handleDeletePill(myEmails[myEmails.length - 1].key);
      }
      resetHighlightedPill();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backspaceCount, setBackspaceCount]);

  // the default way other Hotelengine fields handle error / touched / validation flow
  const fieldTouched = getNestedProperty(touched, field.name, ".");
  const fieldError = getNestedProperty(errors, field.name, ".");
  const showValidation = submitCount || submitAttempts || fieldTouched;

  let validateStatus: undefined | "error" = showValidation && fieldError && "error";
  let help = (showValidation && fieldError) || propsHelp;
  // Custom matching validation due to this Yup issue https://github.com/jquense/yup/issues/504
  if (match && showValidation) {
    if (values[match] !== values[field.name]) {
      validateStatus = "error";
      help = matchError;
    }
  }

  // handler for the new email that the user has typed or pasted into new-email input
  const handleChangeEmailInput = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    return changeEmailInput(val, event);
  };

  // this function is used to handle new email input, it is also called when the user blurs from the new-email input
  const changeEmailInput = (val: string, event) => {
    emailsModified();
    const lastIndex = val.length - 1;
    const lastChar = val.charAt(lastIndex);

    const userAttemptingNewEmail = lastChar === " " || lastChar === "," || lastChar === ";";

    // CASE user is attempting to add another email
    const massagedEmailsResult = massageCSVEmails(val);
    const massagedEmails = massagedEmailsResult.length !== 0 ? massagedEmailsResult : [""];

    // the new emails to be added to myEmails
    const myNewEmails: IEmailPillValue[] = massagedEmails.map((email, index) => {
      const lengthMeasure = index + 1 + myEmails.length;

      // gather the conditions need to find out how valid is the email
      const isLessThanMaxEmails = lengthMeasure <= maxEmails;
      const isActualEmailFormat = emailRegEx.test(email);
      const massagedEmailsContainsDuplicate =
        massagedEmails.filter(
          (massagedEmail) => massagedEmail.toLowerCase() === email.toLowerCase()
        ).length > 1;
      const currentEmailsContainsDuplicate = myEmails
        .map((emailPill) => emailPill.value)
        .includes(email);
      const isUnique = !massagedEmailsContainsDuplicate && !currentEmailsContainsDuplicate;

      const isValid = isLessThanMaxEmails && isActualEmailFormat && isUnique;
      let lastErrorMessage;
      if (!isActualEmailFormat) {
        lastErrorMessage = INVALID_EMAIL_MESSAGE;
      } else if (!isLessThanMaxEmails) {
        lastErrorMessage = MAX_EMAILS_MESSAGE;
      } else if (!isUnique) {
        lastErrorMessage = EMAIL_NOT_UNIQUE_MESSAGE;
      } else {
        lastErrorMessage = NO_ERROR_MESSAGE;
      }

      return {
        value: email,
        key: `email-pill-${Math.random()}`,
        valid: isValid,
        clickState: EnumPillClickState.EMAIL_TEXT_CLICKABLE,
        lastErrorMessage,
        hasWaterLine: false,
      };
    });

    // get the initial valid emails up to but not including last
    const initialValidEmails = [...myNewEmails];
    let lastEmail: undefined | IEmailPillValue = undefined;
    if (0 < myNewEmails.length) {
      // exclude the last email into its own variable
      lastEmail = initialValidEmails.pop();
    }

    const newEmailValue = [...myEmails, ...initialValidEmails];

    if (lastEmail && userAttemptingNewEmail) {
      // Case user is attempting a new email
      newEmailValue.push(lastEmail);
      const newInputEmailValue = { ...inputEmailValue };
      newInputEmailValue.value = "";

      setInputEmailValue(newInputEmailValue);
    } else if (lastEmail) {
      // Case user is attempting to extend last email
      const newInputEmailValue = { ...lastEmail };

      setInputEmailValue(newInputEmailValue);
    }

    // update the data structure that tracks unique emails
    updateEmailObject(newEmailValue);

    setMyEmails(newEmailValue);

    // figure out if there is a defined nodeEmailInputRef, if there is means that we can use this to readjust length of input
    if (nodeEmailInputRef.current) {
      const width = nodeEmailInputRef.current?.offsetWidth;
      nodeEmailInputRef.current.style.width = `${width}px`;
    }

    // call the parent's injected onChange if it has one
    onChange && onChange(event);
  };

  const handleDeletePill = (findKey, event?: React.MouseEvent) => {
    // rules engine needs to know delete pill Clicked
    setClickState("deletePillButton", true);
    event?.stopPropagation();
    const emails = myEmails.filter(({ key }) => key === findKey);
    if (emails.length !== 1) {
      // should be in myEmails but if not then return
      return;
    }
    const email = emails[0].value.toLowerCase();

    // remove from object
    removeFromEmailObject(email);

    // remove from list of pills
    const newMyEmails = myEmails.filter(({ key }) => key !== findKey);
    const updatedErrors = validateAll(newMyEmails, hasEmailObject, maxEmails);
    emailsModified();
    setMyEmails(updatedErrors);

    updateMoreButtonAccumulator();
    setShowAlreadyInvitedError(false);
  };

  const handleDeleteOverflow = (ev?: React.MouseEvent) => {
    ev?.stopPropagation();

    // this will keep anything under the water line but time anything above the water
    const underwaterEmails = validUnderwater(myEmails);
    const invalidUnderwaterEmails = invalidUnderwater(myEmails);

    const updatedErrors = validateAll(
      [...underwaterEmails, ...invalidUnderwaterEmails],
      hasEmailObject,
      maxEmails
    );
    emailsModified();

    // rules engine needs to know that pills were deleted
    setClickState("deletePillButton", true);

    setMyEmails(updatedErrors);
  };

  /**
   * handler for editing a single email pill
   * @param event
   * @param findKey
   */
  const handleChangePill = (event: React.FormEvent<HTMLInputElement>, findKey) => {
    const val = event.currentTarget.value;
    let emailFound = myEmails.filter(({ key }) => key === findKey)[0];
    const emails = myEmails.map((email, index) => {
      if (findKey === email.key) {
        emailFound = email;
        return {
          ...email,
          value: val,
          valid: index + 1 <= maxEmails ? emailRegEx.test(val) : false,
        };
      } else {
        return email;
      }
    });

    if (emailFound) {
      updateSingleEmailObject(emailFound);
    }
    const updatedErrors = validateAll(emails, hasEmailObject, maxEmails);
    emailsModified();
    setMyEmails(updatedErrors);
  };

  // when the user begins a selection in the span get the indexes
  const handlePillInputMimic = (key: string, ev?: React.MouseEvent) => {
    if (isWithAutoComplete) {
      return;
    }
    ev?.stopPropagation();

    emailInputMode.current = "default";
    setClickState("pillInput", true);
    setClickState("moreButton", false);

    allowPillEditable(key);

    // PillInputMimic changes from a span to an input and this will allow us to transfer the selection from the span to input transformation
    recordTextSelection();
  };

  // when the user decides to stop typing out an email and removes focus from input this will be called
  const handleBlurEmailInput = (key: string) => {
    allowPillClickable(key);
  };

  // used by the components container (the whole thing) to remove focus from it
  const handleKeyPressRemoveFocus = (ev: React.KeyboardEvent) => {
    if (ev.key === "Escape") {
      updateMoreButtonAccumulator();
    }
  };
  const validUnderwaterEmails = validUnderwater(myEmails);
  const invalidUnderwaterEmails = invalidUnderwater(myEmails);

  const showEmails = [...validUnderwaterEmails, ...invalidUnderwaterEmails];
  const doNotShowEmails = invalidOverTheWater(myEmails);

  const emailsAreValid = !myEmails.some(({ valid }) => !valid);

  const formikErrors = fieldError && touched.emails;
  const isValid = emailsAreValid && !formikErrors;

  const handleKeyDownEmailInput = (ev: React.KeyboardEvent, key: string) => {
    const dir = ev.key;
    if (
      dir === "Backspace" &&
      showEmails.length > 0 &&
      (ev.target as HTMLInputElement).value.length === 0
    ) {
      setBackspaceCount((count) => count + 1);
    }
    if (backspaceCount > 0 && dir !== "Backspace") {
      // removes highlighted pill if user types in the input after hitting backspace
      resetHighlightedPill();
    }
    handleTransition(dir, key);
  };
  const handleKeyDownPillInput = (ev: React.KeyboardEvent, key: string) => {
    const dir = ev.key;
    const target = ev.target as HTMLInputElement;
    const val: string = target.value;

    if (dir !== "ArrowLeft" && dir !== "ArrowRight") {
      emailInputMode.current = "default";
      return;
    }

    if (emailInputMode.current === "whole-pill-selection") {
      // will allow user to use keyboard arrows to move cursor
      emailInputMode.current = "default";
    }
    if (!nodeEmailInputRef.current) {
      return;
    }

    const [start, end] = getNodeSelectionIndex(nodeEmailInputRef?.current);
    const goingLeft = start === end && start === 0;
    const goingRight = start === end && start === val.length;
    // HANDLE Arrow key press logic
    if (
      dir === "ArrowLeft" &&
      goingLeft &&
      emailInputMode.current !== "whole-pill-selection-next-left"
    ) {
      // CASE not in a transition state
      emailInputMode.current = "whole-pill-selection";
    } else if (
      dir === "ArrowRight" &&
      goingRight &&
      emailInputMode.current !== "whole-pill-selection-next-right"
    ) {
      // CASE not in a transition state
      emailInputMode.current = "whole-pill-selection";
    }
    if (dir === "ArrowLeft" && goingLeft && emailInputMode.current === "whole-pill-selection") {
      // CASE in transition state
      handleTransition(dir, key);
    }
    if (dir === "ArrowRight" && goingRight && emailInputMode.current === "whole-pill-selection") {
      // CASE in transition state
      handleTransition(dir, key);
    }
  };

  const handleTransition = (dir: string, key: string) => {
    if (dir !== "ArrowLeft" && dir !== "ArrowRight") {
      return;
    }
    const emailKeys = myEmails.map((email) => email.key);
    emailKeys.push(EMAIL_INPUT_KEY);
    let indexFound: null | number = null;
    for (let i = 0; i < emailKeys.length; i++) {
      if (emailKeys[i] === key) {
        indexFound = i;
        break;
      }
    }

    let nextIndex: null | number = null;
    if (dir === "ArrowLeft" && indexFound !== null) {
      nextIndex = (indexFound - 1) % emailKeys.length;
    }
    if (dir === "ArrowRight" && indexFound !== null) {
      nextIndex = (indexFound + 1) % emailKeys.length;
    }
    if (nextIndex !== null && emailKeys[nextIndex] === EMAIL_INPUT_KEY) {
      updateEmailInputAccumulator();
    } else {
      emailInputMode.current = "whole-pill-selection";
      if (nextIndex !== null && !emailKeys[nextIndex]) {
        updateEmailInputAccumulator();
        return;
      }
      nextIndex !== null && allowPillEditable(emailKeys[nextIndex]);
    }
  };
  return (
    <FormControl
      className={className}
      label={label}
      isRequired={required}
      status={validateStatus}
      errorText={!!hideHelpMessage ? null : help}
      data-testid="emailpills"
      data-private
    >
      <Styled.PillContainer
        $hasFocus={isOuterContainerFocused}
        $isValid={isValid}
        id={"emails"}
        tabIndex={0}
        onMouseDown={() => {
          expandNewEmailInput();
        }}
        onClick={() => {
          setClickState("container", true);
          setClickState("moreButton", false);
          updateEmailInputAccumulator();
        }}
        onBlur={() => {
          setClickState("containerFocus", false);
          setClickState("container", false);
          setClickState("moreButton", false);
        }}
        onKeyUp={handleKeyPressRemoveFocus}
        aria-label={"list-of-emails"}
        onFocus={() => {
          setClickState("containerFocus", true);
        }}
        className="batchEmails-pillContainer"
      >
        {showEmails.map(({ key, value: val, valid, clickState }, index) => {
          /**********  Email pill tag span element turned to input **********/
          if (clickState === EnumPillClickState.EMAIL_TEXT_EDIT) {
            return (
              <>
                <Styled.InvisibleText ref={ghostTextRef}>{val}</Styled.InvisibleText>
                <Styled.PillInput
                  key={key}
                  ref={(node) => pillInputRef(node, key)}
                  value={val}
                  onKeyDown={(ev) => handleKeyDownPillInput(ev, key)}
                  onKeyUp={handleKeyPressRemoveFocus}
                  onChange={(ev) => handleChangePill(ev, key)}
                  aria-label={`pillinput${index}`}
                  onBlur={() => {
                    setClickState("pillInput", false);
                    setClickState("moreButton", false);
                    setClickState("pillInputFocus", false);
                    updateMoreButtonAccumulator();
                    handleBlurEmailInput(key);
                  }}
                  onFocus={() => {
                    setClickState("pillInputFocus", true);
                    outerContainerShouldBeFocused();
                  }}
                ></Styled.PillInput>
              </>
            );
          }

          /**********  Email pill tag span is clickable. clicking turns it to an input **********/
          if (clickState === EnumPillClickState.EMAIL_TEXT_CLICKABLE) {
            return (
              <Styled.EmailTag
                label={val}
                color={valid ? "gray" : "red"}
                variant={highlightedPill === key ? "outlined" : "filled"}
                onBlur={() => {
                  setClickState("pillInput", false);
                  setClickState("moreButton", false);
                }}
                dismissibleIcon="xmark"
                onDismiss={() => {
                  setClickState("deletePillButton", true);
                  handleDeletePill(key);
                }}
                onClick={() => handlePillInputMimic(key)}
                role="listitem"
                key={key}
                aria-label={valid ? `validpill${index}` : `invalidpill${index}`}
                data-private
              />
            );
          }

          return <></>;
        })}

        {/********* will show more pills button for pills over 25 count *************/}
        {0 < doNotShowEmails.length && (
          <Styled.EmailTag
            label={`${doNotShowEmails.length} More`}
            color="red"
            variant={highlightedPill === "show-more-pill" ? "outlined" : "filled"}
            role="listitem"
            key={"show-more-pill"}
            aria-label={"press-to-show-more"}
            onClick={() => {
              setClickState("moreButton", true);

              // reset all water lines to false so that they all show
              const newEmailValue = myEmails.map((item) => ({
                ...item,
              }));
              setMyEmails(newEmailValue);
              incrementWaterline(doNotShowEmails.length);
              updateMoreButtonAccumulator();
            }}
            dismissibleIcon="xmark"
            onDismiss={handleDeleteOverflow}
          />
        )}

        <Styled.ListItem $isHidden={!isNewEmailInputIsReady}>
          {/** New-Email Input */}
          <Styled.NewEmailInput
            id="emailinput"
            $isHidden={!isNewEmailInputIsReady}
            role={"listitem"}
            onKeyDown={(ev: React.KeyboardEvent) => {
              handleKeyDownEmailInput(ev, EMAIL_INPUT_KEY);
            }}
            onBlur={(event) => {
              setClickState("emailInput", false);
              setClickState("moreButton", false);
              setClickState("emailInputFocus", false);
              updateMoreButtonAccumulator();
              outerContainerShouldNotBeFocused();

              // if user removes focus then attempt to turn value into pill, we will allow that even if validation is bad
              if (inputEmailValue.value !== "") {
                changeEmailInput(inputEmailValue.value + ";", event);
                setInputEmailValue({ ...inputEmailValue, value: "" });
              }
            }}
            ref={newEmailInputRef}
            aria-label={"emailinput"}
            onChange={handleChangeEmailInput}
            value={inputEmailValue.value}
            onFocus={() => {
              resetClickState();
              setClickState("emailInput", true);
              setClickState("emailInputFocus", true);
              outerContainerShouldBeFocused();
              expandNewEmailInput();

              emailInputMode.current = "default";
              resetFocus();
            }}
            placeholder={myEmails.length === 0 ? placeholder : ""}
            data-private
          />
        </Styled.ListItem>
      </Styled.PillContainer>
    </FormControl>
  );
};

const getNodeSelectionIndex = (node: HTMLInputElement | null) => {
  if (!node) {
    return [null, null];
  }
  const startIndex = node.selectionStart;
  const endIndex = node.selectionEnd;
  return [startIndex, endIndex];
};

/**
 *
 * Does something like .replaceAll(/spaces commas semicolons/ig, " ").trim().split(" ") or something like that. Unfortunately IE11 does not support replaceAll. This function will parse a string of a comma-separated (space-separate and semicolon-separated also) string into a list of yet-to-be-validated emails.
 * Its for people wanting to paste a list of emails into the component.
 * @param value
 * @returns
 */
const massageCSVEmails = (value: string) => {
  const separatorMatchRegex = new RegExp(/[ ,;\n]+/g);
  const results: string[] = value.replace(separatorMatchRegex, " ").trim().split(" ");

  return results;
};
