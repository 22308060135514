import { useState } from "react";
import { Typography } from "@hotelengine/atlas-web";

import { useFormikContext } from "formik";

import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import Button from "@hotel-engine/common/Button";
import { useBreakpoint } from "@hotel-engine/hooks";
import { colors } from "@hotel-engine/styles";
import type { IUserLoyaltyProgram } from "@hotel-engine/types/user";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import { Prefix } from "pages/Checkout/LegacyLodging/GuestSelection/Room/Prefix";
import LoyaltyForm from "pages/Checkout/LegacyLodging/GuestSelection/Room/LoyaltyForm";
import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";
import type { IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";

interface IExpressBookSelecteGuestProps {
  index: number;
  guestType: "primary" | "secondary";
  roomIsLoyaltyEligible?: boolean;
  propertyLoyaltyRewards?: IPropertyLoyaltyRewards;
}

export const ExpressBookSelectedGuest = ({
  index,
  guestType,
  propertyLoyaltyRewards,
  roomIsLoyaltyEligible,
}: IExpressBookSelecteGuestProps) => {
  const [showLoyaltyForm, setShowLoyaltyForm] = useState(false);

  const form = useFormikContext<IExpressBookCheckoutForm>();
  const user = useAppSelector((state) => state.Auth.user);
  const amplitudeAnalyticsProps = useAppSelector((state) => state.ExpressBook.analytics);
  const isMobile = useBreakpoint("lg", "max");

  const selectedUser = form.values.guestList[index][guestType].user;
  if (!selectedUser) return null;

  let displaySelectedUser: string | undefined = "";
  const { id, firstName, lastName, email, employeeId } = selectedUser;
  const isCurrentUser = user?.id === id;

  if (!firstName || !lastName) {
    displaySelectedUser = email;
  } else {
    displaySelectedUser = `${firstName} ${lastName}${isCurrentUser ? " (you)" : ""}`;
  }

  const guestIsLoyaltyEligible = roomIsLoyaltyEligible && guestType === "primary";

  // Determine whether loyalty rewards are from a guest, user, or member
  const selectedGuestLoyaltyRewards = (): IUserLoyaltyProgram[] => {
    if (Object.keys(selectedUser).includes("userLoyaltyRewards")) {
      return selectedUser["userLoyaltyRewards"] || [];
    } else if (Object.keys(selectedUser).includes("guestLoyaltyRewards")) {
      return selectedUser["guestLoyaltyRewards"];
    }
    return [];
  };

  const matchingLoyaltyReward =
    roomIsLoyaltyEligible &&
    selectedGuestLoyaltyRewards().find(
      (reward) => reward.loyaltyRewardId === propertyLoyaltyRewards?.id
    );

  const handleChangeGuestClick = () => {
    // Only reset the loyalty info if it's the primary user
    const newGuestListObject =
      guestType === "primary"
        ? {
            ...form.values.guestList[index],
            [guestType]: { user: undefined, mode: "search" },
            loyaltyInfo: null,
          }
        : {
            ...form.values.guestList[index],
            [guestType]: { user: undefined, mode: "search" },
          };

    if (guestType === "primary") {
      newGuestListObject.secondary = {
        user: undefined,
        mode: "hidden",
      };
    }

    ampli.clickSelectGuestNameDropdownMenuOnExpressBookModal({
      propertyId: amplitudeAnalyticsProps?.propertyId,
      searchId: amplitudeAnalyticsProps?.searchId,
    });

    form.setFieldValue(`guestList.${index}`, newGuestListObject);
  };

  const handleShowLoyaltyForm = () => {
    setShowLoyaltyForm(true);
    ampli.clickAddHotelLoyaltyProgram(amplitudeAnalyticsProps);
  };

  const handleCancel = () => {
    setShowLoyaltyForm(false);
    ampli.clickCancelLoyaltyNumber(amplitudeAnalyticsProps);
  };

  const renderSelectedGuestContent = () => (
    <Styled.GuestContentContainer data-testid="selected-guest">
      <Styled.GuestContentWrapper $isMobile={isMobile}>
        <Prefix guestType={guestType} index={index} />
        <Styled.GuestRoomWrapper>
          <Typography
            variant="body/sm"
            as={Styled.RoomTypography}
            margin={0}
            marginRight={8}
            marginLeft={12}
          >
            {displaySelectedUser}{" "}
            {!!matchingLoyaltyReward && (
              <span data-testid="select-loyalty-badge">
                <LoyaltyBadge
                  fill={colors.black}
                  style={{
                    margin: "0 4px",
                    verticalAlign: "middle",
                    width: "12px",
                    height: "12px",
                  }}
                />{" "}
              </span>
            )}
            {!!firstName && !!lastName && !isMobile && (
              <span data-testid={`selected-guest-email-span-${index + 1}`} data-private>
                {email}
              </span>
            )}
            {!!firstName && !!lastName && !isMobile && employeeId ? (
              <span>(EID: {employeeId})</span>
            ) : null}
          </Typography>
          {!!isMobile && (
            <Typography
              variant="body/sm"
              as={Styled.RoomTypography}
              margin={0}
              marginRight={8}
              marginLeft={12}
            >
              {!!firstName && !!lastName && employeeId ? <span>(EID:{employeeId})</span> : null}
            </Typography>
          )}
        </Styled.GuestRoomWrapper>
      </Styled.GuestContentWrapper>
      {index === 0 && (
        <Styled.ChangeButton
          data-testid="change-guest-btn"
          style={{ alignSelf: "flex-end" }}
          type="link"
          onClick={handleChangeGuestClick}
        >
          <span>Change Guest</span>
        </Styled.ChangeButton>
      )}
    </Styled.GuestContentContainer>
  );

  return (
    <Styled.SelectedGuestContainer>
      <Styled.SelectedGuest>{renderSelectedGuestContent()}</Styled.SelectedGuest>
      {!!guestIsLoyaltyEligible && (
        <Styled.AddHotelLoyalty
          renderForm={!!(showLoyaltyForm || form.values.guestList[index].loyaltyInfo)}
        >
          {!form.values.guestList[index].loyaltyInfo && !showLoyaltyForm && (
            <>
              <LoyaltyBadge fill={colors.purple[400]} />
              <Button type="link" onClick={handleShowLoyaltyForm} data-testid="add-loyalty-button">
                <Typography variant="body/sm" as={Styled.RoomTypography} margin={0} marginLeft={12}>
                  Add hotel loyalty program
                </Typography>
              </Button>
            </>
          )}
          {!!(showLoyaltyForm || form.values.guestList[index].loyaltyInfo) && (
            <LoyaltyForm
              handleCancel={handleCancel}
              index={index}
              selectedGuest={selectedUser}
              passedPropertyLoyaltyRewards={propertyLoyaltyRewards}
            />
          )}
        </Styled.AddHotelLoyalty>
      )}
    </Styled.SelectedGuestContainer>
  );
};
