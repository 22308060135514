import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { Typography } from "@hotelengine/atlas-web";
import { useMemo } from "react";

interface ICancellationTitle {
  isFlight?: boolean;
  isCar?: boolean;
  isCarInsurance?: boolean;
}

/** Basic title used for all cancellations, only broken out to avoid having to duplicate styling, Typography props */
const CancellationTitle = ({ isFlight, isCar, isCarInsurance }: ICancellationTitle) => {
  const { typographyVariants } = useTypographyScaling();

  const isNewType = isFlight || isCar || isCarInsurance;

  const title = useMemo(() => {
    if (isFlight) return "Cancel flight";
    if (isCar) return "Cancel car";
    if (isCarInsurance) return "Cancel coverage";

    return "Cancel booking";
  }, [isCar, isCarInsurance, isFlight]);

  return (
    <Typography
      as="h3"
      variant={typographyVariants.title}
      color="foregroundPrimary"
      style={{
        marginTop: isNewType ? 0 : 24,
        marginBottom: 24,
        paddingTop: 0,
        textAlign: isNewType ? "center" : "left",
      }}
    >
      {title}
    </Typography>
  );
};

export default CancellationTitle;
