import { Box, Button, DialogIcon, Icon, Typography } from "@hotelengine/atlas-web";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import { useTripsLegacyContext } from "pages/Trips/contextLegacy";

import ModalWrapper from "../ModalWrapper";
import { RefundInfo } from "./RefundInfo";
import * as Styled from "./styles";
import { useStatusModalCopy } from "./useStatusModalCopy";

/** Common loading/success modal that can be used across all modification types, will exit modificationView
 * flow upon closing modal
 */
const ModificationStatusModal = () => {
  const {
    state: { modificationInfo, modificationStatus },
    dispatch,
  } = useBaseModificationsContext();

  const isTripsFilteringImprovementsOn = useIsFeatureFlagOn("trips_filtering_improvements");

  const { dispatch: tripsDispatch } = useTripsLegacyContext();
  const { title, subtitle } = useStatusModalCopy();

  const isFlightCancellationRequest =
    !modificationInfo.refundAmount || !modificationInfo.refundType;

  const shouldShowRefundInfo =
    (["cancel", "modifyDates"].includes(String(modificationInfo.modificationType)) ||
      !isFlightCancellationRequest) &&
    modificationInfo.showRefundInfo !== false;

  const handleCloseStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: false,
    });
    dispatch({
      type: "SET_NAVIGATION_ROUTE",
      payload: undefined,
    });
    dispatch({
      type: "SET_MODIFICATION_INFO",
      payload: {
        refundAmount: undefined,
        refundType: undefined,
        roomsModified: 0,
        modificationType: undefined,
        bookingType: null,
      },
    });

    if (!isTripsFilteringImprovementsOn) {
      tripsDispatch?.({
        type: "setActiveModification",
        activeModification: { isActive: false, isNavigating: false },
      });
    }
  };

  const modalBody = modificationStatus.isLoading ? (
    <Styled.LoadingContainer>
      <Icon
        name="spinner-third"
        color="foregroundPrimary"
        animationStyle="spin"
        size="lg"
        style={{ marginBottom: 24 }}
        data-testid="itin-content-loader"
      />
      <Typography variant="heading/lg" color="foregroundPrimary">
        We are on it!
      </Typography>
      <Typography variant="body/md" color="foregroundSecondary">
        We are processing your request.
      </Typography>
    </Styled.LoadingContainer>
  ) : (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingBottom={16}
      >
        <DialogIcon sentiment="positive" />
      </Box>
      <Box display="flex" flexDirection="column" gap={24}>
        <div>
          <Styled.CenterParagraph variant="heading/lg" as="h2">
            {title}
          </Styled.CenterParagraph>
          {!!subtitle && (
            <Styled.CenterParagraph variant="body/md" as="p">
              {subtitle}
            </Styled.CenterParagraph>
          )}
        </div>
        {!!shouldShowRefundInfo && <RefundInfo />}
      </Box>
      <Button
        onClick={handleCloseStatusModal}
        data-testid="confirm-success-button"
        color="primary"
        style={{ width: "100%", marginTop: 32 }}
      >
        Got it
      </Button>
    </>
  );

  return (
    <ModalWrapper dataTestId="modification-status-modal" onCancel={handleCloseStatusModal}>
      {modalBody}
    </ModalWrapper>
  );
};

export default ModificationStatusModal;
