import { useEffect, useMemo, useState } from "react";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { ITrip } from "@hotel-engine/types/trips";
import { useTripsLegacyContext } from "../../contextLegacy";

export const usePaginationControls = () => {
  const [cachedTrips, setCachedTrips] = useState<ITrip[]>([]);
  const isMobileScreen = useBreakpoint("xxl", "max");

  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const { counts, trips, limit, status, page } = tripsState;

  const showInMemoryPagination = useMemo(() => ["all", "past"].includes(status), [status]);

  const isNextDisabled = useMemo(() => {
    if (showInMemoryPagination) {
      return trips.length < limit;
    }

    return page * limit >= counts[status];
  }, [showInMemoryPagination, page, counts, status, trips.length, limit]);

  useEffect(() => {
    if (isMobileScreen) return;

    if (showInMemoryPagination && trips) {
      setCachedTrips((prevTrips) => [...prevTrips, ...trips]);
    }
  }, [isMobileScreen, setCachedTrips, showInMemoryPagination, trips]);

  useEffect(() => {
    if (isMobileScreen) return;

    setCachedTrips([]);
    tripsDispatch({
      type: "pagination",
      page: 1,
      pageSize: limit,
    });
    tripsDispatch({ type: "resetPagination" });
  }, [status, tripsDispatch, isMobileScreen, setCachedTrips, limit]);

  return { isNextDisabled, showInMemoryPagination, cachedTrips };
};
