import { clone, mergeDeepRight } from "ramda";
import { useState } from "react";
import { useFormikContext } from "formik";
import { ampli } from "ampli";

import { Icon } from "@hotelengine/atlas-web";
import type { SelectValue } from "@hotel-engine/common/Select";
import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";

import { useRoomIsLoyaltyEligible } from "pages/Checkout/LegacyLodging/hooks/useRoomIsLoyaltyEligible";
import { isPrimaryGuestError } from "pages/Checkout/validation";
import { useLoyaltyRewards } from "pages/Checkout/LegacyLodging/hooks/useLoyaltyRewards";
import {
  ADD_GUEST_KEY,
  useGuestFormOptions,
} from "pages/Checkout/LegacyLodging/GuestSelection/Room/GuestFormOptions";
import { Prefix } from "pages/Checkout/LegacyLodging/GuestSelection/Room/Prefix";

import * as Styled from "./styles";
import { useAppSelector } from "store/hooks";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";

export const ExpressBookSearchGuest = ({
  index,
  guestType,
}: {
  index: number;
  guestType: "primary" | "secondary";
}) => {
  const [searchUser, setSearchUser] = useState("");
  const { propertyLoyaltyRewards } = useLoyaltyRewards();
  const isLoyaltyEligible = useRoomIsLoyaltyEligible();
  // Grabs necessary info for usage tracking //
  const amplitudeAnalytics = useAppSelector((state) => state.ExpressBook.analytics);

  const form = useFormikContext<IExpressBookCheckoutForm>();
  const { redirectToCheckout } = useExpressBook();

  let selectedUserId = -1;
  if (guestType === "secondary") {
    selectedUserId = form.values.guestList[index]["primary"].user?.id ?? -1;
  }

  const guestFormOptions = useGuestFormOptions(
    index,
    searchUser,
    selectedUserId,
    (isAdding: boolean) => {
      if (isAdding) {
        const guestList = clone(form.values.guestList);
        guestList[index][guestType].mode = "adding";

        const formValues = mergeDeepRight(form.values, { guestList });
        redirectToCheckout(formValues);
      }
    }
  );

  const mode = form.values.guestList[index][guestType].mode;

  const setGuestInFormik = (guest, rewards) => {
    if (guestType === "primary") {
      form.setFieldValue(`guestList.${index}`, {
        ...form.values.guestList[index],
        [guestType]: { user: guest, mode: "selected" },
        loyaltyInfo: rewards || null,
      });
    } else {
      form.setFieldValue(`guestList.${index}`, {
        ...form.values.guestList[index],
        [guestType]: { user: guest, mode: "selected" },
      });
    }
  };

  const handleSelect = (value: SelectValue, typedText: string) => {
    // TODO: Revisit ampli updates in future ticket
    ampli.editSearchOrAddNewGuest({
      typedText,
      autofillOptionSelected: JSON.stringify(value),
      ...amplitudeAnalytics,
    });

    if (typeof value !== "string" || value === ADD_GUEST_KEY) return;

    const guest = JSON.parse(value);

    const guestLoyaltyRewards = guest?.userLoyaltyRewards || guest?.guestLoyaltyRewards;

    const matchingLoyaltyRewards =
      isLoyaltyEligible &&
      guestLoyaltyRewards?.find(
        (reward) => reward.loyaltyRewardName === propertyLoyaltyRewards.name
      );

    // Only primary guests should set loyalty rewards
    setGuestInFormik(guest, matchingLoyaltyRewards);
    setSearchUser("");
  };

  const primaryGuestError = form.errors?.guestList?.[index];

  return (
    <>
      <Styled.SearchGuestAutoComplete
        dataSource={guestFormOptions}
        defaultActiveFirstOption={false}
        onSearch={(value) => setSearchUser(value)}
        onSelect={(value) => handleSelect(value, searchUser)}
        value={searchUser}
        $error={!!form.errors.guestList}
      >
        <Styled.SearchGuestInput
          prefix={<Prefix guestType={guestType} index={index} />}
          size="large"
          data-testid="guest-form-input"
          placeholder="Search or add new guest"
          suffix={mode[guestType] === "search" && <Icon name="chevron-down" />}
        />
      </Styled.SearchGuestAutoComplete>
      {guestType === "primary" && isPrimaryGuestError(primaryGuestError) && (
        <Styled.GuestError id="guest-error">{primaryGuestError?.primary?.user}</Styled.GuestError>
      )}
    </>
  );
};
