import { Popover, PopoverContent, PopoverTrigger } from "@hotelengine/atlas-web";
import type { ReactNode } from "react";

interface IBookingDetailsContainer {
  showBookingDetails: boolean;
  content: ReactNode;
  children: JSX.Element;
}

const BookingDetailsContainer = ({
  showBookingDetails,
  content,
  children,
}: IBookingDetailsContainer) => {
  return showBookingDetails ? (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>{content}</PopoverContent>
    </Popover>
  ) : (
    <div>{children}</div>
  );
};

export default BookingDetailsContainer;
