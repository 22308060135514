import { useEffect } from "react";

import { Alert, Box, toast, Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { ToastErrorMessage } from "@hotel-engine/app/ToastErrorMessage";
import { FlightsTriggerOnCancel } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

import type { IFlightItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/flights";

import CancellationTitle from "../common/CancellationTitle";
import CancellationButton from "../common/CancellationButton";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import { useCancelFlight } from "../../hooks/useCancelFlight";
import { usePreviewFlightRefund } from "../../hooks/usePreviewFlightRefund";
import { useSupportPhone } from "../../hooks/useSupportPhone";
import FlightInformation from "./components/FlightInformation";
import RefundInfo from "./components/RefundInfo";
import * as Styled from "./styles";
import { useCancellationMessage } from "./useCancellationMessage";

const FlightCancellation = () => {
  const { typographyVariants } = useTypographyScaling();
  const {
    isPreview,
    state: { showConfirmationModal },
    reservation,
    dispatch,
  } = useFlightModificationsContext();

  const {
    refundInfo,
    isLoading,
    isError: isRefundCalcError,
  } = usePreviewFlightRefund(
    (reservation as IFlightItineraryWithId).tripId,
    (reservation as IFlightItineraryWithId).bookingNumber
  );

  const cancellationAlertTitle = useCancellationMessage({
    refundInfo,
  });

  const cancelBySupportRequest =
    refundInfo?.triggerOnCancel === FlightsTriggerOnCancel.OpenSupportRequestToCancel;

  const cancellationButtonText = cancelBySupportRequest ? "Request cancellation" : "Cancel flight";
  const supportPhone = useSupportPhone();

  useEffect(() => {
    if (isRefundCalcError) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at ${supportPhone}.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [isRefundCalcError, supportPhone]);

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelFlight } = useCancelFlight({
    reservation: reservation as IFlightItineraryWithId,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(refundInfo?.refundAmount),
      refundType: refundInfo?.refundType,
    },
    triggerOnCancel: refundInfo?.triggerOnCancel,
    dispatch,
  });

  return (
    <Box backgroundColor="backgroundSecondary">
      <CancellationTitle isFlight />
      <Typography
        variant={typographyVariants.body}
        color="foregroundPrimary"
        as="p"
        marginBottom={16}
        style={{ textAlign: "center" }}
      >
        All passengers and flights will be cancelled from this booking. Cancellations cannot be
        reversed.
      </Typography>
      {!!cancellationAlertTitle && !isLoading && (
        <Alert sentiment="positive" variant="outlined" size="sm" title={cancellationAlertTitle} />
      )}
      <FlightInformation />
      <Styled.StickyContainer $isPreview={isPreview}>
        <RefundInfo charges={reservation.charges} isLoading={isLoading} refundInfo={refundInfo} />
        <CancellationButton text={cancellationButtonText} isRefundCalcLoading={isLoading} />
      </Styled.StickyContainer>
      {!!showConfirmationModal && (
        <CancelConfirmationModal
          onConfirm={submitCancelFlight}
          isNonAPIRequest={cancelBySupportRequest}
          isFlight
        />
      )}
    </Box>
  );
};

export default FlightCancellation;
