import type { IBooking } from "@hotel-engine/types/trips";
import { Box, Card, Chip, Divider, Icon, Typography } from "@hotelengine/atlas-web";
import { TravelersFooter } from "../TravelersFooter";
import { useTheme } from "styled-components";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";
import type { ICarDetails } from "@hotel-engine/types/cars/cars.shared";
import { useBreakpoint } from "@hotel-engine/hooks";

const CarCard = ({ travelers, details }: IBooking) => {
  const { colors, borderRadius, spacing } = useTheme();
  const { getAcrissCategoryType } = useAcrissInfo();
  const carDetails: ICarDetails = details as ICarDetails;
  const isMobile = useBreakpoint("md", "max");

  const sameMonth =
    new Date(carDetails.startTime).getMonth() === new Date(carDetails.endTime).getMonth();
  const checkIn = useFormatDate(details.startTime, "MMM D", true);
  const checkOut = useFormatDate(details.endTime, sameMonth ? "D" : "MMM D", true);

  if (!details?.car) return null;

  const carType = `${getAcrissCategoryType(details.car?.category, details.car?.type)}`;
  const carVariant = details.car.variant ? `${details.car.variant} or similar` : "";
  const isFullyCancelled = !!details.isFullyCancelled;

  return (
    <Card display="flex" flexDirection="column" style={{ width: "100%" }}>
      <Box display="flex" flexDirection="column" style={{ padding: "16px 12px 10px" }}>
        <Box display="flex" flexDirection="column" gap={8}>
          {!!isFullyCancelled && (
            <Box>
              <Chip
                label="CANCELLED"
                color="red"
                size="xs"
                leadingIcon="circle-exclamation"
                style={{ borderRadius: "4px" }}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="heading/lg" color="foregroundPrimary">
              {carType}
            </Typography>
            <Typography
              variant="body/sm"
              color={isMobile ? "foregroundSecondary" : "foregroundPrimary"}
            >
              {carVariant}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        as="img"
        height="120px"
        alt="car illustration"
        src={details.car.image}
        style={{
          objectFit: "contain",
          objectPosition: "center",
          marginBottom: spacing[16],
        }}
      />
      <Divider style={{ borderColor: colors.neutralN100 }} />
      <Box
        paddingLeft={12}
        paddingRight={12}
        paddingTop={16}
        paddingBottom={16}
        display="flex"
        flexDirection="row"
        gap={8}
      >
        <Box
          as="img"
          src={details.rentalCompany?.logo}
          alt="brand logo"
          height="12px"
          width="auto"
          style={{
            borderRadius: borderRadius[4],
            paddingTop: "2px",
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ flexGrow: 1, flexWrap: "wrap" }}
          gap={12}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={8}
          >
            <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
              {checkIn} - {checkOut}
            </Typography>
            <Icon name="circle-small" color="neutralN200" style={{ height: 5, width: 5 }} />
            <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
              {`${details.location?.pickUp.city}, ${details.location?.pickUp.state}`}
            </Typography>
          </Box>
          <Typography
            variant="uppercase/2xs-strong"
            color="foregroundSecondary"
            display="flex"
            alignItems="center"
          >
            #{details.carConfirmation}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        backgroundColor="neutralN500"
        paddingLeft={16}
        paddingTop={12}
        paddingBottom={12}
        gap={6}
        color="foregroundInverse"
      >
        <Icon name="user" size="sm" />
        <TravelersFooter travelers={travelers} />
      </Box>
    </Card>
  );
};

export default CarCard;
