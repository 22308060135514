import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";
import moment from "moment-timezone";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

const DEFAULT_FLEX_CUTOFF_TIME = 12;

type FlexShortenCopy = {
  // Travel credit refund copy
  flexTravelCreditCopy: string;
  // Original payment refund copy
  flexOriginalPaymentCopy: string;
  // The operationalFlexCutoffTime like '1pm'
  shortFlexCutOffTimeCopy: string;
};

export type FlexCutoffTimes = {
  // The business defined flex cutoff time that will be considered
  businessFlexCutoffTime?: number;
  // The derived custom flex cutoff time that will be functionally used after some config consideration
  operationalFlexCutoffTime: number;
  // Some copy helpers
  copy: FlexShortenCopy;
};

// This hook is used to help with general flex related functionality relative to a user or biziness
// It does not take a reservation into account
export const useFlexCutoffTime = () => {
  const isCustomFlexCutoffTimeEnabled = useIsFeatureFlagOn("custom_flex_cutoff_time");
  const user = useAppSelector((store) => store.Auth.user);
  const isFlex24hEnabled = useIsFeatureFlagOn("flex-policy-24h");

  const businessFlexCutoffTime = user?.business.flexCutoffTime;
  const operationalFlexCutoffTime =
    isCustomFlexCutoffTimeEnabled && businessFlexCutoffTime
      ? businessFlexCutoffTime
      : DEFAULT_FLEX_CUTOFF_TIME;

  let shortFlexCutOffTimeCopy = moment(operationalFlexCutoffTime, ["H"]).format("ha");
  // The above returns 'Invalid date' if the format is wrong, so manual catch
  if (shortFlexCutOffTimeCopy === "Invalid date") {
    console.warn(
      "Error formatting flex cutoff time with operationalFlexCutoffTime = ",
      operationalFlexCutoffTime
    );
    shortFlexCutOffTimeCopy = moment(DEFAULT_FLEX_CUTOFF_TIME, ["H"]).format("ha");
  }

  // Derive some of the copy output
  const flexShortenCutoffFullText = isFlex24hEnabled
    ? "24 hours of your actual checkout date"
    : "48 hours of your original checkout date";

  const flexTravelCreditCopy = `Adding Flex to your booking allows you to cancel your reservation by ${shortFlexCutOffTimeCopy} local time on check-in
    day for a refund. Depending on your hotel’s cancellation policy, you’ll either be reimbursed with the
    original form of payment or with travel credit. This benefit works for any individually booked,
    multi-night reservation and for any room, refundable or non-refundable. If you need to leave early,
    check out at the front desk so they have a record of your departure. Then shorten your trip directly
    from your ${COMPANY_NAME} itinerary page within ${flexShortenCutoffFullText} to be refunded
    with travel credit for any unused nights. Travel credit expires one year to the day of cancellation or
    shortening.`;

  const flexOriginalPaymentCopy = `Adding Flex to your booking allows you to cancel your reservation up to ${shortFlexCutOffTimeCopy} local time on
    check-in day for a refund to your original form of payment. This benefit works for any individually
    booked, multi-night reservation and for any room, refundable or non-refundable. If you need to
    leave early, check out at the front desk so they have a record of your departure. Then shorten your
    trip directly from your ${COMPANY_NAME} itinerary page within ${flexShortenCutoffFullText} to
    be refunded for any unused nights. `;

  const flexCutoffTimes: FlexCutoffTimes = {
    businessFlexCutoffTime,
    operationalFlexCutoffTime,
    copy: {
      shortFlexCutOffTimeCopy,
      flexTravelCreditCopy,
      flexOriginalPaymentCopy,
    },
  };

  return flexCutoffTimes;
};
