import { mergeDeepRight } from "ramda";

export type MergeStrategy = "shallow-merge" | "deep-merge";

// allowing 'any' for generic object type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectLike = { [key: string]: any };

export type MergeValue<Base, Update> = Base extends ObjectLike
  ? Update extends ObjectLike
    ? Base & Update
    : Update
  : Base;

export type DeepMerge<Base, Update> = {
  [K in keyof Base | keyof Update]: K extends keyof Base
    ? K extends keyof Update
      ? MergeValue<Base[K], Update[K]>
      : Base[K]
    : K extends keyof Update
      ? Update[K]
      : never;
};

export type ShallowMerge<Base, Update> = {
  [K in keyof Base | keyof Update]: K extends keyof Base
    ? Base[K]
    : K extends keyof Update
      ? Update[K]
      : never;
};

/**
 * Performs a shallow merge of two objects, only merging one level deep of nested objects
 * @param base - The base object to merge into
 * @param update - The object containing updates to apply
 * @returns A new object with the merged properties
 * @example
 * const base = { a: 1, b: { x: 1, y: 2 }, c: [1, 2] };
 * const update = { b: { x: 3 }, c: [3, 4] };
 * shallowMerge(base, update);
 * // Result: { a: 1, b: { x: 3 }, c: [3, 4] }
 */
function shallowMerge<Base extends ObjectLike, Update extends Partial<ObjectLike>>(
  base: Base,
  update: Update = {} as Update
): ShallowMerge<Base, Update> {
  const result = { ...base } as ShallowMerge<Base, Update>;

  for (const key in update) {
    if (Object.prototype.hasOwnProperty.call(update, key)) {
      result[key] = update[key];
    }
  }

  return result;
}

/**
 * Merges two objects using either shallow or deep merge strategy
 * @param base - The base object to merge into
 * @param update - The object containing updates to apply
 * @param strategy - The merge strategy to use ('shallow-merge' or 'deep-merge')
 * @returns A new object with the merged properties
 * @example
 * const base = { a: 1, b: { x: 1, y: 2 }, c: [1, 2] };
 * const update = { b: { x: 3 }, c: [3, 4] };
 *
 * // Deep merge (default)
 * mergeObjects(base, update);
 * // Result: { a: 1, b: { x: 3, y: 2 }, c: [3, 4] }
 *
 * // Shallow merge
 * mergeObjects(base, update, 'shallow-merge');
 * // Result: { a: 1, b: { x: 3 }, c: [3, 4] }
 */
function mergeObjects<
  Base extends ObjectLike,
  Update extends Partial<ObjectLike>,
  Strategy extends MergeStrategy,
>(
  base: Base,
  update: Update,
  strategy: Strategy = "deep-merge" as Strategy
): Strategy extends "shallow-merge" ? ShallowMerge<Base, Update> : DeepMerge<Base, Update> {
  if (strategy === "shallow-merge") {
    return shallowMerge(base, update);
  }

  return mergeDeepRight(base, update) as unknown as Base & Update;
}

export default mergeObjects;
