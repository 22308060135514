import { ampli } from "ampli";
import { useFormikContext } from "formik";
import config from "config";
import { Button, Typography } from "@hotelengine/atlas-web";
import { useNavigate, useParams } from "@hotel-engine/lib/react-router-dom";
import { useAppSelector } from "store/hooks";
import * as Styled from "./styles";
import type { ITripsSearch } from "../TripsFilters/SearchForm";
import type { TripsStatus } from "pages/Trips/data/querySchema";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { BASE_QUERY_PARAMS } from "pages/Trips/data/loader";

const votNoTripsCopy = (status: TripsStatus) => {
  switch (status) {
    case "all": {
      return "No trips";
    }
    case "today": {
      return "No trips today";
    }
    default: {
      return `No ${status} trips`;
    }
  }
};

const EXCLUDED_PARAMS = [
  ...Object.keys(BASE_QUERY_PARAMS),
  "timezone",
  "group",
  "view",
  "booking_type",
];

const NoTripsMessage = ({
  isCalendarView,
  isEmptyMapView,
  endOfList,
}: { isCalendarView?: boolean; isEmptyMapView?: boolean; endOfList?: boolean }) => {
  const { status } = useParams();
  const [params, tripsControl] = useTripsControl();
  const { handleReset: handleResetSearch } = useFormikContext<ITripsSearch>();
  const user = useAppSelector((state) => state.Auth?.user);
  const isVOT = user?.role === "view_only_traveler";
  const navigate = useNavigate();

  const filteredFilters = Object.keys(params).filter((key) => !EXCLUDED_PARAMS.includes(key));

  const navigateToDashboard = () => {
    navigate("/");
    ampli.clickLetsBookATrip({
      userId: user?.id,
    });
  };

  const clearTripsFilters = () => {
    const filtersToClear = filteredFilters.map((key) => key);

    tripsControl.unsetParams("search", ...filtersToClear);
    handleResetSearch();
  };

  if (!!filteredFilters.length && !isEmptyMapView) {
    return (
      <Styled.NoTripsMessage data-testid="no-trips-results" $isCalendarView={isCalendarView}>
        <img src={`${config.cdnHost}/assets/trips/empty-trips-filters.svg`} alt="No Trips" />
        <Typography variant="heading/md" color="foregroundPrimary" data-testid="no-trips-filters">
          No trips due to filters
        </Typography>
        <Button id="clear-filters" onClick={clearTripsFilters}>
          Clear filters
        </Button>
      </Styled.NoTripsMessage>
    );
  }

  return (
    <Styled.NoTripsMessage data-testid="no-trips-results" $isCalendarView={isCalendarView}>
      <img src={`${config.cdnHost}/assets/trips/empty-trips.svg`} alt="No Trips" />
      <Typography variant="heading/md" color="foregroundPrimary">
        {!!isVOT
          ? votNoTripsCopy(status as TripsStatus)
          : endOfList
            ? "You're all caught up"
            : "Feeling grounded?"}
      </Typography>
      {!isVOT && (
        <Button id="book-trip" onClick={navigateToDashboard}>
          Let's book a trip
        </Button>
      )}
    </Styled.NoTripsMessage>
  );
};

export default NoTripsMessage;
