import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import type { IDateMarker } from "@hotel-engine/app/RefundPolicy/types";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import moment from "moment-timezone";
import type { FlexCutoffTimes } from "./useFlexCutoffTime";
import { useFlexCutoffTime } from "./useFlexCutoffTime";

type UseFlexReservationShorten = (
  reservation: IReservationBase,
  timezone?: string
) => {
  // is flexEnabled && before end of day of flexShortenCutoffDate
  isFlexAndBeforeFlexExpiration: boolean;
  // Last day of the flex coverage period
  flexShortenCutoffDate: moment.Moment;
  // Some copy helpers for flex shorten
  // copy: FlexShortenCopy;
  // Refund date markers to be used in other areas
  shortenDateMarkers: IDateMarker | null;
  // The flex cutoff times and copy for the reservation
  flexCutoffTimes: FlexCutoffTimes;
};

// This hook is used to determine if the reservation is within the flex shorten period
// It is the flex related hook that hold functionality relative to a reservation: IReservationBase
export const useFlexReservationShorten: UseFlexReservationShorten = (
  reservation,
  timezone = "UTC"
) => {
  const isFlex24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const { cancelBy, checkIn, checkOut, flexEnabled, modificationActions, createdAt } = reservation;

  // Derive the cutoff time for flex protection and build flexCutoffTimes
  const flexCutoffTimes = useFlexCutoffTime();

  // Derive flexShortenCutoffDate: We allow the user to shorten their within 24 hours of their actual checkout date (48 with the feature flag off)
  const flexShortenCutoffDate = moment(checkOut)
    .endOf("day")
    .add(isFlex24hEnabled ? 1 : 2, "d");

  // Derive isFlexAndBeforeFlexExpiration from if flex enabled and before flex protection ending time
  const now = moment().tz(timezone).startOf("day");
  const isFlexAndBeforeFlexExpiration =
    flexEnabled && now.isSameOrBefore(flexShortenCutoffDate, "day");

  // Derive the shortenDateMarkers based on the reservation
  let shortenDateMarkers: IDateMarker | null = null;

  if (modificationActions?.cancellable) {
    // Supplier Upcoming refundable OR flex
    // .cancellable means by supplier policy or because flex enabled
    shortenDateMarkers = {
      start: moment(createdAt, "YYYY-MM-DD"),
      today: moment().startOf("day"),
      end: flexShortenCutoffDate, // IMPORTANT: May allow cancel when flex is not enabled @Eliana question
      policyExpiration: cancelBy ? moment(cancelBy, "YYYY-MM-DD") : null, // supplier cancelBy date only with supplier cancellable
    };
  } else if (isFlexAndBeforeFlexExpiration && modificationActions?.flexShortenable) {
    // Not cancelable by supplier but flex enabled
    // Flex after check-in AND Before flex shorten expiration
    shortenDateMarkers = {
      start: moment(checkIn, "YYYY-MM-DD"),
      today: moment().startOf("day"),
      end: flexShortenCutoffDate,
    };
  }

  return {
    isFlexAndBeforeFlexExpiration,
    flexShortenCutoffDate,
    shortenDateMarkers,
    flexCutoffTimes,
  };
};
