import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";

export const useCarsFF = () => {
  const user = useAppSelector((store) => store.Auth.user);

  return {
    isCarsEnabled: useIsFeatureFlagOn("cars-search-shop") && !user?.business.hideCars,
    isDirectBillEnabled: useIsFeatureFlagOn("cars_direct_bill"),
    isInsuranceEnabled: useIsFeatureFlagOn("cars_insurance"),
    isInsuranceWithDBEnabled: useIsFeatureFlagOn("cars_insurance_with_db"),
  };
};
