import { ampli } from "ampli";

import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { IUser } from "@hotel-engine/types/user";
import {
  Icon,
  BottomSheet,
  BottomSheetTrigger,
  BottomSheetContent,
  BottomSheetTitle,
  BottomSheetClose,
  Box,
  Button,
  useTheme,
} from "@hotelengine/atlas-web";

import { capitalize } from "../../../helpers";
import { useTripsLegacyContext } from "../../contextLegacy";
import { FilterPills } from "./FilterPills";
import { MobileTripsDropdown } from "./MobileTripsDropdown";
import * as Styled from "./styles";
import omitKeys from "./helpers/omitKeys";
import FiltersDropdown from "./FiltersDropdown";
import DropdownContent from "./FiltersDropdown/DropdownContent";
import SearchForm from "./SearchForm";

interface ITripsFiltersProps {
  isCalendarView?: boolean;
  isMapView?: boolean;
  isPreviewOpen?: boolean;
  user: IUser;
}

const TripsFilters = ({
  isCalendarView = false,
  isMapView = false,
  isPreviewOpen,
  user,
}: ITripsFiltersProps) => {
  const { tokens } = useTheme();

  const { state: tripsState, dispatch: tripsDispatch, setSearchValue } = useTripsLegacyContext();

  const filterStatuses = ["today", "upcoming", "past", "all"];
  const { filters, status, counts } = tripsState;

  const isMobile = useBreakpoint("xxl", "max");
  const isSmallMobileScreen = useBreakpoint("md", "max");
  const hideFiltersBreakpoint = isPreviewOpen ? "xxxxl" : "xxxl";
  const shouldHideFilters = useBreakpoint(hideFiltersBreakpoint, "max");

  const navigate = useNavigate();

  const handleFilterSelection = (e, value?: unknown | unknown[]) => {
    user &&
      ampli.clickCheckboxFilterInTrips({
        filterName: e.key,
        selectedFilter: !filters[e.key],
        userId: user.id,
      });

    const keys = {
      pendingModificationOnly: true,
      contractType: "multi_room", // this value needs to be snake_case
      refundableOnly: true,
      manual_bookings: true,
      unverified: true,
    };

    if (Array.isArray(value)) {
      tripsDispatch({
        type: "arrayFilter",
        key: e.key,
        values: value,
      });
    } else {
      tripsDispatch({
        type: "filter",
        filters: [{ key: e.key, value: keys[e.key] }],
      });
    }
  };

  const handleStatusClick = (nextStatus) => {
    user &&
      ampli.clickStateOfTrip({
        selectedOption: nextStatus,
        userId: user.id,
      });

    if (status !== nextStatus) {
      navigate(`${routes.trips.base}/${nextStatus}`);
    }
  };

  const handleClearFilters = () => {
    tripsDispatch({ type: "clearFilters" });
  };

  const showMobileFiltersDot =
    (!isMapView && !!Object.keys(omitKeys(filters, "search")).length) ||
    (isMapView && !!(Object.keys(omitKeys(filters, "search")).length > 1));

  const searchInput = (
    <Styled.MobileSearchContainer>
      <SearchForm isSmallMobileScreen={isSmallMobileScreen} />
      {!!isSmallMobileScreen && (
        <BottomSheet>
          <BottomSheetTrigger asChild>
            <Box paddingLeft={8} paddingRight={8} position="relative">
              <Icon name="sliders" color="foregroundPrimary" />
              {!!showMobileFiltersDot && <Styled.FiltersDot />}
            </Box>
          </BottomSheetTrigger>
          <BottomSheetContent
            style={{
              padding: `0 ${tokens.spacing[16]} ${tokens.spacing[16]} ${tokens.spacing[16]}`,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={8}
            >
              <BottomSheetTitle>Filters</BottomSheetTitle>
              <BottomSheetClose />
            </Box>
            <DropdownContent isCalendarView={isCalendarView} isMapView={isMapView} />
            <Button
              color="everdark"
              style={{ width: "100%", marginTop: tokens.spacing[20] }}
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </BottomSheetContent>
        </BottomSheet>
      )}
    </Styled.MobileSearchContainer>
  );

  return (
    <Styled.TripsFiltersContainer $isMobile={isSmallMobileScreen}>
      {!!isMobile && searchInput}
      <Styled.TripsFilters>
        <Styled.ButtonGroup style={isSmallMobileScreen ? { flex: 1 } : {}} columnGap="xsmall">
          {!!shouldHideFilters ? (
            <MobileTripsDropdown
              counts={counts}
              handleStatusClick={handleStatusClick}
              status={status}
            />
          ) : (
            <>
              {filterStatuses.map((filterStatus) => {
                return (
                  <Styled.StatusButton
                    id={filterStatus}
                    name={filterStatus}
                    key={filterStatus}
                    data-testid={"status-button-" + filterStatus}
                    variant="plain"
                    className={`${"status-button"} ${status === filterStatus ? "active" : ""}`}
                    onClick={() => handleStatusClick(filterStatus)}
                    isDisabled={!!tripsState.error}
                  >
                    {capitalize(filterStatus)}
                    {!!["today", "upcoming"].includes(filterStatus) && ` (${counts[filterStatus]})`}
                  </Styled.StatusButton>
                );
              })}
            </>
          )}
        </Styled.ButtonGroup>
        <Styled.ButtonGroup columnGap="small" style={{ alignItems: "flex-start" }}>
          {!isSmallMobileScreen && (
            <FiltersDropdown isCalendarView={isCalendarView} isMapView={isMapView} />
          )}
          {!isMobile && searchInput}
        </Styled.ButtonGroup>
      </Styled.TripsFilters>
      {!isSmallMobileScreen && (
        <FilterPills
          filters={filters}
          handleFilterSelection={handleFilterSelection}
          setSearchValue={setSearchValue}
          tripsDispatch={tripsDispatch}
          lodgingOnly={isCalendarView || isMapView}
        />
      )}
    </Styled.TripsFiltersContainer>
  );
};

export default TripsFilters;
