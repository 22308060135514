import { BottomSheet } from "react-spring-bottom-sheet";

import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "@hotelengine/atlas-web";
import React from "react";

export interface IModalWrapperProps {
  dataTestId: string;
  children: JSX.Element;
  closable?: boolean;
  onCancel?: () => void;
  title?: string;
}

/** A common component that will handle switching between modal and bottomsheet at the correct breakpoint and sets up
 * the closability and high level modal props. Still needs to be passed all content you want inside the modal/bottomsheet
 * as `children`.
 */
const ModalWrapper = ({ children, dataTestId, closable, onCancel, title }: IModalWrapperProps) => {
  const isMobile = useBreakpoint("md", "max");

  if (isMobile) {
    return (
      <>
        <Styled.GlobalBottomSheetStyles />
        <BottomSheet
          data-testid={dataTestId}
          open={true}
          blocking={true}
          title={title}
          onDismiss={() => (closable ? onCancel?.() : null)}
        >
          {!!title && (
            <Typography variant="heading/lg" style={{ paddingLeft: 16 }}>
              {title}
            </Typography>
          )}
          <Styled.BottomSheetContentContainer>{children}</Styled.BottomSheetContentContainer>
        </BottomSheet>
      </>
    );
  } else {
    return (
      <React.Fragment>
        <Dialog isOpen onOpenChange={(open) => !open && onCancel?.()}>
          <DialogContent>
            <DialogTitle>{title}</DialogTitle>
            <DialogClose onClick={onCancel} />
            <Box data-testid={dataTestId}>{children}</Box>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default ModalWrapper;
