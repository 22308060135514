import { useEffect, useRef } from "react";

import { ampli } from "ampli";
import { isEmpty } from "ramda";
import { useAppSelector } from "store/hooks";

import type { IAction } from "../../../../contextLegacy";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import preventPathTraversal from "../../helpers/preventPathTraversal";

function createSearchAction(value: string): IAction {
  if (value === "") {
    return {
      type: "filter",
      filters: [{ key: "search" }],
    };
  }
  return {
    type: "filter",
    filters: [{ key: "search", value }],
  };
}

function createResetPageAction(currentPageSize: number): IAction {
  return {
    type: "pagination",
    page: 1,
    pageSize: currentPageSize,
  };
}

function useSearchForTrips() {
  const user = useAppSelector((state) => state.Auth.user);
  const { state, dispatch, searchValue, setSearchValue } = useTripsLegacyContext();
  const currentPageSize = state.limit;
  // This will start as true so that we cannot trigger on initial page load
  const zeroResultsTriggered = useRef(true);

  useEffect(() => {
    if (!!user && state.trips.length === 0 && !state.loading && !zeroResultsTriggered.current) {
      ampli.searchTripsZeroResultsReturned({
        userId: user.id,
        business_id: user.business.id,
        role: user.role,
        platform: "web",
        typedText: searchValue,
        viewUrl: globalThis?.location.href,
        tripsTab: state.status,
        searchResponse: JSON.stringify(state),
      });

      zeroResultsTriggered.current = true;
    }
  }, [searchValue, state, user]);

  const getUpdatedQuery = (typedText: string) => {
    const sanitizedQuery = preventPathTraversal(typedText);
    if (isEmpty(sanitizedQuery)) return "";

    return sanitizedQuery;
  };

  const triggerSearch = (typedText: string) => {
    const trimmedInput = typedText.trim();
    const performSearch = createSearchAction(trimmedInput);
    const performResetPage = createResetPageAction(currentPageSize);

    dispatch(performSearch);
    dispatch(performResetPage);
    setSearchValue(trimmedInput);
    zeroResultsTriggered.current = false;

    if (user) {
      ampli.editSearchByNameOrLocationInTrips({
        typedText: trimmedInput,
        userId: user.id,
      });
    }
  };

  return { triggerSearch, getUpdatedQuery };
}

export { createSearchAction, createResetPageAction };
export default useSearchForTrips;
