import { ampli } from "ampli";
import { HELP_CENTER, routes } from "@hotel-engine/constants";
import { COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";

import type { IUser } from "@hotel-engine/types/user";

interface IGetFooterSectionArgs {
  user: IUser;
  faqURL: string;
  flexProFeatureOn: boolean;
}

export interface IFooterSection {
  title: string;
  links: {
    label: string;
    href: string;
    external?: boolean;
    onClick?: () => void;
    shouldShow: boolean;
  }[];
}

export const getFooterSections = ({
  user,
  faqURL,
  flexProFeatureOn,
}: IGetFooterSectionArgs): IFooterSection[] => {
  return [
    {
      title: "Company",
      links: [
        {
          label: "About Us",
          href: COMPANY_ROUTES.aboutUs,
          external: true,
          shouldShow: true,
        },
        {
          label: "Careers",
          href: COMPANY_ROUTES.careers,
          external: true,
          shouldShow: true,
        },
        {
          label: "Blog",
          href: COMPANY_ROUTES.library,
          external: true,
          shouldShow: true,
        },
      ],
    },
    {
      title: "Flex",
      links: [
        { label: "Flex FAQ", href: "/flex-overview", external: true, shouldShow: true },
        {
          label: "FlexPro FAQ",
          href: "/flexpro-overview",
          external: true,
          shouldShow: flexProFeatureOn,
        },
      ],
    },
    {
      title: "Support",
      links: [
        { label: "Help Center", href: HELP_CENTER, external: true, shouldShow: true },
        { label: "FAQ", href: faqURL, external: true, shouldShow: true },
        {
          label: "Contact Us",
          href: COMPANY_ROUTES.contactUs,
          external: true,
          shouldShow: true,
        },
        {
          label: "Groups",
          href: routes.groups.base,
          onClick: () =>
            ampli.clickStartNewSpecialBookingRequest({ source: "footer", userId: user?.id }),
          shouldShow: true,
        },
        {
          label: "Terms & Conditions",
          href: COMPANY_ROUTES.terms,
          external: true,
          shouldShow: true,
        },
      ],
    },
    {
      title: "Rewards",
      links: [
        { label: "About Rewards", href: routes.rewardsEnrollment, shouldShow: true },
        { label: "Rewards FAQ", href: routes.rewardsFaq, shouldShow: true },
        { label: "Rewards Terms", href: routes.rewardsToc, shouldShow: true },
      ],
    },
  ];
};
