import { useTranslation } from "react-i18next";

import { isCarsTranslationKey } from "../utilities";

export const useTransmission = () => {
  const { t, i18n } = useTranslation("cars");

  return {
    getTransmission: (code: string) => {
      const translationKey = `transmission.${code}`;

      return isCarsTranslationKey(translationKey, i18n) ? t(translationKey, "") : undefined;
    },
  };
};
