import { COMPANY_NAME_POSSESSIVE, COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";
import * as Styled from "./styles";

const PolicyTerm = () => {
  return (
    <Styled.TermsText variant="body/xs">
      By continuing, you agree to {COMPANY_NAME_POSSESSIVE}
      <Styled.SmallLink href={COMPANY_ROUTES.terms} target="_blank" rel="noreferrer noopener">
        {" "}
        Terms and Conditions
      </Styled.SmallLink>{" "}
      and&nbsp;
      <Styled.SmallLink href={COMPANY_ROUTES.privacy} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </Styled.SmallLink>
      .
    </Styled.TermsText>
  );
};

export default PolicyTerm;
