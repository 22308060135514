import { routes } from "@hotel-engine/constants";
import { useNavigate, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import { ampli } from "ampli";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";

function useMonthlyStatsEmailTracking() {
  const user = useAppSelector((state) => state.Auth.user);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const isMonthlyStatsEmailClick = searchParams.get("monthly_stats_email");

    if (isMonthlyStatsEmailClick && user?.id) {
      ampli.clickMonthlyStatsEmailTrips({ userId: user.id });
      navigate(`${routes.trips.base}/all`, { replace: true });
    }
  }, [navigate, searchParams, user?.id]);
}

export default useMonthlyStatsEmailTracking;
