import { useSearchParams } from "@hotel-engine/lib/react-router-dom";
import { useCallback, useMemo, useState, useEffect } from "react";

import type { DeepPartial } from "redux";

import type { MergeStrategy } from "../utils/mergeObjects";
import mergeObjects from "../utils/mergeObjects";
import { TRIPS_DEFAULT_VIEW, type BaseQueryParams, type TripsFilter } from "../data/querySchema";
import { BASE_QUERY_PARAMS, BLOCKED_PARAMS } from "../data/loader";
import serializeQueryParams from "../utils/serializeQueryParams";
import { decomposeFilterKeys, transformFilterKeys } from "../utils/filterKeys";
import deserializeQueryParams from "../utils/deserializeQueryParams";

export type TripsView = "list" | "map" | "calendar";

export interface ITripsState extends TripsFilter, BaseQueryParams {
  view: TripsView;
}

export type TripsParams = DeepPartial<ITripsState>;

export type FlexibleTripsParams = TripsParams & { [key: string]: unknown };

export type NextInit = FlexibleTripsParams | ((prev: TripsParams) => TripsParams);

export interface ISetParamsOptions {
  mergeStrategy?: MergeStrategy;
}

export type SetTripsParams = (nextInit: NextInit, options?: ISetParamsOptions) => void;

// (string & {}) allows arbitrary strings in addition to typed keys
// eslint-disable-next-line @typescript-eslint/ban-types
export type UnsetTripsParams = (...params: (keyof ITripsState | (string & {}))[]) => void;

export type ITripsControl = [
  NonNullable<FlexibleTripsParams>,
  {
    setParams: SetTripsParams;
    unsetParams: UnsetTripsParams;
  },
];

const baseParams = { ...BASE_QUERY_PARAMS, view: TRIPS_DEFAULT_VIEW };

/**
 * Parses the raw URL search parameters into a structured TripsRequestParams object.
 *
 * @param rawObject - The URLSearchParams object containing the raw query parameters.
 * @returns An object of type TripsQueryParams with the parsed parameters.
 */
function parseParams(rawObject: URLSearchParams): FlexibleTripsParams {
  const params = deserializeQueryParams(rawObject.toString());

  return transformFilterKeys({ ...baseParams, ...params });
}

/**
 * Parses the raw URL search parameters into a structured TripsRequestParams object.
 *
 * @param rawObject - The URLSearchParams object containing the raw query parameters.
 * @returns An object of type TripsQueryParams with the parsed parameters.
 */
function buildParams(rawObject: TripsParams = {}): URLSearchParams {
  const finalParams = transformFilterKeys(rawObject);
  const serialized = serializeQueryParams(finalParams);

  return new URLSearchParams(serialized);
}

/**
 * Applies updates to the current trip parameters while respecting merge strategies and blocked parameters
 *
 * @param currentParams - The current state of trip parameters
 * @param nextInit - Either new parameters object or a function that returns new parameters
 * @param mergeStrategy - Optional strategy for merging current and new parameters
 * @returns Updated trip parameters with blocked parameters removed
 */
function applyUpdatedParams(
  currentParams: TripsParams,
  nextInit: NextInit,
  mergeStrategy?: MergeStrategy
): TripsParams {
  const nextParams = typeof nextInit === "function" ? nextInit(currentParams) : nextInit;
  const unifiedParams = mergeObjects(currentParams, nextParams, mergeStrategy);
  const paramsWithFilters = transformFilterKeys(unifiedParams);

  for (const blocked of BLOCKED_PARAMS) {
    if (blocked in paramsWithFilters) {
      delete paramsWithFilters[blocked];
    }
  }

  return paramsWithFilters;
}

/**
 * Filters URL search parameters to retain only the base parameters defined in baseParams
 *
 * @param current - The current URLSearchParams object to filter
 * @returns A new URLSearchParams object containing only the base parameters
 */
function retainBaseParams(current: URLSearchParams) {
  const parsedParams = parseParams(current);
  const cleanedParams = Object.keys(parsedParams).reduce((acc, key) => {
    if (key in baseParams) {
      acc[key] = parsedParams[key];
    }
    return acc;
  }, {});

  return buildParams(cleanedParams);
}

/**
 * Hook for managing trip parameters and controlling trip-related state.
 *
 * @returns A tuple containing the current trip parameters and the actions (setParams, unsetParams).
 */
function useTripsControl(): ITripsControl {
  const [searchParams, setSearchParams] = useSearchParams();
  const [optimisticParams, setOptimisticParams] = useState(() =>
    decomposeFilterKeys(parseParams(searchParams))
  );

  const setParams: SetTripsParams = useCallback(
    (nextInit, options) => {
      setOptimisticParams((current) => {
        const updatedParams = applyUpdatedParams(current, nextInit, options?.mergeStrategy);

        return decomposeFilterKeys(updatedParams);
      });

      setSearchParams((current) => {
        const parsedParams = parseParams(current);
        const decomposedParams = decomposeFilterKeys(parsedParams);
        const updatedParams = applyUpdatedParams(
          decomposedParams,
          nextInit,
          options?.mergeStrategy
        );

        return buildParams(updatedParams);
      });
    },
    [setSearchParams]
  );

  const unsetParams: UnsetTripsParams = useCallback(
    (...params) => {
      setOptimisticParams((current) => {
        const newParams = { ...current };

        if (!params.length) {
          return Object.keys(newParams).reduce((acc, key) => {
            if (key in baseParams) {
              acc[key] = newParams[key];
            }

            return acc;
          }, {});
        }

        for (const key of params) {
          if (!BLOCKED_PARAMS.includes(key as (typeof BLOCKED_PARAMS)[number])) {
            delete newParams[key];
          }
        }

        return newParams;
      });

      setSearchParams((current) => {
        if (!params.length) {
          return retainBaseParams(current);
        }

        for (const key of params) {
          if (!BLOCKED_PARAMS.includes(key as (typeof BLOCKED_PARAMS)[number])) {
            current.delete(key);
          }

          const prefix = `filter[${key}]`;

          for (const paramName of Array.from(current.keys())) {
            if (paramName === prefix || paramName.startsWith(`${prefix}[`)) {
              current.delete(paramName);
            }
          }
        }

        return current;
      });
    },
    [setSearchParams]
  );

  /**
   * keeps optimistic params synchronized with URL search parameters.
   * this ensures that params stay hydrated when search params change
   * (e.g., browser navigation, direct URL changes).
   */
  useEffect(() => {
    const parsedParams = parseParams(searchParams);
    const decomposedParams = decomposeFilterKeys(parsedParams);

    setOptimisticParams(decomposedParams);
  }, [searchParams]);

  return useMemo(() => {
    const modifiers = { setParams, unsetParams };

    return [optimisticParams, modifiers];
  }, [optimisticParams, setParams, unsetParams]);
}

export default useTripsControl;
