import styled, { createGlobalStyle } from "styled-components";

import { Box, type BoxProps } from "@hotelengine/atlas-web";

export const GlobalBottomSheetStyles = createGlobalStyle`
  [data-rsbs-overlay] {
    z-index: ${({ theme }) => theme.zIndex.modal}
  }
`;

const BottomSheetContentContainer = styled<React.FC<BoxProps>>(Box)`
  text-align: left;
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]}`};
  margin-bottom: ${({ theme }) => theme.spacing[6]};

  .ant-btn {
    height: ${({ theme }) => theme.spacing[48]};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius[12]};
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[16]};
    }
  }
`;

export { BottomSheetContentContainer };
