import type { ICarItinerary } from "@hotel-engine/types/itinerary";
import moment from "moment";
import type { ICarsEntry } from "pages/Cars/Search/types";

export const constructCarsEntryFromCarsItinerary = (
  carItinerary: ICarItinerary
): Pick<ICarsEntry, "vehicleDetails" | "paymentType" | "cancelType"> => {
  return {
    cancelType: carItinerary.cancelType,
    paymentType: carItinerary.paymentType,
    vehicleDetails: {
      bagCount: carItinerary.car.luggageCapacity,
      description: carItinerary.car.variant,
      doorCount: 0,
      imageUrl: carItinerary.car.image,
      seatCount: carItinerary.car.passengerCapacity,
      transmissionCode: carItinerary.car.transmission,
      categoryCode: carItinerary.car.category,
      typeCode: carItinerary.car.type,
      unlimitedMileage: carItinerary.car.unlimitedKm,
    },
  };
};

export function isPastBooking(reservation: ICarItinerary): boolean {
  return !moment().isBefore(reservation.startTime);
}

export function hasCancellationAllowed(reservation: ICarItinerary): boolean {
  return Boolean(
    moment().isBefore(reservation.startTime) &&
      !reservation.isFullyCancelled &&
      // The || true can be removed once this field is added to the endpoint, will always show the cancel option for now, some might just error if there is no preview
      (reservation.modificationActions?.cancellable === undefined
        ? true
        : reservation.modificationActions?.cancellable)
  );
}
