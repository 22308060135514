import { useEffect } from "react";
import { isNaN } from "lodash";

import type { MapPosition } from "../../../types";
import OverlayView from "../../OverlayView";
import PropertyMarker from "../PropertyMarker";

type GoogleMapsPropertyMarkerProps = {
  /** the map object this marker is being applied to */
  map: google.maps.Map;
  /** the lat, lng of the marker */
  position: MapPosition;
};

const GoogleMapsPropertyMarker = ({ map, position }: GoogleMapsPropertyMarkerProps) => {
  /** If position is updated pan the map */
  useEffect(() => {
    const lat = typeof position.lat === "function" ? position.lat() : position.lat;
    const lng = typeof position.lng === "function" ? position.lng() : position.lng;
    if (!!map && !!position && !isNaN(lat) && !isNaN(lng)) {
      map.panTo(position);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return (
    <OverlayView position={position} map={map}>
      <PropertyMarker />
    </OverlayView>
  );
};
export default GoogleMapsPropertyMarker;
