import { DataTableGrid, useDataTable } from "@hotelengine/atlas-web";
import { useEffect } from "react";

const DataTableContent = ({
  tripIdentifier,
  rowSelectionState,
}: { tripIdentifier: string | null; rowSelectionState: Record<string, boolean> }) => {
  const { table } = useDataTable();
  const rowSelectionStateExists = !!Object.keys(rowSelectionState).length;

  useEffect(() => {
    if (!tripIdentifier || !!rowSelectionStateExists) {
      table?.resetRowSelection();
    }

    if (!!rowSelectionStateExists && !!tripIdentifier) {
      table?.setRowSelection(rowSelectionState);
    }
  }, [rowSelectionState, rowSelectionStateExists, table, tripIdentifier]);

  return <DataTableGrid />;
};

export default DataTableContent;
