import type { IBooking, ITrip } from "@hotel-engine/types/trips";
import type { TripsStatus } from "pages/Trips/data/querySchema";
import type { ITripsState } from "pages/Trips/hooks/useTripsControl";

interface IIdentifyMisplacedTrips {
  trips: ITrip[];
  status: TripsStatus;
  filters: ITripsState;
}

const now = new Date();

const getCheckInOutDates = (booking: IBooking) => {
  let checkIn: Date | null = null;
  let checkOut: Date | null = null;

  if (booking.bookingType === "lodging") {
    if (booking.details.checkIn) {
      checkIn = new Date(booking.details.checkIn);
    }

    if (booking.details.checkOut) {
      checkOut = new Date(booking.details.checkOut);
    }
  } else {
    if (booking.details.startTime) {
      checkIn = new Date(booking.details.startTime);
    }

    if (booking.details.endTime) {
      checkOut = new Date(booking.details.endTime);
    }
  }

  return { checkIn, checkOut };
};

const verifyIsPastTrip = (status: TripsStatus, checkIn: Date | null, checkOut: Date | null) => {
  switch (status) {
    case "today":
      if (!checkIn) return false;

      return checkIn.toDateString() === now.toDateString();

    case "past":
      if (!checkOut) return false;

      return now > checkOut;

    case "upcoming":
      if (!checkIn) return false;

      return checkIn > now;

    case "all":
      return true;

    default:
      return false;
  }
};

const matchesStatus = (status: TripsStatus, booking: IBooking) => {
  const { checkIn, checkOut } = getCheckInOutDates(booking);

  if (booking.bookingType === "flight") {
    return booking.details.slices?.every((slice) => {
      const startDate = new Date(slice.segments[0].origin.flightTime);
      const endDate = new Date(slice.segments[0].destination.flightTime);

      return verifyIsPastTrip(status, startDate, endDate);
    });
  }

  return verifyIsPastTrip(status, checkIn, checkOut);
};

const identifyMisplacedTrips = ({ trips, status, filters }: IIdentifyMisplacedTrips) => {
  const filterStartDate = filters["start_time_gt"]
    ? new Date(filters?.["start_time_gt"] as string)
    : null;
  const filterEndDate = filters["start_time_lt"]
    ? new Date(filters?.["start_time_lt"] as string)
    : null;

  const isTripMisplaced = (trip: ITrip) => {
    const matchesBookingType = (booking: IBooking) =>
      !filters["booking_type"] || filters["booking_type"].includes(booking.bookingType);

    const withinDateFilters = (booking: IBooking) => {
      const { checkIn, checkOut } = getCheckInOutDates(booking);

      if (!filterStartDate || !filterEndDate) return true;

      return !!checkIn && checkIn >= filterStartDate && !!checkOut && checkOut <= filterEndDate;
    };

    return !trip.bookings.some(
      (booking) =>
        matchesBookingType(booking) && matchesStatus(status, booking) && withinDateFilters(booking)
    );
  };

  return trips.filter(isTripMisplaced).map((trip) => ({
    tripId: trip.id,
    businessId: trip.businessId,
    status,
    message: `Incorrect trip ${trip.id} displayed`,
  }));
};

export default identifyMisplacedTrips;
