import { useMemo } from "react";
import moment from "moment";
import type { IFlightRefund } from "@hotel-engine/types/itinerary";
import type { ISummaryOfChargesItemProps } from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { formatCurrency } from "@hotel-engine/utilities";
import { useSupportPhone } from "@hotel-engine/app/ItineraryContent/shared/ModficationViews/components/CancelRouter/hooks/useSupportPhone";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

interface IDetails {
  key: string;
  items: ISummaryOfChargesItemProps[];
}

const getRefundMethod = (refund: IFlightRefund) => {
  if (Number(refund.airlineCreditAmount) > 0) {
    return "Airline credit";
  }

  if (refund.originalFormOfPayment) {
    return "Original form of payment";
  }

  return "-";
};

export interface IUseFlightRefundDetailsArgs {
  refunds?: IFlightRefund[];
  bookingFee?: string;
}

export const alertRefundTexts = {
  refundMinusFee:
    "We refunded your reservation amount, minus the booking fee, to your original payment method as per our cancellation policy.",
  nonRefundable: "Your fare was non-refundable.",
  airlineCreditRemeedDirectly: "Airline credit can only be redeemed through the airline directly.",
  refundBasedOnFarePolicies: "Refund based on fare policies. Booking fee is non-refundable.",
  contactSupport: (supportPhone: string) =>
    `The final refund amount will be reflected on your payment method’s billing statement. \nFor an estimated refund quote, please contact ${COMPANY_NAME} support at ${supportPhone}.`,
  unknown: "Refund amount unknown",
};

export const useFlightRefundDetails = ({ refunds, bookingFee }: IUseFlightRefundDetailsArgs) => {
  const supportPhone = useSupportPhone();

  const alert = useMemo(() => {
    const totalRefund = (refunds ?? []).reduce(
      (total, refund) => (total += Number(refund.totalRefund)),
      0
    );

    const hasRefund = totalRefund > 0;

    const hasNoTotalRefund =
      refunds?.every((refund) => refund.totalRefund === undefined || refund.totalRefund === null) ??
      false;

    if (refunds?.some((refund) => refund.originalFormOfPayment) && hasRefund) {
      return alertRefundTexts.refundBasedOnFarePolicies;
    }

    if (hasNoTotalRefund) {
      return alertRefundTexts.contactSupport(supportPhone);
    }

    if (totalRefund === 0) {
      return alertRefundTexts.nonRefundable;
    }

    if (bookingFee && parseInt(bookingFee) > 0 && hasRefund) {
      return alertRefundTexts.refundMinusFee;
    }

    if (refunds?.some((refund) => Number(refund.airlineCreditAmount) > 0)) {
      return alertRefundTexts.airlineCreditRemeedDirectly;
    }

    return alertRefundTexts.unknown;
  }, [bookingFee, refunds, supportPhone]);

  const breakdowns: IDetails[] = useMemo(() => {
    return (refunds || []).map((refundItem) => {
      return {
        key: refundItem.createdAt,
        items: [
          {
            label: "Refunded on",
            value: moment(refundItem.createdAt).format("MMMM D, YYYY"),
          },
          {
            label: "Refund method",
            value: getRefundMethod(refundItem),
          },
          {
            label: "Refund amount",
            value:
              refundItem.totalRefund !== undefined && refundItem.totalRefund !== null
                ? formatCurrency(refundItem.totalRefund)
                : "Unknown",
          },
        ].filter((refund) => Boolean(refund.value)),
      };
    }, []);
  }, [refunds]);

  return { breakdowns, alert };
};
