import { useMemo } from "react";

import { formatCurrency } from "@hotel-engine/utilities";
import moment from "moment";

import type { ISummaryOfChargesItemProps } from "../../../shared/SummaryOfChargesItem";
import type { ICarRefund } from "@hotel-engine/types/itinerary";

interface IDetails {
  key: string;
  items: ISummaryOfChargesItemProps[];
}

const typeMap = {
  normal_cancellation: "Cancellation",
  flex_cancellation: "Cancellation",
  manual_cancellation: "Cancellation",
  guest_only_cancellation: "Cancellation",
};

const getFullDate = (date: string | undefined) => {
  return moment(date).format("MMM D, YYYY");
};

const getRefundMethod = (refund: ICarRefund) => {
  if (Number(refund.originalFormOfPayment) > 0) {
    return "Original form of payment";
  }
  return "-";
};

export const useCarRefundDetails = (refunds?: ICarRefund[]) => {
  const details = useMemo(() => {
    return (refunds || []).reduce<IDetails[]>((acc, item) => {
      const totalRefund = [
        item.creditCardAmount,
        item.directBillAmount,
        item.rewardPointsDollarAmount,
        item.travelCreditAmount,
      ].reduce((sum, curr) => sum + Number(curr), 0);

      acc.push({
        key: item.createdAt,
        items: [
          {
            label: "Refund type",
            value: typeMap[item.action],
          },
          {
            label: "Refund date",
            value: getFullDate(item.createdAt),
          },
          {
            label: "Refund method",
            value: getRefundMethod(item),
          },
          {
            label: "Refund amount",
            value: totalRefund ? formatCurrency(totalRefund) : null,
          },
        ].filter((refund) => Boolean(refund.value)),
      });

      return acc;
    }, []);
  }, [refunds]);

  return { details };
};
