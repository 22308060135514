import { catchModuleMissingProtection } from "@hotel-engine/helpers/catchModuleMissingProtection";
import { lazy } from "react";

// ========================================================
// ===== Chunk 1: What's necessary for login page =========
// ========================================================

export const Login = lazy(() =>
  import(/* webpackChunkName: "Login" */ "./pages/Login/Login").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

// ========================================================
// =========== Chunk 2: Dashboard and Search ==============
// ========================================================

export const Dashboard = lazy(() =>
  import(/* webpackChunkName: "FindResults" */ "./pages/Dashboard").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const SearchResults = lazy(() =>
  import(/* webpackChunkName: "FindResults" */ "./pages/SearchResults").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);
export const SearchResultsLegacy = lazy(
  () => import(/* webpackChunkName: "FindResults" */ "./pages/SearchResults/SearchResultsLegacy")
);

export const FlightsSearch = lazy(() =>
  import("./pages/Flights/Search").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const TestPage = lazy(() =>
  import("./pages/Flights/TestPage").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

// ========================================================
// ======= Chunk 3: Everything else, all other pages ======
// ========================================================

export const AutopayCancel = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/AutopayCancel").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const AutopayFAQ = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/AutopayFAQ").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const AutopaySetup = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/AutopaySetup").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Balance = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Balance").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Billing = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Billing").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const CreditCardTransactions = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/CreditCardTransactions").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const CreditLineIncrease = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/CreditLineIncrease").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const BillingIssue = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/BillingIssue").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Checkout = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Checkout").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const FlightsCheckout = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Checkout/Flights").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const FlightsSeatmapPortal = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Checkout/FlightSeatmapPortal").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const CheckoutVerification = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/CheckoutVerification").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const CompanySettings = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/CompanySettings").catch(
    (e) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchModuleMissingProtection(e, { location: "RouteProviders" }) as any
  )
);

export const CompanySettingsNew = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/CompanySettingsNew").catch(
    (e) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchModuleMissingProtection(e, { location: "RouteProviders" }) as any
  )
);

export const DirectBillFAQ = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/DirectBillFAQ").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const ExtendTrips = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/ExtendTrips").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PersonalAccountLanding = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PersonalAccountLanding").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const FlexFAQ = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/FlexFAQ").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const FlexPassFAQ = lazy(() =>
  import(/* webpackChunkName: "Other" */ "pages/FlexPassFAQ").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Groups = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Groups").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const GroupsNew = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/GroupsNew").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const IncidentalsFAQ = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/IncidentalsFAQ")
);

export const Itinerary = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Itinerary").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinPageLayout = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinFlow = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/JoinFlow").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinConfirm = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/components/Confirm").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinReferFriendsFlow = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/ReferFriendsFlow").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinPartnersFlow = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/PartnersFlow").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinFinish = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/components/Finish").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const GroupsJoin = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Groups/pages/GroupsJoin").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const JoinRegistering = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Join/Registering").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

// import { Confirm } from "./components/Confirm";
// import { ReferFriendsFlow } from "./definitions";
// import { PartnersFlow } from "./definitions/PartnersFlow";

export const MemberDetails = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/MemberDetails").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Members = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Members").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const ModifyReservation = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/ModifyReservation").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const MyProperties = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/MyProperties").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const NewSSOConfigConfirm = lazy(() =>
  import(
    /* webpackChunkName: "Other" */
    "./pages/CompanySettings/components/AuthStrategyForm/components/Confirmation"
  ).catch((e) => catchModuleMissingProtection(e, { location: "RouteProviders" }))
);

export const NewSSOConfigConfirmNew = lazy(() =>
  import(
    /* webpackChunkName: "Other" */
    "./pages/CompanySettingsNew/components/AuthStrategyForm/components/Confirmation"
  ).catch((e) => catchModuleMissingProtection(e, { location: "RouteProviders" }))
);

export const Orders = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Orders").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PaymentDetails = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PaymentDetails").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PaymentHistory = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PaymentHistory").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PaymentMethods = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PaymentMethods").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PaymentReview = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PaymentReview").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PersonalTravelConfirm = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravelConfirm").catch(
    (e) => catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PersonalTravelUnconfirmed = lazy(() =>
  import(
    /* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravelUnconfirmed.tsx"
  ).catch((e) => catchModuleMissingProtection(e, { location: "RouteProviders" }))
);

export const PersonalTravel2FV = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravel2FV").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Property = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Property").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PropertyReview = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PropertyReview").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const ReferFriends = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/ReferFriends").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const ReferFriendsProgramDetails = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/ReferFriendsProgramDetails").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const RequestAdminAccess = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/RequestAdminAccess").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Rewards = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Rewards").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const RewardsEnrollment = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/RewardsEnrollment").catch(
    (e) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchModuleMissingProtection(e, { location: "RouteProviders" }) as any
  )
);

export const RewardsFaq = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/RewardsFaq").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const RewardsTerms = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/RewardsTerms").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Settings = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Settings").catch(
    (e) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchModuleMissingProtection(e, { location: "RouteProviders" }) as any
  )
);

export const SettingsLegacy = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/SettingsLegacy").catch(
    (e) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchModuleMissingProtection(e, { location: "RouteProviders" }) as any
  )
);

export const CarsCheckout = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Checkout/Cars").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const ShareOptions = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/ShareOptions/ShareOptionsWrapper").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const SignUp = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/SignUp").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const Statement = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Statement").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const SupportCenter = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/SupportCenter").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const PartnerOffers = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/PartnerOffers").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const TravelCredits = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/TravelCredits").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);

export const TravelPoliciesLandingPage = lazy(() =>
  import(
    /* webpackChunkName: "Other" */
    "./pages/CompanySettings/components/TravelPolicies/TravelPoliciesLandingPage"
  ).catch((e) => catchModuleMissingProtection(e, { location: "RouteProviders" }))
);

export const TravelPolicyFormPage = lazy(() =>
  import(
    /* webpackChunkName: "Other" */ "./pages/CompanySettings/components/TravelPolicies/TravelPolicyFormPage"
  ).catch((e) => catchModuleMissingProtection(e, { location: "RouteProviders" }))
);

export const Trends = lazy(() =>
  import(/* webpackChunkName: "Other" */ "./pages/Trends").catch((e) =>
    catchModuleMissingProtection(e, { location: "RouteProviders" })
  )
);
