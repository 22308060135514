import type { IDateFormat } from "@hotel-engine/types/business";
import type { IBooking } from "@hotel-engine/types/trips";
import { formatDate } from "@hotel-engine/utilities";
import moment from "moment";

export const getStartDate = ({
  booking,
  preferredDateFormat,
}: {
  booking: IBooking;
  preferredDateFormat: IDateFormat;
}) => {
  return booking.details.startTime || booking.details.checkIn
    ? formatDate(
        /flight|car/.test(booking.bookingType)
          ? moment.parseZone(booking.details.startTime)
          : moment.parseZone(booking.details.checkIn),
        "MM/DD/YY",
        preferredDateFormat
      )
    : "Date unavailable";
};

export const getEndDate = ({
  booking,
  preferredDateFormat,
}: {
  booking: IBooking;
  preferredDateFormat: IDateFormat;
}) => {
  return booking.details.endTime || booking.details.checkOut
    ? formatDate(
        /flight|car/.test(booking.bookingType)
          ? moment.parseZone(booking.details.endTime)
          : moment.parseZone(booking.details.checkOut),
        "MM/DD/YY",
        preferredDateFormat
      )
    : "Date unavailable";
};
