import type { TextInputProps } from "@hotelengine/atlas-web";
import { InputIconButtonAdornment, TextInput } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import type { ITripsSearch } from "../..";

const placeholderMap = {
  traveler: "Name or email",
  location: "Property, airport code, city, state",
  confirmation_number: "Confirmation number",
  all: "Search trips",
};

export interface ISearchInputProps {
  values: ITripsSearch;
  isOnColor: boolean;
  isLoading: boolean;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  isMobile: boolean;
  size?: TextInputProps["size"];
}

const SearchInput = ({
  values,
  isOnColor,
  isLoading,
  handleOnChange,
  handleSubmit,
  isMobile,
  size,
}: ISearchInputProps) => {
  const [params, tripsControl] = useTripsControl();
  const { setFieldValue } = useFormikContext<ITripsSearch>();

  const handleClearSearch = () => {
    setFieldValue("search", "");

    if (params.search) {
      tripsControl.unsetParams("search", "search_facet");
    }
  };

  return (
    <TextInput
      id="searchTrips"
      aria-label="Search trips"
      placeholder={placeholderMap[values.search_facet || "all"]}
      value={values.search}
      isOnColor={!isOnColor}
      isDisabled={isLoading}
      onChange={handleOnChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      style={{ width: isMobile ? "100%" : 336 }}
      size={size || "md"}
      enterKeyHint="search"
      trailingAdornment={
        !!(values.search || params.search) ? (
          <InputIconButtonAdornment
            icon="circle-xmark"
            aria-label="clear search"
            onClick={handleClearSearch}
          />
        ) : undefined
      }
    />
  );
};

export default SearchInput;
