import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const ListItem = styled<React.FC<BoxProps>>(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InfoContainer = styled<React.FC<BoxProps>>(Box)<{
  $pad?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme, $pad }) => ($pad ? theme.spacing[16] : theme.spacing[0])};
`;
