import { useEffect, useMemo, useState } from "react";
import type { IFlightCancelData } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";
import { usePreviewCancellationQuery } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

export enum FlightRefundType {
  OriginalFormOfPayment = "Original form of payment",
  AirlineCredit = "Airline credit",
  Unknown = "unknown",
  Nonrefundable = "nonrefundable",
}

export const flightRefundTypeMap = {
  flight_original_form_of_payment: FlightRefundType.OriginalFormOfPayment,
  airline_credit: FlightRefundType.AirlineCredit,
};

export const usePreviewFlightRefund = (tripId: number, bookingNumber: string) => {
  const [cancelData, setCancelData] = useState<IFlightCancelData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const flightCancelCalc = usePreviewCancellationQuery({
    id: tripId,
  });

  useEffect(() => {
    if (!cancelData && !isError && !isLoading) {
      try {
        setIsLoading(true);

        flightCancelCalc.mutate(
          { bookingNumber: bookingNumber, bookingType: "flight" },
          {
            onSuccess: (data) => {
              setCancelData(data);
              setIsLoading(false);
              setIsError(false);
            },
            onError: () => {
              setIsLoading(false);
              setIsError(true);
            },
          }
        );
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    }
  }, [bookingNumber, cancelData, flightCancelCalc, isError, isLoading]);

  const refundInfo = useMemo(() => {
    if (!cancelData) {
      return null;
    }

    const refundCredit = cancelData.refundDetails?.credits?.[0];

    return {
      refundType: flightRefundTypeMap[refundCredit?.type || "flight_original_form_of_payment"],
      refundAmount: refundCredit?.amount,
      triggerOnCancel: cancelData.triggerOnCancel,
      us24HourCancellableWindowEnds: cancelData.us24HourCancellableWindowEnds,
    };
  }, [cancelData]);

  return { refundInfo, isLoading, isError };
};

export type FlightRefundInfo = ReturnType<typeof usePreviewFlightRefund>["refundInfo"];
