import {
  ModificationTypes,
  useCarModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { useBreakpoint } from "@hotel-engine/hooks";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { Box, Button, Chip, Icon, Typography } from "@hotelengine/atlas-web";

import { useCarsFF } from "@hotel-engine/hooks/useCarsFF";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useGetCarInsuranceQuery } from "@hotel-engine/react-query/reservation/useCarInsurancePolicy";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { useIsFeatureFlagOn } from "../../../../../Experiments";
import { isPastBooking } from "../../helpers";
import * as Styled from "./styles";
import { ampli } from "ampli";

const RENTAL_COVER_FULL_POLICY_URL = "https://rentalcover.com/en/pds";
const RENTAL_COVER_FILE_A_CLAIM_URL = "https://www.rentalcover.com/en/claim";

const Insurance = ({ hideManage }: { hideManage?: boolean }) => {
  const { t } = useTranslation("cars_trips");
  const user = useAppSelector((state) => state.Auth.user);
  const isMobile = useBreakpoint("md", "max");
  const {
    reservation,
    dispatch: modificationsDispatch,
    isPreview,
    state: {
      modificationStatus: { isSubmitted },
    },
  } = useCarModificationsContext();
  const navigate = useNavigate();
  const { isInsuranceEnabled } = useCarsFF();
  const { dispatch: tripsDispatch } = useTripsLegacyContext();
  const isTripsFilteringImprovementsOn = useIsFeatureFlagOn("trips_filtering_improvements");

  const isPast = isPastBooking(reservation);

  const {
    isError,
    data: insurancePolicy,
    refetch,
  } = useGetCarInsuranceQuery(reservation?.insurancePolicyId, {
    enabled: !!reservation?.insurancePolicyId,
  });

  useEffect(() => {
    if (isSubmitted) {
      void refetch();
    }
  }, [isSubmitted, refetch]);

  if (!isInsuranceEnabled || !insurancePolicy || isError) {
    return null;
  }

  const rawPolicies = insurancePolicy.coverageInformation.disclaimer || "";

  const contentManage = (
    <Box
      marginTop={24}
      gap={16}
      display="flex"
      flexDirection="column"
      marginBottom={isMobile ? 12 : 0}
      data-testid="insurance-bottom-sheet"
    >
      <Box>
        <Typography variant="body/sm-strong" color="foregroundPrimary">
          {t("helpAndPolicies.insurance.content", {
            policyId: insurancePolicy?.policyId,
          })}
        </Typography>
      </Box>
      <Typography variant="body/sm" color="foregroundPrimary">
        <Styled.PolicyDescriptionContainer
          marginTop={false}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(rawPolicies),
          }}
        />
      </Typography>
      <Button
        variant="filled"
        size="lg"
        data-testid="insurance-view-full-policy"
        onClick={() => {
          ampli.clickViewFullPolicy({
            policyReferenceNumber: insurancePolicy?.policyId,
            contractNumber: reservation.bookingNumber,
            userId: user?.id,
          });

          openNewTab(
            `${RENTAL_COVER_FULL_POLICY_URL}/${insurancePolicy?.policyId}`,
            "_blank",
            navigate
          );
        }}
      >
        <Box display="flex" alignItems="center" gap={8}>
          {t("helpAndPolicies.insurance.modal.viewFullPolicy")}
          <Icon name="arrow-up-right-from-square" style={{ flexShrink: 0, marginTop: "2px" }} />
        </Box>
      </Button>
      <Button
        variant="outlined"
        size="lg"
        data-testid="insurance-file-a-claim"
        onClick={() => {
          ampli.clickFileAClaim({
            policyReferenceNumber: insurancePolicy?.policyId,
            contractNumber: reservation.bookingNumber,
            userId: user?.id,
          });

          openNewTab(RENTAL_COVER_FILE_A_CLAIM_URL, "_blank", navigate);
        }}
      >
        <Box display="flex" alignItems="center" gap={8}>
          {t("helpAndPolicies.insurance.modal.fileAClaim")}
          <Icon name="arrow-up-right-from-square" style={{ flexShrink: 0, marginTop: "2px" }} />
        </Box>
      </Button>
      {!isPast && (
        <Button
          variant="outlined"
          size="lg"
          data-testid="insurance-cancel-trigger"
          onClick={() => {
            ampli.clickCancelCoverageFromManageTrip({
              policyReferenceNumber: insurancePolicy?.policyId,
              contractNumber: reservation.bookingNumber,
              userId: user?.id,
            });

            modificationsDispatch({
              type: "SET_MODIFICATION_VIEW",
              payload: ModificationTypes.CancelInsurance,
            });
            /** If we are within the TripsContext, we need to set isActive to set up the management flow for clicking other
             * Trips while a modification is active.
             */
            if (isPreview && !isTripsFilteringImprovementsOn) {
              tripsDispatch({
                type: "setActiveModification",
                activeModification: {
                  isActive: true,
                  isNavigating: false,
                },
              });
            }
          }}
        >
          {t("helpAndPolicies.insurance.modal.cancelCoverage")}
        </Button>
      )}
    </Box>
  );

  return (
    <CollapsibleContent
      toggleTestID="insurance-information"
      suppressBottomMargin={hideManage}
      defaultOpen={hideManage}
      toggleBtnLabel={
        <Box display="flex" flexDirection="column" gap={16}>
          {insurancePolicy.status === "cancelled" && (
            <Box display="flex" justifyContent="flex-start">
              <Chip
                label="CANCELLED"
                color="red"
                size="xs"
                leadingIcon="circle-exclamation"
                style={{ borderRadius: "4px" }}
              />
            </Box>
          )}
          <Box display="flex" gap={12} alignItems="center">
            <Typography variant="heading/lg">{t("helpAndPolicies.insurance.title")}</Typography>
          </Box>
        </Box>
      }
      onChangeState={() => {
        if (user) {
          // Commented until taking the tracking ticket
          // ampli.clickPolicyInformation({
          //   carBookingID: reservation.bookingNumber,
          //   userId: user.id,
          // });
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={32}
        padding={24}
        data-testid="insurance-information-content"
      >
        <Box display="flex" alignItems="center" gap={8}>
          {insurancePolicy.status === "confirmed" && (
            <Icon name="circle-check" color="sentimentPositiveStrong" />
          )}

          <Typography variant="body/md-strong" color="foregroundPrimary">
            {t("helpAndPolicies.insurance.content", {
              policyId: insurancePolicy?.policyId,
            })}
          </Typography>
        </Box>

        {Boolean(!hideManage && insurancePolicy.status === "confirmed") && (
          <ResponsiveAtlasDialog
            title="Coverage details"
            content={contentManage}
            triggerComponent={
              <Button
                variant="outlined"
                size="lg"
                data-testid="insurance-trigger-bottom-sheet"
                onClick={() => {
                  ampli.clickManagePolicy({
                    policyReferenceNumber: insurancePolicy?.policyId,
                    contractNumber: reservation.bookingNumber,
                    userId: user?.id,
                  });
                }}
              >
                {t("helpAndPolicies.insurance.button")}
              </Button>
            }
            isModal
          />
        )}
      </Box>
    </CollapsibleContent>
  );
};

export default Insurance;
