import type { ITripPreview } from "pages/Trips/contextLegacy";

import GoogleMap from "../..";
import GoogleMapsTripResults from "./components/GoogleMapsTripResults";
import GoogleMapsTripResultsLegacy from "./components/GoogleMapsTripResultsLegacy";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

type GoogleTripsMapProps = {
  /** Used for map bounds after loading is done */
  loading: boolean;
  /** Callback to get trip details and open side panel when a pin is clicked */
  onSelection?: (trip: ITripPreview) => void;
};

const GoogleTripsMap = ({ loading, onSelection }: GoogleTripsMapProps) => {
  const isTripsFilteringImprovementsEnabled = useIsFeatureFlagOn("trips_filtering_improvements");
  return (
    <GoogleMap
      controlOptions={{
        showExpand: false,
      }}
      /** This allows the user to see the whole world, preventing the map from centering in an area where there
       * aren't actually any pins because it's finding the center of a group of trips far away from each other
       */
      mapOptions={{ minZoom: 2 }}
    >
      {(map) => (
        <>
          {isTripsFilteringImprovementsEnabled ? (
            <GoogleMapsTripResults loading={loading} map={map as google.maps.Map} />
          ) : (
            <GoogleMapsTripResultsLegacy
              loading={loading}
              map={map as google.maps.Map}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onSelection={onSelection!}
            />
          )}
        </>
      )}
    </GoogleMap>
  );
};

export default GoogleTripsMap;
