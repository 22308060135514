import { Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

interface IProgramLengthWarning {
  visible: boolean;
}

export const ProgramLengthWarning = ({ visible }: IProgramLengthWarning) => (
  <>
    {visible ? (
      <Styled.ProgramLengthWarningContainer>
        <div>
          <Styled.WarningIcon name="circle-info" color="accentBlue" />
        </div>
        <Typography
          as="p"
          variant="body/sm"
          color="uiAccent"
          data-testid="loyalty-program-length-warning"
        >
          Check your account number and if it's correct, please continue.
        </Typography>
      </Styled.ProgramLengthWarningContainer>
    ) : null}
  </>
);
