import styled from "styled-components";

import Image from "@hotel-engine/common/Image";
import { ellipsisText } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const TripType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const TripDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:${({ theme }) => theme.spacing[12]};
`;

export const ImageContainer = styled.div`
  height: ${({ theme }) => theme.spacing[32]};
  width: ${({ theme }) => theme.spacing[32]};
  pointer-events: none;
`;

export const CarImageContainer = styled.div`
  height: "auto";
  width: ${({ theme }) => theme.spacing[32]};
  pointer-events: none;
`;

export const CarTripImage = styled(Image)`
  object-fit: contain;
  height: "auto";
  width: ${({ theme }) => theme.spacing[32]};
  max-width: ${({ theme }) => theme.spacing[32]};
`;

export const TripImage = styled(Image)`
  border-radius: ${({ theme }) => theme.borderRadius[4]};
  object-fit: contain;
  height: "auto";
  width: ${({ theme }) => theme.spacing[32]};
  max-width: ${({ theme }) => theme.spacing[32]};
`;

export const DesktopContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: scroll;

  table {
    border-collapse: unset;
  }
`;

export const PrimaryText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/sm",
  color: "foregroundPrimary",
})`
  ${ellipsisText};
`;

export const SubText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/xs",
  color: "foregroundPrimary",
})<{
  isSuccess?: boolean;
  $isVerified?: boolean;
}>`
  ${ellipsisText};
  display: block;
  color: ${({ theme, isSuccess, $isVerified }) =>
    isSuccess || $isVerified ? theme.colors.accentGreenLight : theme.colors.neutralN500};
`;

export const LoadMore = styled.div`
  ${flexbox({
    justifyContent: "center",
  })}
`;
