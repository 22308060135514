import { useState } from "react";

import { Formik } from "formik";
import config from "config";

import Image from "@hotel-engine/common/Image";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useTextMessagesMutation } from "@hotel-engine/react-query/textMessages/useTextMessagesMutation";

import * as Styled from "./styles";
import {
  Box,
  Button,
  FormControl,
  InputIconButtonAdornment,
  TextInput,
  Typography,
} from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { forceNavigateToAppStore } from "@hotel-engine/constants/appStore";

const DownloadAppBanner = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const isMobile = useBreakpoint("md", "max");

  const { mutate, reset: resetMutation } = useTextMessagesMutation();

  const handleOnSubmit = (values, { setFieldError }) => {
    setLoading(true);
    mutate(
      { phone: values.phone },
      {
        onSuccess: () => setSuccess(true),
        onError: (error) => setFieldError("phone", error.response?.data.title),
        onSettled: () => {
          resetMutation();
          setLoading(false);
        },
      }
    );
  };

  return (
    <Styled.DownloadAppBanner>
      <Styled.ImageContainer>
        <Image
          setContainerSize={false}
          src={`${config.cdnHost}/assets/refresh-app-download.svg`}
          alt={`Preview of a customer using the ${COMPANY_NAME} app`}
        />
      </Styled.ImageContainer>
      <Styled.InstructionsContainer>
        <Typography variant="heading/md" color="foregroundInverse">
          Save money when you're on the go with our mobile app.
        </Typography>
        {isMobile ? (
          <Box marginTop={16} display="flex" flexDirection="column" alignItems="flex-start" gap={8}>
            <Button
              role="button"
              variant="plain"
              color="everdark"
              onClick={() => forceNavigateToAppStore("iOS")}
              isLoading={loading}
              isDisabled={loading || success}
              style={{ padding: 0 }}
              data-testid="download-ios"
            >
              <Image
                src={`${config.cdnHost}/assets/appstores/app_store.svg`}
                alt="download ios app"
              />
            </Button>
            <Button
              role="button"
              variant="plain"
              color="everdark"
              onClick={() => forceNavigateToAppStore("Android")}
              isLoading={loading}
              isDisabled={loading || success}
              style={{ padding: 0 }}
              data-testid="download-google"
            >
              <Image
                src={`${config.cdnHost}/assets/appstores/google_play.svg`}
                alt="download android app"
              />
            </Button>
          </Box>
        ) : (
          <>
            <Formik initialValues={{ phone: undefined }} onSubmit={handleOnSubmit}>
              {({ handleSubmit, isValid, touched }) => (
                <form onSubmit={handleSubmit} data-testid="download-form">
                  <FormControl
                    label=""
                    errorText="Please enter a valid US phone number"
                    status={touched.phone && !isValid ? "error" : "default"}
                  >
                    <TextInput
                      name="phone"
                      type="tel"
                      placeholder="Phone number"
                      className="phone-input"
                      isDisabled={success}
                      width="100%"
                      trailingAdornment={
                        <InputIconButtonAdornment
                          icon="arrow-right"
                          type="submit"
                          isLoading={loading || success}
                        />
                      }
                    />
                  </FormControl>
                </form>
              )}
            </Formik>
            {!!success && (
              <Typography
                variant="body/xs"
                color="accentGreenLighter"
                marginTop={4}
                marginBottom={4}
              >
                We've sent you a text message with a link to download the app
              </Typography>
            )}
            <Typography variant="body/xs" color="foregroundInverse">
              Standard text message rates may apply
            </Typography>
          </>
        )}
      </Styled.InstructionsContainer>
    </Styled.DownloadAppBanner>
  );
};

export default DownloadAppBanner;
