// import { datadogRum } from "@datadog/browser-rum";
import { Form, Formik } from "formik";
import moment from "moment";
// import { useLocation } from "@hotel-engine/lib/react-router-dom";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useCreateCheckoutVerificationMutation } from "@hotel-engine/react-query/checkoutVerification/useCreateCheckoutVerification";
import type { ICheckoutVerification } from "@hotel-engine/types/notifications";

import { PropertyInfo } from "../../PropertyInfo";
import CheckoutDateSelectorInput from "../CheckoutDateSelectorInputs";
import * as Styled from "./styles";

type CheckoutVerificationDetailsProps = {
  /** the checkout verification info associated with the contract */
  checkoutVerification: ICheckoutVerification;
  setStep: () => void;
  // TODO re-enable if datadog rum brought back
  // referrer: string;
};

const CheckoutVerificationDetails = ({
  checkoutVerification,
  setStep,
}: // TODO add back if re-enable datadogrum
// referrer,
CheckoutVerificationDetailsProps) => {
  const { contractCheckIn, contractCheckOut, id } = checkoutVerification;
  // const { pathname } = useLocation();

  const createCheckoutVerification = useCreateCheckoutVerificationMutation({
    mutationKey: checkoutVerification.id,
  });

  // Post Checkout Actions context
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  const handleOnSubmit = (values: {
    checkIn: moment.Moment;
    checkOut: moment.Moment;
  }) => {
    const checkOut = values.checkOut.format("YYYY-MM-DD");
    createCheckoutVerification.mutate(
      {
        id,
        date: checkOut,
      },
      {
        onSuccess: () => {
          dispatchPostCheckoutActions({
            type: "VERIFY_POST_CHECKOUT_ACTION_SUCCESS",
            payload: id,
          });
          setStep();
          // datadogRum.addAction("checkoutVerification", {
          //   referrer,
          //   pageSubmittedFrom: pathname,
          //   verifyCheckOutDiffersFromContractCheckOut:
          //     checkOut !== contractCheckOut,
          //   checkOut,
          //   contractCheckOut,
          // });
        },
      }
    );
  };

  return (
    <Styled.VerificationContentsWrapper>
      <>
        <PropertyInfo propertyInfo={checkoutVerification} />
        <Styled.VerificationInstructions data-testid="verificationModal">
          <h1>When did you check out?</h1>
          <p>
            Please let us know the date that you left the property. This helps your team understand
            booking patterns and may assist in recouping unused days.
          </p>
        </Styled.VerificationInstructions>
        <Styled.VerificationForm>
          <Formik
            onSubmit={handleOnSubmit}
            initialValues={{
              checkIn: moment(contractCheckIn),
              checkOut: moment(contractCheckOut),
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <CheckoutDateSelectorInput />
                <Styled.SubmitButton
                  size="lg"
                  type="submit"
                  isDisabled={createCheckoutVerification.isLoading}
                  isLoading={createCheckoutVerification.isLoading}
                >
                  Continue
                </Styled.SubmitButton>
              </Form>
            )}
          </Formik>
        </Styled.VerificationForm>
      </>
    </Styled.VerificationContentsWrapper>
  );
};

export default CheckoutVerificationDetails;
