import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const CancellableRoomsContainer = styled.div`
  margin-bottom: 24px;
`;

export const RoomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing[16]};
`;

export const CheckboxContent = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[0]} ${theme.spacing[16]}`}
`;

export const InnerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

export const AdditionalGuests = styled<React.FC<TypographyProps>>(Typography)`
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: ${({ theme }) => theme.colors.foregroundSecondary};
  width: max-content;
  cursor: default;
  padding: 0 40px 12px;
`;
