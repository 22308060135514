import type { ColumnDef } from "@hotelengine/atlas-web";
import { Box, DataTableSortHeader, Icon, Skeleton } from "@hotelengine/atlas-web";
import type { ICarSummaryOfCharges, ITrip } from "@hotel-engine/types/trips";
import type { IconName } from "@hotelengine/atlas-core";

import { useAppSelector } from "store/hooks";

import { bookingTypesMap } from "../../../constants";
import { getStartDate } from "../helpers/parseTripDate";
import * as Styled from "../styles";

import TripLocationCell from "./TripLocationCell";
import TripDetailCell from "./TripDetailCell";
import Travelers from "./Travelers";
import TripPriceCell from "./TripPriceCell";
import TripEndTimeCell from "./TripEndTimeCell";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { useParams } from "@hotel-engine/lib/react-router-dom";
import useTripsResult from "pages/Trips/hooks/useTripsResult";

const defaultDescSorts = ["past", "all"];

export const useTableColumns = () => {
  const [params] = useTripsControl();
  const { status } = useParams();
  const user = useAppSelector((state) => state.Auth?.user);
  const { isLoading } = useTripsResult();
  const preferredDateFormat = user?.business?.preferredDateFormat ?? "mdy";
  const sortDescFirstBasedOnStatus = defaultDescSorts.includes(status as string);

  const RowPaddingContainer = ({ children }) => (
    <Box paddingTop={8} paddingBottom={8}>
      {children}
    </Box>
  );

  const columnWidths = {
    type: "6%",
    traveler: "20%",
    start: "8%",
    end: "8%",
    location: "14%",
    details: "32%",
    price: "12%",
  };

  const columns: ColumnDef<ITrip>[] = [
    {
      header: "Type",
      accessorKey: "type",
      meta: {
        percentageWidth: columnWidths.type,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            ) : (
              <Styled.TripType data-testid="trips-type-cell">
                <Icon name={bookingTypesMap[record?.bookings[0].bookingType].icon as IconName} />
                <Styled.PrimaryText>
                  {bookingTypesMap[record?.bookings[0].bookingType].label}
                </Styled.PrimaryText>
              </Styled.TripType>
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: "Traveler",
      accessorKey: "traveler",
      meta: {
        percentageWidth: columnWidths.traveler,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={25} />
            ) : (
              <Travelers travelers={record.bookings[0].travelers} searchValue={params.search} />
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: ({ column }) => <DataTableSortHeader column={column} label="Start" />,
      accessorKey: "start_time",
      sortDescFirst: sortDescFirstBasedOnStatus,
      meta: {
        percentageWidth: columnWidths.start,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={25} />
            ) : (
              <Styled.PrimaryText>
                {getStartDate({ booking: record.bookings[0], preferredDateFormat })}
              </Styled.PrimaryText>
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: ({ column }) => <DataTableSortHeader column={column} label="End" />,
      accessorKey: "end_time",
      sortDescFirst: sortDescFirstBasedOnStatus,
      meta: {
        percentageWidth: columnWidths.end,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={25} />
            ) : (
              <TripEndTimeCell booking={record.bookings[0]} user={user} />
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: "Location",
      accessorKey: "location",
      meta: {
        percentageWidth: columnWidths.location,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={25} />
            ) : (
              <TripLocationCell data={record.bookings[0]} searchValue={params.search as string} />
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: "Details",
      accessorKey: "details",
      meta: {
        percentageWidth: columnWidths.details,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={40} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        return (
          <RowPaddingContainer>
            {isLoading ? (
              <Skeleton height={40} />
            ) : (
              <TripDetailCell data={record.bookings[0]} searchValue={params.search as string} />
            )}
          </RowPaddingContainer>
        );
      },
    },
    {
      header: ({ column }) => <DataTableSortHeader column={column} label="Price" />,
      accessorKey: "price",
      meta: {
        percentageWidth: columnWidths.price,
      },
      cell: ({ row }) => {
        const record = row.original;

        if (!record) {
          return (
            <RowPaddingContainer>
              <Skeleton height={25} data-testid="trips-skeleton-row" />
            </RowPaddingContainer>
          );
        }

        const booking = record.bookings[0];
        const total =
          booking.bookingType === "car"
            ? (booking.details.charges as ICarSummaryOfCharges)?.totalCharges
            : booking.details.charges.total;

        return isLoading ? (
          <Skeleton height={25} />
        ) : (
          !!total && (
            <RowPaddingContainer>
              <TripPriceCell booking={record.bookings[0]} total={total} />
            </RowPaddingContainer>
          )
        );
      },
    },
  ];

  return { columns };
};
