import type { ICarsEntry } from "../../Search/types";

import { useAirportAccess } from "../../hooks/useAirportAccess";
import { FEATURES, type TCarFeatureInfo } from "./constants";
import { pluralize } from "@hotel-engine/utilities/formatters/formatStrings";
import { useTransmission } from "../../hooks/useTransmission";
import type { TCarFeatureNames } from "@hotel-engine/types/cars/cars.shared";
import {
  CarRentalVendorAirportAccessType,
  ECancellationType,
  ECarBottomFeatures,
  ECarListFeatures,
  EPaymentType,
} from "@hotel-engine/types/cars/cars.shared";

type TOptions<T> = {
  split?: T;
  omit?: TCarFeatureNames[];
  showPostpaidRates?: boolean;
  showDirectBill?: boolean;
};

export function useCarCardFeatures<T extends boolean = false>(
  data: Pick<ICarsEntry, "vehicleDetails" | "paymentType" | "cancelType">,
  airportAccess: CarRentalVendorAirportAccessType | undefined,
  directBillAllowed: boolean | undefined,
  { omit = [], showPostpaidRates, showDirectBill, split }: TOptions<T>
): T extends true
  ? { bottomList: TCarFeatureInfo[]; writtenList: TCarFeatureInfo[] }
  : TCarFeatureInfo[] {
  const { getAirportAccess } = useAirportAccess();
  const { getTransmission } = useTransmission();
  const bottomList: TCarFeatureInfo[] = [];
  const writtenList: TCarFeatureInfo[] = [];
  const prioritizedWrittenList: TCarFeatureInfo[] = [];
  const { bagCount, seatCount, transmissionCode, unlimitedMileage } = data.vehicleDetails;

  if (seatCount && !omit.includes(ECarBottomFeatures.passengers)) {
    bottomList.push({
      ...FEATURES[ECarBottomFeatures.passengers],
      label: pluralize(data.vehicleDetails.seatCount, "passenger"),
    });
  }
  if (bagCount && !omit.includes(ECarBottomFeatures.numberBags)) {
    bottomList.push({
      ...FEATURES[ECarBottomFeatures.numberBags],
      label: pluralize(data.vehicleDetails.bagCount, "bag"),
    });
  }

  if (
    directBillAllowed &&
    showDirectBill &&
    data.paymentType === EPaymentType.Prepaid &&
    !omit.includes(ECarListFeatures.directBill)
  ) {
    prioritizedWrittenList.push(FEATURES[ECarListFeatures.directBill]);
  }

  if (unlimitedMileage && !omit.includes(ECarListFeatures.unlimitedMileage)) {
    writtenList.push(FEATURES[ECarListFeatures.unlimitedMileage]);
  }

  if (transmissionCode && !omit.includes(ECarBottomFeatures.transmissionType)) {
    bottomList.push({
      ...FEATURES[ECarBottomFeatures.transmissionType],
      label: getTransmission(data.vehicleDetails.transmissionCode) ?? "",
    });
  }

  if (
    ECancellationType.Free === data.cancelType &&
    !omit.includes(ECarListFeatures.freeCancellation)
  ) {
    writtenList.push(FEATURES[ECarListFeatures.freeCancellation]);
  } else if (
    ECancellationType.Flexible === data.cancelType &&
    !omit.includes(ECarListFeatures.flexibleCancellation)
  ) {
    writtenList.push(FEATURES[ECarListFeatures.flexibleCancellation]);
  }

  if (
    [EPaymentType.Postpaid].includes(data.paymentType) &&
    showPostpaidRates &&
    !omit.includes(ECarListFeatures.payLater)
  ) {
    writtenList.push(FEATURES[ECarListFeatures.payLater]);
  }

  if (
    airportAccess &&
    CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT === airportAccess &&
    !omit.includes(CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT)
  ) {
    writtenList.push({
      ...FEATURES[CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT],
      label: getAirportAccess(airportAccess) || "",
    });
  } else if (
    airportAccess &&
    CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT ===
      airportAccess &&
    !omit.includes(
      CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT
    )
  ) {
    writtenList.push({
      ...FEATURES[
        CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT
      ],
      label: getAirportAccess(airportAccess) || "",
    });
  }

  return (
    split
      ? { bottomList, writtenList: [...prioritizedWrittenList, ...writtenList] }
      : [...prioritizedWrittenList, ...bottomList, ...writtenList]
  ) as T extends true
    ? { bottomList: TCarFeatureInfo[]; writtenList: TCarFeatureInfo[] }
    : TCarFeatureInfo[];
}
