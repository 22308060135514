import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";
import { RentalCompanyImage } from "../../../styles";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";
import { Fragment } from "react";
import type { ICarNotice } from "@hotel-engine/types/cars/cars.shared";

const PolicyInformation = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const isMobile = useBreakpoint("md", "max");
  const { reservation } = useCarModificationsContext();
  const rentalCompany = reservation?.rentalCompany;
  const showArrayPolicies = !!reservation?.policyInformations?.length;
  const arrayPoliciesCurated = reservation.policyInformations?.reduce(
    (acum, curr) => {
      return {
        policyInformations: [
          ...acum.policyInformations,
          {
            ...curr,
            textNotice: {
              ...curr.textNotice,
              title: curr.textNotice.title,
              key:
                curr.textNotice.title === ""
                  ? `${acum.lastPolicyTitle}-${acum.policyTitleCount}`
                  : curr.textNotice.title,
            },
          },
        ],
        lastPolicyTitle:
          curr.textNotice.title === "" ? acum.lastPolicyTitle : curr.textNotice.title,
        policyTitleCount: curr.textNotice.title === "" ? acum.policyTitleCount + 1 : 0,
      };
    },
    { policyInformations: [], lastPolicyTitle: "", policyTitleCount: 0 } as {
      policyInformations: ICarNotice[];
      lastPolicyTitle: string;
      policyTitleCount: number;
    }
  );
  const content = (
    <Box marginTop={24} marginBottom={isMobile ? 12 : 0}>
      {!rentalCompany?.logo ? (
        <Typography variant="body/md-strong" color="foregroundPrimary">
          {rentalCompany?.name}
        </Typography>
      ) : (
        <RentalCompanyImage src={rentalCompany?.logo} $size={146} width={146} />
      )}
      <Typography variant="body/xs-strong" color="foregroundPrimary">
        {showArrayPolicies ? (
          arrayPoliciesCurated?.policyInformations?.map((policy) => (
            <Fragment key={`${reservation.bookingNumber}-${policy.textNotice.key}`}>
              <Typography variant="heading/md" color="foregroundPrimary" as="h2" marginTop={16}>
                {policy.textNotice.title.slice(0, 1).toUpperCase()}
                {policy.textNotice.title.slice(1)}
              </Typography>
              <Typography variant="body/sm" color="foregroundPrimary" as="p">
                <Styled.PolicyDescriptionContainer
                  marginTop={false}
                  key={`${reservation.bookingNumber}-${policy.textNotice.key}`}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(policy.textNotice.text),
                  }}
                />
              </Typography>
            </Fragment>
          ))
        ) : (
          <Styled.PolicyDescriptionContainer
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(reservation.policyInformation || ""),
            }}
          />
        )}
      </Typography>
    </Box>
  );

  const dialogSheetWrapper = (
    <ResponsiveAtlasDialog
      title="View rental policy"
      content={content}
      triggerComponent={
        <Button variant="outlined" size="lg">
          View rental policy
        </Button>
      }
      isModal
    />
  );

  return (
    <CollapsibleContent
      toggleTestID="policy-information"
      toggleBtnLabel={<Typography variant="heading/lg">Policy Information</Typography>}
      onChangeState={() => {
        if (user) {
          ampli.clickPolicyInformation({
            carBookingID: reservation.bookingNumber,
            userId: user.id,
          });
        }
      }}
    >
      <Box display="flex" flexDirection="column" gap={16} padding={24} paddingTop={0}>
        <Typography variant="body/md" color="foregroundPrimary">
          Please review the policies and terms applicable to your rental car agreement.
        </Typography>
        {dialogSheetWrapper}
      </Box>
    </CollapsibleContent>
  );
};

export default PolicyInformation;
