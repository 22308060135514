import styled from "styled-components";
import { Box, Typography, type BoxProps } from "@hotelengine/atlas-web";

export const LoadingContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const CenterParagraph = styled(Typography).attrs({
  color: "foregroundPrimary",
})`
  text-align: center;
  margin: 0;
`;
