import type { ICurrency } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { formatCurrency } from "@hotel-engine/utilities";
import SummaryOfChargesItem from "../../../../../shared/SummaryOfChargesItem";
import * as Styled from "../../styles";

export interface IFeeDetailsProps {
  charges: IReservationBase;
  currencyCode: ICurrency;
}

const FeeDetails = ({ charges, currencyCode }: IFeeDetailsProps) => {
  const notZero = (value: number | undefined) => Number(value) > 0;
  const { feeDetails = [], nightCount } = charges;

  return (
    <Styled.NoMarginGridContainer>
      {feeDetails.map((fee, idx) => {
        const { name, amount, feeType, total } = fee;

        const isFlat = feeType === "flat";
        const value = isFlat ? amount : amount * nightCount;
        const isValid = !fee.isHotelFee && notZero(value);

        if (!isValid) {
          return null;
        }

        const feeMath = isFlat
          ? formatCurrency(total, true, currencyCode)
          : `${formatCurrency(amount, true, currencyCode)} x ${nightCount} night${
              nightCount > 1 ? "s" : ""
            } = ${formatCurrency(total, true, currencyCode)}`;

        return (
          <SummaryOfChargesItem
            key={idx}
            label={name}
            value={formatCurrency(total, true, currencyCode)}
            popoverContent={`${name}: ${feeMath}`}
          />
        );
      })}
    </Styled.NoMarginGridContainer>
  );
};

export default FeeDetails;
