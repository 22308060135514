import { useEffect } from "react";
import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { Button } from "@hotelengine/atlas-web";

import { useVisibility } from "@hotel-engine/hooks";
import config from "config";

import * as Styled from "./styles";
import { usePromptToInstall } from "./usePromptToInstall";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

export function InstallPrompt() {
  const { pathname } = useLocation();
  const { prompt, promptToInstall, nullifyPrompt } = usePromptToInstall();
  const { visible, show, hide } = useVisibility();

  const dismissPrompt = () => {
    hide();
    nullifyPrompt();
  };

  useEffect(() => {
    if (prompt) {
      show();
    }
  }, [prompt, show]);

  if (!visible || pathname.includes("/sign_up")) {
    return null;
  }

  return (
    <Styled.Prompt display="flex" alignItems="center" position="fixed">
      <Styled.Dismiss
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={dismissPrompt}
      >
        <Styled.DismissIcon name="xmark" />
      </Styled.Dismiss>
      <Styled.InfoRight display="flex" alignItems="center" justifyContent="space-between">
        <Styled.ImageContainer display="flex" alignItems="center">
          <Styled.HEImage src={`${config.cdnHost}/assets/icon-72.png`} alt={COMPANY_NAME} />
          <Styled.Info display="flex" flexDirection="column" justifyContent="center">
            <span>{COMPANY_NAME}</span>
            <Styled.StoreText>Google Play Store</Styled.StoreText>
          </Styled.Info>
        </Styled.ImageContainer>
        <Button onClick={promptToInstall} style={{ marginRight: 15 }}>
          Download
        </Button>
      </Styled.InfoRight>
    </Styled.Prompt>
  );
}
