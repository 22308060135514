import { type LoaderFunctionArgs, redirect, defer } from "@hotel-engine/lib/react-router-dom";
import {
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
} from "@hotel-engine/app/ItineraryPreviewPanel/constants";

import type { TripsQueryParams, TripsStatus } from "./querySchema";
import { queryParams, querySchema, TRIPS_DEFAULT_GROUP } from "./querySchema";
import { queryClient } from "@hotel-engine/contexts";
import type { ObjectLike } from "../utils/mergeObjects";
import deserializeQueryParams from "../utils/deserializeQueryParams";
import { transformFilterKeys } from "../utils/filterKeys";
import { fetchTripsNonAwait } from "./fetchTrips";

export const TRIPS_FEATURE_FLAG = "trips_filtering_improvements";

/**
 * List of parameters that are blocked from being included in URL.
 */
export const BLOCKED_PARAMS = ["limit", "filter[group]", "filter[timezone]"] as const;

/**
 * List of parameters that are allowed to be preserved during URL redirects.
 * These parameters are only used for redirect purposes and are not sent to the API.
 */
export const ALLOWED_PARAMS = [
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
  "sort_calendar",
  "view",
] as const;

/*
  This is the base query params that are used to build the query params object.
  It is used to ensure that the query params object is always valid.
*/
export const BASE_QUERY_PARAMS = querySchema.parse({});

/**
 * Sanitizes query parameters by removing empty and blocked parameters.
 * @param params - The query parameters to sanitize.
 * @returns A record of sanitized query parameters.
 */
export function getSanitizedQueryParams(params: ObjectLike): Record<string, string | string[]> {
  const result: Record<string, string | string[]> = {};

  for (const key in params) {
    const value = params[key];
    const isNotEmpty = value != null && value !== "";
    const isBlockedParam = BLOCKED_PARAMS.includes(key as (typeof BLOCKED_PARAMS)[number]);
    const isAllowedParam = ALLOWED_PARAMS.includes(key as (typeof ALLOWED_PARAMS)[number]);

    if (isNotEmpty && (!isBlockedParam || isAllowedParam)) {
      result[key] = Array.isArray(value) ? value.map(String) : String(value);
    }
  }

  return result;
}

/**
 * Builds a redirect URL pathname based on the trip status and query parameters.
 * @param status - The status of the trips.
 * @param params - The query parameters to include in the URL.
 * @returns The constructed URL pathname with query parameters.
 */
export function buildRedirectUrl(
  status: TripsStatus,
  sanitizedParams: Record<string, string | string[]>,
  rawParams: ObjectLike
) {
  const params = {
    ...sanitizedParams,
  };

  for (const param of ALLOWED_PARAMS) {
    if (rawParams[param] != null) {
      params[param] = Array.isArray(rawParams[param])
        ? (rawParams[param] as string[]).map(String)
        : String(rawParams[param]);
    }
  }

  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((v) => searchParams.append(`${key}[]`, v));
    } else {
      searchParams.append(key, value);
    }
  }

  let pathname = `/trips/${status}`;

  /** If there is somehow a status other than one that is valid (an old bookmark or a typo), default back to upcoming */
  if (!["all", "today", "upcoming", "past"].find((type) => status === type)) {
    pathname = "/trips/upcoming";
  }

  const search = searchParams.toString() ? `?${searchParams.toString()}` : "";

  return `${pathname}${search}`;
}

/**
 * Validates and parses query parameters from the URL.
 * @param url - The URL containing the query parameters.
 * @returns An object containing validated query parameters.
 */
export function getValidatedQueryParams(rawParams: ObjectLike): TripsQueryParams {
  const parsedQueryParams = queryParams.safeParse(rawParams);

  return {
    ...BASE_QUERY_PARAMS,
    ...(parsedQueryParams.success ? parsedQueryParams.data : {}),
  } as TripsQueryParams;
}

/**
 * Validates and normalizes the URL, specifically handling the "view" parameter.
 * If the parameter is invalid, redirects to a URL with the normalized value.
 *
 * @param requestUrl - The original request URL
 * @returns {URL | Response} Returns the URL object if valid, or a redirect Response if normalization is needed.
 */
export function getTripsUrl(requestUrl: string): URL | Response {
  const url = new URL(requestUrl);

  const currentUrl = `${url.pathname}${url.search}`;
  const view = url.searchParams.get("view")?.replace(/[^a-zA-Z]/g, "");

  if (!view || !/^(list|map|calendar)$/i.test(view)) {
    url.searchParams.set("view", "list");
  }

  const nextUrl = `${url.pathname}${url.search}`;

  if (currentUrl !== nextUrl) {
    return redirect(nextUrl);
  }

  return url;
}

/**
 * Loader function for fetching trips based on the request and parameters.
 * @param request - The request object containing the URL and parameters.
 * @param params - The parameters from the URL.
 * @returns A redirect or the fetched trips based on the validation.
 */
export async function loader({ request, params }: LoaderFunctionArgs) {
  const featureFlagEnabled = queryClient.getQueryData(TRIPS_FEATURE_FLAG);

  if (!featureFlagEnabled) {
    return null;
  }

  const tripsUrl = getTripsUrl(request.url);

  if (tripsUrl instanceof Response) {
    return tripsUrl;
  }

  const queryString = tripsUrl.search.replace(/^\?/, "");
  const bracketParsed = deserializeQueryParams(queryString);
  const withFilters = transformFilterKeys(bracketParsed);
  const validatedQueryParams = getValidatedQueryParams(withFilters);
  const sanitizedQueryParams = getSanitizedQueryParams(validatedQueryParams);
  const redirectUrl = buildRedirectUrl(
    (params.status as TripsStatus) || TRIPS_DEFAULT_GROUP,
    sanitizedQueryParams,
    bracketParsed
  );

  if (tripsUrl.pathname + tripsUrl.search !== redirectUrl) {
    return redirect(redirectUrl);
  }

  return defer({
    trips: fetchTripsNonAwait({
      ...validatedQueryParams,
      "filter[group]": (params.status as TripsStatus) || TRIPS_DEFAULT_GROUP,
    }),
  });
}
