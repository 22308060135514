import { useSearchParams } from "@hotel-engine/lib/react-router-dom";
import { NoTripsMessageLegacy } from "../components";
import type { ITripPreview } from "../contextLegacy";
import { useTripsLegacyContext } from "../contextLegacy";
import * as Styled from "./styles";
import GoogleTripsMap from "@hotel-engine/app/GoogleMap/components/GoogleTripsMap";

const TripsMapView = () => {
  const [_, setSearchParams] = useSearchParams();
  const { state: tripsState, dispatch: tripsDispatch } = useTripsLegacyContext();
  const { loading, trips, showPreview } = tripsState;

  const handleItemSelection = async (item: ITripPreview) => {
    tripsDispatch({ type: "rowSelection", record: item });

    if (item.id !== showPreview?.id) {
      setSearchParams({ booking_number: String(item.id) });
    } else {
      setSearchParams({});
    }
  };

  return (
    <>
      {trips.length > 0 ? (
        <Styled.TripsMapView>
          <Styled.MapView data-testid="trips-map">
            <GoogleTripsMap
              loading={loading}
              onSelection={(record) => handleItemSelection(record)}
            />
          </Styled.MapView>
        </Styled.TripsMapView>
      ) : (
        <Styled.NoTripsView>
          <NoTripsMessageLegacy isEmptyMapView />
        </Styled.NoTripsView>
      )}
    </>
  );
};

export default TripsMapView;
