import { useEffect } from "react";

import { usePrevious, useToggle } from "@hotel-engine/hooks";
import {
  DEFAULT_PRODUCT_COPY,
  DEFAULT_ROOM_DESCRIPTION,
  formatRoomDescription,
} from "@hotel-engine/utilities/formatters/formatPropertyInformation";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import { Box, Button, Divider, Icon, Typography } from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";

import * as Styled from "./styles";
import { areRoomDatesDifferent, isRoomModified } from "../../../helpers/helpers";
import RoomStatusPill from "./components/RoomStatusPill";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import RoomBookingDetails from "./components/RoomBookingDetails";

export const MAX_ROOMS_DISPLAYED = 8;
export const INIT_ROOMS_DISPLAYED = 2;

export const RoomInfo = () => {
  const { tokens } = useGlobalTheme();
  const [showAllRooms, toggleShowAllRooms] = useToggle(false);
  const { reservation: itinerary, isPreview } = useModificationsContext();

  const previousItinerary = usePrevious(itinerary);
  const showDatesForEachRoom = areRoomDatesDifferent(itinerary) && itinerary.roomCount > 1;

  useEffect(() => {
    const shouldToggleShowAllOff =
      isPreview && itinerary?.id !== previousItinerary?.id && showAllRooms;

    if (shouldToggleShowAllOff) {
      toggleShowAllRooms();
    }
  }, [itinerary, previousItinerary, showAllRooms, toggleShowAllRooms, isPreview]);

  if (!itinerary?.rooms?.[0]?.occupants?.[0]) {
    return null;
  }

  const { rooms, roomCount, roomDescription, roomTitle, product } = itinerary;
  const roomType =
    roomTitle ??
    roomDescription ??
    `Contact your ${COMPANY_NAME} Representative for more information.`;
  const displayedRooms = showAllRooms
    ? rooms.slice(0, MAX_ROOMS_DISPLAYED)
    : rooms.slice(0, INIT_ROOMS_DISPLAYED);
  const hasOverflowRooms = rooms.length > MAX_ROOMS_DISPLAYED;
  const overflowCount = hasOverflowRooms ? rooms.length - MAX_ROOMS_DISPLAYED : 0;

  const showRoomTitle = !!product || !!roomTitle;

  const getRoomsList = (allRooms: boolean) =>
    (allRooms ? rooms : displayedRooms).map((room, i) => {
      const roomIsModified = isRoomModified(room);
      const roomIsCancelled = room.status === "cancelled";

      return (
        <Styled.DetailsList
          key={i}
          $isLast={i === displayedRooms.length - 1}
          $isOnly={roomCount === 1}
        >
          <Typography variant="body/md-strong" color="foregroundPrimary">
            Room {i + 1}
            <RoomStatusPill modified={roomIsModified} cancelled={roomIsCancelled} />
          </Typography>
          <Styled.ListItem>
            <Icon
              name="bed-front"
              color="foregroundPrimary"
              style={{ alignSelf: "flex-start", marginTop: tokens.spacing[4] }}
            />
            <Typography variant="body/md" color="foregroundPrimary">
              <Typography variant="body/md" color="foregroundPrimary">
                {showRoomTitle ? product : DEFAULT_PRODUCT_COPY}
              </Typography>
              {!!roomTitle && formatRoomDescription(roomType)}
              {!showRoomTitle && (
                <>
                  <br />
                  <Typography variant="body/xs" color="foregroundSecondary">
                    {DEFAULT_ROOM_DESCRIPTION}
                  </Typography>
                </>
              )}
            </Typography>
          </Styled.ListItem>
          <Box>
            <Styled.ListItem>
              <Icon name="user--solid" color="foregroundPrimary" />
              <Typography
                variant="body/md"
                color="foregroundPrimary"
                data-testid="primary-guest-name"
              >{`${room.occupants[0].firstName} ${room.occupants[0].lastName}`}</Typography>
            </Styled.ListItem>
            {!!room.occupants[1] && (
              <Typography variant="body/xs" color="foregroundSecondary" paddingLeft={24}>
                Primary
              </Typography>
            )}
          </Box>
          {!!room.occupants[1] && (
            <Styled.ListItem>
              <Icon name="user--solid" color="foregroundPrimary" />
              <Typography
                variant="body/sm"
                color="foregroundPrimary"
              >{`${room.occupants[1].firstName} ${room.occupants[1].lastName}`}</Typography>
            </Styled.ListItem>
          )}
          {!!itinerary.clientProgramName && (
            <Styled.ListItem>
              <Icon name="circle-info" color="foregroundPrimary" />
              <Typography variant="body/sm" color="foregroundPrimary" data-testid="rate-type">
                {itinerary.clientProgramName}
              </Typography>
            </Styled.ListItem>
          )}
          <Divider variant="dotted" />
          {!!showDatesForEachRoom && <RoomBookingDetails itinerary={itinerary} roomIndex={i} />}
          <Styled.ListItem>
            <Typography variant="body/sm" color="foregroundSecondary">
              {room.propertyConfirmationNumber ? "Confirmation" : "Contract"} #
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary" style={{ textAlign: "right" }}>
              {room.propertyConfirmationNumber
                ? room.propertyConfirmationNumber
                : room.contractNumber}
            </Typography>
          </Styled.ListItem>
        </Styled.DetailsList>
      );
    });

  const dialogTrigger = (
    <Box marginBottom={16} marginTop={16} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="link/sm" style={{ cursor: "pointer" }}>
        {overflowCount} additional room{overflowCount > 1 ? "s" : ""}
      </Typography>
    </Box>
  );

  return (
    <CollapsibleContent
      toggleTestID="toggle-room-details"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Room details</Typography>
        </Box>
      }
      defaultOpen
    >
      <Styled.RoomDetails>
        {getRoomsList(false)}
        {roomCount > INIT_ROOMS_DISPLAYED && (
          <Styled.ShowRoomsContainer>
            <Button
              variant="outlined"
              size="lg"
              onClick={toggleShowAllRooms}
              style={{ width: "100%" }}
            >
              {showAllRooms ? "Hide full room list" : `Show all ${roomCount} rooms`}
            </Button>
            {Boolean(hasOverflowRooms) && Boolean(showAllRooms) && (
              <ResponsiveAtlasDialog
                triggerComponent={dialogTrigger}
                title={`Rooms (${rooms.length})`}
                content={<Box marginTop={24}>{getRoomsList(true)}</Box>}
                isModal
              />
            )}
          </Styled.ShowRoomsContainer>
        )}
      </Styled.RoomDetails>
    </CollapsibleContent>
  );
};
