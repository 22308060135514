import type { IPropertyLegacy } from "@hotel-engine/types/property";
import type { IUser } from "@hotel-engine/types/user";
import moment from "moment";

export const isFlexProEligibleBookingExpressBook = ({
  checkIn,
  checkOut,
  property,
  user,
  contractCancelBy,
}: {
  checkIn: string | null;
  checkOut: string | null;
  property: IPropertyLegacy | null;
  user: IUser | null;
  contractCancelBy?: string;
}) => {
  if (!checkIn || !checkOut || !property || !user) {
    return false;
  }

  const flexConfigurationNotBlocked = user?.business.flexConfiguration !== "blocked";

  if (
    !user?.business.flexAllowedBusiness ||
    !user?.business.flexAllowedUser ||
    !flexConfigurationNotBlocked
  )
    return false;

  const noonOnDayOfCheckIn = moment(checkIn)
    .tz(property?.timezone || "UTC")
    .set({ hour: 12, minute: 0, second: 0 });

  const cancelBy = contractCancelBy
    ? moment(contractCancelBy)
    : moment()
        .tz(property?.timezone || "UTC")
        .set({ hour: 12, minute: 0, second: 0 });

  let latestCancelDate: moment.Moment;

  // the last time to cancel whichever is later between 12pm on the day of check in or the room's cancellation policy
  if (noonOnDayOfCheckIn.isBefore(cancelBy)) {
    latestCancelDate = cancelBy;
  } else {
    latestCancelDate = noonOnDayOfCheckIn;
  }

  const numberOfNights = moment(checkOut, "MM-DD-YYYY").diff(moment(checkIn, "MM-DD-YYYY"), "days");

  const isAfterCancellationWindow = Boolean(
    moment()
      .tz(property?.timezone || "UTC")
      .isSameOrAfter(latestCancelDate) && numberOfNights === 1
  );

  return Boolean(!isAfterCancellationWindow);
};
